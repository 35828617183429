import React from "react";
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
} from "@material-ui/core";
import * as Yup from 'yup'
import { HighlightOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

const style: any = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    borderRadius: 14,
    boxShadow: 24,
    p: 3,
    overflow: "auto",
};

const RequestAccessViewModel = ({ open, handleClose ,getWorkExperience,workExperienceDetails}: any) => {
    const [data, setData] = React.useState();

    const contactUsSchema: any = Yup.object().shape({
        achievements: Yup.string().required('achievements is required.'),
    })

    const handleModelClose = (value:any) => {
        const header = {
            // "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };

        let formdata = new FormData();
        formdata.append("career_experience[add_key_achievements][]", value.achievements);
        //@ts-ignore
        // formdata.append("id", localStorage.getItem('account_id'))
        formdata.append("id", localStorage.getItem('profile_id'))

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_profile/career_experiences/add_achievement"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PATCH"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        handleClose()
        getWorkExperience()
        
      }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <HighlightOff
                        style={modalContactStyle.closeIcon}
                        onClick={handleClose}
                    />
                    <Typography style={modalContactStyle.contact_textContent}>
                    Request access to view
                    </Typography>
                    <Formik
                        initialValues={{ achievements: workExperienceDetails?.attributes?.add_key_achievements }}
                        validationSchema={contactUsSchema}
                        onSubmit={(values: any) => handleModelClose(values)
                        }
                    >
                        {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
                            <form onSubmit={handleSubmit}>
                                <Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box style={modalContactStyle.field}>
                                            
                                            <InputField
                                                error={errors.achievements && touched.achievements}
                                                testID="contactName"
                                                data-test-id="inputName"
                                                placeholder="Name"
                                                fullWidth={true}
                                                id="achievements"
                                                name="achievements"
                                                variant="outlined"
                                                value={values.achievements}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                             {errors.achievements && touched.achievements &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.achievements}
                                                </div>
                                            }
                                            
                                            <InputField
                                                error={errors.achievements && touched.achievements}
                                                testID="contactName"
                                                data-test-id=""
                                                placeholder="Email address"
                                                fullWidth={true}
                                                id="achievements"
                                                name="achievements"
                                                variant="outlined"
                                                value={values.emailAddress}
                                                onBlur={handleBlur}
                                            />
                                             {errors.achievements && touched.achievements &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.achievements}
                                                </div>
                                            }
                                        
                                            <InputField
                                                error={errors.achievements && touched.achievements}
                                                testID="contactName"
                                                data-test-id=""
                                                placeholder="Write a text"
                                                fullWidth={true}
                                                id="achievements"
                                                name="achievements"
                                                variant="outlined"
                                                value={values.text}
                                                onBlur={handleBlur}
                                                multiline
                                                rows={3}
                                            ></InputField>

                                            {errors.achievements && touched.achievements &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.achievements}
                                                </div>
                                            }
                                           
                                        </Box>
                                    </Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box
                                            style={modalContactStyle.submit}
                                        >
                                            <Button variant="contained" type="submit" style={modalContactStyle.submitButton}>
                                            Submit request
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </>
    );
}

export default RequestAccessViewModel;


const modalContactStyle: any = {
    contact_textContent: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px"
    },
    closeIcon: {
        cursor: "pointer",
        position: "absolute",
        right: "20px",
        top: "20px"
    },
    fieldError: {
        color: "#D11024",
        fontSize: "14px",
        margin: "-7px 5px 10px 0"
    },
    row: {
        display: "flex",
        gap: "24px"
    },
    name: {
        width: "426px"
    },
    field: {
        width: "500px",
        marginBottom: "10px"
    },


    submitButton: {
        height: "32px",
        backgroundColor: "#BE9E44",
        borderRadius: "8px",
        color: "#fff",
        width: "500px",
    },
    submit: {
        width: "500px",
        display: "flex",
    }

};

const InputField: any = withStyles({
    root: {
        padding: '10px 0',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue-medium",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)"
            }
        },
        "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D11024"
            }
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue-Medium",
        }
    }
})(TextField);