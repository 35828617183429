export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const hashtag = require("../assets/hashtag.png");
export const circle = require("../assets/circle.png");
export const moreOptions = require("../assets/moreOptions.png");
export const filter = require("../assets/filter.png");
export const report = require("../assets/report.png");
export const following = require("../assets/following.png");
export const connections = require("../assets/connections.png");
export const activeConnection = require("../assets/activeConnection.png");
export const profile1 = require("../assets/profile1.png");
export const profile2 = require("../assets/profile2.png");
export const profile3 = require("../assets/profile3.png");
export const profile4 = require("../assets/profile4.png");
export const downArrow = require("../assets/downArrow.png");
export const search = require("../assets/search.png");

