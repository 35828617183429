import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
 Grid,Menu,MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { bellTurnOff, circleLink, deleteIcon, personImg } from "./assets";
import  NavBarWithSearch  from "../../ElasticSearch/src/NavBarWithSearch.web";
import RightSection from "../../HashtagGenerator/src/RightSection.web";
import { moreOptions } from "../../HashtagGenerator/src/assets";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  newerNotifications=[0,1,2,3]
  olderNotifications=[0,1,2,3]
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavBarWithSearch  navigation={undefined} id={""} userAccountObj={undefined}/>
        <Container style={{marginTop:'25px'}}>
          <Grid container spacing={3} >
            <Grid item md={3}>
              <Box sx={{bgcolor:'background.paper',p:2,borderRadius:'10px'}}>
                <Typography variant="h5">Manage your<br/>Notifications</Typography>
                <Typography variant={"subtitle1"} color={'primary'} onClick={()=>this.props?.navigation?.navigate('Notification')}>Notification settings</Typography>


              </Box>

            </Grid>
            <Grid item md={6}>
              <Box sx={{bgcolor:'background.paper',borderRadius:'10px',border:'1px solid #F0F0F0',p:2}}>
                <Typography variant="h6">Newer</Typography>
                <hr style={{border:'0.5px solid #D7D7D7'}}/>
               {this.newerNotifications?.map(()=>{
                return  <div style={{display:'flex',justifyContent:'space-between',margin:'25px 0px'}}>
                  <div style={{display:'flex'}}>

                <div style={{display:'flex',alignItems:'center',marginRight:'10px'}}>
                <img src={circleLink} width={'8px'} height={'8px'}/>
                </div>
                 <div>
                   <img src={personImg} style={{borderRadius:'6px',border:'1px solid #D4D4D4'}} width={'60px'} height={'60px'}/>
                 </div>
                

                 <Typography variant="h6">Steven joy published a post: Experience is more than a number Inexperienced companies measure experience solely*O in years. Experience is better be measured by - Quality…</Typography>
                  </div>
                
                 <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-evenly'}}>
                   <div style={{fontSize:'14px'}}>2h</div>
                   <div style={{cursor:'pointer'}} onClick={(e:any)=>this.setState({notificationEl:e.currentTarget })}>
                   <img src={moreOptions} style={{transform:'rotate(-90deg)'}} width='24px'/>
                   </div>
                   <Menu 
                   anchorEl={this.state?.notificationEl}
                   open={Boolean(this.state?.notificationEl)}
                   onClose={() => this.setState({ notificationEl: null })}
                   PaperProps={{ style: { boxShadow: "0px 0px 2px rgba(156, 156, 156, 0.169471)", border: '2px solid #F0F0F0', borderRadius: '12px 0px 12px 12px' } }}
                   anchorPosition={{ top: 50, left: 20 }}
                   transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                   >
                   <MenuItem  style={{ padding: '15px' }}>
                    <div style={{display:'flex'}}>
                        <div style={{ marginRight: '15px' }}><img src={deleteIcon} width="24px" /></div>
                        <div>
                          <div style={{color:'#1C1C1C',fontSize:'12px'}}>Delete</div>
                          <div style={{color:'#696868',fontSize:'10px'}}>Delete this notification</div>
                        </div>

                    </div>
                    </MenuItem>
                    <MenuItem  style={{ padding: '15px' }}>
                    <div style={{display:'flex'}}>
                        <div style={{ marginRight: '15px' }}><img src={bellTurnOff} width="24px" /></div>
                        <div>
                          <div style={{color:'#1C1C1C',fontSize:'12px'}}>Turn off </div>
                          <div style={{color:'#696868',fontSize:'10px'}}>Turn off notification from this</div>
                        </div>

                    </div>
                    </MenuItem>
                   </Menu>
                   
                 </div>

               </div>
               })}
                <Typography variant="h6">Older</Typography>
                <hr style={{border:'0.5px solid #D7D7D7'}}/>
                {this.olderNotifications?.map(()=>{
                return  <div style={{display:'flex',justifyContent:'space-between',margin:'25px 0px'}}>
                  <div style={{display:'flex'}}>

                <div style={{display:'flex',alignItems:'center',marginRight:'10px'}}>
                <img src={circleLink} width={'8px'} height={'8px'}/>
                </div>
                 <div>
                   <img src={personImg} style={{borderRadius:'6px',border:'1px solid #D4D4D4'}} width={'60px'} height={'60px'}/>
                 </div>
                

                 <Typography variant="h6">Abcd school of arts and design viewed your ED portfolio.</Typography>
                  </div>
                
                 <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-evenly'}}>
                   <div style={{fontSize:'14px'}}>2h</div>
                   <div>
                   <img src={moreOptions} style={{transform:'rotate(-90deg)'}} width='24px'/>
                   </div>
                 </div>

               </div>
               })}
               

              </Box>

            </Grid>
            <Grid item md={3}>
              <RightSection/>

            </Grid>

          </Grid>
          
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#BE9E44",
      contrastText: "#BE9E44",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize:'18px'
    },
    h6: {
      fontWeight: 500,
      fontSize:'14px',
      color:'#1C1C1C',
      margin:'0px 15px',
      display:'flex',
      alignItems:'center'
    },
    subtitle1: {
      fontWeight: 500,
      fontSize:'16px',
      marginTop: "10px",
      cursor:'pointer'
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};
// Customizable Area End
