import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Tab, Tabs, Grid, Paper, Button, Modal, InputLabel, TextField, CircularProgress } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { CloseIcon, EditIcon, CoverImage, collegeImage } from "./assets";
import { Formik } from 'formik';
import * as Yup from 'yup'; 

const theme = createTheme({
    palette: {
        primary: {
            main: "#be9e44",
            contrastText: "#fff",
        },
    },
});
const modalStyle: any = {
    position: "absolute",
    top: "49%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 920,
    maxHeight: "700px",
    bgcolor: "#fff",
    borderRadius: 12,
    boxShadow: 24,
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    "@media (max-width: 1092px)": {
        padding: "20px",
        width: "75%"
    }
};

const removeCoverImageModalStyle: any = {
    position: "absolute",
    top: "49%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '450px',
    maxWidth: "100%",
    maxHeight: "700px",
    bgcolor: "#fff",
    borderRadius: 12,
    boxShadow: 24,
    padding: "0",
    overflowY: "auto",
    "@media (max-width: 500px)": {
        padding: "20px",
        width: "75%"
    }
};
import TimePicker from 'rc-time-picker';
import CompanyInfo from "../../../components/src/CompanyInfo";
import NavBarWithSearch from "../../ElasticSearch/src/NavBarWithSearch.web";
import EducationalUserProfileController, {
    Props,
} from "./EducationalUserProfileController";
import HomeTab from "./HomeTab.web";
import PostsTab from "./PostsTab.web";
import AlumniTab from "./AlumniTab.web";
import AppFooter from "../../../components/src/AppFooter.web";
// Customizable Area End

export class AcademicOrganisationProfile extends EducationalUserProfileController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        const profileDetailSchema = Yup.object().shape({
            name_of_organisation: Yup.string().required("Name of organisation is required."),
            address: Yup.string().required("Street name is required."),
            postal_code: Yup
                .string()
                .required("Postal code is required.")
                .matches(/^\d*$/, "Please enter valid postal code."),
            city: Yup.string().required("City is required."),
            country: Yup.string().required("Country is required."),
            opening_hour: Yup.string().required("Opening Hours is required."),
            closing_hour: Yup.string().required("Closing Hours is required."),
          }) 

        return (
            <ThemeProvider theme={theme}>
                <Container style={webStyle.container}>
                <NavBarWithSearch navigation={this.props.navigation} id={''} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9}>
                            <CustomPaper style={webStyle.userDetails}>
                                <Box style={webStyle.coverImage}>
                                    <img src={this.checkCoverImage()} style={{ width: "100%", height: "100%", borderTopLeftRadius: '10px', borderTopRightRadius: '10px'  }} alt="cover image" />
                                    <div style={webStyle.profileImage}>
                                        <img src={this.checkProfileImage()} style={this.state.orgProfileDetails?.profile_image === null ? webStyle.defaultImage : webStyle.selectedImage} alt="profile picture" />
                                    </div>
                                    {this.checkEditIcon()}
                                </Box>
                                <Box className={classes.userDetail}>
                                    {this.state.isLoading ? <CircularProgress color="primary" /> : <Box>
                                        <CustomTypography style={webStyle.orgName}>{this.state.orgProfileDetails?.name_of_organisation}</CustomTypography>
                                        <CustomTypography style={webStyle.orgAddress}>{this.state.orgProfileDetails?.address}, {this.state.orgProfileDetails?.city}, {this.state.orgProfileDetails?.country}, {this.state.orgProfileDetails?.postal_code}</CustomTypography>
                                        <CustomTypography style={webStyle.orgHours}>Opening hours: {new Date(this.state.orgProfileDetails?.opening_hour).toLocaleTimeString() + " to " + new Date(this.state.orgProfileDetails?.closing_hour).toLocaleTimeString()}</CustomTypography>
                                        <CustomTypography style={webStyle.orgFollowers}>{this.state.followersCount <= 1 ? this.state.followersCount + " Follower" : this.state.followersCount + " Followers"}</CustomTypography>
                                        {this.checkFollowButton()}
                                    </Box>}
                                    <Box style={webStyle.smallProfilePicture}>
                                        <img src={this.checkProfileImage()} style={this.state.orgProfileDetails?.profile_image === null ? webStyle.orgImage : webStyle.orgSmallImage} alt="profile picture" />
                                    </Box>
                                    {this.checkProfileEditIcon()}
                                </Box>
                                <hr style={{ margin: "0", borderTop: "0.03rem solid #d7d7d7" }} />
                                <CustomTabs
                                    value={this.state.selectedTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={this.handleTabChange}
                                    aria-label="disabled tabs example"
                                >
                                    <CustomTab label="Home" />
                                    <CustomTab label="Posts" />
                                    <CustomTab label="Alumni" id="alumniTabId"/>
                                </CustomTabs>
                            </CustomPaper>
                            {this.checkTabs()}
                        </Grid>
                        <Grid item xs={3} className={classes.footer}>
                            <CompanyInfo/>
                        </Grid>
                    </Grid>
                </Container>
                <AppFooter />
                <Modal  //modal for cover photo update
                    open={this.state.coverImagePopup}
                    onClose={this.handleCoverImagePopupClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <img
                            src={CloseIcon}
                            className={classes.closeIcon}
                            onClick={this.handleCoverImagePopupClose}
                        />
                        <Typography className={classes.coverTitle}>
                            {this.addBgPhotoText}
                        </Typography>
                        {this.newSelectCoverPhoto(classes)}
                    </Box>
                </Modal>

                <Modal  //modal for remove cover image
                    open={this.state.removeCoverImagePopup}
                    onClose={this.handleRemoveCoverImagePopupClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={removeCoverImageModalStyle}>
                        <img
                            src={CloseIcon}
                            className={classes.removeCoverImageCloseIcon}
                            onClick={this.handleRemoveCoverImagePopupClose}
                        />
                        <CustomTypography className={classes.removeCoverModalTitle}>
                            {this.removeCoverPhotoText}
                        </CustomTypography>
                        <hr />
                        <CustomTypography className={classes.removeCoverImagecoverTitle}>
                            {this.removeCoverConfirmText}
                        </CustomTypography>
                        <hr />
                        <div style={{ display: 'flex', justifyContent: "end", alignItems: "center", padding: "10px 24px 15px 0" }}>
                            <Button className={classes.cancelBtn} onClick={() => { this.handleRemoveCoverImagePopupClose() }}>Cancel</Button>
                            <Button className={classes.removeBtn} onClick={() => { this.setState({ coverImagePopup: false, removeCoverImagePopup: false, coverImageState: null, selectedCoverFile: null }, () => this.updateProfileDetails({ updateCoverPhoto: true })) }}>Remove</Button>
                        </div>
                    </Box>
                </Modal>
               
                <Modal  //modal for remove profile image
                    open={this.state.removeProfileImagePopup}
                    onClose={this.handleRemoveProfileImagePopupClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={removeCoverImageModalStyle}>
                        <img
                            src={CloseIcon}
                            className={classes.removeCoverImageCloseIcon}
                            onClick={this.handleRemoveProfileImagePopupClose}
                        />
                        <CustomTypography className={classes.removeCoverModalTitle}>
                            {this.removeProfilePhotoText}
                        </CustomTypography>
                        <hr />
                        <CustomTypography className={classes.removeCoverImagecoverTitle}>
                            {this.removeProfileConfirmText}
                        </CustomTypography>
                        <hr />
                        <div style={{ display: 'flex', justifyContent: "end", alignItems: "center", padding: "10px 24px 15px 0" }}>
                            <Button className={classes.cancelBtn} onClick={() => { this.handleRemoveProfileImagePopupClose() }}>Cancel</Button>
                            <Button className={classes.removeBtn} onClick={() => { this.setState({ coverImagePopup: false, removeProfileImagePopup: false, profileImageState: null, selectedProfileFile: null }, () => this.updateProfileDetails({ updateProfilePhoto: true })) }}>Remove</Button>
                        </div>
                    </Box>
                </Modal>

                <Modal
                    open={this.state.profileDetailsPopup}
                    onClose={this.handleProfileDetailsPopupClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    id="modal-content"
                >
                    <Box sx={modalStyle} id="modal-content">
                        <img
                            src={CloseIcon}
                            className={classes.closeIcon}
                            onClick={this.handleProfileDetailsPopupClose}
                        />
                        <Typography className={classes.coverTitle}>
                            {this.addProfilePhotoText}
                        </Typography>
                       {this.newSelectProfilePhoto(classes)}
                        <Typography className={classes.coverSubtitle1}>
                            {this.addProfilePhotoSubText}
                        </Typography>
                        <Typography className={classes.coverSubtitle2}>
                            {this.addProfilePhotoSubText2}
                        </Typography>
                        <Formik
                            initialValues={this.state.initialProfileDetails}
                            validationSchema={profileDetailSchema}
                            onSubmit={(values: any) => { this.updateProfileDetails(values) }}
                            data-test-id="profileFormik"
                        >
                            {({ errors, values, touched, handleSubmit, handleChange, handleBlur, setFieldValue }: any) => (
                                <form onSubmit={handleSubmit} data-test-id="profileForm">
                                    <Grid container spacing={2} id="modal-content">
                                        <Grid item xs={12}>
                                            <InputLabel style={webStyle.fieldLabel}>{this.nameOfOrgLabel} <span>*</span></InputLabel>
                                            <InputField
                                                error={this.checkError(errors.name_of_organisation, touched.name_of_organisation)}
                                                id="name_of_organisation"
                                                nmae="name_of_organisation"
                                                fullWidth={true}
                                                placeholder="Name of organization"
                                                variant="outlined"
                                                value={values.name_of_organisation}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {this.finalErrorMessage(errors.name_of_organisation, touched.name_of_organisation)}
                                        </Grid>

                                        <Grid item xs={6} style={webStyle.fieldSet}>
                                            <InputLabel style={webStyle.fieldLabel}>{this.streetNameLabel} <span>*</span></InputLabel>
                                            <InputField
                                                error={this.checkError(errors.address, touched.address)}
                                                id="address"
                                                name="address"
                                                fullWidth={true}
                                                placeholder="Street name"
                                                variant="outlined"
                                                value={values.address}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {this.finalErrorMessage(errors.address, touched.address)}
                                        </Grid>
                                        <Grid item xs={6} style={webStyle.fieldSet}>
                                            <InputLabel style={webStyle.fieldLabel}>{this.postalCodeLabel} <span>*</span></InputLabel>
                                            <InputField
                                                error={this.checkError(errors.postal_code, touched.postal_code)}
                                                id="postal_code"
                                                name="postal_code"
                                                fullWidth={true}
                                                placeholder="Pincode"
                                                variant="outlined"
                                                value={values.postal_code}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {this.finalErrorMessage(errors.postal_code, touched.postal_code)}
                                        </Grid>

                                        <Grid item xs={6} style={webStyle.fieldSet}>
                                            <InputLabel style={webStyle.fieldLabel}>{this.cityLabel}<span>*</span></InputLabel>
                                            <InputField
                                                error={this.checkError(errors.city, touched.city)}
                                                id="city"
                                                name="city"
                                                fullWidth={true}
                                                placeholder="City"
                                                variant="outlined"
                                                value={values.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {this.finalErrorMessage(errors.city, touched.city)}
                                        </Grid>
                                        <Grid item xs={6} style={webStyle.fieldSet}>
                                            <InputLabel style={webStyle.fieldLabel}>{this.countryLabel}<span>*</span></InputLabel>
                                            <InputField
                                                error={this.checkError(errors.country, touched.country)}
                                                id="country"
                                                name="country"
                                                fullWidth={true}
                                                placeholder="Country"
                                                variant="outlined"
                                                value={values.country}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {this.finalErrorMessage(errors.country, touched.country)}
                                        </Grid>

                                        <Grid item xs={6} style={webStyle.fieldSet} className="openTimePicker">
                                            <InputLabel style={webStyle.fieldLabel}>{this.openingHoursLabel} <span>*</span></InputLabel>
                                            <TimePicker
                                                id="opening_hour"
                                                name="opening_hour"
                                                showSecond={false}
                                                placeholder="00:00 AM"
                                                format={"h:mm A"}
                                                use12Hours
                                                className={this.checkOpeningErrorClass(touched.opening_hour && errors.opening_hour)}
                                                defaultValue={values.opening_hour}
                                                onChange={(value: any) => setFieldValue('opening_hour', value.format("h:mm a"))}
                                                data-test-id="opening_hour"
                                                //@ts-ignore
                                                popupStyle={{ zIndex: 99999 }}
                                                placement="bottomLeft"
                                            />
                                            {this.finalErrorMessage(errors.opening_hour, touched.opening_hour)}
                                        </Grid>
                                        <Grid item xs={6} style={webStyle.fieldSet}>
                                            <InputLabel style={webStyle.fieldLabel}>{this.closingHoursLabel} <span>*</span></InputLabel>
                                            <TimePicker
                                                id="closing_hour"
                                                name="closing_hour"
                                                showSecond={false}
                                                placeholder="00:00 PM"
                                                format={"h:mm A"}
                                                use12Hours
                                                className={this.checkClosingErrorClass(touched.closing_hour && errors.closing_hour)}
                                                defaultValue={values.closing_hour}
                                                onChange={(value: any) => setFieldValue('closing_hour', value.format("h:mm a"))}
                                                data-test-id="closing_hour"
                                                //@ts-ignore
                                                popupStyle={{ zIndex: 99999 }}
                                                placement="bottomLeft"
                                            />
                                            {this.finalErrorMessage(errors.closing_hour, touched.closing_hour)}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <hr style={{ borderTop: "0.03rem solid #d7d7d7", width: "99%" }} />
                                            <Box style={webStyle.uploadSection}>
                                                <Button
                                                    type="submit"
                                                    style={webStyle.uploadBtn}
                                                >
                                                    {this.saveBtnText}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Modal>
            </ThemeProvider>
        );
        // Customizable Area End
    }
    // Customizable Area Start
    finalErrorMessage(error: any, touch: any) {
        if (error) {
            if (touch) {
                return <div style={webStyle.fieldError}>
                    {error}
                </div>
            }
            else {
                return null
            }
        }
        else {
            return;
        }
    }
    
    checkError(error: any, touch: any) {
        if (error && touch) {
            return true
        }
    }

    defaultCoverPhoto(classes: any) {
        return (<>
            <div style={{ position: 'relative', margin: "36px 0 12px" }}>
                <img src={this.state.selectedCoverImage} className={classes.coverPhoto} alt="cover_photo" />
            </div>
            <Typography className={classes.coverSubtitle1}>
                {this.addCoverPhotoSubText}
            </Typography>
            <Typography className={classes.coverSubtitle2}>
                {this.addCoverPhotoSubText2}
            </Typography>
            <hr style={{ borderTop: "0.03rem solid #d7d7d7", width: "99%" }} />
            <Box style={webStyle.uploadSection}>
                <label htmlFor="contained-button-file">
                <Button variant="contained" component="span" style={webStyle.uploadBtn}>
                    {this.uploadBgPhotoBtnText}
                    <input
                        accept="image/*"
                        style={webStyle.selectProfile}
                        id="contained-button-file"
                        // multiple
                        type="file"
                        onChange={this.handleCoverPhotoUpload}
                    />
                </Button>
                </label>
            </Box>
        </>
        )
    }

    selectedCoverPhoto(classes:any) {
        return (<>
            <div style={{position: 'relative', margin: "36px 0 12px"}}>
                <img src={this.state.coverImageState === "uploaded" ? this.state.selectedCoverImage : this.state.orgProfileDetails.photo} className={classes.coverPhoto} alt="cover_photo" />
                <img
                    src={CloseIcon}
                    className={classes.removeCoverImageClose}
                    onClick={this.handleRemoveCoverImagePopupOpen}
                />
            </div>
            <Typography className={classes.coverSubtitle1}>
                Add a cover photo for your profile.
            </Typography>
            <Typography className={classes.coverSubtitle2}>
                A good background photo will help you stand out.
            </Typography>
            <hr style={{ borderTop: "0.03rem solid #d7d7d7", width: "99%" }} />
            <Box style={webStyle.uploadSection}>
                <label htmlFor="contained-button-file">
                    <Button variant="contained" component="span" style={webStyle.uploadBtn}>
                        {this.changePhotoBtnText}
                        <input
                            accept="image/*"
                            style={webStyle.selectProfile}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={this.handleCoverPhotoUpload}
                        />
                    </Button>
                </label>
                <Button style={webStyle.uploadBtn} data-test-id="updateCoverPhoto" onClick={() => {this.updateProfileDetails()}}>Save</Button>
            </Box>
        </>
        )
    }

    defaultProfilePicture(classes: any) {
        return (<>
            <div style={{ position: 'relative', margin: "36px 0 12px" }}>
                <img src={this.state.selectedProfileImage} className={classes.coverPhoto} alt="profile_photo" />
            </div>
            <label htmlFor="contained-button-file">
                <Button variant="contained" component="span" style={{ ...webStyle.uploadBtn, marginTop: "15px", marginBottom: "10px" }}>
                    {this.uploadProfilePhotoBtnText}
                    <input
                        accept="image/*"
                        style={webStyle.selectProfile}
                        id="contained-button-file"
                        // multiple
                        type="file"
                        onChange={this.handleProfilePhotoUpload}
                    />
                </Button>
            </label>
        </>
        )
    }

    selectedProfilePicture(classes: any) {
        return (<>
            <div style={{ position: 'relative', margin: "36px 0 12px" }}>
                <img src={this.state.profileImageState=='uploaded'?this.state.selectedProfileImage: this.state.orgProfileDetails.profile_image} className={classes.coverPhoto} alt="profile_photo" />
                <img
                    src={CloseIcon}
                    className={classes.removeCoverImageClose}
                    onClick={this.handleRemoveProfileImagePopupOpen}
                />
            </div>
            <label htmlFor="contained-button-file">
                <Button variant="contained" component="span" style={{ ...webStyle.uploadBtn, marginTop: "15px", marginBottom: "10px" }}>
                    {this.changeBtnText}
                    <input
                        accept="image/*"
                        style={webStyle.selectProfile}
                        id="contained-button-file"
                        // multiple
                        type="file"
                        onChange={this.handleProfilePhotoUpload}
                    />
                </Button>
            </label>
        </>
        )
    }

    checkEditIcon() {
        if(this.state.isOwner) {
            return <img src={EditIcon} style={webStyle.coverImageEditIcon} alt="edit icon" onClick={this.handleCoverImagePopupOpen} />
        }
    }
    
    checkFollowButton() {
        if(!this.state.isOwner) {
            return <Button style={webStyle.followBtn} onClick={() => {this.state.isFollow ? this.unfollowOrganisation() : this.followOrganization()}}>{this.state.isFollow ? "Following" : "Follow" }</Button>
        }
    }
    
    checkProfileEditIcon() {
        if(this.state.isOwner) {
            return <img src={EditIcon} style={webStyle.userDetailsEditIcon} alt="edit icon" data-test-id="profileEditIcon" onClick={() => { this.setState({ profileDetailsPopup: true }) }} />
        }
    }

    checkTabs() {
        if(this.state.selectedTab === 0){
            return <HomeTab navigation={this.props.navigation} id={""} />
        }
        else if(this.state.selectedTab === 1){
            return <PostsTab navigation={this.props.navigation} id={""} classes={undefined} />
        }
        else if(this.state.selectedTab === 2) {
            return <AlumniTab navigation={this.props.navigation} id={""} />
        }
    }


    checkOpeningErrorClass(classCondition:any) {
        if(classCondition){
            return "errorHourProfile"
        }
        else {
            return "openingHourProfile"
        }
    }

    checkClosingErrorClass(classCondition:any) {
        if(classCondition){
            return "errorHourProfile"
        }
        else {
            return "closingHourProfile"
        }
    }

    checkCoverImage() {
        if(this.state.orgProfileDetails?.photo === null){
            return CoverImage
        }
        else {
            return this.state.orgProfileDetails?.photo
        }
    }

    checkProfileImage() {
        if(this.state.orgProfileDetails?.profile_image === null){
            return collegeImage
        }
        else {
            return this.state.orgProfileDetails?.profile_image
        }
    }

    newSelectCoverPhoto(classes: any) {
        if (this.state.orgProfileDetails?.photo || this.state.selectedCoverFile) {
            return this.selectedCoverPhoto(classes)
        }
        else {
            return this.defaultCoverPhoto(classes)
        }
    }

    newSelectProfilePhoto(classes: any) {
        if (this.state.orgProfileDetails.profile_image || this.state.selectedProfileFile) {
            return this.selectedProfilePicture(classes)
        }
        else {
            return this.defaultProfilePicture(classes)
        }
    }
    // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
    container: {
        minHeight: "calc(100vh - 270px)"
    },
    userDetails: {
        padding: 0
    },
    coverImage: {
        height: "195px",
        position: "relative",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
        borderBottom: "1px solid #7c7c7c"
    },
    profileImage: {
        width: "154px",
        height: "154px",
        position: "absolute",
        bottom: "-34px",
        left: "23px",
        borderRadius: "50%",
        border: '4px solid #fff',
        background: '#e9e9e9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    defaultImage: {
        width: "60px",
        height: "60px"
    },
    selectedImage: {
        width: "100%",
        height: "100%",
        borderRadius: '50%'
    },
    coverImageEditIcon: {
        width: "32px",
        height: "32px",
        position: "absolute",
        right: "23px",
        top: "12px",
        cursor: "pointer"
    },
    userDetailsEditIcon: {
        width: "32px",
        height: "32px",
        position: "absolute",
        right: "23px",
        top: "12px",
        cursor: "pointer"
    },
    smallProfilePicture: {
        width: '40px',
        height: "40px",
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        backgroundColor: '#e9e9e9',
        borderRadius: "50%"
    },
    orgImage: {
        width: "20px",
        height: "20px",
    },
    orgSmallImage: {
        width: "40px",
        height: "40px",
        borderRadius: '50%'
    },
    orgName: {
        fontSize: "24px",
        color: "#be9e44"
    },
    orgAddress: {
        fontSize: "17px",
        color: "#7c7c7c"
    },
    orgHours: {
        fontSize: "17px",
        color: "#565656"
    },
    orgFollowers: {
        fontSize: "17px",
        color: "#ab9045"
    },
    followBtn: {
        marginTop: "5px",
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "4px",
        textTransform: "capitalize"
    },
    footerMenus: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: "15px"
    },
    footerContent: {
        fontSize: "10px",
        color: "#7c7c7c",
        marginTop: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    contact_textContent: {
        textAlign: "center"
    },
    uploadSection: {
        width: "100%",
        display: "flex",
        justifyContent: "end"
    },
    uploadBtn: {
        marginTop: "5px",
        marginLeft: "10px",
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "4px",
        height: "38px",
        textTransform: 'initial'
    },
    fieldLabel: {
        marginBottom: "2px",
        color: "#1c1c1c",
        fontSize: "14px"
    },
    fieldSet: {
        padding: "12px 8px"
    },
    fieldError: {
        color: "#D11024",
        fontSize: "14px",
        margin: "5px 5px 5px 0"
    },
    selectProfile: {
        display: "none" 
    },
};

const styles: any = () => ({
    footer: {
        "@media (max-width: 959px)": {
            display: "none"
        }
    },
    footerMenu: {
        fontSize: "10px",
        color: "#7c7c7c",
        margin: "5px 35px",
        cursor: "pointer",
        "@media (max-width: 1200px)": {
            margin: "5px 25px",
        }
    },
    userDetail: {
        display: "flex",
        justifyContent: "space-between",
        padding: "55px 80px 26px 24px",
        position: "relative",
        "@media (max-width: 576px)": {
            padding: "50px 20px 15px",
        }
    },
    closeIcon: {
        position: "absolute",
        right: "24px",
        top: "24px",
        width: "24px",
        height: "24px",
        cursor: "pointer"
    },
    coverPhoto: {
        width: "383px",
        height: '216px'
    },
    coverTitle: {
        fontSize: "20px",
        color: "#1c1c1c",
        textAlign: "center"
    },
    coverSubtitle1: {
        fontSize: "16px",
        color: "#1c1c1c",
        textAlign: "center",
        marginBottom: "12px"
    },
    coverSubtitle2: {
        fontSize: "14px",
        color: "#696868",
        textAlign: "center",
        marginBottom: "12px"
    },
    removeCoverImageCloseIcon: {
        position: "absolute",
        right: "24px",
        top: "24px",
        width: "24px",
        height: "24px",
        cursor: "pointer"
    },
    removeCoverImageClose: {
        position: "absolute",
        right: "-11px",
        top: "-12px",
        width: "22px",
        height: "22px",
        cursor: "pointer",
        background: "#fff",
        border: '1px solid black',
        borderRadius: "50%"
    },
    removeCoverModalTitle: {
        padding: '20px 0 5px 24px',
        fontSize: "18px",
        fontWeight: "600",
    },
    removeCoverImagecoverTitle: {
        padding: "8px 24px"
    },
    cancelBtn: {
        width: "100px",
        color: "#be9e44",
        background: "#f8f8f8",
        borderRadius: "4px",
        textTransform: "capitalize",
        border: '1px solid #be9e44',
        marginRight: '5px'
    },
    removeBtn: {
        width: "100px",
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "4px",
        textTransform: "capitalize",
        marginLeft: '5px',
        fontSize: "14px",
        "&:hover": {
            color: "#f8f8f8",
            background: "#be9e44",
        },
    },
})

const CustomPaper: any = withStyles({
    root: {
        boxShadow: "unset",
        margin: "15px 0",
        border: "1px solid #e2e2e2",
        padding: "15px",
        borderRadius: "10px"
    }
})(Paper);

const CustomTabs: any = withStyles({
    root: {
        textTransform: "capitalize"
    }
})(Tabs);

const CustomTab: any = withStyles({
    root: {
        textTransform: "capitalize",
        minWidth: "65px",
        margin: "0 15px"
    }
})(Tab);

const CustomTypography: any = withStyles({
    root: {
        color: "#1c1c1c"
    },
})(Typography);

const InputField: any = withStyles({
    root: {
        width: '100%',
        paddingTop: '10px',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        },
        "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D11024"
            }
        },
    }
})(TextField);

export default withStyles(styles)(AcademicOrganisationProfile)

// Customizable Area End
