// Customizable Area Start
import React, { Component } from 'react'
import { edConnectLogo } from '../../educational-user-profile/src/assets'

export default class RightSection extends Component {
    render() {
        return (
            <div data-test-id="rightSection" >

                <div style={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}>
                    <div style={webStyles.footer}>About Us</div>
                    <div style={webStyles.footer}>Privacy Policy</div>
                    <div style={webStyles.footer}>Contact Us</div>

                </div>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0px' }}>
                    <div style={webStyles.footer}>Terms & Conditions</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '10px 0px' }}>
                    <img src={edConnectLogo} width="28px" />
                    <div style={webStyles.footer}>Copyright @2023 EDConnect. All rights reserved</div>

                </div>
            </div>
        )
    }
}

const webStyles = {
    
    footer: {
        fontSize: '10px',
        color: '#7C7C7C',
        fontFamily: "HelveticaNeue"
    }
}
// Customizable Area End