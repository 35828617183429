// Customizable Area Start
import React from 'react'
import { Container, Grid, Box, } from "@material-ui/core";
import Loader from '../../../components/src/Loader';

import OrganisationList from '../../../components/src/OrganizationList.web';
import StudentList from '../../../components/src/StudentList.web';
import PostList from '../../../components/src/PostList.web';
import SearchResultController from './SearchResultController.web';
import NavBarWithSearch from '../../ElasticSearch/src/NavBarWithSearch.web';


interface Props {
    navigation: any;

}
export default class SearchResult extends SearchResultController<Props> {
    constructor(props: Props) {
        super(props);
      }

    render() {

        return (
            <>
                <Loader loading={this.state?.isLoading} />
                <NavBarWithSearch navigation={this.props.navigation} id={''}/>
                <Container style={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                        <Grid item md={3}>
                            <Box sx={{ bgcolor: 'background.paper', width: '238px', height: "153px", borderRadius: '10px', border: '1px solid #F0F0F0', position: 'fixed', padding: '15px' }}>
                                <div style={webStyles.heading}>On this page</div>
                                <div style={webStyles.textCommonStyle}>Acadmic Organization</div>
                                <div style={webStyles.textCommonStyle}>Student</div>
                                <div style={webStyles.textCommonStyle}>Post</div>
                            </Box>
                        </Grid>
                        <Grid item md={6} >
                            {/* Feeds component */}
                            <PostList postData={this.state.PostData?.slice(0, 2)} isLoad={this.state.isLoading} />
                            <MoreButton title='See all post results' navigation={this.props.navigation} endpoint="" />
                            <OrganisationList organizationData={this.state.PostData?.slice(0, 2)} isLoad={this.state.isLoading} />
                            <MoreButton title='See all academic organisations' navigation={this.props.navigation} endpoint="" />
                            <StudentList studentsData={this.state.PostData?.slice(0, 2)} isLoad={this.state.isLoading} />
                            <MoreButton title='See all students results' navigation={this.props.navigation} endpoint="" />
                        </Grid>
                        <Grid item md={3}>
                            {/* <Messaging /> */}
                        </Grid>

                    </Grid>

                </Container>



            </>
        )
    }
}

const webStyles = {
    newPost: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sendBox: {
        width: '32px',
        height: '32px',
        backgroundColor: '#BE9E44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    heading: {
        fontSize: '14px',
        lineHeight: '14px',
        fontWeight: 400,
        color: '#1C1C1C',
        padding: '10px 0px',
        fontFamily: "HelveticaNeue",


    }
    ,
    feedsFont: {
        fontSize: '14px',
        fontFamily: "HelveticaNeue",
        color: '#565656',
        lineHight: "14.18px",
        fontStyle: 'italic',
    },
    actionLIne: {
        display: 'flex',
        margin: '5px 0px'
    },
    actionText: {
        fontSize: '16px',
        color: '#696868',
        marginLeft: '10px',
        fontFamily: 'HelveticaNeue'
    },
    textCommonStyle: {
        color: '#7C7C7C',
        fontSize: '12px',
        marginBottom: '5px',
        fontFamily: "HelveticaNeue"
    },


}
export function MoreButton({ title, navigation, endpoint }: { title: string, navigation: any, endpoint: string }) {
    return (
        <Box id="moreButton1" onClick={() => navigation.navigate(endpoint)} sx={{ display: 'flex', justifyContent: "center", alignItems: "center", bgcolor: 'background.paper', p: 2, margin: "4px 0 10px 0", borderRadius: '0 0 10px 10px', border: '1px solid #F0F0F0' }}>
            <div style={{ cursor: "pointer", color: "#BE9E44", fontSize: "16px", textAlign: "center", fontFamily: "HelveticaNeue" }}>{title}</div>
        </Box>
    )
}
// Customizable Area End
