// Customizable Area Start
import React from 'react'
import UserProfileBasicController from './UserProfileBasicController'
import { Grid, Box,Container } from "@material-ui/core";
import AppFooter from '../../../components/src/AppFooter.web';
import LeftMenu from './LeftMenu.web';
import SettingsHeader from './SettingsHeader.web';
import {  secure, notification, sub, lightAcc, darkSub } from './assets';
export default class ManageSubscription extends UserProfileBasicController {
    tabs = [{ 
        img: lightAcc, tab: 'Account preferences',path:'settings',isActive:false }, 
        { img: secure, tab: 'Sign in & security',path:'SignInSecurity',isActive:false }, 
        { img: notification, tab: 'Notifications',path:'Notification',isActive:false }, 
        { img: sub,darkIcon:darkSub, tab: 'Manage my subscriptions',isActive:true }]
    render() {
        return (
            <>
                <SettingsHeader navigation={this.props?.navigation} id={''} />
                <Container style={{ marginTop: '25px' }}>
                    <Grid container spacing={3}>
                        <Grid item md={3}>
                            <LeftMenu navigation={this.props?.navigation} id={''} tabs={this.tabs} />

                        </Grid>
                        <Grid item md={8}>
                            <Box data-test-id="subBox" sx={{ bgcolor: 'background.paper', height: '800px', p: 2, borderRadius: '10px', marginBottom: '100px' }}>
                                <div style={{ fontSize: '18px', fontFamily: "HelveticaNeue" }}>
                                    Subscriptions
                                </div>
                                <div style={{ fontFamily: "HelveticaNeue", margin: '20px 0px' }}>
                                    You are using Basic (Free) subscription of <span style={{ color: 'B4A065', fontWeight: 'bold' }}>EDConnect</span> which includes limited features.
                                </div>
                                <div style={{ color: '#BE9E44', fontFamily: "HelveticaNeue", fontSize: "14px" }}>Services included in the plan</div>

                                <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <li><div style={webStyles?.lists}>All free Services on EDConnect Platform</div></li>
                                    <li><div style={webStyles?.lists}>Give Recommendations</div></li>
                                    <li><div style={webStyles?.lists}>Browse all EDConnect Users</div></li>
                                    <li><div style={{
                                        marginRight: '40px',
                                        fontSize: '14px',
                                        fontFamily: "HelveticaNeue", marginTop: '20px'
                                    }}>Receive and respond to messages</div></li>
                                </ul>
                                <hr style={{ border: '0.5px solid #D7D7D7' }} />

                                <div style={{ margin: '20px 0px', fontFamily: "HelveticaNeue", fontSize: '18px' }}>
                                    Upgrade plan to access all features of <span style={{ color: '#B4A671', fontWeight: 'bold' }}>EDConnect</span>
                                </div>
                                {/* <div style={{display:'flex',justifyContent:'space-between'}}> */}
                                <Grid container spacing={2}>
                                    <Grid item md={6}>
                                        <Box sx={{ height: '532px', border: '2px solid #F0F0F0', borderRadius: '10px', boxSizing: 'border-box', p: 2 }}>
                                            <div style={{ color: '#BE9E44', fontFamily: "HelveticaNeue", marginTop: '10px', fontSize: '20px' }}>ED Career</div>
                                            <div style={{ fontSize: '16px', margin: '10px 0px', fontFamily: "HelveticaNeue" }}>Service included</div>

                                            <ul>
                                                <li style={webStyles?.subLists}>All free Services on EDConnect Platform</li>
                                                <li style={webStyles?.subLists}>Give Recommendations</li>
                                                <li style={webStyles?.subLists}>Browse all EDConnect Users</li>
                                                <li style={webStyles?.subLists}>Receive and respond to messages</li>
                                                <li style={webStyles?.subLists}>Filtered search</li>
                                                <li style={webStyles?.subLists}>Post status updates (will be broadcasted to fanwall feed of all users)</li>
                                            </ul>


                                            <div style={{ backgroundColor: '#BE9E44', padding: '10px', color: 'white', fontSize: '14px', fontFamily: "HelveticaNeue", textAlign: 'center', borderRadius: '6px', marginTop: '105px', border: '1px solid #BE9E44' }}>
                                                Upgrade plan
                                            </div>

                                        </Box>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box sx={{ height: '532px', border: '2px solid #F0F0F0', borderRadius: '10px', boxSizing: 'border-box', p: 2 }}>
                                            <div style={{ color: '#BE9E44', fontFamily: "HelveticaNeue", marginTop: '10px', fontSize: '20px' }}>ED Premium</div>
                                            <div style={{ fontSize: '16px', margin: '10px 0px', fontFamily: "HelveticaNeue" }}>Service included</div>

                                            <ul>
                                                <li style={webStyles?.subLists}>All free Services on EDConnect Platform</li>
                                                <li style={webStyles?.subLists}>Give Recommendations</li>
                                                <li style={webStyles?.subLists}>Browse all EDConnect Users</li>
                                                <li style={webStyles?.subLists}>Receive and respond to messages</li>
                                                <li style={webStyles?.subLists}>Filtered search</li>
                                                <li style={webStyles?.subLists}>Post status updates (will be broadcasted to fanwall feed of all users)</li>
                                                <li style={webStyles?.subLists}>Alumini List</li>
                                                <li style={webStyles?.subLists}>Initiate new Direct messages</li>
                                            </ul>
                                            <div style={{ backgroundColor: 'rgba(190, 158, 68, 0.1)', padding: '10px', color: '#BE9E44', fontSize: '14px', fontFamily: "HelveticaNeue", textAlign: 'center', borderRadius: '6px', border: '2px solid #BE9E44' }}>
                                                Upgrade plan
                                            </div>
                                        </Box>
                                    </Grid>

                                </Grid>



                                {/* </div> */}

                            </Box>

                        </Grid>

                    </Grid>

                </Container>
                <div style={{ marginTop: 'auto' }}>

                    <AppFooter />
                </div>
            </>
        )
    }
}
const webStyles = {
    lists: {
        marginRight: '40px',
        fontSize: '14px',
        fontFamily: "HelveticaNeue"
    },
    subLists: {
        marginBottom: '20px',
        fontSize: '16px',
        fontFamily: "HelveticaNeue"
    }
}
// Customizable Area End