import React from "react";

// Customizable Area Start
import {
  Container
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AppHeader from '../../../components/src/AppHeader.web'
import AppFooter from '../../../components/src/AppFooter.web'

const theme = createTheme({
  palette: {
    primary: {
      main: "#bd9e44",
      contrastText: "#f9f8f8",
    }
  },
});

// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import Loader from "../../../components/src/Loader.web";

export default class VerifyEmailWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.userVerification();
    // Customizable Area End
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppHeader />
        <Container maxWidth="sm" style={webStyle.container}>
          <Loader loading={true} />
        </Container>
        <AppFooter />
      </ThemeProvider>
    );
  }

}
// Customizable Area Start
const webStyle: any = {
  container: {
    minHeight: "calc(100vh - 250px)",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center'
  }
};


// Customizable Area End
