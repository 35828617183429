import React from "react";

// Customizable Area Start
import { Typography, Grid, Paper, Box } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#be9e44",
            contrastText: "#fff",
        },
    },
});
import { collegeImage } from "./assets";
import Feeds1 from "../../../components/src/Feeds1.web";
import EducationalUserProfileController, {
    Props,
} from "./EducationalUserProfileController";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

export default class PostsTab extends EducationalUserProfileController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                {this.state.isLoading ? <div style={{textAlign: 'center'}}><CircularProgress color="primary" /></div> : 
                <Grid container spacing={2}>
                    {!this.state.isOwner && <Grid item xs={3}>
                        <CustomPaper style={webStyle.followers}>
                            <div style={webStyle.profileImage}>
                                <img src={this.state.orgProfileDetails?.profile_image === null ? collegeImage : this.state.orgProfileDetails?.profile_image} style={this.state.orgProfileDetails?.profile_image === null ? webStyle.defaultImage : webStyle.selectedImage} alt="profile picture" />
                            </div>
                            <Typography style={webStyle.orgFollowers}>{this.state.followersCount <= 1 ? this.state.followersCount + " Follower" : this.state.followersCount + " Followers"}</Typography>
                        </CustomPaper>
                    </Grid>}
                    <Grid item xs={9}>
                        <CustomPaper style={webStyle.posts}>
                            {this.state.userPost?.length !== 0 ? 
                                    <Feeds1 navigation={this.props?.navigation} postData={this.state.userPost} isLoad={this.state.isLoading} getPostData={this.getPostData}/>
                            :
                            <Box sx={{ bgcolor: '#fff', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0', marginTop: '15px', textAlign: "center" }}>{this.noPostText}</Box>
                        }
                        </CustomPaper>
                    </Grid>
                </Grid>}

            </ThemeProvider>
        );
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
    followers: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    profileImage: {
        width: "69px",
        height: "69px",
        borderRadius: "50%",
        border: '4px solid #fff',
        background: '#e9e9e9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    defaultImage: {
        width: "30px",
        height: "30px",
    },
    selectedImage: {
        width: "100%",
        height: "100%",
        borderRadius: '50%'
    },
    orgFollowers: {
        fontSize: "17px",
        color: "#ab9045",
        margin: "20px 0 15px"
    },
};

const CustomPaper: any = withStyles({
    root: {
        boxShadow: "unset",
        margin: "15px 0",
        border: "1px solid #e2e2e2",
        padding: "15px",
        borderRadius: "10px"
    }
})(Paper);

// Customizable Area End
