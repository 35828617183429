import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Grid, Paper, Button } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { CoverImage, UserIcon} from "./assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#be9e44",
            contrastText: "#fff",
        },
    },
}); 
import EducationalUserProfileController, {
    Props,
} from "./EducationalUserProfileController";
import AppFooter from "../../../components/src/AppFooter.web";
import Loader from "../../../components/src/Loader.web";
import NavBarWithSearch from "../../ElasticSearch/src/NavBarWithSearch.web";
import Feeds1 from "../../../components/src/Feeds1.web";
import CompanyInfo from "../../../components/src/CompanyInfo";
// Customizable Area End


export class AllActivity extends EducationalUserProfileController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Loader loading={this.state.isLoading} />
                <Container style={webStyle.container}>
                <NavBarWithSearch navigation={this.props.navigation} id={''} />
                    <Grid container spacing={2} className={classes.myActivity}>
                        <Grid item xs={3} md={3} className={classes.userDetailsColumn}>
                            <CustomPaper className={classes.userDetails}>
                                <Box style={webStyle.coverImage}>
                                    <img src={this.checkCoverImage()} className={classes.coverImage} alt="cover image" />
                                    <img src={this.checkProfileImage()} className={classes.profileImage} alt="profile picture" />
                                </Box>
                                {this.checkUserAccountDetails(classes)}
                                <hr style={{ borderTop: "0.03rem solid #d7d7d7" }} />
                                <div className={classes.followerDetails}>
                                    <CustomTypography className={classes.followerText}>{this.followerText}</CustomTypography>
                                    <CustomTypography className={classes.followerNumber}>{this.state.followersCount}</CustomTypography>
                                </div>
                                <div className={classes.followerDetails}>
                                    <CustomTypography className={classes.followerText}>{this.followingText}</CustomTypography>
                                    <CustomTypography className={classes.followerNumber}>{this.state.followingCount}</CustomTypography>
                                </div>
                            </CustomPaper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomPaper>
                                <CustomTypography>{this.myActivityText}</CustomTypography>
                                <hr style={{ borderTop: "0.03rem solid #d7d7d7" }} />
                                <CustomButton data-test-id="allActivityButton" className={this.state.isActiveFilter === 0 ? classes.activeFilterButton : ""} onClick={() => { this.setState({ isActiveFilter: 0 }); this.getUserAllActivity(0) }}>{this.allActivityText}</CustomButton>
                                <CustomButton data-test-id="postButton" className={this.state.isActiveFilter === 1 ? classes.activeFilterButton : ""} onClick={() => { this.setState({ isActiveFilter: 1 }); this.getUserAllActivity(1) }}>{this.postsText}</CustomButton>
                                <CustomButton data-test-id="likesButton" className={this.state.isActiveFilter === 2 ? classes.activeFilterButton : ""} onClick={() => { this.setState({ isActiveFilter: 2 }); this.getUserAllActivity(2) }}>{this.likedText}</CustomButton>
                                <CustomButton data-test-id="commentsButton" className={this.state.isActiveFilter === 3 ? classes.activeFilterButton : ""} onClick={() => { this.setState({ isActiveFilter: 3 }); this.getUserAllActivity(3) }}>{this.commentsText}</CustomButton>
                            </CustomPaper>
                            {this.checkFeeds()}
                        </Grid>
                        <Grid item xs={3} className={classes.footer}>
                            <Box className={classes.footerSec}>
                                <CompanyInfo />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
               <AppFooter />
            </ThemeProvider>
        );
        // Customizable Area End
    }
    // Customizable Area Start
    checkUserAccountDetails(classes: any) {
        let getStringData: any = localStorage.getItem("user_details");
        let getData: any = JSON.parse(getStringData);
        if (getData?.account_type === "organisation_account" || getData?.account_type === "OrganisationAccount") {
            return (<>
                <CustomTypography className={classes.orgName}>{this.state.orgProfileDetails?.name_of_organisation}</CustomTypography>
                <CustomTypography className={classes.orgAddress}> {this.state.orgProfileDetails?.city}, {this.state.orgProfileDetails?.country}</CustomTypography>
            </>)
        }
        else if (getData?.account_type === "student_account" || getData?.account_type === "StudentAccount") {
            return (<>
                <CustomTypography className={classes.orgName}>{localStorage.getItem("user_details") && (JSON.parse(localStorage.getItem("user_details") || ""))?.first_name}&nbsp;{localStorage.getItem("user_details") && (JSON.parse(localStorage.getItem("user_details") || ""))?.last_name}</CustomTypography>
                <CustomTypography className={classes.orgAddress}>{this.state.orgProfileDetails?.name_of_organisation?.length !== 0 ? `Student at ${this.state.orgProfileDetails?.name_of_organisation}` : "Student"}</CustomTypography></>)
        }
        else {
            return (<> <CustomTypography className={classes.orgName}>{this.userNameText}</CustomTypography>
                <CustomTypography className={classes.orgAddress}>{this.studentatOrgText}</CustomTypography></>)
        }
    }

    checkCoverImage() {
        if(this.state.orgProfileDetails?.photo) {
            return this.state.orgProfileDetails?.photo
        }
        else {
            return CoverImage
        }
    }

    checkProfileImage() {
        if(this.state.orgProfileDetails?.profile_image) {
            return this.state.orgProfileDetails?.profile_image
        }
        else {
            return UserIcon
        }
    }

    checkFeeds() {
        if (this.state.userActivityPost?.length !== 0 && this.state.userActivityPost !== undefined) {
            return <Feeds1 navigation={this.props?.navigation} postData={this.state.userActivityPost} isLoad={this.state.isLoading} getPostData={this.getPostData} />
        }
        else {
            return this.checkPostFilter(this.state.isActiveFilter)
        }
    }

    checkPostFilter(filter:any) {
        if(filter === 0) {
            return <Box sx={{ bgcolor: '#fff', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0', marginTop: '15px', textAlign: "center" }}>{this.notPostedText}</Box>
        }
        if(filter === 1) {
            return <Box sx={{ bgcolor: '#fff', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0', marginTop: '15px', textAlign: "center" }}>{this.notPostedText}</Box>
        }
        if(filter === 2) {
            return <Box sx={{ bgcolor: '#fff', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0', marginTop: '15px', textAlign: "center" }}>{this.notLikedText}</Box>
        }
        if(filter === 3) {
            return <Box sx={{ bgcolor: '#fff', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0', marginTop: '15px', textAlign: "center" }}>{this.notCommentedText}</Box>
        }
    }
    // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
    container: {
        minHeight: "calc(100vh - 270px)"
    },
    coverImage: {
        height: "55px",
        position: "relative",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px"
    },
    coverImageEditIcon: {
        width: "32px",
        height: "32px",
        position: "absolute",
        right: "23px",
        top: "12px",
        cursor: "pointer"
    },
    userDetailsEditIcon: {
        width: "32px",
        height: "32px",
        position: "absolute",
        right: "23px",
        top: "12px",
        cursor: "pointer"
    },
    orgImage: {
        width: "40px",
        height: "40px",
    },
    orgName: {
        fontSize: "24px",
        color: "#be9e44"
    },
    orgAddress: {
        fontSize: "17px",
        color: "#7c7c7c"
    },
    orgHours: {
        fontSize: "17px",
        color: "#565656"
    },
    orgFollowers: {
        fontSize: "17px",
        color: "#ab9045"
    },
    followBtn: {
        marginTop: "5px",
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "4px",
        textTransform: "capitalize"
    },
    footerMenus: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: "15px"
    },
    footerContent: {
        fontSize: "10px",
        color: "#7c7c7c",
        marginTop: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    contact_textContent: {
        textAlign: "center"
    },
    uploadSection: {
        width: "100%",
        display: "flex",
        justifyContent: "end"
    },
    uploadBtn: {
        marginTop: "5px",
        marginLeft: "10px",
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "4px",
        textTransform: "capitalize",
        height: "38px"
    },
    fieldLabel: {
        marginBottom: "2px",
        color: "#1c1c1c",
        fontSize: "14px"
    },
    fieldSet: {
        padding: "12px 8px"
    },
    fieldError: {
        color: "#D11024",
        fontSize: "14px",
        margin: "5px 5px 5px 0"
    },
    selectProfile: {
        display: "none" 
    },
};

const styles: any = () => ({
    footer: {
        "@media (max-width: 959px)": {
            display: "none"
        }
    },
    myActivity: {
        "@media (max-width: 768px)": {
           justifyContent: 'center'
        }
    },
    userDetails:{
        padding: 0,
        position: "sticky",
        top: "97px",
    },
    userDetailsColumn:{
        "@media (max-width: 768px)": {
            display: "none"
        }
    },
    footerSec: {
        position: "sticky",
        top: "97px"
    },
    footerMenu: {
        fontSize: "10px",
        color: "#7c7c7c",
        margin: "5px 20px",
        cursor: "pointer",
        "@media (max-width: 1200px)": {
            margin: "5px 25px",
        }
    },
    userDetail: {
        display: "flex",
        justifyContent: "space-between",
        padding: "55px 80px 26px 24px",
        position: "relative",
        "@media (max-width: 576px)": {
            padding: "50px 20px 15px",
        }
    },
    closeIcon: {
        position: "absolute",
        right: "24px",
        top: "24px",
        width: "24px",
        height: "24px",
        cursor: "pointer"
    },
    coverPhoto: {
        width: "383px",
        height: '216px',
        margin: "36px 0 12px"
    },
    coverTitle: {
        fontSize: "20px",
        color: "#1c1c1c",
        textAlign: "center"
    },
    coverSubtitle1: {
        fontSize: "16px",
        color: "#1c1c1c",
        textAlign: "center",
        marginBottom: "12px"
    },
    coverSubtitle2: {
        fontSize: "14px",
        color: "#696868",
        textAlign: "center",
        marginBottom: "12px"
    },
    coverImage: {
        width: "100%", 
        height: "100%",
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px'
    },
    profileImage: {
        width: "74px",
        height: "74px",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%)",
        top: "25px",
        border: '1px solid #fff',
        borderRadius: '50%',
        backgroundColor: "#e9e9e9"
    },
    followerText: {
        fontSize: "12px",
        color: "#1c1c1c"
    },
    followerNumber: {
        fontSize: "12px",
        color: "#be9e44"
    },
    orgName: {
        fontWeight: "600",
        textAlign: "center",
        marginTop: "60px"
    },
    orgAddress: {
        fontSize: "14px",
        color: "#7c7c7c",
        textAlign: "center"
    },
    followerDetails: {
        display: 'flex',
        justifyContent: "space-between",
        margin: "10px 20px",
        color: "#7c7c7c",
    },
    activeFilterButton: {
        width: "100px",
        height: "38px",
        margin: "0 6px",
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "4px",
        textTransform: "capitalize",
        "@media (max-width: 768px)": {
            width: "auto",
            margin: '5px'
        }
    }
})

const CustomPaper: any = withStyles({
    root: {
        boxShadow: "unset",
        margin: "15px 0",
        border: "1px solid #e2e2e2",
        padding: "15px",
        borderRadius: "10px"
    }
})(Paper);

const CustomTypography: any = withStyles({
    root: {
        color: "#1c1c1c"
    },
})(Typography);

const CustomButton: any = withStyles({
    root: {
        width: "100px",
        height: "38px",
        margin: "0 6px",
        background: "#f8f8f8",
        color: "#be9e44",
        borderRadius: "4px",
        textTransform: "capitalize",
        border: "1px solid #be9e44",
        "&:hover": {
            color: "#f8f8f8",
            background: "#be9e44",
        },
        "@media (max-width: 768px)": {
            width: "auto",
            margin: '5px'
        }
    },
})(Button);

export default withStyles(styles)(AllActivity)

// Customizable Area End
