import React from "react";

// Customizable Area Start
import { Box, Typography, Paper, Button, Modal, InputLabel, TextField, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#be9e44",
            contrastText: "#fff",
        },
    },
});
const modalStyle: any = {
    position: "absolute",
    top: "26%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 920,
    bgcolor: "#fff",
    borderRadius: 12,
    boxShadow: 24,
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 1092px)": {
        padding: "20px",
        width: "75%"
    }
};
import { ArrowIcon, CloseIcon, EditIcon } from "./assets";
import { Formik } from 'formik';
import Feeds1 from "../../../components/src/Feeds1.web";
import CreatePostModal from "../../../components/src/CreatePostModal.web";
import EducationalUserProfileController, {
    Props,
} from "./EducationalUserProfileController";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

export class HomeTab extends EducationalUserProfileController {
    // Customizable Area Start
    closeModal = () => {
        this.setState({ openCreatePost: false });
    }

    handlePostText = (value: any) => {
        this.setState({ description: value });
        let temp: any = []
        const words = value.split(/\s+/);
        words.map((word: any) => {
            if (word.startsWith("#")) {
                // this.searchHashTag(word)
                temp.push(word)
            }
        });
        this.setState({ body: temp.join(", ") })
    }
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                {this.state.isLoading ? <div style={{textAlign: 'center'}}><CircularProgress color="primary" /></div> : 
                <>
                <CustomPaper style={webStyle.about}>
                    <CustomTypography style={webStyle.sectionTitle}>{this.aboutText}</CustomTypography>
                    {this.checkEditIcon()}
                    <Box>
                        <CustomTypography className={classes.aboutContent}>
                            {this.state.orgProfileDetails?.headline?.length <= 185 ?
                                this.state.orgProfileDetails?.headline :
                                <>
                                {this.state.isAboutMore ? <>{this.state.orgProfileDetails?.headline}<span 
                                    style={{ color: "#be9e44", cursor: "pointer", position: "relative" }}
                                    data-test-id="txtSeeMore"
                                    onClick={()=>{this.setState({isAboutMore: false})}}
                                    >
                                        …see less
                                    </span></> : <>{this.state.orgProfileDetails?.headline?.slice(0, 185)}<span 
                                    style={{ color: "#be9e44", cursor: "pointer", position: "relative" }}
                                    data-test-id="txtSeeMore"
                                    onClick={()=>{this.setState({isAboutMore: true})}}
                                    >
                                        …see more
                                    </span></>}
                                </>
                            }
                        </CustomTypography>
                    </Box>
                </CustomPaper>
                {this.state.isOwner && <CustomPaper style={webStyle.myActivity}>
                    <CustomTypography style={{ ...webStyle.sectionTitle, padding: "24px 24px 0" }}>{this.myActivityText}</CustomTypography>
                    <CustomTypography style={webStyle.connections}>{this.state.connectionCount} Connections</CustomTypography>
                    {this.state.userPost.length !== 0 ?
                            <Grid container spacing={2} style={{width: "100%", margin: '0'}}>
                                <Grid item xs={12} md={6}>
                                    <Feeds1 navigation={this.props?.navigation} postData={this.state.userPost.slice(0, 1)} isLoad={this.state.isLoading} getPostData={this.getPostData} />
                                </Grid>
                                {this.state.userPost.slice(1, 2).length !== 0 && <Grid item xs={12} md={6}>
                                    <Feeds1 navigation={this.props?.navigation} postData={this.state.userPost.slice(1, 2)} isLoad={this.state.isLoading} getPostData={this.getPostData} />
                                </Grid>}
                            </Grid>
                        :
                        <>
                            <CustomTypography style={{ padding: "0 24px" }}>{this.notPostedText}</CustomTypography>
                            <CustomTypography style={{ padding: "0 24px", color: "#717171" }}>{this.recentPostText}</CustomTypography>
                        </>
                    }
                    <hr style={{ marginTop: "28px", borderTop: "0.03rem solid #d7d7d7" }} />
                    <CustomTypography style={webStyle.seeActivity} data-test-id="btnSeeActivity" onClick={() => {this.props.navigation.navigate("AllActivity")}}>{this.seeAllActivityText}</CustomTypography>
                    <CustomButton data-test-id="startPostButton" style={webStyle.startButton} onClick={()=>{this.setState({openCreatePost: true})}}>{this.startPostText}</CustomButton>
                </CustomPaper>}

                {this.state.isOwner && <CustomPaper style={{...webStyle.followerConnection, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                        <CustomTypography style={webStyle.sectionTitle}>{this.followerConnectionText}</CustomTypography>
                        <CustomTypography style={{ paddingTop: "10px", color: "#717171" }}>{this.manageConnectionText}</CustomTypography>
                    </div>
                    <Button style={webStyle.viewBtn} data-test-id="viewFollowerList">{this.viewBtnText}</Button>
                </CustomPaper>}

                {this.state.isOwner && <CustomPaper style={webStyle.alumniList}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <CustomTypography style={webStyle.sectionTitle}>{this.alumniListText}</CustomTypography>
                        <Button style={webStyle.viewBtn} data-test-id="redirectAlumniTab" onClick={() => {this.redicretAlumniTab()}}>{this.viewBtnText}</Button>
                    </Box>
                </CustomPaper>}

                {!this.state.isOwner && <CustomPaper style={webStyle.followerConnection}>
                    <CustomTypography style={{...webStyle.sectionTitle, display: 'flex', justifyContent: "space-between"}}>
                        <>{this.postsText}</>
                        <div style={{ display: 'flex' }}>
                            <div data-test-id="backButton" onClick={() => { this.handleBackPost() }} className={classes.arrowIcon}>
                                <img src={ArrowIcon} alt="arrow icon" className={classes.leftArrow} />
                            </div>
                            <div onClick={() => { this.handleNextPost() }} className={classes.arrowIcon}>
                                <img src={ArrowIcon} alt="arrow icon" className={classes.rightArrow} />
                            </div>
                        </div>
                    </CustomTypography>
                    <Box>
                        <Grid container spacing={2}>
                            {this.state.postData.length !== 0 ? this.state.postData.slice(this.state.startPost, this.state.endPost).map((item: any) => {
                                let newData:any = [item];
                                return (<>
                                    <Grid item xs={12} md={6}>
                                        <Feeds1 navigation={this.props?.navigation} postData={newData} isLoad={this.state.isLoading} getPostData={this.getPostData}/>
                                    </Grid>
                                </>
                                )
                            }) : <Box sx={{ p: 2, marginTop: '15px', textAlign: "center" }}>{this.noPostText}</Box>}

                        </Grid>
                    </Box>
                </CustomPaper>}
                </>}

                <CreatePostModal
                    openCreatePost={this.state.openCreatePost}
                    closeModal={this.closeModal}
                    handlePostText={this.handlePostText}
                    uploadedImages={this.state.uploadedImages}
                    handleRemoveImage={this.handleRemoveImage.bind(this)}
                    hashTags={this.state.hashTags}
                    handleImage={this.handleImage.bind(this)}
                    AddPostCreation={this.AddPostCreation.bind(this)}
                    imgRef={this.imgRef}
                    userAccountObj={this.state?.userAccountObj}
                    loading={this.state.isLoading}
                />

                <Modal  //modal for about update
                    open={this.state.aboutPopup}
                    onClose={this.handleAboutPopupClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <img
                            src={CloseIcon}
                            className={classes.closeIcon}
                            onClick={this.handleAboutPopupClose}
                        />
                        <Typography className={classes.aboutTitle}>
                            {this.aboutOrgText}
                        </Typography>
                        <Formik
                            initialValues={this.state.initialProfileDetails}
                            validationSchema={""}
                            onSubmit={(values: any) => { this.updateProfileDetails(values) }}
                            data-test-id="aboutFormik"
                        >
                            {({ values, handleSubmit, handleChange, handleBlur }: any) => (
                                <form onSubmit={handleSubmit} style={{width: "100%", margin: "0"}} data-test-id="aboutForm">
                                    <InputLabel style={{ display: "flex", justifyContent: "start", ...webStyle.fieldLabel }}>{this.aboutText}</InputLabel>
                                    <TextArea
                                        id="headline"
                                        name="headline"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        fullWidth={true}
                                        placeholder="A short sentence about organization."
                                        value={values.headline}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <hr style={{ borderTop: "0.03rem solid #d7d7d7", width: "99%" }} />
                                    <Box style={webStyle.saveSection}>
                                        <Button type="submit" style={webStyle.saveBtn}>{this.saveBtnText}</Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Modal>
            </ThemeProvider>
        );
        // Customizable Area End
    }
    // Customizable Area Start
    checkEditIcon() {
        if(this.state.isOwner){
            return <img src={EditIcon} style={webStyle.aboutEditIcon} data-test-id="aboutEditIcon" onClick={() => {this.setState({aboutPopup: true})}}/>
        }
    }
    // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
    sectionTitle: {
        fontSize: "20px",
        fontWeight: "normal",
        color: "#1c1c1c",
    },
    about: {
        padding: "24px",
        position: "relative"
    },
    aboutEditIcon: {
        width: "32px",
        height: "32px",
        position: "absolute",
        right: "24px",
        top: "18px",
        cursor: "pointer"
    },
    myActivity: {
        padding: "0",
        position: "relative"
    },
    startButton: {
        position: "absolute",
        top: "24px",
        right: "24px"
    },
    seeActivity: {
        textAlign: "center",
        padding: "3px 10px 10px",
        color: "#be9e44",
        cursor: "pointer"
    },
    followerConnection: {
        padding: "24px"
    },
    connections: {
        color: "#be9e44",
        fontSize: "14px",
        fontWeight: "normal",
        margin: "10px 0",
        padding: "0 24px"
    },
    alumniList: {
        padding: "30px 24px 28px"
    },
    viewBtn: {
        background: "#be9e44",
        color: "#f8f8f8",
        textTransform: "capitalize",
    },
    aboutMore: {
        position: 'absolute',
    },
    fieldLabel: {
        marginBottom: "2px",
        color: "#1c1c1c", 
        fontSize: "14px",
        width: "100%"
    },
    saveSection:{
        width: "100%",
        display: "flex",
        justifyContent: "end"
    },
    saveBtn: {
        marginTop: "5px",
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "4px",
        textTransform: "capitalize",
        height: "38px"
    }
};

const styles:any = () => ({
    aboutContent: {
        width: "65%",
        "@media (max-width: 959px)": {
            width: '100%'
        }
    },
    closeIcon: {
        position: "absolute",
        right: "24px",
        top: "24px",
        width: "24px",
        height: "24px",
        cursor: "pointer"
    },
    aboutTitle:{
        fontSize: "20px",
        color: "#1c1c1c",
        textAlign: "center"
    },
    arrowIcon: {
        width: "35px",
        height: "35px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor: "#e1e1e1",
        cursor: "pointer",
        marginLeft: "15px"
    },
    leftArrow: {
        width: "25px"
    },
    rightArrow: {
        width: "25px",
        transform: 'rotate(180deg)'
    }
})

const CustomPaper: any = withStyles({
    root: {
        boxShadow: "unset",
        margin: "15px 0",
        border: "1px solid #e2e2e2",
        padding: "20px",
        borderRadius: "10px"
    }
})(Paper);

const CustomTypography: any = withStyles({
    root: {
        color: "#1c1c1c"
    },
})(Typography);

const CustomButton: any = withStyles({
    root: {
        width: "100px",
        height: "36px",
        opacity: 1,
        boxShadow: "none",
        border: "1px solid #be9e44",
        background: "rgba(190, 158, 68, 0.1)",
        borderRadius: "6px",
        fontSize: '14px',
        textTransform: "none",
        color: "#be9e44",
        margin: "0 10px",
        "&:hover": {
            background: "rgba(190, 158, 68, 0.3)",
            boxShadow: "none",
        },
    },
})(Button);

const TextArea:any = withStyles({
    root: {
        width: '100%',
        paddingTop: '10px',
        borderRadius: "10px", 
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            borderRadius: "10px",
            "& fieldset": {
                fontSize: "16px", 
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
          opacity: 1,
          color: "#565656",
          fontFamily: "HelveticaNeue", 
        },
        "& .Mui-error":{
          "& .MuiOutlinedInput-notchedOutline":{
            borderColor: "#D11024"
          }
        },
    }
})(TextField);

export default withStyles(styles)(HomeTab)

// Customizable Area End
