import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Select,
  Button,
  MenuItem,
  Container,
  IconButton,
  Drawer
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { edConnectLogo as EdConnectLogo } from "./assets";
import { TermsConditionsPop } from "../../../components/src/TermsConditionsPop.web";
import "../../../web/src/App.css";
import AgeConfirmationModal from "../../../components/src/AgeConfirmationModal.web";
// Customizable Area End


export default function LandingPageHeader({
  userType,
  handleChangeUserType,
  termsPolicesData,
}:any) {
  // terms and conditions modal
  const [openTermsMod, setOpenTermsMod] = React.useState(false);
  const handleTermsAndConditionsModal = () => {
    setOpenTermsMod(!openTermsMod);
  };
  // Privacy policy modal
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const handlePrivacyPolicyModal = () =>
    setOpenPrivacyPolicy(!openPrivacyPolicy);

  // age confirm modal
  const [openAgeConfirm, setOpenAgeConfirm] = React.useState(false);
  const handleOpenAgeConfirm = () => setOpenAgeConfirm(true);
  const handleCloseAgeConfirm = () => setOpenAgeConfirm(false);
// Customizable Area Start
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps: any = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };
// Customizable Area End
  return (
    // Customizable Area Start
    <Container maxWidth="lg" style={webStyle.topBar}>
      <Box>
        <NavLink to="/">
          <img style={webStyle.logo} className="logo" src={EdConnectLogo} data-test-id="companyLogo"/>
        </NavLink>
      </Box>
      <Box>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ gap: "5px" }}
        >
          <Select
            defaultValue={"student_account"}
            style={{ paddingLeft: "8px", marginRight: "10px" }}
            onChange={(e) => handleChangeUserType(e.target.value)}
            data-test-id="selectUserType"
            MenuProps={MenuProps}
          >
            <MenuItem value={"student_account"} style={{ textAlign: "center" }}>
              Student
            </MenuItem>
            <MenuItem value={"organisation_account"}>Organization</MenuItem>
          </Select>
          <Box
            sx={{ display: { xs: "none", sm: "none", md: "flex", lg: "flex" } }}
            className="appBarRoute"
          >
            <CustomGrid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ gap: "15px" }}
            >
              <Box>
                <Typography style={webStyle.navMenu} data-test-id='discover1' onClick={handleOpenAgeConfirm}>
                  Discover
                </Typography>
              </Box>
              <Box>
                <Typography
                  style={webStyle.navMenu}
                  onClick={handlePrivacyPolicyModal}
                  data-test-id = 'privacyTypo'
                >
                  Privacy Policy
                </Typography>
              </Box>
              <Box>
                <Typography
                  style={webStyle.navMenu}
                  onClick={handleTermsAndConditionsModal}
                  data-test-id='termsTypo'
                >
                  Terms & Conditions
                </Typography>
              </Box>
              <Box>
                <NavLink to="/Login" style={{ textDecoration: "none" }} onClick={() => {localStorage.setItem("userType", userType === "student_account" ? 'student' : 'organization')}}>
                  <Typography style={webStyle.navMenu} data-test-id='txtSignin1'>Sign In</Typography>
                </NavLink>
              </Box>
              <Button
                variant="outlined"
                style={{
                  background: "#f8f4ec",
                  color: "#BE9E44",
                  border: "1px solid #BE9E44",
                  padding: "5px 10px",
                  borderRadius: "8px",
                  textTransform: "none",
                  display: "inline-block",
                  textAlign: "center",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                <NavLink to="/Register" style={{ textDecoration: "none", color: "#BE9E44", }} onClick={() => {localStorage.setItem("userType", userType === "student_account" ? 'student' : 'organization')}}>
                  Join now
                </NavLink>
              </Button>
            </CustomGrid>
          </Box>
          <Box sx={{ display: { xs: "flex", sm: "flex", md: "none", lg: "none" } }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>

            <Drawer
              anchor={'right'}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <CustomGrid
                container
                alignItems="center"
                justifyContent="space-between"
                style={{ gap: "15px", padding: "10px 15px", flexDirection: "column" }}
              >
                <Box>
                  <Typography style={webStyle.navMenu} data-test-id='discover2' onClick={() => {
                    handleOpenAgeConfirm();
                    handleCloseNavMenu();
                  }}>
                    Discover
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={webStyle.navMenu}
                    onClick={() => {
                      handlePrivacyPolicyModal();
                      handleCloseNavMenu();
                    }}
                    data-test-id='privacyTypo1'
                  >
                    Privacy Policy
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={webStyle.navMenu}
                    onClick={() => {
                      handleTermsAndConditionsModal();
                      handleCloseNavMenu();
                    }}
                    data-test-id='termsTypo1'
                  >
                    Terms & Conditions
                  </Typography>
                </Box>
                <Box>
                  <NavLink to="/Login" style={{ textDecoration: "none" }} onClick={() => {localStorage.setItem("userType", userType === "student_account" ? 'student' : 'organization')}}>
                    <Typography style={webStyle.navMenu} data-test-id='txtSignin'>Sign In</Typography>
                  </NavLink>
                </Box>
                <Button
                  variant="outlined"
                  style={{
                    background: "#f8f4ec",
                    color: "#BE9E44",
                    border: "1px solid #BE9E44",
                    padding: "5px 10px",
                    borderRadius: "8px",
                    textTransform: "none",
                    display: "inline-block",
                    textAlign: "center",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  <NavLink to="/Register" style={{ textDecoration: "none", color: "#BE9E44", }} onClick={() => {localStorage.setItem("userType", userType === "student_account" ? 'student' : 'organization')}}>
                    Join now
                  </NavLink>
                </Button>
              </CustomGrid>
            </Drawer>
          </Box>
        </Grid>
        <TermsConditionsPop
          open={openTermsMod}
          handleModal={handleTermsAndConditionsModal}
          data={termsPolicesData?.terms}
          title={"Terms & Conditions"}
          data-test-id="termsTypoMod"
        />
        <TermsConditionsPop
          open={openPrivacyPolicy}
          handleModal={handlePrivacyPolicyModal}
          data={termsPolicesData?.poilicies}
          title={"Privacy Policy"}
          data-test-id="privacyTypoMod"
        />
        <AgeConfirmationModal
          open={openAgeConfirm}
          handleOpen={handleOpenAgeConfirm}
          handleClose={handleCloseAgeConfirm}
          data={termsPolicesData}
        />
      </Box>
    </Container>
    // Customizable Area End
  );
}

// Customizable Area Start
const webStyle:any = {
  topBar: {
    // width: "90%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "10px",
  },
  logo: {
    width: "95px",
  },
  navMenu: {
    textDecoration: "none",
    color: "black",
    fontWeight: "500",
    fontSize: "15px",
    cursor: "pointer",
  },
  navSelectTag: {
    marginRight: "20px",
  },
};

const CustomGrid: any = withStyles({
  root: {
    "& .MuiList-padding": {
      paddingBottom: `0px !important`,
    },
  },
})(Grid);

// Customizable Area End
