import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  InputLabel,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Tabs,
  Tab,
  Grid,
  Select,
  MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { BackArrow, TickIcon } from "./assets";
import AppHeader from '../../../components/src/AppHeader.web'
import AppFooter from '../../../components/src/AppFooter.web'
import { Formik } from 'formik'
import * as Yup from 'yup'

export const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#bd9e44",
      contrastText: "#f9f8f8",
    }
  },
});
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController";

export default class NewPasswordsWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  setNewPasswordSection = () => {
    const SetPasswordSchema = Yup.object().shape({
      new_password: Yup
        .string()
        .required(this.errorPasswordRequired)
        .min(6, this.errorPasswordLength)
        .matches(/[0-9]/, this.errorPassNumber)
        .matches(/[a-z]/, this.errorPassLower)
        .matches(/[A-Z]/, this.errorPassUpper)
        .matches(/[^\w]/, this.errorPassSymbol),
        confirm_password: Yup
        .string()
        .required(this.errorConfirmPassRequired)
        .oneOf([Yup.ref('new_password'), null], this.errorPasswordMatch),
    })
    return <Formik
      initialValues={this.state.initialPasswordDetails}
      validationSchema={SetPasswordSchema}
      onSubmit={(values: any) => { this.handleNewPassword(values) }}
      data-test-id="formSetPassword"
    >
      {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
        <form onSubmit={handleSubmit} style={{margin: "0"}}>
          <Container maxWidth="sm" style={webStyle.container}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "50px",
                bgcolor: "#ffffff",
                borderRadius: "14px",
                position: 'relative',
                margin: "10px 0",
                maxWidth: "434px",
                fontFamily: "HelveticaNeue",
              }}
            >
              <Box style={webStyle.pageTitle}>
                <img src={BackArrow} style={webStyle.backArrow} data-test-id="backArrow" onClick={() => { this.props.navigation.navigate("ForgotPassword") }} />
                <Typography variant="h5" component="h1">
                  Set a new password!
                </Typography>
              </Box>


              <Box sx={{ width: "100%", paddingTop: "20px" }}>
                <Box sx={{ padding: "10px 0px" }}>
                   <InputLabel style={{ display: "flex", justifyContent: "space-between", ...webStyle.fieldLabel }}>
                      <span>{this.labelPassword}</span>
                      <span style={{ color: "#bd9e44", fontSize: "14px", letterSpacing: "-1" }}>(6 or more characters)</span>
                    </InputLabel>
                  <InputField
                    error={errors.new_password && touched.new_password}
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    id="new_password"
                    name="new_password"
                    fullWidth={true}
                    placeholder="New password"
                    variant="outlined"
                    value={values.new_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {this.state.enablePasswordField ? (
                              <Typography style={{ color: "#bd9e44", fontSize: "14px" }}>Show</Typography>
                            ) : (
                              <Typography style={{ color: "#bd9e44", fontSize: "14px" }}>Hide</Typography>
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {this.finalErrorMessage(errors.new_password, touched.new_password)}
                </Box>

                <Box sx={{ padding: "10px 0px" }}>
                  <InputLabel style={{ display: "flex", justifyContent: "space-between", ...webStyle.fieldLabel }}>
                      <span>{this.labelConfirmPassword}</span>
                      <span style={{ color: "#bd9e44" , fontSize: "14px", letterSpacing: "-1"}}>(6 or more characters)</span>
                    </InputLabel>
                  <InputField
                    error={errors.confirm_password && touched.confirm_password}
                    data-test-id="txtInputPassword"
                    type={this.state.enableConfirmPasswordField ? "password" : "text"}
                    id="confirm_password"
                    name="confirm_password"
                    fullWidth={true}
                    placeholder="Confirm password"
                    variant="outlined"
                    value={values.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {this.state.enableConfirmPasswordField ? (
                              <Typography style={{ color: "#bd9e44", fontSize: "14px" }}>Show</Typography>
                            ) : (
                              <Typography style={{ color: "#bd9e44", fontSize: "14px" }}>Hide</Typography>
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {this.finalErrorMessage(errors.confirm_password, touched.confirm_password)}
                  {this.state.isError && <div style={{color: "#D11024", fontSize: "14px", marginTop: "8px"}}>{this.state.isError}</div>}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px 0px",
                  }}
                >
                  <Button
                    type="submit"
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    color="primary"
                    style={{ height: "56px", fontSize: "16px", textTransform: "none", borderRadius: "8px" }}
                    fullWidth
                  >
                    {this.btnTxtConfirmPass} {/*UI Engine::From Sketch*/}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </form>
      )}
    </Formik>
  }

  PasswordChangedSection = () => {
    return <Container maxWidth="sm" style={webStyle.container}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px",
        bgcolor: "#ffffff",
        borderRadius: "14px",
        position: 'relative',
        maxWidth: "434px",
        fontFamily: "HelveticaNeue",
      }}
    >

      <Box sx={{ width: "100%", paddingTop: "20px", textAlign: "center" }}>
        <img src={TickIcon} style={webStyle.tickIcon} />
        <Typography variant="h5" style={{ margin: "25px 0" }}>{this.titlePasswordChanged}</Typography>
        <Typography variant="subtitle1" style={{ color: '#7c7c7c', margin: "10px 0" }}>{this.txtPasswordChanged}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px 0px",
          }}
        >
          <Button
            data-test-id={"btnEmailLogIn"}
            variant="contained"
            color="primary"
            style={{ height: "56px", fontSize: "16px", textTransform: "none", borderRadius: "8px" }}
            fullWidth
            onClick={() => {this.props.navigation.navigate("Login")}}
          >
            {this.btnTxtContinueSignin} {/*UI Engine::From Sketch*/}
          </Button>
        </Box>
      </Box>
    </Box>
  </Container>
  }

  finalErrorMessage(error: any, touch: any) {
    if (error) {
      if (touch) {
        return <div style={webStyle.fieldError}>
          {error}
        </div>
      }
      else {
        return null
      }
    }
    else {
      return;
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppHeader />
        <Loader loading={this.state.isLoading}/>
        {
          this.state.isPassword === true ? 
          this.setNewPasswordSection() :
          this.PasswordChangedSection()
         }
        <AppFooter />
      </ThemeProvider>
    );
  }
// Customizable Area End
}
// Customizable Area Start
const webStyle: any = {
  container: {
    minHeight: "calc(100vh - 250px)",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
  },
  Tab1: {
    background: '#bd9e44',
    color: '#f9f8f8'
  },
  Tab2: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: '#fffaec',
    color: "#bd9e44",
  },
  activeTransferredTickets: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    background: '#fffaec',
    color: "#bd9e44",
  },
  tab3: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  backArrow: {
    position: "absolute",
    width: "25px",
    left: "0",
    top: "3px",
    cursor: "pointer"
  },
  profileImg: {
    width: "80px",
    height: "80px",
    borderRadius: "50%"
  },
  beforeSelect: {
    width: "30px",
    height: "30px",
  },
  profile: {
    width: "80px",
    height: "80px",
    border: "2px solid #bd9e44",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 15px 0 0"
  },
  selectProfile: {
    display: "none"
  },
  uploadButton: {
    width: "150px",
    height: "40px",
    borderRadius: "8px",
    backgroundColor: "#bd9e44",
    color: "#f9f8f8",
    letterSpacing: 0,
    textTransform: "unset",
    marginRight: "10px"
  },
  changeProfile: {
    width: "150px",
    height: "40px",
    borderRadius: "8px",
    backgroundColor: "#fffaec",
    color: "#bd9e44",
    letterSpacing: 0,
    textTransform: "unset",
    border: "2px solid #bd9e44",
    fontWeight: "bold"
  },
  select: {
    width: "100%",
    borderRadius: "8px",
    margin: "10px 0"
  },
  fieldError: {
    color: "#D11024",
    fontSize: "14px",
    margin: "5px 5px 5px 0"
  },
  typePlaceHolder: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#696969",
  },
  tickIcon: {
    width: '85px'
  },
  pageTitle: {
    position: "relative",
    width: "100%",
    textAlign: "center"
  },
  fieldLabel: {
    color: "#1c1c1c",
    fontSize: "14px"
  }
};

const InputField:any = withStyles({
  root: {
      width: '100%',
      paddingTop: '10px',
      borderRadius: "10px",
      "& .MuiOutlinedInput-root": {
          fontWeight: 400,
          fontFamily: "HelveticaNeue",
          fontSize: "16px",
          "& fieldset": {
              fontSize: "16px",
              borderRadius: "10px",
              borderWidth: 1,
              fontWeight: 400,
          },
      },
      "& ::placeholder": {
        opacity: 1,
        color: "#696969",
        fontFamily: "HelveticaNeue",
      },
      "& .Mui-error":{
        "& .MuiOutlinedInput-notchedOutline":{
          borderColor: "#D11024"
        }
      },
  }
})(TextField);

// Customizable Area End
