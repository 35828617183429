import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading:boolean;
  postDataWithSelectedHashTags:any;
  openCreatePost:boolean;
  reportHashTagEl:any;
  openUnfollowModal:boolean;
  sortEl:any;
  followedHashTagsList:any;
  isHashTagFollowed:boolean;
  selectedHashTagObj:any;
  hashtagId:any;
  totalHashtagFollowedCount:any;
  hashtagName:any;
  userAccountObj:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HashtagGeneratorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postsWithHashtagApiCallId:any;
  followedHashTagListApiCallId:any;
  followHashtagApiCallId:any;
  unFollowHashtagApiCallId:any;
  getProfileApi:any;
  getFollowingCountCallId: any;
  getConnectionCountCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading:false,
      postDataWithSelectedHashTags:[],
      openCreatePost:false,
      reportHashTagEl:null,
      openUnfollowModal:false,
      sortEl:null,
      followedHashTagsList:[],
      isHashTagFollowed:false,
      selectedHashTagObj:'',
      hashtagId:'',
      totalHashtagFollowedCount:0,
      hashtagName:'',
      userAccountObj:{
        first_name:'',
        middle_name:'',
        last_name:'',
        profile_image:'',
        job_title:'',
        name_of_organisation:'',
        type:'',
        photo: '',
        connectionCount: 0,
        followingCount: 0
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
      switch (apiRequestCallId) {
        case this.postsWithHashtagApiCallId:this.handlePostWithHashTagResponse(responseJson,errorReponse);
        break;
        case this.followedHashTagListApiCallId: this.handleFollowedHashTagListResponse(responseJson,errorReponse);
        break;
        case this.followHashtagApiCallId: this.handleFollowHashTagResponse(responseJson,errorReponse);
        break;
        case this.unFollowHashtagApiCallId: this.handleUnfollowHashTagResponse(responseJson,errorReponse);
        break;
        case this.getProfileApi: this.handleGetProfileInfoResponse(responseJson);
        break;
        case this.getFollowingCountCallId: this.getFollowingCountResponse(responseJson)
        break;
        case this.getConnectionCountCallId: this.getConnectionCountResponse(responseJson)
        break;
        
        }
      }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    this.getPostDataWithHashTags();
    this.getFollowedHashTagLists();
    this.getProfileDetails()
    this.getFollowingCount();
    this.getConnectionCount();
    
    
  }
  getProfileDetails() {
  
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem('authToken')
    };
    console.log("header  ", JSON.stringify(header));
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);
 
    this.getProfileApi = requestMessage.messageId;
 
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileEndPoint
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProfileMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }
  getPostDataWithHashTags(){
   let hashTagWord=localStorage.getItem('selectedHashTag')||'';
   let newWord=hashTagWord.substring(1,hashTagWord.length)
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem('authToken')
    };
   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   

    this.postsWithHashtagApiCallId = requestMessage.messageId;
   

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/hashtag_search?body=%23${newWord}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsForSelectedHashtag
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
   

  }
  getFollowedHashTagLists(){
    
     this.setState({isLoading:true})
     const header = {
       "Content-Type": configJSON.postContentType,
       token: localStorage.getItem('authToken')
     };
    
 
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
    
 
     this.followedHashTagListApiCallId = requestMessage.messageId;
    
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       configJSON.getFollowedHashTagListsUrl
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.followedHashTagMethodType
     );
     
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return true;
  }

   followHashTag(hashtagId:any){
    this.setState({isLoading:true})
     const header = {
       "Content-Type": configJSON.postContentType,
       token: localStorage.getItem('authToken')
     };

     const formData= new FormData();
     formData.append('id',hashtagId)
    
 
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
    
 
     this.followHashtagApiCallId = requestMessage.messageId;
    
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       configJSON.followHashtagUrl
     );
 

     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formData
    );

     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.followHashtagMethodType
     );
     
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return true;

   }

   unfollowHashTag(hashtagId:any){
    this.setState({isLoading:true})
     const header = {
       "Content-Type": configJSON.postContentType,
       token: localStorage.getItem('authToken')
     };

     const formData= new FormData();
     formData.append('id',hashtagId)
    
 
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
    
 
     this.unFollowHashtagApiCallId = requestMessage.messageId;
    
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       configJSON.unfollowHashtagUrl
     );
 

     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formData
    );

     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.unfollowHashtagMethodType
     );
     
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return true;


   }

  handlePostWithHashTagResponse(responseJson:any,errorReponse:any){
    if (responseJson) {
     
      this.setState({
        isLoading:false,
        postDataWithSelectedHashTags:responseJson?.post?.data,  
        selectedHashTagObj:responseJson?.hashtag,
        isHashTagFollowed:responseJson?.follow
      })
      
    }
    else if(errorReponse){
      this.setState({isLoading:false})
      console.log(errorReponse)

    }

  }
  handleFollowedHashTagListResponse(responseJson:any,errorReponse:any){
    if (responseJson) {
      console.log(responseJson)
    
      this.setState({followedHashTagsList:responseJson?.hashtags,totalHashtagFollowedCount:responseJson?.count,isLoading:false})
      
    }
   

  }
  handleFollowHashTagResponse(responseJson:any,errorReponse:any){
    if (responseJson) {
      console.log(responseJson)
      this.setState({isLoading:false});
     this.getPostDataWithHashTags()
      
    }
   

  }

  handleUnfollowHashTagResponse(responseJson:any,errorReponse:any){
    if (responseJson) {
      console.log(responseJson)
      this.setState({isLoading:false,openUnfollowModal:false});
      this.getPostDataWithHashTags()
      this.getFollowedHashTagLists()
      
    }
    

  }
  handleGetProfileInfoResponse(responseJson:any){
    localStorage.setItem('account_id', responseJson?.profile?.data?.attributes?.account_id);
    const {first_name,middle_name,last_name}=responseJson?.account?.data?.attributes
    const {name_of_organisation,job_title,profile_image, photo}=responseJson?.profile?.data?.attributes
    const {type}=responseJson?.account?.data?.type
    this.setState({userAccountObj:{...this.state.userAccountObj,first_name,middle_name,last_name,profile_image, photo,job_title,name_of_organisation,type}})
  }

  getFollowingCount = () => {
    const header = {
      "Content-type": configJSON.validationApiContentType,
      'token': localStorage.getItem("authToken")
    }
    let httpBody:any = {
      'account_id': localStorage.getItem('account_id')
    }
    let url = configJSON.followingCountUrl;
    const getAccount = apiCall({
      header: header,
      httpBody: httpBody,
      url: url,
      httpMethod: configJSON.followingCountMethod,
    });
    
    this.getFollowingCountCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getFollowingCountResponse(responseJson:any) {
    if(responseJson?.following_count){
      this.setState({ userAccountObj: { ...this.state.userAccountObj, followingCount: responseJson?.following_count } })
    }
  }
  
  getConnectionCount = () => {
    const header = {
      "Content-type": configJSON.validationApiContentType,
      'token': localStorage.getItem("authToken")
    }
    let url = configJSON.connectionCountUrl;
    const getAccount = apiCall({
      header: header,
      httpBody: {},
      url: url,
      httpMethod: configJSON.connectionCountMethod,
    });
    
    this.getConnectionCountCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getConnectionCountResponse = (responseJson:any) => {
    if(responseJson?.connections_count){
      this.setState({ userAccountObj: { ...this.state.userAccountObj, connectionCount: responseJson.connections_count } })
    }
  }
  // Customizable Area End
}
