// Customizable Area Start
import React from 'react'
import LeftProfile from '../../../components/src/LeftProfile.web';
import Messaging from '../../../components/src/Messaging.web';
import HashtagGeneratorController, { Props } from './HashtagGeneratorController';
import { Container, Grid, Box, TextField, withStyles, styled, Button, Menu, MenuItem } from "@material-ui/core";
import Loader from '../../../components/src/Loader.web';
import NavBarWithSearch from '../../ElasticSearch/src/NavBarWithSearch.web';
import { gallery, send } from '../../postcreation/src/assets';
import { filter, hashtag, moreOptions, report } from './assets';
import Feeds1 from '../../../components/src/Feeds1.web';



export default class Hashtag extends HashtagGeneratorController {
    constructor(props: Props) {
        super(props);
    }
    render() {
      
        return (
            <>
                <Loader loading={this.state?.isLoading} />
                <NavBarWithSearch navigation={this.props.navigation} id={''}  />

                <Container style={{ marginTop: '40px' }}>
                    <Grid container spacing={3}>
                        <Grid item md={3}>
                            <LeftProfile userAccountObj={this.state?.userAccountObj} />
                        </Grid>
                        <Grid item md={6}>

                            {/* hashtag container */}
                            <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ position: 'relative', border: '4px solid #BE9E44', borderRadius: '100%', width: '82px', height: '82px' }}>
                                            <div style={webStyle.innerCircle}></div>
                                            <div style={{ position: 'absolute', transform: 'translate(-50%,-50%)', top: '54%', left: '49%' }}><img src={hashtag} width="45px" /></div>


                                        </div>

                                        <div style={{ marginLeft: '15px' }}>
                                            <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{this.state?.selectedHashTagObj?.name}</div>
                                            <div style={{ fontSize: '14px', color: '#565656', margin: '2px 0px 6px 0px' }}>{this.state?.selectedHashTagObj?.followers_count} followers</div>

                                            {this.state?.isHashTagFollowed ?
                                                <StyledFollowButton data-test-id="unFollow" onClick={()=>this.unfollowHashTag(this.state?.selectedHashTagObj?.id)}>Following</StyledFollowButton> :
                                                <StyledFollowButton  data-test-id="follow" onClick={()=>this.followHashTag(this.state?.selectedHashTagObj?.id)}>Follow</StyledFollowButton>
                                            }


                                        </div>

                                    </div>
                                    <div data-test-id="moreOptions" onClick={(e: any) => this.setState({ reportHashTagEl: e.currentTarget })} style={{ cursor: 'pointer' }}>
                                        <img src={moreOptions} style={{ transform: 'rotate(90deg)', }} width='24px' />
                                    </div>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={this.state?.reportHashTagEl}
                                        open={Boolean(this.state?.reportHashTagEl)}
                                        onClose={() => this.setState({ reportHashTagEl: null })}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                        PaperProps={{ style: { boxShadow: "0px 0px 2px rgba(156, 156, 156, 0.169471)", border: '2px solid #F0F0F0', borderRadius: '12px 0px 12px 12px' } }}
                                        anchorPosition={{ top: 50, left: 20 }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}

                                    >
                                        <MenuItem onClick={() => this.props?.navigation.navigate("Managehashtags")} style={{ padding: '15px' }}>
                                            <div style={{ marginRight: '15px' }}><img src={filter} width="24px" /></div>Manage your hashtags
                                        </MenuItem>

                                    </Menu>

                                </div>


                            </Box>



                            {/* new post cntainer */}
                            <Box sx={{ bgcolor: 'background.paper', height: '120px', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0', mt: 2 }}>
                                <div style={webStyles.heading}>New post</div>
                                <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                                <div style={webStyles.newPost}>
                                    <div style={{ padding: '15px 0px' }}>
                                        <InputField
                                            data-test-id="inputPost"
                                            placeholder='Whats in your mind ?'
                                            variant='outlined'
                                            onClick={() => {
                                                this.setState({ openCreatePost: true })

                                            }}
                                        />
                                    </div>

                                    <div>
                                        <img src={gallery} width="24px" />
                                    </div>

                                    <div style={webStyles.sendBox}>
                                        <img src={send} width="16px" alt='send' />
                                    </div>

                                </div>

                            </Box>

                            {/* Feeds component */}
                            <Feeds1 navigation={this.props?.navigation} postData={this.state.postDataWithSelectedHashTags} isLoad={this.state.isLoading} getPostData={this.getPostDataWithHashTags} />
                        </Grid>
                        <Grid item md={3}>
                            <Messaging />
                        </Grid>

                    </Grid>

                </Container>
                

            </>
        )
    }
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 556,
    height: 'auto',
    bgcolor: 'background.paper',
    border: '1px solid #979797',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};
const webStyle = {
    postButton: {
        fontFamily: "HelveticaNeue",
        backgroundColor: '#BE9E44',
        color: 'white',
        width: '70px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '14px',
        cursor: 'pointer'
    },
    innerCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(190, 158, 68, 0.190232',
        width: '70px',
        height: '70px',
        margin: '6px',
        borderRadius: '100%'

    },
    imgClose: {
        position: 'absolute',
        top: '12%',
        left: '95%',
        width: '24px',
        height: '24px',
        backgroundColor: 'white',
        borderRadius: '50%',
        opacity: '0.74',
        transform: 'translate(-50%,-50%)'
    } as React.CSSProperties
}
const webStyles = {
    newPost: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sendBox: {
        width: '32px',
        height: '32px',
        backgroundColor: '#BE9E44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    heading: {
        fontSize: '12px',
        lineHeight: '14px',
        color: '#1C1C1C',
        padding: '5px 0px 5px 0px',
        fontFamily: "HelveticaNeue",


    }
    ,
    feedsFont: {
        fontSize: '14px',
        fontFamily: "HelveticaNeue",
        color: '#565656'
    },
    actionLIne: {
        display: 'flex',
        margin: '5px 0px'
    },
    actionText: {
        fontSize: '16px',
        color: '#696868',
        marginLeft: '10px',
        fontFamily: 'HelveticaNeue'
    }
};
const StyledFollowButton = styled(Button)({
    backgroundColor: '#BE9E44',
    border: '1px solid #BE9E44',
    borderRadius: '5px',
    color: 'white',
    padding: '5px 15px',
    fontSize: '12px',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#BD9E44'

    }

});
const InputField: any = withStyles({
    root: {
        width: '460px',
        paddingTop: '10px',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        }
    }
})(TextField);
// Customizable Area End