// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import LandingPageBlock from '../../blocks/email-account-login/src/LandingPageBlock.web';
import AboutPage from '../../blocks/email-account-login/src/About.web';
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistration.web'
import EmailAccountRegistrationLinkSentWeb from '../../blocks/email-account-registration/src/EmailAccountRegistrationLinkSent.web'
import VerifyEmailWeb from '../../blocks/email-account-registration/src/VerifyEmail.web'
import EmailAccountStudentDetailsWeb from '../../blocks/email-account-registration/src/EmailAccountStudentDetails.web'
import EmailAccountOrganisationDetailsWeb from '../../blocks/email-account-registration/src/EmailAccountOrganisationDetails.web'
import EmailAccountLoginBlockWeb from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web'
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web'
import NewPasswordWeb from '../../blocks/forgot-password/src/NewPassword.web'
import FanWall from '../../blocks/postcreation/src/FanWall.web'
import Hashtag from '../../blocks/HashtagGenerator/src/Hashtag.web'
import ManageHashtags from '../../blocks/HashtagGenerator/src/ManageHashtags.web'
import SearchResult from '../../blocks/postcreation/src/SearchResult.web'
import Settings from '../../blocks/user-profile-basic/src/Settings.web'
import Accounts from '../../blocks/user-profile-basic/src/Accounts.web'
import SignInSecurity from '../../blocks/user-profile-basic/src/SignInSecurity.web'
import Notification from '../../blocks/user-profile-basic/src/Notification.web'
import NotificationsPage from '../../blocks/notifications/src/Notifications.web'
import ManageSubscription from '../../blocks/user-profile-basic/src/ManageSubscription.web'

import AcademicOrganisationProfile from '../../blocks/educational-user-profile/src/AcademicOrganisationProfile.web'
import AllActivityWeb from '../../blocks/educational-user-profile/src/AllActivity.web'
import MessagesPage from '../../components/src/AllMessagesPage.web'
import ConnectionsWeb from '../../blocks/FriendList/src/Connections.web'
import FollowingsWeb from '../../blocks/FriendList/src/Followings.web'
import StudentProfileWeb from "../../blocks/educational-user-profile/src/StudentProfile.web";
import PostProfileGuestsWeb from "../../blocks/educational-user-profile/src/PostProfileGuests.web";
import AccessRequestForGuest from '../../blocks/educational-user-profile/src/AccessRequest.web'
import UserDetailsWeb from '../../blocks/educational-user-profile/src/UserDetails.web';
import AppHeader from '../../components/src/AppHeader.web'
import AppFooter from '../../components/src/AppFooter.web'


const routeMap = {
  Login: {
    component: EmailAccountLoginBlockWeb,
    path: '/Login',
    exact: true
  },
  LandingPage: {
    component: LandingPageBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  About: {
    component: AboutPage,
    path: '/About'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  RegisterEmailSent: {
    component: EmailAccountRegistrationLinkSentWeb,
    path: "/RegistrationEmailSent",
    exact: true
  },
  Register: {
    component: EmailAccountRegistrationWeb,
    path: "/Register",
    exact: true
  },
  VerifyEmail: {
    component: VerifyEmailWeb,
    path: "/email-confirmation/:token/:email/:name",
    // exact: true
  },
  EmailAccountOrganisationDetails: {
    component: EmailAccountOrganisationDetailsWeb,
    path: "/organisation-details",
    exact: true
  },
  RegisterStudentDetails: {
    component: EmailAccountStudentDetailsWeb,
    path: "/student-details",
    exact: true
  },
  ForgotPassword: {
    component: ForgotPasswordWeb,
    path: '/ForgotPassword',
    exact: true
  },
  NewPassword: {
    component: NewPasswordWeb,
    path: '/NewPassword',
    exact: true
  },
  AcademicOrganisationProfileToOther: {
    component: AcademicOrganisationProfile,
    path: '/AcademicOrganisationProfile/:viewerId',
    exact: true
  },
  AcademicOrganisationProfile: {
    component: AcademicOrganisationProfile,
    path: '/AcademicOrganisationProfile',
    exact: true
  },
  FanWall: {
    component: FanWall,
    path: '/FanWall',
    exact: true
  },
  settings: {
    component: Settings,
    path: '/settings',
    exact: true
  },
  SearchResult:{
    component:SearchResult,
    path: '/search',
    exact:true
  },
  hashtag:{
    component:Hashtag,
    path: '/hashtag/:word',
    exact:true
  },
  Managehashtags:{
    component:ManageHashtags,
    path: '/manage-hashtags',
    exact:true
  },

  Connections:{
    component:ConnectionsWeb,
    path: '/Connections',
    exact:true
  },
  Following:{
    component:FollowingsWeb,
    path: '/Followings',
    exact:true
  },
  AllActivity: {
    component: AllActivityWeb,
    path: "/AllActivity",
    exact: true
  },
  MessagesPage:{
    component: MessagesPage,
    path: "/messaging",
    exact: true
  },
  Accounts: {
    component: Accounts,
    path: '/account',
    exact: true
  },
  SignInSecurity: {
    component: SignInSecurity,
    path: '/sign-in-and-security',
    exact: true,
  },
  Notification: {
    component: Notification,
    path: '/notifications',
    exact: true
  },
 
  ManageSubscription: {
    component: ManageSubscription,
    path: '/ManageSubscription',
    exact: true
  },
  StudentProfile: {
    component: StudentProfileWeb,
    path: '/student-profile',
    exact: true
  },
  GuestStudentProfile: {
    component: UserDetailsWeb,
    path: '/user-details/:id',
    exact: true
  },
  PostProfileGuestsWeb: {
    component: PostProfileGuestsWeb,
    path: '/post-profile/:id',
    exact: true
  },
  AccessRequestForGuest: {
    component: AccessRequestForGuest,
    path: '/access-request/:access_id',
  },
  NotificationsPage: {
    component: NotificationsPage,
    path: '/notifications-page',
    exact: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    return (
      <View style={{ height: 'unset', minHeight: "100vh", backgroundColor: "#f6f6f6" }}>
        {/* <TopNav /> */}
        {/* <AppHeader /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        {/* <AppFooter /> */}
      </View>
    );
  }
}

export default App;
