import * as React from "react";

import {
  Modal,
  Typography,
  Box,
  Button,
  createStyles,
  TextField
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import { HighlightOff } from "@material-ui/icons";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import * as Yup from 'yup'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
const style: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 470,
  bgcolor: "#fff",
  borderRadius: 14,
  boxShadow: 24,
  p: 4,
  "@media (max-width: 576px)": {
    padding: "30px",
    width: "80%"
  }
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function ContactPop({ open, handleClose }: any) {
  const [data, setData] = React.useState({ name: "", email: "", message: "" });
  const [isopen, setIsOpen] = React.useState(false);

  const handleContact = (values: any) => {
    handleClose()
    setIsOpen(true);
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_request_management/contact_us"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "data": { ...values }
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const contactUsSchema: any = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    email: Yup.string().email("Invalid email").required("Email address is required."),
    message: Yup.string().required("Message is required."),
  })



  const handleCloseMessage = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <HighlightOff
            style={modalContactStyle.closeIcon}
            onClick={handleClose}
          />
          <Typography style={modalContactStyle.contact_textContent}>
            Contact us
          </Typography>
          <Formik
            initialValues={data}
            validationSchema={contactUsSchema}
            onSubmit={(values: any) => { handleContact(values) }}
            data-test-id="formContactUs"
          >
            {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
              <form onSubmit={handleSubmit}>
                <InputField
                  error={errors.name && touched.name}
                  testID="contactName"
                  data-test-id="inputName"
                  placeholder="Name"
                  fullWidth={true}
                  id="name"
                  name="name"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name &&
                  <div style={modalContactStyle.fieldError}>
                    {errors.name}
                  </div>
                }

                <InputField
                  error={errors.email && touched.email}
                  placeholder="Email address"
                  data-test-id="inputEmail"
                  fullWidth={true}
                  variant="outlined"
                  testID="contactEmail"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email &&
                  <div style={modalContactStyle.fieldError}>
                    {errors.email}
                  </div>
                }
                <TextArea
                  error={errors.message && touched.message}
                  testID="contactMessage"
                  data-test-id="inputMessage"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Message"
                  multiline
                  id="message"
                  name="message"
                  rows={7}
                  variant="outlined"
                  fullWidth={true}
                />
                {errors.message && touched.message &&
                  <div style={{ ...modalContactStyle.fieldError, margin: "0" }}>
                    {errors.message}
                  </div>
                }
                <Button type="submit" style={modalContactStyle.button}>Submit</Button>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Snackbar open={isopen} autoHideDuration={3000} onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity="success">
          Contact-Us form submitted successfully
        </Alert>
      </Snackbar>
    </>
  );
}

const modalContactStyle: any = {
  contact_textFelid: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
  },
  formStyle: {
    width: "90%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "350px",
    gap: 10,
  },
  contact_textContent: {
    fontSize: "20px",
    textAlign: "center",
    marginBottom: "20px"
  },
  textArea: {
    marginBottom: "20px",
    justifyContent: "flex-start",
    outlineStyle: "none",
  },
  button_ButtonsContainedEnabledCopy3Button: {
    position: "absolute",
    opacity: 1,
    backgroundColor: "rgba(190, 158, 68, 1)",
    justifyContent: "center",
  },
  button: {
    background: "#BE9E44",
    color: "#FFFFFF",
    width: "100%",
    height: "50px",
    textTransform: "capitalize",
    borderRadius: "8px",
    fontSize: "16px",
    marginTop: "15px",
    "&:hover": {
      background: "#BE9E44",
    },
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "20px",
    top: "20px"
  },
  fieldError: {
    color: "#D11024",
    fontSize: "14px",
    margin: "-7px 5px 5px 0"
  },
};

const InputField: any = withStyles({
  root: {
    width: '100%',
    padding: '10px 0',
    borderRadius: "10px",
    fontFamily: "HelveticaNeue-medium",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontFamily: "HelveticaNeue",
      fontSize: "16px",
      "& fieldset": {
        fontSize: "16px",
        borderRadius: "10px",
        borderWidth: 1,
        fontWeight: 400,
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)"
      }
    },
    "& .Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D11024"
      }
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#565656",
      fontFamily: "HelveticaNeue-Medium",
    }
  }
})(TextField);

const TextArea: any = withStyles({
  root: {
    width: '100%',
    paddingTop: '10px',
    borderRadius: "10px",
    fontFamily: "HelveticaNeue",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontFamily: "HelveticaNeue-Medium",
      fontSize: "16px",
      "& fieldset": {
        fontSize: "16px",
        borderRadius: "10px",
        borderWidth: 1,
        fontWeight: 400,
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#565656",
      fontFamily: "HelveticaNeue",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)"
      }
    },
    "& .Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D11024"
      }
    },
  }
})(TextField);