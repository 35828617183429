// Customizable Area Start
import React, { useEffect, useState } from 'react';
import { Menu, MenuItem } from "@material-ui/core";
import axios from 'axios';
import { isLoggedIn } from './common';
import { userAccImg } from '../../blocks/ElasticSearch/src/assets';

const { baseURL: apiBaseUrl } = require('../../framework/src/config.js')
const moreOptions = require("../src/moreOptions.png");
const profile = require("../src/profile.png");
const like = require("../src/likeReaction.png");
const smilie = require("../src/smilie.png");
const funny = require("../src/emoji.png");
const love = require("../src/love.png");
const support1 = require("../src/support1.png");
const support = require("../src/support.png");
const filledLike = require("../src/filledLike.png");
const filledHeart = require("../src/filledHeart.png");

interface ReplyComments {
    reply: any
    comment: any
    setCommentId: any
    setPostId: any
    setReportModal: any
    setCommentOfCommentIdForReaction: any
    setReactionModal: any
    getAllReactionAccountsComments: any
    openReplyCommentBox: any
    setReplyCommentBox: any
    loadReplies: any

}
function CommentsReplyComponent(props: ReplyComments) {
    const { reply,
        comment,
        setCommentId,
        setPostId,
        setReportModal,
        setCommentOfCommentIdForReaction,
        setReactionModal,
        getAllReactionAccountsComments,
        setReplyCommentBox,
        loadReplies
    } = props;

    const reactionTypeToIconMap: any = {
        Like: { icon: like, text: 'Like' },
        Love: { icon: love, text: 'Love' },
        Support: { icon: support, text: 'Support' },
        Funny: { icon: funny, text: 'Funny' }
    };

    const [reportCommentEl, setReportCommentEl] = useState(null);
    const [openReplyReactionBox, setReplyReactionBox] = useState(false);
    const [dynamicReactionId, setDynamicReactionId] = useState({
        icon: '',
        text: ''
    })
    const [replyLikeCount, setReplyLikeCount] = useState(reply?.likes_count?.total_likes);

    const [likedBy, setLikedBy] = useState(reply?.liked_by_account_ids);
    const [likesCountForReply, setLikesCountForReply] = useState({ ...reply?.likes_count });

    const [reactionSelectedByUser, setReactionByUser] = useState('');
    const [arr1, setAllArr] = useState<any>([])


    useEffect(() => {
        
        getAllReactionReply(reply?.id)
    }, [])

    const convertToHours = (timeStamp: any) => {
        const date = new Date(timeStamp);
        const currentTime = new Date();

        const diffInMillis = currentTime.getTime() - date.getTime();
        const diffInMinutes = Math.round(diffInMillis / (1000 * 60));
        const diffInHours = Math.round(diffInMillis / (1000 * 60 * 60));

        return `${diffInHours == 0 ? diffInMinutes == 0 ? 'now' : `${diffInMinutes}m` : `${diffInHours>24?`${Math.floor(diffInHours/24)}d`:`${diffInHours}h`}`}`
    }
    const createCommentReplyLike = (comment: any, id: any, type: any) => {
        
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };
        const formData = new FormData();
        formData.append('comment_id', id);
        formData.append('reaction_type', type)

        axios.post(`${apiBaseUrl}/bx_block_like/likes`, formData, {
            headers: header
        })
            .then((response: { data: any; }) => {
                console.log(response?.data);
                loadReplies(comment?.id, '')




            })
            .catch(error => {
                console.log(error);
            });

    }
    const destroyLike = async (cId: any) => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };


        try {

            let response = await axios.delete(`${apiBaseUrl}/bx_block_like/likes/${cId}?likeable_id=${cId}`, {
                headers: header
            })
            console.log(response)
            loadReplies(comment?.id, '')


        }
        catch (error) {

            console.error(error);
        }

    }


    const handleReplyofReply = (id: any, obj: any) => {
        setCommentId(obj?.id)
        setReplyCommentBox(id)


    }
    const renderReplyIconText = (obj: any, reactionSelectedByUser: any) => {

        let reactionText = reactionSelectedByUser.toLowerCase();
        if (reactionText == 'like') {
            reactionText = 'likes'
        }
        let tempArr: any = ''
        Object.keys(obj)?.map((item: any) => {
            if (item != 'total_likes') {
                if (item == reactionText) {
                    tempArr = item

                }

            }

        })
        switch (tempArr) {
            case 'likes': return 'Like';
            case 'love': return 'Love';
            case 'support': return 'Support'
            case 'funny': return 'Funny'
            default:
                break;
        }

    }
    const getAllReactionReply = (id: any) => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };



        axios.get(`${apiBaseUrl}/bx_block_like/show_accounts_for_reactions_to_comment?comment_id=${id}`, {
            headers: header
        })
            .then((response: { data: any; }) => {
                setAllArr(
                    response?.data?.total_accounts?.map((item: any) => ({
                        ...item,
                        ...reactionTypeToIconMap[item?.reaction_type]
                    }))
                );


                response?.data?.total_accounts?.find((acc: any) => {
                    if (acc?.id == JSON.parse(localStorage.getItem('account_id') || '')) {

                        setReactionByUser(acc?.reaction_type)

                    }

                })

            })
            .catch(error => {
                console.log(error);
            });

    }
    const handleDynamicReactionBox = (icon: any, text: any, objText: any, icon1: any) => {
        setReplyReactionBox(false)
        likedBy?.includes(JSON.parse(localStorage.getItem('account_id') || '')) ?
            (
                setReplyLikeCount(replyLikeCount)
            ) :
            (

                setLikesCountForReply({ ...likesCountForReply, objText: likesCountForReply[objText] + 1 }),
                setLikedBy(likedBy.concat(JSON.parse(localStorage.getItem('account_id') || ''))),
                setReplyLikeCount(replyLikeCount + 1)
            );

        const objectExists = arr1.some((obj: { id: any, reaction_type: any }) => obj?.id === JSON.parse(localStorage.getItem('account_id') || ''));
        const newArray = objectExists
            ? arr1.map((item: any) => item?.id === JSON.parse(localStorage.getItem('account_id') || '') ? { ...item, icon: icon1, text } : item)
            : [...arr1, { id: JSON.parse(localStorage.getItem('account_id') || ''), icon: icon1, text }];

        setAllArr(newArray);



        createCommentReplyLike(comment, reply?.id, text)
        setDynamicReactionId({ ...dynamicReactionId, icon, text });

    }
    return (
        <React.Fragment>
            {/* replies */}
            <div key={reply?.id}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <div style={{ marginRight: '10px' }}><img src={reply?.profile_image??userAccImg} style={{ borderRadius: '100%' }} width="48px" height='48px'/></div>
                    <div style={{ backgroundColor: '#F7F7F7', width: '445px', height: 'auto', padding: '10px', borderRadius: '0px 10px 10px 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: '14px', fontFamily: "HelveticaNeue", }}>
                                <div>{reply?.account_name}</div>
                                <div style={{ color: '#7C7C7C' }}>{reply?.organisation_name}</div>

                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: '5px', fontSize: '12px' }}>{convertToHours(comment?.created_at)}</div>
                                <div style={{ cursor: 'pointer' }}
                                    onClick={(e: any) => {
                                        setReportCommentEl(e.currentTarget);
                                        console.log(reply?.id)
                                        setCommentId(reply?.id);
                                        setPostId('')

                                    }}

                                >
                                    <img src={moreOptions} style={{ transform: 'rotate(90deg)' }} width='24px' />
                                </div>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={reportCommentEl}
                                    open={Boolean(reportCommentEl)}
                                    onClose={() => setReportCommentEl(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    PaperProps={{ style: { boxShadow: "0px 0px 2px rgba(156, 156, 156, 0.169471)", border: '1px solid #F0F0F0', borderRadius: '6px 0px 6px 6px' } }}

                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}

                                ><MenuItem onClick={() => {

                                    setReportModal(true)
                                }}>Report Comment
                                    </MenuItem>

                                </Menu>
                            </div>
                        </div>
                        <div style={{ marginTop: '10px', fontSize: '14px', fontFamily: "HelveticaNeue", }}>
                            {reply?.comment}
                        </div>
                        {reply?.gif && <div><img src={reply?.gif} width="50px" /></div>}

                    </div>
                </div>
                <div style={{ marginTop: '10px', marginLeft: '105px' }}>
                    <div style={{ position: 'relative', display: 'flex', fontSize: '14px', fontFamily: "HelveticaNeue", color: '#666666' }}>
                        {openReplyReactionBox && <div
                            onMouseEnter={() => setReplyReactionBox(true)}
                            onMouseLeave={() => setReplyReactionBox(false)}

                            style={webStyles?.reactionBoxComments}>
                            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '5px' }}>
                                <div className='reactionHover' onClick={() => handleDynamicReactionBox(filledLike, 'Like', 'likes', like)} ><img src={filledLike} width="24px" alt="likes" /></div>
                                <div className='reactionHover' onClick={() => handleDynamicReactionBox(support1, 'Support', 'support', support)} ><img src={support1} width="24px" alt="support" /></div>
                                <div className='reactionHover' onClick={() => handleDynamicReactionBox(filledHeart, 'Love', 'love', love)} ><img src={filledHeart} width="24px" alt="heart" /></div>
                                <div className='reactionHover' onClick={() => handleDynamicReactionBox(smilie, 'Funny', 'funny', funny)}><img src={smilie} width="24px" alt="smilie" /></div>
                            </div>
                        </div>}
                        <div
                            className='likeButton'
                            onMouseEnter={() => setReplyReactionBox(true)}
                            onMouseLeave={() => setReplyReactionBox(false)}

                            style={{
                                marginRight: replyLikeCount > 0 ? '10px' : '0px', cursor: 'pointer',

                            }}>
                            {dynamicReactionId?.icon == '' ? <>
                                <div style={webStyles.actionText}>

                                    {likedBy?.includes(JSON.parse(localStorage.getItem('account_id') || '')) ? <span
                                        onClick={() => {
                                            if (isLoggedIn(null)) {


                                                console.log('dislike 1')
                                                setReplyReactionBox(false)
                                                setLikedBy(likedBy?.filter((item: any) => {
                                                    return item !== JSON.parse(localStorage.getItem('account_id') || '')
                                                }));
                                                setAllArr(arr1?.filter((item: any) => {
                                                    return item?.id !== JSON.parse(localStorage.getItem('account_id') || '')
                                                }));

                                                setReplyLikeCount(replyLikeCount - 1);
                                                destroyLike(reply?.id);
                                                setDynamicReactionId({ ...dynamicReactionId, icon: '', text: '' })
                                            }
                                        }}
                                        style={{ color: '#BE9E44' }}>{renderReplyIconText(reply?.likes_count, reactionSelectedByUser)}

                                    </span> :
                                        <span onClick={() => {
                                            if (isLoggedIn(null)) {


                                                console.log('like');
                                                setReplyReactionBox(false)
                                                setLikedBy(likedBy.concat(JSON.parse(localStorage.getItem('account_id') || '')));
                                                setAllArr([...arr1, { id: JSON.parse(localStorage.getItem('account_id') || ''), icon: like, text: 'Like' }])
                                                setReplyLikeCount(replyLikeCount + 1);
                                                setDynamicReactionId({ ...dynamicReactionId, icon: filledLike, text: 'Like' });
                                                createCommentReplyLike(comment, reply?.id, 'Like')
                                            }
                                        }}>Like</span>}

                                    {replyLikeCount > 0 && <span> .</span>}
                                </div>

                            </> : <>
                                <div style={webStyles.actionText}>
                                    {dynamicReactionId?.text == '' ? <span onClick={() => {

                                        createCommentReplyLike(comment, reply?.id, 'Like')
                                    }}>Like</span> : <span
                                        onClick={() => {
                                            if (isLoggedIn(null)) {
                                                console.log('dislike 2');
                                                setReplyReactionBox(false)
                                                setLikedBy(likedBy?.filter((item: any) => {
                                                    return item !== JSON.parse(localStorage.getItem('account_id') || '')
                                                }));

                                                setAllArr(arr1?.filter((item: any) => {
                                                    return item?.id !== JSON.parse(localStorage.getItem('account_id') || '')
                                                }));
                                                setReplyLikeCount(replyLikeCount - 1);
                                                destroyLike(reply?.id);
                                                setDynamicReactionId({ ...dynamicReactionId, icon: '', text: '' })
                                            }
                                        }}
                                        style={{ color: '#BE9E44' }}>{dynamicReactionId.text}</span>}

                                    {replyLikeCount > 0 && <span> .</span>}
                                </div>


                            </>}




                        </div>
                        {/* <div
                            className='likeButton'
                            onMouseEnter={() => setReplyReactionBox(true)}
                            onMouseLeave={() => setReplyReactionBox(false)}
                            style={{ cursor: 'pointer', marginRight: reply?.likes_count?.total_likes > 0 ? '10px' : '0px' }}>
                            {reply?.liked_by_account_ids?.includes(JSON.parse(localStorage.getItem('account_id') || '')) ? <span
                                onClick={() => destroyLike(reply?.id)}
                                style={{ color: '#BE9E44' }}>Like</span> : <span onClick={() => createCommentReplyLike(comment, reply?.id, 'Like')}>Like</span>}



                            {reply?.likes_count?.total_likes > 0 && <span>.</span>}
                        </div> */}

                        {replyLikeCount > 0 && <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
                            {arr1?.reduce((uniqueArr: any[], current: any) => {
                                if (!uniqueArr.find((item) => item.icon === current.icon)) {
                                    uniqueArr.push(current);
                                }
                                return uniqueArr;
                            }, []).map((icon: any, index: any) => (
                                <img key={index} src={icon.icon} width="16px" style={{ marginLeft: index > 0 ? "-4px" : "0px" }} />
                            ))}

                        </div>}
                        {replyLikeCount > 0 && <div className='reactionCount'
                            onClick={() => {
                                setCommentOfCommentIdForReaction(reply?.id)
                                setReactionModal(true);
                                getAllReactionAccountsComments(reply?.id)

                            }}

                            style={webStyles.feedsFont}>{replyLikeCount}</div>}
                        <div onClick={() => handleReplyofReply(reply?.id, comment)} style={{ borderLeft: '1px solid #666666', margin: '0px 10px', paddingLeft: '10px', cursor: 'pointer' }}>
                            Reply
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
const webStyles = {
    newPost: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sendBox: {
        width: '32px',
        height: '32px',
        backgroundColor: '#BE9E44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    heading: {
        fontSize: '12px',
        lineHeight: '14px',
        color: '#1C1C1C',
        padding: '5px 0px 5px 0px',
        fontFamily: "HelveticaNeue",


    }
    ,
    feedsFont: {
        fontSize: '14px',
        fontFamily: "HelveticaNeue",

    },
    actionLIne: {
        display: 'flex',
        margin: '5px 0px',
        cursor: 'pointer',
        alignItems: 'center'
    },
    actionText: {
        fontSize: '14px',
        color: '#696868',
        fontFamily: 'HelveticaNeue'
    },
    commentsPostButton: {
        backgroundColor: '#BE9E44',
        color: 'white',
        padding: '5px 15px',
        fontSize: '12px',
        borderRadius: '4px',
        fontFamily: "HelveticaNeue",
        cursor: 'pointer'
    },
    reactionBox: {
        position: 'absolute',
        transform: 'translate(-50%,-50%)',
        width: '176px',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.127841)',
        borderRadius: '6px',
        top: '-22px',
        left: '85px',
        border: '1px solid #F0F0F0'
    } as React.CSSProperties,
    reactionBoxComments: {
        position: 'absolute',
        transform: 'translate(-50%,-50%)',
        width: '176px',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.127841)',
        borderRadius: '6px',
        top: '-22px',
        left: '85px',
        border: '1px solid #F0F0F0'
    } as React.CSSProperties,
    reactionHover: {
        transform: 'scale(1.2)'
    },
    loadReply: {
        margin: '10px 0px 10px 60px',
        cursor: 'pointer',
        fontSize: '14px',
        fontFamily: "HelveticaNeue",
        boxItem: {
            display: 'flex',
            padding: '10px',
            alignItems: 'center',
            gap: '10px'
        },
        boxImg: {
            width: '15px',
            height: '15px',
        }
    }
}

export default CommentsReplyComponent
// Customizable Area End