import React from "react";
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
} from "@material-ui/core";
import * as Yup from 'yup'
import { HighlightOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
const close = require("../src/close.png");

const style: any = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    borderRadius: 14,
    boxShadow: 24,
    p: 3,
    overflow: "auto",
};

const AboutModal = ({ open, handleClose, studentProfileDetails, handlePatchProfileDetails }: any) => {

    const contactUsSchema: any = Yup.object().shape({
        hobbies: Yup.string().required('Hobbies is required.'),
        personality: Yup.string().required('Personality is required.'),
        goal: Yup.string().required('Goal is required.'),
    })

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img
                        src={close}
                        style={modalContactStyle.closeIcon}
                        onClick={handleClose}
                    />
                    <Typography style={modalContactStyle.contact_textContent}>
                        About
                    </Typography>
                    <Formik
                        initialValues={{
                            hobbies: studentProfileDetails?.profile?.data?.attributes?.hobbies,
                            goal: studentProfileDetails?.profile?.data?.attributes?.goal,
                            personality: studentProfileDetails?.profile?.data?.attributes?.personality
                        }}
                        validationSchema={contactUsSchema}
                        onSubmit={(values: any) => handlePatchProfileDetails(values)
                        }
                    >
                        {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
                            <form onSubmit={handleSubmit}>
                                <Box style={modalContactStyle.main}>
                                    <Box>
                                        <Box style={modalContactStyle.field}>
                                            <label>Hobbies *</label>
                                            <InputField
                                                error={errors.achievements && touched.achievements}
                                                testID="contactName"
                                                data-test-id="inputName"
                                                placeholder="Hobbies"
                                                fullWidth={true}
                                                id="hobbies"
                                                name="hobbies"
                                                variant="outlined"
                                                value={values.hobbies}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            {errors.hobbies && touched.hobbies &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.hobbies}
                                                </div>
                                            }

                                        </Box>
                                        <Box style={modalContactStyle.field}>
                                            <label>Personality *</label>
                                            <InputField
                                                error={errors.personality && touched.personality}
                                                testID="contactName"
                                                data-test-id="inputName"
                                                placeholder="Personality"
                                                fullWidth={true}
                                                id="personality"
                                                name="personality"
                                                variant="outlined"
                                                value={values.personality}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            {errors.personality && touched.personality &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.personality}
                                                </div>
                                            }

                                        </Box>
                                        <Box style={modalContactStyle.field}>
                                            <label>Goal *</label>
                                            <InputField
                                                error={errors.goal && touched.goal}
                                                testID="contactName"
                                                data-test-id="inputName"
                                                placeholder="Goal"
                                                fullWidth={true}
                                                id="goal"
                                                name="goal"
                                                variant="outlined"
                                                value={values.goal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            {errors.goal && touched.goal &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.goal}
                                                </div>
                                            }

                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box
                                            style={modalContactStyle.submit}
                                        >
                                            <Button variant="contained" type="submit" style={modalContactStyle.submitButton}>
                                                Save
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </>
    );
}

export default AboutModal;


const modalContactStyle: any = {
    contact_textContent: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px"
    },
    closeIcon: {
        cursor: "pointer",
        position: "absolute",
        right: "20px",
        top: "20px",
        height: "20px",
    },
    fieldError: {
        color: "#D11024",
        fontSize: "14px",
        margin: "-7px 5px 10px 0"
    },
    main: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    name: {
        width: "426px"
    },
    field: {
        width: "876px",
        marginBottom: "10px"
    },


    submitButton: {
        height: "36px",
        backgroundColor: "#BE9E44",
        borderRadius: "6px",
        color: "#fff",
    },
    submit: {
        width: "876px",
        display: "flex",
        justifyContent: "end"
    }

};

const InputField: any = withStyles({
    root: {
        padding: '10px 0',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue-medium",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)"
            }
        },
        "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D11024"
            }
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue-Medium",
        }
    }
})(TextField);