import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Grid,
    Paper,
    Button,
    Typography,
    Select,
    MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import { NavLink } from 'react-router-dom';
import AppFooter from "../../../components/src/AppFooter.web";
import { ContactPop } from "../../../components/src/ContactUsModal.web";
import { TermsConditionsPop } from "../../../components/src/TermsConditionsPop.web";
import { ArrowIcon, ConnectionLightIcon, edConnectLogo, FollwingLightIcon, MenuIcon, SearchIcon, UserIcon } from "./assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import FriendListController, {
    Props,
} from "./FriendListController";
import NavBarWithSearch from "../../ElasticSearch/src/NavBarWithSearch.web";
import CompanyInfo from "../../../components/src/CompanyInfo";
// Customizable Area End

export class Follwings extends FriendListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    responsiveSideMenu(classes: any) {
        return (<>
            <CustomPaper className={classes.userDetailsResponsive}>
                <CustomTypography className={classes.userDetailsTitle}>Manage my network</CustomTypography>
                <CustomTypography className={classes.userConnections} onClick={() => { this.props.navigation.navigate("Connections") }}>
                    <img src={ConnectionLightIcon} style={webStyle.userDetailsIcon} alt="connection-icon" />
                    <span>Connections</span>
                </CustomTypography>
                {this.state.showMore && <>
                    <CustomTypography className={classes.userFollowing} onClick={() => { this.props.navigation.navigate("Following") }}>
                        <img src={FollwingLightIcon} style={webStyle.userDetailsIcon} alt="Follwing-icon" />
                        <span className={classes.ActiveUserConnectionDetails}>Following</span>
                    </CustomTypography>
                    <CustomTypography className={classes.userHashtags} onClick={() => { this.props.navigation.navigate("Managehashtags") }}>
                        <span style={webStyle.userDetailsIcon}>#</span>
                        <span>Hashtags</span>
                    </CustomTypography>
                </>}

                {this.state.showMore ? <CustomTypography className={classes.showMoreLess} onClick={() => { this.setState({ showMore: false }) }}>
                    Show less <img src={ArrowIcon} style={{ transform: 'rotate(180deg)', width: "30px", height: "25px" }} alt="arrow icon" />
                </CustomTypography> :
                    <CustomTypography className={classes.showMoreLess} onClick={() => { this.setState({ showMore: true }) }}>
                        Show more <img src={ArrowIcon} style={{ width: "30px", height: "25px" }} alt="arrow icon" />
                    </CustomTypography>
                }
            </CustomPaper>
        </>)
    }

    simpleSideMenu(classes: any) {
        return (<>
            <CustomPaper className={classes.userDetails}>
                <CustomTypography className={classes.userDetailsTitle}>Manage my network</CustomTypography>
                <CustomTypography className={classes.userConnections} onClick={() => { this.props.navigation.navigate("Connections") }}>
                    <img src={ConnectionLightIcon} style={webStyle.userDetailsIcon} alt="connection-icon" />
                    <span>Connections</span>
                </CustomTypography>

                <CustomTypography className={classes.userFollowing} onClick={() => { this.props.navigation.navigate("Following") }}>
                    <img src={FollwingLightIcon} style={webStyle.userDetailsIcon} alt="Follwing-icon" />
                    <span className={classes.ActiveUserConnectionDetails}>Following</span>
                </CustomTypography>
                <CustomTypography className={classes.userHashtags} onClick={() => { this.props.navigation.navigate("Managehashtags") }}>
                    <span style={webStyle.userDetailsIcon}>#</span>
                    <span>Hashtags</span>
                </CustomTypography>
            </CustomPaper>
        </>)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Loader loading={this.state.isLoading} />
                <NavBarWithSearch navigation={this.props.navigation} id={''} />
                <Container style={webStyle.container}>
                    <Grid container spacing={2} className={classes.myActivity}>
                        <Grid item xs={12} sm={3} md={3} className={classes.userDetailsColumn}>
                            {this.simpleSideMenu(classes)}
                            {this.responsiveSideMenu(classes)}
                        </Grid>
                        <Grid item xs={12} sm={9} md={6}>
                            <CustomPaper style={webStyle.about}>
                                <CustomTypography style={webStyle.title}>75 Connections</CustomTypography>
                                <Box className={classes.follwingHeader}>
                                    <CustomTypography className={classes.sortFilter}>
                                        Sort by :
                                        <Select
                                            id="sortby"
                                            name="sortby"
                                            style={webStyle.sortbyFilter}
                                            disableUnderline
                                            MenuProps={{
                                                PaperProps: {
                                                  style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    width: 150,
                                                  },
                                                },
                                                anchorOrigin: {
                                                  vertical: "bottom",
                                                  horizontal: "left"
                                                },
                                                transformOrigin: {
                                                  vertical: "top",
                                                  horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                              }}
                                        >
                                            <MenuItem value="" selected>Recently added</MenuItem>
                                            <MenuItem value="0">First name</MenuItem>
                                            <MenuItem value="1">Last name</MenuItem>
                                        </Select>
                                    </CustomTypography>
                                    <Box className={classes.alumniBtn}>
                                        <div className={classes.searchInput}>
                                            <input
                                                type="text"
                                                id="search"
                                                name="search"
                                                placeholder="Search by name"
                                                className={classes.searchField}
                                            />
                                            <img src={SearchIcon} style={webStyle.searchIcon} alt="search icon" />
                                        </div>
                                    </Box>
                                </Box>

                                {this.state.mockData && this.state.mockData?.map((userData: any, index: any) => {
                                    return (<>
                                        <hr style={{ borderTop: "0.03rem solid #d7d7d7", margin: "15px 0" }} key={index} />
                                        <Box className={classes.connectionUserDetails} key={index}>
                                            <Box style={{ display: "flex", width: "75%" }}>
                                                <div
                                                    className={classes.userImage}
                                                >
                                                    <img src={UserIcon} className={classes.userProfileImage} alt="user-profile-image" />
                                                </div>
                                                <Box>
                                                    <CustomTypography style={webStyle.userName}>{userData.name}</CustomTypography>
                                                    <CustomTypography style={webStyle.userClg}>{userData.college}</CustomTypography>
                                                    <CustomTypography style={webStyle.userAddress}>{userData.connected}</CustomTypography>
                                                </Box>
                                            </Box>
                                            <Box className={classes.actionButtons}>
                                                <img src={MenuIcon} className={classes.removeIcon} alt="remove icon" />
                                            </Box>
                                        </Box>
                                    </>)
                                })}
                            </CustomPaper>

                        </Grid>
                        <Grid item xs={3} className={classes.footer}>
                            <Box className={classes.footerSec}>
                                <CompanyInfo/>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <AppFooter />
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: any = {
    container: {
        minHeight: "calc(100vh - 270px)"
    },
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    footerMenus: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: "15px"
    },
    footerContent: {
        fontSize: "10px",
        color: "#7c7c7c",
        marginTop: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    userName: {
        fontSize: "16px",
        color: "#1c1c1c",
        fontWeight: "normal"
    },
    userClg: {
        fontSize: "14px",
        color: "#1c1c1c",
        fontWeight: "normal"
    },
    userAddress: {
        fontSize: "12px",
        color: "#6a6a6a",
        fontWeight: "normal",
        margin: "7px 0 0"
    },
    about: {
        position: 'relative',
        padding: '24px'
    },
    title: {
        fontSize: "18px",
        fontWeight: "normal",
        color: "#1c1c1c"
    },
    sortbyFilter: {
        minWidth: '120px',
        marginLeft: "10px"
    },
    searchIcon: {
        width: "20px",
        height: "20px",
        position: "absolute",
        left: "11px",
        top: "11px"
    },
    userDetailsIcon: {
        width: "20px",
        height: "20px",
        marginRight: "15px"
    },
};

const styles: any = () => ({
    footer: {
        "@media (max-width: 959px)": {
            display: "none"
        }
    },
    myActivity: {
        "@media (max-width: 768px)": {
            justifyContent: 'center'
        }
    },
    userDetails: {
        padding: "24px",
        position: "sticky",
        top: "97px",
        "@media (max-width: 576px)": {
            margin: "15px 0 0",
            display: "none"
        }
    },
    userDetailsResponsive: {
        padding: "24px",
        position: "sticky",
        top: "97px",
        display: "none",
        "@media (max-width: 600px)": {
            margin: "15px 0 0",
            display: "block"
        }
    },
    userDetailsColumn: {
        "@media (max-width: 576px)": {
            paddingBottom: "0"
        }
    },
    footerSec: {
        position: "sticky",
        top: "97px"
    },
    footerMenu: {
        fontSize: "10px",
        color: "#7c7c7c",
        margin: "5px 20px",
        cursor: "pointer",
        "@media (max-width: 1200px)": {
            margin: "5px 25px",
        }
    },
    coverImage: {
        width: "100%",
        height: "100%"
    },
    profileImage: {
        width: "74px",
        height: "74px",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%)",
        top: "25px"
    },
    followerText: {
        fontSize: "12px",
        color: "#1c1c1c"
    },
    followerNumber: {
        fontSize: "12px",
        color: "#be9e44"
    },
    userDetailsTitle: {
        margin: "0 0 15px 0",
        fontWeight: "normal",
    },
    userConnections: {
        margin: "13px 0",
        fontSize: "16px",
        color: "#a8a8a8",
        textAlign: "center",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    userFollowing: {
        margin: "13px 0",
        fontSize: "16px",
        textAlign: "center",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    userHashtags: {
        margin: "13px 0",
        fontSize: "16px",
        color: "#a8a8a8",
        textAlign: "center",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    followerDetails: {
        display: 'flex',
        justifyContent: "space-between",
        margin: "10px 20px",
        color: "#7c7c7c",
    },
    activeFilterButton: {
        width: "100px",
        height: "38px",
        margin: "0 6px",
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "4px",
        textTransform: "capitalize",
        "@media (max-width: 768px)": {
            width: "auto",
            margin: '5px'
        }
    },
    connectionUserDetails: {
        display: 'flex',
        justifyContent: "space-between",
        position: "relative",
        margin: "15px 0px",
        "@media (max-width: 576px)": {
            flexDirection: "column",
        }
    },
    userImage: {
        width: "64px",
        minWidth: "64px",
        height: "64px",
        minHeight: "64px",
        border: "1px solid black",
        borderRadius: "50%",
        marginRight: "10px",
        "@media (max-width: 576px)": {
            width: "50px",
            minWidth: "50px",
            height: "50px",
            minHeight: "50px"
        }
    },
    actionButtons: {
        display: "flex",
        alignItems: "center",
        "@media (max-width: 576px)": {
            alignItems: "center",
            justifyContent: "start",
            marginLeft: "60px"
        }
    },
    follwingHeader: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "10px",
        flexWrap: "wrap-reverse",
        "@media (max-width: 576px)": {
            flexDirection: "column-reverse",
            alignItems: "end",
        }
    },
    sortFilter: {
        display: "flex",
        alignItems: "center",
        color: "#565656",
        margin: "5px 0",
        "@media (max-width: 576px)": {
            marginTop: "10px"
        }
    },
    alumniBtn: {
        display: "flex",
        margin: "5px 0",
        "@media (max-width: 576px)": {
            width: "100%"
        }
    },
    searchInput: {
        position: "relative",
        "@media (max-width: 576px)": {
            width: "100%"
        }
    },
    removeIcon: {
        width: "24px",
        height: "24px",
        transform: "rotate(90deg)",
        "@media (max-width: 576px)": {
            position: "absolute",
            top: "0",
            right: "0"
        }
    },
    searchField: {
        width: "315px",
        height: "42px",
        border: "none",
        borderRadius: '6px',
        backgroundColor: "#f7f7f7",
        color: "#1c1c1c",
        paddingLeft: "40px",
        "&:focus": {
            outline: "none"
        },
        "@media (max-width: 576px)": {
            width: "100%"
        }
    },
    ActiveUserConnectionDetails: {
        textDecoration: "underline"
    },
    pendingRequestTitle: {
        padding: "18px 0 5px 24px"
    },
    acceptButton: {
        backgroundColor: "#be9e44",
        color: "#ffffff"
    },
    userProfileImage: {
        width: "100%"
    },
    showMoreLess: {
        display: "none",
        color: "#7c7c7c",
        margin: "15px 0 0 5px",
        "@media (max-width: 576px)": {
            display: "flex",
        }
    }
})

const CustomTypography: any = withStyles({
    root: {
        color: "#1c1c1c"
    },
})(Typography);

const CustomButton: any = withStyles({
    root: {
        width: "100px",
        height: "38px",
        margin: "0 6px",
        background: "rgba(190, 158, 68, 0.1)",
        color: "#be9e44",
        borderRadius: "6px",
        textTransform: "capitalize",
        border: "1px solid #be9e44",
        "&:hover": {
            color: "#f8f8f8",
            background: "#be9e44",
        },
        "@media (max-width: 768px)": {
            width: "auto",
            margin: '5px'
        }
    },
})(Button);

const CustomPaper: any = withStyles({
    root: {
        boxShadow: "unset",
        margin: "15px 0",
        border: "1px solid #e2e2e2",
        padding: "15px",
        borderRadius: "10px"
    }
})(Paper);

export default withStyles(styles)(Follwings)
// Customizable Area End
