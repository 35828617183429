import React, { useEffect } from 'react'
import {
  Box,
  Grid,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  Typography,
  Container
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from 'react-router-dom';
import { TermsConditionsPop } from './TermsConditionsPop.web';
import AgeConfirmationModal from './AgeConfirmationModal.web';
const configJSON = require("../../blocks/email-account-login/src/config.js");
const EdConnectLogo = require("../src/edconnect_logo.png");
// import baseURL from '../../framework/src/config.js';
const baseURL = require("../../framework/src/config.js");


export default function AppHeader() {
  let urlRoute: any = window.location.pathname;
  console.log("url", urlRoute)
  // const userDetails = getUserdetails();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [openTermsMod, setOpenTermsMod] = React.useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const [openAgeConfirm, setOpenAgeConfirm] = React.useState(false);
  const [termsPolicesData, setTermsPolicesData] = React.useState({});

  const handleOpenAgeConfirm = () => setOpenAgeConfirm(true);

  const handleCloseAgeConfirm = () => setOpenAgeConfirm(false);

  const handlePrivacyPolicyModal = () => {
    setOpenPrivacyPolicy(!openPrivacyPolicy); 
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleTermsAndConditionsModal = () => {
    setOpenTermsMod(!openTermsMod);
  };

  useEffect(() => {
    fetch(baseURL.baseURL + "/" +configJSON?.TermAndConditonsEndPoint)
    .then((response:any) => response.json())
    .then((res:any)=> {
      setTermsPolicesData(res);
    });
  },[]);

  return <Toolbar disableGutters style={webStyle.topBar}>
      <Container style={webStyle.container}>
    <NavLink to="/">
      <img style={webStyle.logo} className="logo" src={EdConnectLogo} />
    </NavLink>
    <Grid container justifyContent="flex-end" alignItems="center">
      <Box sx={{ display: { xs: "none", sm: "flex", md: 'flex', lg: 'flex' } }} className="appBarRoute">
        <ResponsiveAppHeader 
        urlRoute={urlRoute} 
        handleOpenAgeConfirm={handleOpenAgeConfirm}
        handleTermsAndConditionsModal={handleTermsAndConditionsModal}
        handlePrivacyPolicyModal={handlePrivacyPolicyModal}
        />
      </Box>
      <Box sx={{ display: { xs: "flex", sm: "none", md: "none", lg: 'none' } }}>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          anchor={'right'}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          <SideDrawer
            urlRoute={urlRoute}
            handleOpenAgeConfirm={handleOpenAgeConfirm}
            handleTermsAndConditionsModal={handleTermsAndConditionsModal}
            handlePrivacyPolicyModal={handlePrivacyPolicyModal}
            handleCloseNavMenu={handleCloseNavMenu}
          />
        </Drawer>
      </Box>
    </Grid>
    <TermsConditionsPop
      open={openTermsMod}
      handleModal={handleTermsAndConditionsModal}
      //@ts-ignore
      data={termsPolicesData?.terms}
      title={"Terms & Conditions"}
      data-test-id="termsTypoMod"
    />
    <TermsConditionsPop
      open={openPrivacyPolicy}
      handleModal={handlePrivacyPolicyModal}
      //@ts-ignore
      data={termsPolicesData?.poilicies}
      title={"Privacy Policy"}
      data-test-id="privacyTypoMod"
    />
    <AgeConfirmationModal
      open={openAgeConfirm}
      handleOpen={handleOpenAgeConfirm}
      handleClose={handleCloseAgeConfirm}
      //@ts-ignore
      data={termsPolicesData}
    />
</Container>
  </Toolbar>
}

const ResponsiveAppHeader = ({ 
  urlRoute, 
  handleOpenAgeConfirm, 
  handleTermsAndConditionsModal,
  handlePrivacyPolicyModal
}: any) => {
  return (
    <CustomGrid container alignItems="center">
      {(urlRoute === "/ForgotPassword" || urlRoute === "/VerifyOtp" || urlRoute === "/NewPassword") ? "" : <Typography style={webStyle.navMenu}
        onClick={handleOpenAgeConfirm}
      >
        Discover
      </Typography>}
      {(urlRoute === "/ForgotPassword" || urlRoute === "/VerifyOtp" || urlRoute === "/NewPassword") ? "" : <Typography style={webStyle.navMenu}
        onClick={handleTermsAndConditionsModal}
      >
        Terms & Conditions
      </Typography>}
      {(urlRoute === "/ForgotPassword" || urlRoute === "/VerifyOtp" || urlRoute === "/NewPassword") ? "" : <Typography style={webStyle.navMenu}
        onClick={handlePrivacyPolicyModal}
      >
        Privacy Policy
      </Typography>}
      {(urlRoute === "/ForgotPassword" || urlRoute === "/VerifyOtp" || urlRoute === "/NewPassword") && <NavLink to="/Login" style={{ textDecoration: "none" }}>
        <Buttons
          data-test-id={"btnEmailLogIn"}
          variant="contained"
          color="primary"
          style={{ marginRight: "10px" }}
          fullWidth
        >
          Sign In
        </Buttons>
      </NavLink>}
      {(urlRoute === "/ForgotPassword" || urlRoute === "/VerifyOtp" || urlRoute === "/NewPassword") && <NavLink to="/Register" style={{ textDecoration: "none" }}>
        <Buttons
          data-test-id={"btnEmailLogIn"}
          variant="outlined"
          color="primary"
          // style={{  }}
          fullWidth
        >
          Join now
        </Buttons>
      </NavLink>}
    </CustomGrid>)
}

const SideDrawer = ({ 
  urlRoute,
  handleOpenAgeConfirm, 
  handleTermsAndConditionsModal,
  handlePrivacyPolicyModal,
  handleCloseNavMenu
 }: any) => {
  return (
    <>
      {/* // <CustomGrid container alignItems="center"> */}
      {(urlRoute === "/ForgotPassword" || urlRoute === "/VerifyOtp" || urlRoute === "/NewPassword") ? "" : <Typography style={webStyle.responsiveNavMenu}
        onClick={() => {
          handleOpenAgeConfirm();
          handleCloseNavMenu();
        }}
      >
        Discover
      </Typography>}
      {(urlRoute === "/ForgotPassword" || urlRoute === "/VerifyOtp" || urlRoute === "/NewPassword") ? "" : <Typography style={webStyle.responsiveNavMenu}
        onClick={() => {
          handleTermsAndConditionsModal();
          handleCloseNavMenu();
        }}
      >
        Terms & Conditions
      </Typography>}
      {(urlRoute === "/ForgotPassword" || urlRoute === "/VerifyOtp" || urlRoute === "/NewPassword") ? "" : <Typography style={webStyle.responsiveNavMenu}
        onClick={() => {
          handlePrivacyPolicyModal();
          handleCloseNavMenu();
        }}
      >
        Privacy Policy
      </Typography>}
      {(urlRoute === "/ForgotPassword" || urlRoute === "/VerifyOtp" || urlRoute === "/NewPassword") && <NavLink to="/Login" style={{ textDecoration: "none" }}>
        <Buttons
          data-test-id={"btnEmailLogIn"}
          variant="contained"
          color="primary"
          style={{ margin: "10px 15px" }}
          fullWidth
        >
          Sign In
        </Buttons>
      </NavLink>}
      {(urlRoute === "/ForgotPassword" || urlRoute === "/VerifyOtp" || urlRoute === "/NewPassword") && <NavLink to="Register" style={{ textDecoration: "none" }}>
        <Buttons
          data-test-id={"btnEmailLogIn"}
          variant="outlined"
          color="primary"
          style={{ margin: "10px 15px" }}
          fullWidth
        >
          Join now
        </Buttons>
      </NavLink>}
      {/* // </CustomGrid> */}
    </>
  );
};

const webStyle = {
  container: {
    display: "flex"
  },
  topBar: {
    height: "115px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px"
  },
  logo: {
    width: "95px"
  },
  navMenu: {
    margin: "0 15px",
    textDecoration: 'none',
    color: "black",
    cursor: "pointer"
  },
  responsiveNavMenu: {
    margin: "15px 15px",
    textDecoration: 'none',
    color: "black",
    cursor: "pointer"
  }
}

const CustomGrid: any = withStyles({
  root: {
    "& .MuiList-padding": {
      paddingBottom: `0px !important`
    }
  }
})(Grid);

const Buttons: any = withStyles({
  root: {
    width: "115px",
    height: "40px",
    opacity: 1,
    boxShadow: "none",
    borderRadius: "8px",
    fontSize: '16px',
    textTransform: "none",
    "&:hover": {
      backgroundColor: "",
      boxShadow: "none",
    },
  },
})(Button);
