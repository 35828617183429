// Customizable Area Start
import React,{useState} from 'react'
import { Box,styled } from "@material-ui/core";
import MessagingPeoplesList from './MessagingPeoplesList.web';
import MessagingFooter from './MessagingFooter.web';

import { isLoggedIn } from './common';
import { EdConnectLogo } from '../../blocks/email-account-login/src/assets';
import CompanyInfo from './CompanyInfo';
const search = require("../src/search.png");
const profile = require("../src/profile.png");
const moreOptions = require("../src/moreOptions.png");



function Messaging() {
    const arr = [0, 1, 2, 3, 4];
    const [openChat, setOpenChat] = useState(false);
    return (
        <div style={{ position: 'fixed' }}>
            {isLoggedIn(null) && <Box sx={{ bgcolor: 'background.paper', height: '410px', borderRadius: '10px', border: '1px solid #F0F0F0', }}>
                <div style={webStyles.container}>
                    <div style={webStyles.innerContainer}>
                        <div style={webStyles.icons}>
                            <img src={profile} width="32px" />
                            <OnlineCircle/>
                        </div>
                        <div style={{ fontFamily: "HelveticaNeue" }}>Messaging</div>

                    </div>

                    <div style={webStyles.innerContainer}>
                        <div style={webStyles.icons}>
                            <img src={search} width="20px" />
                        </div>
                        <div>
                            <img src={moreOptions} style={{ transform: 'rotate(90deg)' }} width='24px' />
                        </div>

                    </div>

                </div>
                <hr style={{  height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                {arr.map(() => {
                    return <MessagingPeoplesList openChat={openChat} setOpenChat={setOpenChat}/>
                })}
           
            </Box>}
            <CompanyInfo/>
        </div>
    )
}

const webStyles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding:'16px 16px 0px 16px'
    },
    innerContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    icons: {
        marginRight: '15px',
        position:'relative'
    }as React.CSSProperties,
    texts: {
        fontSize: '12px',
        color: '#7C7C7C',
        marginTop: '5px',
        fontFamily: "HelveticaNeue"
    },
    footer: {
        fontSize: '10px',
        color: '#7C7C7C',
        fontFamily: "HelveticaNeue"
    }
}
const OnlineCircle = styled(Box)({
    width: '7px',
    height: '7px',
    backgroundColor: '#008F48',
    borderRadius:'100%',
    border: '1px solid #008F48',
    position:'absolute',
    transform:'translate(-50%,-50%)',
    top:'84%',
    left:'84%'
});
export default Messaging
// Customizable Area End