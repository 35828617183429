import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import { userProfile, edit, plus,dummy } from "./assets";
import GuestprofileWorkEqu2Controller, { Props } from "./GuestprofileWorkEqu2Controller.web";
import WorkModal from "../../../components/src/WorkModal.web";
import AddEducationModelSelect from "../../../components/src/AddEducationModelSelect.web";
import ShowMoreText from "react-show-more-text";

// Customizable Area End

export default class GuestprofileWorkEqu2 extends GuestprofileWorkEqu2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    // Customizable Area Start
    renderEducationDetailsData =(educationData:any)=>{
      return educationData.length <= 0 ? (
                  <>
                    <Box style={webStyle.profileAbout}>
                      <Typography
                        style={webStyle.aboutHead}
                        data-test-id="Educationlable"
                      >
                        Education details
                      </Typography>
                      <img
                        src={plus}
                        data-test-id="Education"
                        style={webStyle.plus}
                        onClick={() => {
                          this.setState({
                            isModalOpen: true,
                          });
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box style={webStyle.profileAbout}>
                      <Typography
                        style={webStyle.aboutHead}
                        data-test-id="EducationLable"
                      >
                        Education details
                      </Typography>
                      <Box>
                        <img
                          src={plus}
                          data-test-id="btnAddEducation"
                          style={webStyle.plus}
                          onClick={() => {
                            this.setState({
                              isModalEduEditId: null,
                              isModalOpen: true,
                            });
                          }}
                        />
                      </Box>
                    </Box>
                    <Box style={{ filter:'blur(5px)' }} >
                    {this.renderEducationDetailsMedia(this.props.educationDetails)}
                    </Box>
                  </>
                )
    }
  
     renderWorkExperienceDetailsWork =(workExperienceDetailsData:any)=>{
      return workExperienceDetailsData.length <= 0 ? (
        <>
          <Box style={webStyle.profileAbout}>
            <Typography
              style={webStyle.aboutHead}
              data-test-id="Work"
            >
              Work experience
            </Typography>
            <img
              src={plus}
              data-test-id="btnAddWork"
              style={webStyle.plus}
              onClick={() => {
                this.setState({
                  isModalOpenWork: true,
                });
              }}
            />
          </Box>
        </>
      ) : (
        <>
          <Box style={webStyle.profiles}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "10px",
              }}
            >
              <Typography
                style={webStyle.aboutHead}
                data-test-id="Education"
              >
                Work experience
              </Typography>
              <Box>
                <img
                  src={plus}
                  data-test-id="btnAddWork"
                  style={webStyle.plus}
                  onClick={() => {
                    this.setState({
                      ModelWorkData: null,
                      isModalOpenWork: true,
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ filter:'blur(5px)' }} >
             {this.renderWorkExperienceDetailsMap(this.props.workExperienceDetails)}
           </Box>
                
        </>
      )
    }

    rendereducationDetailsImageHobbies = (item: any) => {
      return item?.attributes?.images?.length > 0
        ? item?.attributes?.images
        : dummy;
    };
    renderworkExperienceDetailsProfileImage = (item: any) => {
      return item?.attributes?.images?.length > 0
        ? item?.attributesimages
        : userProfile;
    };
    
    renderWorkExperienceDetailsMap = (workExperienceData:any) => {
      return this.props.workExperienceDetails && Array.isArray(this.props.workExperienceDetails) && workExperienceData.map((element:any) => {
        return (
          <Box>
            <Box style={webStyle.profiles}>
              <Box style={{ display: "flex" }}>
                <Box>
                  <img
                    src={this.renderworkExperienceDetailsProfileImage(element)}
                    style={webStyle.logo}
                    id="media"
                  />
                </Box>
                <Box style={{ color: "#717171", overflow: "auto" }}>
                  <Typography style={webStyle.aboutHead}>
                    {element.attributes.job_title}
                  </Typography>
                  <Typography>
                    {element.attributes.company_name} -{" "}
                    {element.attributes.experience_type}
                  </Typography>
                  <Typography>
                    {element.attributes.location} {element.attributes.location_type}
                  </Typography>
                  <Typography>
                    {element.attributes.start_month} {element.attributes.start_year} -{" "}
                    {element.attributes.end_month} {element.attributes.end_year}
                  </Typography>
                  <Typography>
                    activities and societies:{" "}
                    <ShowMoreText
                      lines={3}
                      more="SeeMore"
                      less="SeeLess"
                      className="seeMore"
                      anchorClass="show-more-less-clickable"
                      onClick={this.executeOnClick}
                      expanded={false}
                      width={625}
                      truncatedEndingComponent={" ..."}
                      style={webStyle.seeMore}
                    >
                      {element.attributes.description}
                    </ShowMoreText>
                  </Typography>
                  <Typography>
                    Skill:
                    {element.attributes.add_key_achievements.map((skill:any, key:number) => {
                      return <Typography key={key}>{skill}</Typography>;
                    })}
                  </Typography>
                  <Box style={webStyle.row}>
                    <Box style={webStyle.photo}>
                      <img
                        src={this.rendereducationDetailsImageHobbies(element)}
                        style={webStyle.awardPhoto}
                        id="media"
                       />
                    </Box>
                    <Box style={webStyle.info}>
                      <Typography style={webStyle.medaihtxt}>My skill reward</Typography>
                      <Typography style={webStyle.medaitxt}>
                        Hobbies:{" "}
                        <ShowMoreText
                          lines={1}
                          more="..."
                          less="SeeLess"
                          className="seeMore"
                          anchorClass="show-more-less-clickable"
                          onClick={this.executeOnClick}
                          expanded={false}
                          width={280}
                          truncatedEndingComponent={""}
                          style={webStyle.seeMore}
                        >
                          {element.attributes.description}
                        </ShowMoreText>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <img
                    src={edit}
                    data-test-id="btnEducation"
                    style={webStyle.addPlusIcon}
                    onClick={() => {
                      this.setState({
                        isModalOpenWork: true,
                        ModelWorkData: element,
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Divider variant="middle" />
          </Box>
        );
      });
    }
  
    rendereducationDetailsImage = (item: any) => {
      return item?.attributes?.images?.length > 0
        ? item?.attributes?.images
        : userProfile;
    };
     rendereducationDetailsSchoolname = (item: any) => {
      return item?.attributes?.school_name;
    };
    renderEducationDetailsMedia=(educationDetailsdata:any)=>{
      return Array.isArray(educationDetailsdata) && educationDetailsdata.map((element: any, key: any) => {
                      return (
                        <Box>
                          <Box style={webStyle.profiles}>
                            <Box style={{ display: "flex" }}>
                              <Box>
                                <img
                                  src={this.rendereducationDetailsImage(element)}
                                  style={webStyle.logo}
                                  id="media"
                                />
                              </Box>
                              <Box
                                style={{ color: "#717171", overflow: "auto" }}
                              >
                                <Typography style={webStyle.aboutHead}>
                                  {this.rendereducationDetailsSchoolname(element)}
                                </Typography>
                                <Typography>
                                  {
                                    element.attributes
                                      .educational_qualification_field_studys
                                      .field_of_study
                                  }
                                </Typography>
                                <Typography>
                                  {element.attributes.start_month}{" "}
                                  {element.attributes.start_year} -{" "}
                                  {element.attributes.end_month}{" "}
                                  {element.attributes.end_year}
                                </Typography>
                                <Typography>
                                  Grade:{element.attributes.grades}
                                </Typography>
                                <Typography>
                                  activities and societies:
                                  <ShowMoreText
                                    lines={3}
                                    more="SeeMore"
                                    less="SeeLess"
                                    className="seeMore"
                                    anchorClass="show-more-less-clickable"
                                    onClick={this.executeOnClick}
                                    expanded={false}
                                    width={625}
                                    truncatedEndingComponent={" ..."}
                                  >
                                    {
                                      element.attributes
                                        .activities_and_societies
                                    }
                                  </ShowMoreText>
                                </Typography>
                                <Typography>
                                  Hobbies:
                                  <ShowMoreText
                                    lines={3}
                                    more="SeeMore"
                                    less="SeeLess"
                                    className="seeMore"
                                    anchorClass="show-more-less-clickable"
                                    onClick={this.executeOnClick}
                                    expanded={false}
                                    width={625}
                                    truncatedEndingComponent={" ..."}
                                    style={webStyle.seeMore}
                                  >
                                    {
                                      element.attributes
                                        .activities_and_societies
                                    }
                                  </ShowMoreText>
                                </Typography>
                                <Box style={webStyle.row}>
                                  <Box style={webStyle.photo}>
                                    <img
                                      src={this.rendereducationDetailsImageHobbies(
                                        element
                                      )}
                                      style={webStyle.awardPhoto}
                                      id="media"
                                    />
                                  </Box>
                                  <Box style={webStyle.info}>
                                    <Typography style={webStyle.medaihtxt}>
                                      My skill reward
                                    </Typography>
                                    <Typography style={webStyle.medaitxt}>
                                      Hobbies:
                                      <ShowMoreText
                                        lines={1}
                                        more="..."
                                        less="SeeLess"
                                        className="seeMore"
                                        anchorClass="show-more-less-clickable"
                                        onClick={this.executeOnClick}
                                        expanded={false}
                                        width={280}
                                        truncatedEndingComponent={""}
                                        style={webStyle.seeMore}
                                      >
                                        {element.attributes.description}
                                      </ShowMoreText>
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <img
                                  src={edit}
                                  data-test-id="btnEdit"
                                  style={webStyle.addPlusIcon}
                                  onClick={() => {
                                    this.setState({
                                      isModalOpen: true,
                                      isModalEduEditId: element.id,
                                    });
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Divider variant="middle" />
                        </Box>
                      );
                    })
    }
    // Customizable Area End
  

  render() {
    // Customizable Area Start
   
      const findIdInEducationDetail =
  Array.isArray(this.props.educationDetails) ?
  this.props.educationDetails.find((el: any) => el.id === this.state.isModalEduEditId) ?? {} :
  {};
    
    return (
      <>
       <AddEducationModelSelect
                open={this.state.isModalOpen}
                handleClose={this.handleClose}
                getEducationDetails={this.getEducationDetails}
                educationDetails={findIdInEducationDetail}
              />
              <CustomPaper style={webStyle.userDetails}>
                {this.renderEducationDetailsData(this.props.educationDetails)}
              </CustomPaper>
              <WorkModal
                postWorkDetails={this.postWorkDetails}
                open={this.state.isModalOpenWork}
                handleClose={this.handleClose}
                workExperienceDetails={this.state.ModelWorkData}
              />
              <CustomPaper style={webStyle.userDetails}>
                {this.renderWorkExperienceDetailsWork(this.props.workExperienceDetails)}
              </CustomPaper>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
  
  userDetails: {
    padding: 0,
  }, 
  row: {
    display: "flex",
    gap: "24px",
    paddingTop: "10px",
  },
  photo: {
    width: "123px",
    height: "65px",
  },
  awardPhoto: {
    width: "123px",
    height: "60px",
    borderRadius: "5px",
  },
  logo: {
    width: "44px",
    height: "44px",
    paddingRight: "20px",
    paddingLeft: "3px",
    borderRadius: "50%",
  },
  medaihtxt: {
    fontSize: "14px",
    color: "#454545",
  },
  info: {
    width: "737px",
    height: "65px",
  },
  medaitxt: {
    fontSize: "14px",
    color: "#1C1C1C",
  },

  profileAbout: {
    height: "72px",
    padding: "0px 24px 0px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  profiles: {
    height: "auto",
    padding: "24px",
  },

  aboutHead: {
    fontSize: "20px",
    color: "#1C1C1C",
  },
  plus: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },
  addPlusIcon: {
    height: "24px",
    width: "24px",
    paddingLeft: "10px",
    cursor: "pointer",
  },
};

const CustomPaper: any = withStyles({
  root: {
    boxShadow: "unset",
    margin: "15px 0",
    border: "1px solid #e2e2e2",
    padding: "15px",
    borderRadius: "10px",
  },
})(Paper);

// Customizable Area End
