import React from 'react';
import { CircularProgress,Backdrop } from "@material-ui/core";

interface Props{
    isLoading:boolean
}
function CommonLoader(props:Props) {
  return (
   <div>
    <Backdrop
        style={{ color: '#fff', zIndex:100 }}
        open={props?.isLoading}
        
      >
        <CircularProgress color="inherit" />
      </Backdrop>
   </div>
  )
}

export default CommonLoader