import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Divider,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { userProfile, medal, dummy } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#be9e44",
      contrastText: "#fff",
    },
  },
});

import UserDetailsController, { Props } from "./UserDetailsController";
import AppFooter from "../../../components/src/AppFooter.web";
import ShowMoreText from "react-show-more-text";
import { AccessModal } from "../../../components/src/AccessModal.web";
import CompanyInfo from "../../../components/src/CompanyInfo";
import NavBarWithSearch from "../../ElasticSearch/src/NavBarWithSearch.web";
// Customizable Area End


export default class UserDetailsWeb extends UserDetailsController {
  // Customizable Area Start

  async componentDidMount() {
    let id: any = this.props.navigation.getParam("id");
    this.getStudentProfile(id);
  }



  rendereducationDetailsImage = (item: any) => {
    return item?.attributes?.images?.length > 0
      ? item?.attributes?.images
      : userProfile;
  };
  rendereducationDetailsSchoolname = (item: any) => {
    return item?.attributes?.school_name;
  };
  rendereducationDetailsImageHobbies = (item: any) => {
    return item?.attributes?.images?.length > 0
      ? item?.attributes?.images
      : dummy;
  };
  renderworkExperienceDetailsProfileImage = (item: any) => {
    return item?.attributes?.images?.length > 0
      ? item?.attributesimages
      : userProfile;
  };

  renderWorkExperienceDetailsMap = (workExperienceData: any) => {
    return workExperienceData && Array.isArray(workExperienceData) && workExperienceData.map((element: any) => {
      return (
        <Box>
          <Box style={webStyle.profiles}>
            <Box style={{ display: "flex" }}>
              <Box>
                <img
                  src={this.renderworkExperienceDetailsProfileImage(element)}
                  style={webStyle.logo}
                  id="media"

                />
              </Box>
              <Box style={{ color: "#717171", overflow: "auto" }}>
                <Typography style={webStyle.aboutHead}>
                  {element.attributes.job_title}
                </Typography>
                <Typography>
                  {element.attributes.company_name} -{" "}
                  {element.attributes.experience_type}
                </Typography>
                <Typography>
                  {element.attributes.location} {element.attributes.location_type}
                </Typography>
                <Typography>
                  {element.attributes.start_month} {element.attributes.start_year} -{" "}
                  {element.attributes.end_month} {element.attributes.end_year}
                </Typography>
                <Typography>
                  activities and societies:{" "}
                  <ShowMoreText
                    lines={3}
                    more="SeeMore"
                    less="SeeLess"
                    className="seeMore"
                    anchorClass="show-more-less-clickable"
                    onClick={this.executeOnClick}
                    expanded={false}
                    width={625}
                    truncatedEndingComponent={" ..."}
                    style={webStyle.seeMore}
                  >
                    {element.attributes.description}
                  </ShowMoreText>
                </Typography>
                <Typography>
                  Skill:
                  {element.attributes.add_key_achievements.map((skill: any, key: number) => {
                    return <Typography key={key}>{skill}</Typography>;
                  })}
                </Typography>
                <Box style={webStyle.row}>
                  <Box style={webStyle.photo}>
                    <img
                      src={this.rendereducationDetailsImageHobbies(element)}
                      style={webStyle.awardPhoto}
                      id="media"

                    />
                  </Box>
                  <Box style={webStyle.info}>
                    <Typography style={webStyle.medaihtxt}>My skill reward</Typography>
                    <Typography style={webStyle.medaitxt}>
                      Hobbies:{" "}
                      <ShowMoreText
                        lines={1}
                        more="..."
                        less="SeeLess"
                        className="seeMore"
                        anchorClass="show-more-less-clickable"
                        onClick={this.executeOnClick}
                        expanded={false}
                        width={280}
                        truncatedEndingComponent={""}
                        style={webStyle.seeMore}
                      >
                        {element.attributes.description}
                      </ShowMoreText>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider variant="middle" />
        </Box>
      );
    });
  }

  renderCertificationDetailsMap = (certificationData: any) => {
    return Array.isArray(certificationData) && certificationData.map((element: any) => {
      return (
        <Box>
          <Box style={webStyle.profiles}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box style={{ display: "flex" }}>
                <Box>
                  <img
                    src={userProfile}
                    style={webStyle.logo}
                    id="media"

                  />
                </Box>
                <Box
                  style={{
                    color: "#717171",
                    overflow: "auto",
                  }}
                >
                  <Typography style={webStyle.aboutHead}>
                    {element.attributes.title} ({" "}
                    {element.attributes.associated_with} )
                  </Typography>
                  <Typography>
                    {element.attributes.issuer}
                  </Typography>
                  <Typography>
                    {element.attributes.issue_month}{" "}
                    {element.attributes.issue_year}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider variant="middle" />
        </Box>
      );
    })
  }

  renderWorkExperienceDetailAcademic = (workExperienceData: any) => {
    return (
      <>
        <Box style={webStyle.profiles}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            <Typography
              style={webStyle.aboutHead}
              data-test-id="Education"
            >
              Academic skills
            </Typography>
          </Box>
        </Box>
        {workExperienceData &&
          Array.isArray(workExperienceData) &&
          workExperienceData.map((element: any, key: any) => {
            return (
              <Box>
                <Box style={webStyle.profiles}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {element.attributes.career_experience_system_experiences.map(
                      (skill: any, key: any) => {
                        return (
                          <>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box>
                                <img
                                  src={userProfile}
                                  style={webStyle.logo}
                                  id="media"

                                />
                              </Box>
                              <Box
                                style={{
                                  color: "#717171",
                                  overflow: "auto",
                                }}
                              >
                                <Typography
                                  key={key}
                                  style={webStyle.aboutHead}
                                >
                                  {skill.system_experience}
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        );
                      }
                    )}
                  </Box>
                </Box>
                <Divider variant="middle" />
              </Box>
            );
          })}
      </>
    )
  }

  renderWorkExperienceDetailMyAchievements = (workExperienceData: any) => {
    return (
      <>
        <Box style={webStyle.profiles}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            <Typography
              style={webStyle.aboutHead}
              data-test-id="Education"
            >
              My achievements
            </Typography>
          </Box>
        </Box>
        {workExperienceData &&
          Array.isArray(workExperienceData) &&
          workExperienceData.map((element: any, key: any) => {
            return (
              <Box>
                <Box style={webStyle.profiles}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {element.attributes.add_key_achievements.map(
                      (skill: any, key: any) => {
                        return (
                          <>
                            <Typography
                              key={key}
                              style={webStyle.aboutHead}
                            >
                              {skill}
                            </Typography>
                          </>
                        );
                      }
                    )}
                  </Box>
                </Box>
                <Divider variant="middle" />
              </Box>
            );
          })}
      </>
    )
  }

  renderEducationDetailsData = (educationData: any) => {
    return (
      <>
        <Box style={webStyle.profileAbout}>
          <Typography
            // @ts-ignore
            style={webStyle.aboutHead}
            data-test-id="EducationLable"
          >
            Education details
          </Typography>
        </Box>
        {this.renderEducationDetailsMedia(educationData)}
      </>
    )
  }

  renderCertificationAndDiploamas = (certificationDetailsData: any) => {
    return (
      <>
        <Box style={webStyle.profiles}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            <Typography
              style={webStyle.aboutHead}
              data-test-id="Education"
            >
              Certifications / diplomas
            </Typography>
          </Box>
        </Box>
        {this.renderCertificationDetailsMap(certificationDetailsData)}
      </>
    )
  }

  renderWorkExperienceDetailsWork = (workExperienceDetailsData: any) => {
    return (
      <>
        <Box style={webStyle.profiles}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            <Typography
              style={webStyle.aboutHead}
              data-test-id="Education"
            >
              Work experience
            </Typography>
          </Box>
        </Box>
        {this.renderWorkExperienceDetailsMap(workExperienceDetailsData)}
      </>
    )
  }

  renderEducationDetailsMedia = (educationDetailsdata: any) => {
    return Array.isArray(educationDetailsdata) && educationDetailsdata.map((element: any, key: any) => {
      return (
        <Box>
          <Box style={webStyle.profiles}>
            <Box style={{ display: "flex" }}>
              <Box>
                <img
                  src={this.rendereducationDetailsImage(element)}
                  style={webStyle.logo}
                  id="media"

                />
              </Box>
              <Box
                style={{ color: "#717171", overflow: "auto" }}
              >
                <Typography style={webStyle.aboutHead}>
                  {this.rendereducationDetailsSchoolname(element)}
                </Typography>
                <Typography >
                  {
                    element.attributes
                      .educational_qualification_field_studys
                      .field_of_study
                  }
                </Typography>
                <Typography >
                  {element.attributes.start_month}{" "}
                  {element.attributes.start_year} -{" "}
                  {element.attributes.end_month}{" "}
                  {element.attributes.end_year}
                </Typography>
                <Typography>
                  Grade:{element.attributes.grades}
                </Typography>
                <Typography>
                  activities and societies:
                  <ShowMoreText
                    lines={3}
                    more="SeeMore"
                    less="SeeLess"
                    className="seeMore"
                    anchorClass="show-more-less-clickable"
                    onClick={this.executeOnClick}
                    expanded={false}
                    width={625}
                    truncatedEndingComponent={" ..."}
                  >
                    {
                      element.attributes
                        .activities_and_societies
                    }
                  </ShowMoreText>
                </Typography>
                <Typography>
                  Hobbies:
                  <ShowMoreText
                    lines={3}
                    more="SeeMore"
                    less="SeeLess"
                    className="seeMore"
                    anchorClass="show-more-less-clickable"
                    onClick={this.executeOnClick}
                    expanded={false}
                    width={625}
                    truncatedEndingComponent={" ..."}
                    style={webStyle.seeMore}
                  >
                    {
                      element.attributes
                        .activities_and_societies
                    }
                  </ShowMoreText>
                </Typography>
                <Box style={webStyle.row}>
                  <Box style={webStyle.photo}>
                    <img
                      src={this.rendereducationDetailsImageHobbies(
                        element
                      )}
                      style={webStyle.awardPhoto}
                      id="media"

                    />
                  </Box>
                  <Box style={webStyle.info}>
                    <Typography style={webStyle.medaihtxt}>
                      My skill reward
                    </Typography>
                    <Typography style={webStyle.medaitxt}>
                      Hobbies:
                      <ShowMoreText
                        lines={1}
                        more="..."
                        less="SeeLess"
                        className="seeMore"
                        anchorClass="show-more-less-clickable"
                        onClick={this.executeOnClick}
                        expanded={false}
                        width={280}
                        truncatedEndingComponent={""}
                        style={webStyle.seeMore}
                      >
                        {element.attributes.description}
                      </ShowMoreText>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider variant="middle" />
        </Box>
      );
    })
  }
  onOpenAccessModal = () => {
    this.setState({ isModalOpenView: true });
  }
  checkApproveEmail=(guestUser:any,item: any) =>(item.email_address === guestUser?.email_address)
  
  isApproved = () => {
    const approval_data = this.state.studentProfileDetails?.approval_data;
    let guestUser: any = localStorage.getItem('guest_user') ?? "null"
    if (guestUser) {
      guestUser = JSON.parse(guestUser);
      const findApproval = approval_data?.find(this.checkApproveEmail.bind(this,guestUser))
      if (findApproval?.request_status === 'approved') {
        return true
      }
    } else {
      return false
    }
  }
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }





  render() {
    // Customizable Area Start
    const photo =
      this.state.studentProfileDetails?.profile?.data?.attributes?.photo ?? dummy;
    const profile_image =
      this.state.studentProfileDetails?.profile?.data?.attributes?.profile_image ?? dummy;
    const first_name =
      this.state.studentProfileDetails?.account?.data?.attributes?.first_name;
    const last_name =
      this.state.studentProfileDetails?.account?.data?.attributes?.last_name;
    const city = this.state.studentProfileDetails?.profile?.data?.attributes?.city;
    const country = this.state.studentProfileDetails?.profile?.data?.attributes?.country;
    const educationalDetails = this.state.studentProfileDetails?.educational_details?.data;
    const workExperienceDetails = this.state.studentProfileDetails?.work_experience?.data;
    const certificationsDetails = this.state.studentProfileDetails?.certifications?.data;

    return (
      <ThemeProvider theme={theme}>
        <NavBarWithSearch id="" navigation={this.props.navigation} />
        <Container style={webStyle.container}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <CustomPaper style={webStyle.userDetails}>
                <Box style={webStyle.coverImage}>
                  <img
                    src={photo}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="cover image"
                  />
                  <img
                    src={profile_image ? profile_image : dummy}
                    // src={profile_image}
                    style={webStyle.profileImage}
                  />
                </Box>
                <Box style={webStyle.userDetail}>
                  <Box>
                    <Box>
                      <Typography style={webStyle.stuName}>
                        {first_name} <span style={!this.isApproved() ? webStyle.blurText : null}>{last_name}</span>
                      </Typography>
                      <Typography style={webStyle.stuAddress}>
                        Student at abcd public college
                      </Typography>
                      <Typography style={webStyle.stuHours}>
                        {city} ,{country}
                      </Typography>
                      <Typography style={webStyle.stuFollowers}>
                        0 Connections
                      </Typography>
                    </Box>

                  </Box>
                  <Box>
                    <img src={medal} style={webStyle.stuImage} />
                  </Box>
                </Box>
              </CustomPaper>
              <CustomPaper style={webStyle.userDetails}>
                <Box style={webStyle.profileAbout}>
                  <Typography style={webStyle.aboutHead} data-test-id="About">
                    About
                  </Typography>
                </Box>
              </CustomPaper>
              <Box style={webStyle.portfolio}>
                <Typography
                  style={webStyle.portfolioHead}
                  data-test-id="portfolio"
                >
                  Ed portfolio
                </Typography>
                <Box>
                  {!this.isApproved() && <Button
                    variant="contained"
                    color="primary"
                    data-test-id="button-view"
                    style={{
                      padding: "5px 10px",
                      fontSize: "16px",
                      textTransform: "none",
                      borderRadius: "5px",
                    }}
                    onClick={this.onOpenAccessModal}
                  >
                    Request access to view
                  </Button>}
                  <AccessModal
                    open={this.state.isModalOpenView}
                    handleClose={this.handleClose}
                    handleAccess={this.handleAccess}

                  />
                </Box>
              </Box>
              <CustomPaper style={!this.isApproved() ? { ...webStyle.userDetails, filter: 'blur(4px)' } : webStyle.userDetails}>
                {this.renderEducationDetailsData(educationalDetails)}
              </CustomPaper>
              <CustomPaper style={!this.isApproved() ? { ...webStyle.userDetails, filter: 'blur(4px)' } : webStyle.userDetails}>
                {this.renderWorkExperienceDetailsWork(workExperienceDetails)}
              </CustomPaper>
              <CustomPaper style={!this.isApproved() ? { ...webStyle.userDetails, filter: 'blur(4px)' } : webStyle.userDetails}>
                {this.renderCertificationAndDiploamas(certificationsDetails)}
              </CustomPaper>
              <CustomPaper style={!this.isApproved() ? { ...webStyle.userDetails, filter: 'blur(4px)' } : webStyle.userDetails}>
                {this.renderWorkExperienceDetailAcademic(workExperienceDetails)}
              </CustomPaper>
              <CustomPaper style={!this.isApproved() ? { ...webStyle.userDetails, filter: 'blur(4px)' } : webStyle.userDetails}>
                {this.renderWorkExperienceDetailMyAchievements(workExperienceDetails)}
              </CustomPaper>
            </Grid>
            <Grid item xs={4}>
              <CompanyInfo />
            </Grid>
          </Grid>
        </Container>
        <AppFooter />
      </ThemeProvider>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
  container: {
    minHeight: "calc(100vh - 270px)",
  },
  userDetails: {
    padding: 0,
  },
  coverImage: {
    height: "195px",
    position: "relative",
    background: "lightblue",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  profileImage: {
    width: "154px",
    height: "154px",
    position: "absolute",
    bottom: "-34px",
    left: "23px",
    borderRadius: "50%",
    objectFit: "cover",
  },

  userDetail: {
    display: "flex",
    justifyContent: "space-between",
    padding: "55px 80px 26px 24px",
    position: "relative",
  },
  stuImage: {
    width: "40px",
    height: "40px",
  },
  stuName: {
    fontSize: "24px",
    color: "#1C1C1C",
  },
  stuAddress: {
    fontSize: "17px",
    color: "#252525",
  },
  stuHours: {
    fontSize: "17px",
    color: "#7C7C7C",
  },
  stuFollowers: {
    fontSize: "17px",
    color: "#ab9045",
  },
  row: {
    display: "flex",
    gap: "24px",
    paddingTop: "10px",
  },
  photo: {
    width: "123px",
    height: "65px",
  },
  awardPhoto: {
    width: "123px",
    height: "60px",
    borderRadius: "5px",
  },
  logo: {
    width: "44px",
    height: "44px",
    paddingRight: "20px",
    paddingLeft: "3px",
    borderRadius: "50%",
  },
  medaihtxt: {
    fontSize: "14px",
    color: "#454545",
  },
  info: {
    width: "737px",
    height: "65px",
  },
  medaitxt: {
    fontSize: "14px",
    color: "#1C1C1C",
  },
  profileComplete: {
    height: "236px",
    padding: "24px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  profileAbout: {
    height: "72px",
    padding: "0px 24px 0px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  profiles: {
    height: "auto",
    padding: "24px",
  },

  aboutHead: {
    fontSize: "20px",
    color: "#1C1C1C",
  },
  portfolio: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "40px 0px 30px 0px",
  },
  portfolioHead: {
    fontSize: "24px",
    color: "#1C1C1C",
  },
  aboutHeadtxt: {
    fontSize: "14px",
    color: "#252525",
  },
  blurText: {
    color: 'transparent',
    textShadow: ' 0 0 8px #000',
  }
};

const CustomPaper: any = withStyles({
  root: {
    boxShadow: "unset",
    margin: "15px 0",
    border: "1px solid #e2e2e2",
    padding: "15px",
    borderRadius: "10px",
  },
})(Paper);

// Customizable Area End
