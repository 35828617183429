import React from "react";

// Customizable Area Start
import {
  Container
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AppHeader from '../../../components/src/AppHeader.web'
import AppFooter from '../../../components/src/AppFooter.web'

const theme = createTheme({
  palette: {
    primary: {
      main: "#bd9e44",
      contrastText: "#f9f8f8",
    }
  },
});
import UserDetailsController, {
  Props,
} from "./UserDetailsController";
import Loader from "../../../components/src/Loader.web";
import { isLoggedIn } from "../../../components/src/common";
// Customizable Area End



export default class AccessRequest extends UserDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    if(isLoggedIn(null)){
      this.handleApprove();
    }else{
      this.props.navigation.navigate('Login')
    }
    // Customizable Area End
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppHeader />
        <Container maxWidth="sm" style={webStyle.container}>
          <Loader loading={this.state.isRequestLoading} />
        </Container>
        <AppFooter />
      </ThemeProvider>
    );
  }

}
// Customizable Area Start
const webStyle: any = {
  container: {
    minHeight: "calc(100vh - 250px)",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center'
  }
};


// Customizable Area End

