import React from "react";
// Customizable Area Start
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Button,
  Container,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { LandingImg } from "./assets";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import LandingPageController, { Props } from "./LandingPageController";
import AppFooter from "../../../components/src/AppFooter.web";
import LandingPageHeader from "./LandingPageHeader.web";
import { Sponsers } from "../../../components/src/Sponsers.web";
import "../../../web/src/App.css";
import  { NavLink } from 'react-router-dom';
import BlockedUserModal from "../../../components/src/BlockedUserModal.web";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const styles = (theme: any) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "24px",
      fontWeight: "normal",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "28px",
      fontWeight: "normal",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "35px",
      display: "block",
      margin: "0 0 15px 0",
      lineHeight: "50px",
      fontWeight: "normal",
    },
  },
  landingPage: {
    marginTop: "50px",
    [theme.breakpoints.down("md")]: {
      width: "100%",

    },
    "@media (max-width: 768px)": {
      justifyContent: "center"
    },
  },

  title: {
    fontSize: "35px",
    display: "block",
    margin: "0 0 15px 0",
    lineHeight: "55px",
  },

  button: {
    background: "#BE9E44",
    color: "#FFFFFF",
    margin: "20px 0",
    width: "100%",
    height: "50px",
    textTransform: "capitalize",
    borderRadius: "8px",
    fontSize: "16px",
    "&:hover": {
      background: "#BE9E44",
    },
  },

  buttonOutline: {
    color: "#BE9E44",
    width: "100%",
    border: "1px solid #BE9E44",
    padding: "15px",
    borderRadius: "8px",
    textTransform: "none",
    textDecoration: "none",
    display: "inline-block",
    textAlign: "center",
    "box-sizing": "border-box",
    background: "#f8f4ec",
    fontFamily: "Helvetica",
  },

  form: {
    width: "94%",
    margin: "20px 0 10px 0",
    border: "1.5px solid grey",
    padding: "10px",
    borderRadius: "10px",
    outline: "none",
  },

  saparetor: {
    borderTop: "1px dashed #000000",
    maxWidth: "250px",
    margin: "0 auto 20px",
  },

  bannerForm: {
    maxWidth: "58%",
    "@media (max-width: 1280px)": {
      maxWidth: "75%"
    },
    "@media (max-width: 1024px)": {
      maxWidth: "100%"
    }
  },
  leftCont: {
    "@media (max-width: 768px)": {
      flex: 1,
      maxWidth: "65%"
    },
    "@media (max-width: 576px)": {
      maxWidth: "90%"
    },
    "@media (max-width: 425px)": {
      maxWidth: "95%"
    }
  },
  rightCont: {
    "@media (max-width: 1280px)": {
      display: "flex",
      alignItems: "center"
    },
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  rightImg: {
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
    },
  },

  sponcerLogo: {
    width: "30px",
    height: "30px",
    objectFit: "contain",
    marginRight: "10px",
  },
  overViewContent: {
    width: "76%",
    lineHeight: "25px",
    color: "grey",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  fieldError: {
    color: "#D11024",
    fontSize: "14px",
    margin: "2px 5px 5px 0"
  }
});
// Customizable Area End

export class LandingPageBlock extends LandingPageController {
  static propTypes: {
    classes: PropTypes.Validator<object>;
  };

  constructor(props: Props) {
    super(props);
  }

  validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("email is required"),
  });
  // Customizable Area Start
  validationsSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
  });
  render() {
    {
      /* @ts-ignore */
    }
    const { classes }:any = this.props;
    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader
          userType={this.state.selectUserType}
          handleChangeUserType={this.handleChangeUserType}
          termsPolicesData={this.state.termsAndPolices}
        />
        <Container maxWidth="lg">
          <Grid container spacing={2} className={classes?.landingPage}>
            <Grid item xs={12} sm={6} className={classes.leftCont}>
              <div style={{ width: "fit-content" }}>
                <Typography className={classes?.root}>
                  Welcome to your{" "}
                  <span style={{ color: "#BE9E44", display: "block" }}>
                    {" "}
                    Educational community
                  </span>
                </Typography>
                <div
                  className={classes?.overViewContent}
                >
                  We put your needs first and we charge pocket - friendly prices
                  for our products and services.
                </div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={this.validationsSchema}
                  onSubmit={(values:any) => {this.doEmailLogIn(values)}}
                  data-test-id='loginFormik'
                >
                  {({ errors, values, touched, handleSubmit, handleChange, handleBlur }:any) => {
                    return (
                      <div className={classes?.bannerForm}>
                        <form onSubmit={handleSubmit} data-test-id="loginForm">
                          <InputField
                            error={errors.email && touched.email}
                            id="email"
                            name="email"
                            data-test-id="emailInput"
                            type="email"
                            variant="outlined"
                            placeholder="Email address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                          {errors.email && touched.email &&
                            <div className={classes?.fieldError}>
                              {errors.email}
                            </div>
                          }
                          <Box>
                            <InputField
                              error={errors.password && touched.password}
                              data-test-id="passwordInput"
                              id="password"
                              name="password"
                              variant="outlined"
                              type={
                                this.state.enableField ? "password" : "text"
                              }
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Password"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={this.handleClickShowPassword}
                                      edge="end"
                                    >
                                      {this.state.enableField ? (
                                        <Typography
                                          component="span"
                                          style={{ color: "#BE9E44" }}
                                        >
                                          show
                                        </Typography>
                                      ) : (
                                        <Typography
                                          component="span"
                                          style={{ color: "#BE9E44" }}
                                        >
                                          hide
                                        </Typography>
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                disableUnderline: true,
                              }}
                            />
                            {errors.password && touched.password &&
                              <div className={classes?.fieldError}>
                                {errors.password}
                              </div>
                            }
                            {this.state.accountError && <div style={{ color: "#D11024", fontSize: '14px', marginTop: "8px" }}>{this.state.accountError}</div>}
                          </Box>
                          <Box style={{marginTop: "10px"}}>
                            <NavLink
                              to="/ForgotPassword"
                              style={{
                                color: "#BE9E44",
                                fontFamily: "Helvetica",
                                textDecoration: "none",
                                fontSize: "14px"
                              }}
                              onClick={() => {this.checkUserType()}}
                            >
                              Forgot Password?
                            </NavLink>
                          </Box>
                          <Box>
                            <Button
                              data-test-id="singIn"
                              type="submit"
                              className={classes?.button}
                            >
                              Sign in
                            </Button>
                          </Box>
                          <div className={classes?.saparetor} />
                          <NavLink to="/Register" className={classes?.buttonOutline} onClick={() => {localStorage.setItem('userType', this.state.selectUserType === "student_account" ? 'student' : 'organization')}}>
                            Don't have an account? Join now
                          </NavLink>
                        </form>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.rightCont}>
              <img
                src={LandingImg}
                alt="LandingPage"
                className={classes?.rightImg}
              />
            </Grid>
          </Grid>
          <BlockedUserModal
            open={this.state.blockedUserModal}
            handleBlockUserModal={this.handleBlockedUserModal}
          />
          <Sponsers data={this.state.sponsersData.data} />
        </Container>
        <AppFooter termsPolicesData={this.state.termsAndPolices} />
      </ThemeProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const InputField:any = withStyles({
  root: {
      width: '100%',
      paddingTop: '25px',
      borderRadius: "10px",
      fontFamily: "HelveticaNeue-medium",
      fontWeight: "normal",
      "& .MuiOutlinedInput-root": {
          fontWeight: 400,
          fontFamily: "HelveticaNeue",
          fontSize: "16px",
          "& fieldset": {
              fontSize: "16px",
              borderRadius: "10px",
              borderWidth: 1,
              fontWeight: 400,  
          },
      },
      "& .Mui-error":{
        "& .MuiOutlinedInput-notchedOutline":{
          borderColor: "#D11024"
        }
      },
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.23)"
        }
      },
      "& ::placeholder": {
        opacity: 1,
        color: "#565656",
        fontFamily: "HelveticaNeue-Medium",
      }
  }
})(TextField);
export default withStyles(styles, { withTheme: true })(LandingPageBlock);
  // Customizable Area End
