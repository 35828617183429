import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  InputLabel,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Tabs,
  Tab,
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { BackArrow } from "./assets";
import { NavLink } from "react-router-dom";
import AppHeader from '../../../components/src/AppHeader.web'
import AppFooter from '../../../components/src/AppFooter.web'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#bd9e44",
      contrastText: "#f9f8f8",
    }
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  

  render() {
    const LoginPasswordSchema = Yup.object().shape({
      email: Yup.string().email("Invalid email").required(this.errorEmailRequired),
      password: Yup.string().required(this.errorPasswordRequired),
    })

    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}> 
        <AppHeader />
        <Loader loading={this.state.isLoading}/> 
        <Container maxWidth="sm" style={webStyle.container}>
          <Box
            sx={{
              display: "flex", 
              flexDirection: "column",
              alignItems: "center",
              padding: "50px",
              bgcolor: "#ffffff", 
              borderRadius: "14px",
              position: 'relative',
              maxWidth: "434px",
              fontFamily: "HelveticaNeue", 
            }}
          > 
            <Box style={webStyle.pageTitle}> 
              <img src={BackArrow} style={webStyle.backArrow} data-test-id="backArrow" onClick={() => {this.props.navigation.navigate("LandingPage")}}/>
              <Typography variant="h5" component="h1">
                Sign in as
              </Typography>
            </Box>

            <CustomTabs
              onChange={this.handleChangeTab}
              value={this.state.selectedTab}
              aria-label="Tabs where each tab needs to be selected manually"
            >
              <Grid style={webStyle.Tab2}
                onClick={(e: any) => this.handleChangeTab(e, 0)}
                data-test-id="studentTab">
                <CustomTab
                  value={0}
                  label={
                    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' >
                      <p style={{ margin: '0px', display: 'flex', fontSize: "16px" }}>
                        Student
                      </p>
                    </Box>
                  }
                  style={this.state.selectedTab === 0 ? { ...webStyle.Tab1, position: 'relative' } : webStyle.tab3}
                  disableRipple
                />
              </Grid>
              <Grid style={webStyle.activeTransferredTickets}
                onClick={(e: any) => this.handleChangeTab(e, 1)}
                data-test-id="organisationTab">
                <CustomTab
                  value={1}
                  label={
                    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' >
                      <p style={{ margin: '0px', display: 'flex', fontSize: "16px" }}>
                        Organisation
                      </p>
                    </Box>
                  }
                  style={this.state.selectedTab === 1 ? { ...webStyle.Tab1, position: 'relative' } : webStyle.tab3}
                  disableRipple
                />
              </Grid>
            </CustomTabs>


            <Box sx={{ width: "100%" }}>
              <Formik
                initialValues={this.state.initialCreds}
                validationSchema={LoginPasswordSchema}
                onSubmit={(values: any) => { this.userLogin(values) }}
                data-test-id="loginFormik"
              >
                {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
                  <form onSubmit={handleSubmit} data-test-id="loginForm" style={{margin: "0"}}>
                    <Box sx={{ padding: "12px 0px" }}>
                      <InputLabel style={webStyle.fieldLabel}>{this.labelEmail}</InputLabel>
                      <InputField
                        error={errors.email && touched.email}
                        id="email"
                        name="email"
                        fullWidth={true}
                        placeholder="Email address" 
                        variant="outlined"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email &&
                        <div style={webStyle.fieldError}>
                          {errors.email}
                        </div>
                      }
                    </Box>
                    <Box sx={{ padding: "12px 0px" }}>
                      <InputLabel style={webStyle.fieldLabel}>{this.labelPassword}</InputLabel>
                      <InputField
                        error={errors.password && touched.password}
                        data-test-id="txtInputPassword"
                        type={this.state.enablePasswordField ? "password" : "text"}
                        id="password"
                        name="password"
                        fullWidth={true}
                        placeholder="Password"
                        variant="outlined"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                edge="end"
                              >
                                {this.state.enablePasswordField ? (
                                  <Typography style={{ color: "#bd9e44", fontSize: "14px" }}>Show</Typography>
                                ) : (
                                  <Typography style={{ color: "#bd9e44", fontSize: "14px" }}>Hide</Typography>
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      {errors.password && touched.password &&
                        <div style={webStyle.fieldError}>
                          {errors.password}
                        </div>
                      }
                      {this.state.accountError && <div style={{color: "#D11024", fontSize: '14px', marginTop: "8px"}}>{this.state.accountError}</div>}
                    </Box>
                    <p
                      data-test-id={"btnForgotPassword"}
                      style={{
                        fontSize: "14px",
                        color: "#bd9e44",
                        marginBottom: "20px",
                        marginTop: "0px",
                        cursor: "pointer",
                        width: "max-content"
                      }}
                      onClick={() => {this.checkUserType();this.goToForgotPassword()}}
                    >
                      Forgot Password?
                    </p>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "10px 0px 0px",
                      }}
                    >
                      <Button
                        type="submit"
                        data-test-id={"btnEmailLogIn"}
                        variant="contained"
                        color="primary"
                        style={{ height: "56px", fontSize: "16px", textTransform: "none", borderRadius: "8px" }}
                        fullWidth
                      >
                        {this.btnTxtSignin}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
          <Typography style={{ display: 'flex', justifyContent: "center", marginTop: "20px", color: "#7c7c7c"}}>
            New to EDConnect?&nbsp;
            <NavLink to="/Register" style={{ color: '#bd9e44', cursor: 'pointer', textDecoration: 'none' }} onClick={() => {this.checkUserType()}}>
              Register now
            </NavLink>
          </Typography>
        </Container>
        <AppFooter />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle:any = {
  container: {
    minHeight: "calc(100vh - 300px)",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    margin: "25px auto",
    fontFamily: "HelveticaNeue"
  },
  Tab1: {
    background: '#bd9e44',
    color: '#f9f8f8',
    minWidth: "unset",
    fontFamily: "HelveticaNeue",
  },
  Tab2: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: '#fffaec',
    color: "#bd9e44",
    width: "100%",
    fontFamily: "HelveticaNeue",
  },
  activeTransferredTickets: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    background: '#fffaec',
    color: "#bd9e44",
    width: "100%"
  },
  tab3: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    minWidth: "unset",
    fontFamily: "HelveticaNeue",
  },
  backArrow: {
    position: "absolute",
    width: "25px",
    left: "0",
    top: "3px",
    cursor: "pointer"
  },
  fieldError: {
    color: "#D11024",
    fontSize: "14px",
    margin: "5px 5px 5px 0"
  },
  pageTitle: {
    position: "relative",
    width: "100%",
    textAlign: "center",
    marginBottom: "35px"
  },
  fieldLabel: {
    color: "#1c1c1c",
    fontSize: "14px"
  }
};


const CustomTabs: any = withStyles({
  root: {
      maxHeight: "40px",
      margin: " 10px auto 10px 0",
      fontFamily: "HelveticaNeue",
      "& .MuiTab-root": {
          minHeight: '26px',
          maxHeight: "40px",
          maxWidth: '280px',
      },
      "& .MuiTabs-flexContainer": {
        border: "2px solid rgb(211,206,180)",
        borderRadius: "12px",
        width: "230px"
      },
      "& .MuiTabs-indicator": {
          display: "none",
      },
      "& .Mui-selected": {
          background: "#fffaec",
          color: '#bd9e44',
          maxWidth: '280px',
          "& .MuiTab-textColorInherit": {
              opacity: 0.7,
          },
      },
      "& .MuiTab-textColorInherit": {
          opacity: 1,
      },
      "@media (max-width: 470px)": {
          maxHeight: "80px",
      },
      "@media (max-width: 600px)": {
          marginLeft: "0px",
      },
  },
})(Tabs);

const CustomTab: any = withStyles({
  root: {
      width: "100%",
      color: "#bd9e44",
      fontFamily: "HelveticaNeue",
      fontWeight: 700,
      fontSize: "14px",
      textTransform: "none",
      borderRadius: '10px',
      "& .PrivateTabIndicator-colorSecondary-4": {
          display: "none",
      },
      "@media (max-width: 670px)": {
          width: "auto",
          fontSize: "12px"
      },
  },
})(Tab);

const InputField:any = withStyles({
  root: {
      width: '100%',
      paddingTop: '10px',
      borderRadius: "10px",
      fontFamily: "HelveticaNeue-medium",
      "& .MuiOutlinedInput-root": {
          fontWeight: 400,
          fontFamily: "HelveticaNeue",
          fontSize: "16px",
          "& fieldset": {
              fontSize: "16px",
              borderRadius: "10px",
              borderWidth: 1,
              fontWeight: 400,  
          },
      },
      "& .Mui-error":{
        "& .MuiOutlinedInput-notchedOutline":{
          borderColor: "#D11024"
        }
      },
      "& ::placeholder": {
        opacity: 1,
        color: "#565656",
        fontFamily: "HelveticaNeue-Medium",
      }
  }
})(TextField);

// Customizable Area End
