// Customizable Area Start
import React from 'react'
import UserProfileBasicController from './UserProfileBasicController'
import { Grid,Box, TextField, withStyles,Container } from "@material-ui/core";
import { Formik } from 'formik'
import * as Yup from 'yup'
import {  darkLock, lightAcc, notification, right, secure, sub } from './assets';
import CommonLoader from '../../../components/src/CommonLoader.web';
import AppFooter from '../../../components/src/AppFooter.web';
import LeftMenu from './LeftMenu.web';
import SettingsHeader from './SettingsHeader.web';
import SuccessModal from '../../../components/src/SuccessModalOne.web';
export default class SignInSecurity extends UserProfileBasicController {
    tabs = [{ 
        img: lightAcc, tab: 'Account preferences',path:'settings',isActive:false }, 
        { img: secure,darkIcon:darkLock, tab: 'Sign in & security',isActive:true }, 
        { img: notification, tab: 'Notifications',path:'Notification',isActive:false }, 
        { img: sub, tab: 'Manage my subscriptions',path:'ManageSubscription',isActive:false }]

        handleClose=()=>{
            this.setState({ openSnackBarState: { ...this?.state.openSnackBarState, open: false } })
        }

    render() {
        const changePasswordSChema = Yup.object().shape({
            currentPassword: Yup.string().required('Current Password is required'),
            password: Yup.string()
                .min(6, 'Password must be at least 6 characters long')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
                    'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character')
                .required('Password is required'),
            rePassword: Yup.string().required('Password is required')
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
        })
       
        return (
            <>
                <SettingsHeader navigation={this.props?.navigation} id={''} />
                <Container style={{ marginTop: '25px' }}>
                    <Grid container spacing={3}>
                        <Grid item md={3}>
                            <LeftMenu navigation={this.props?.navigation} id={''} tabs={this.tabs} />

                        </Grid>
                        <Grid item md={8}>
                            <CommonLoader isLoading={this.state.isLoading} />
                            {!this.state?.changePassword && <Box sx={{ bgcolor: 'background.paper', height: '150px', borderRadius: '10px', p: 2 }}>
                                <div style={{ fontSize: '18px', fontFamily: "HelveticaNeue", marginTop: '10px' }}>Account access</div>

                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px', alignItems: 'center' }}>
                                        <div style={{ fontFamily: "HelveticaNeue" }}>Email address</div>
                                        <div style={{ display: 'flex', fontFamily: "HelveticaNeue" }}>
                                            <div style={{ marginRight: '10px', color: '#565656' }}>{this.state?.profileDetailsObj?.email}</div>

                                        </div>
                                    </div>
                                    <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }}  />
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px', alignItems: 'center', fontFamily: "HelveticaNeue" }}>
                                        <div style={{ color: '#565656', fontFamily: "HelveticaNeue" }}>Change password</div>
                                        <div data-test-id="changeButton" onClick={() => this.setState({ changePassword: true })} style={{ color: '#B0A172', cursor: 'pointer' }}>Change</div>
                                    </div>

                                </div>


                            </Box>}
                            {this.state?.changePassword && <Box id='Box' data-test-id='box' sx={{ bgcolor: 'background.paper', height: 'auto', borderRadius: '10px', p: 2 }}>
                                <div data-test-id="back" onClick={() => this.setState({ changePassword: false })} style={{ display: 'flex', cursor: 'pointer' }}>
                                    <div style={webStyle?.leftArrow}><img src={right} width="20px" /></div>
                                    <div style={{ fontSize: "14px", fontFamily: "HelveticaNeue" }}>Back</div>
                                </div>

                                <div style={{ marginTop: '15px' }}>
                                    <div style={{ fontSize: "16px", fontFamily: "HelveticaNeue" }}>Change password</div>
                                    <div style={{ fontSize: "14px", fontFamily: "HelveticaNeue", marginTop: '15px' }}>Create a new password that is atleast 6 characters long</div>
                                    <Formik
                                        initialValues={this.state.initialPwdState}
                                        validationSchema={changePasswordSChema}
                                        onSubmit={(values: any) => { this.changePassword(values) }}
                                        data-test-id="changePwdFormik"
                                    >
                                        {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
                                            <form onSubmit={handleSubmit} data-test-id="changePwdForm" style={{ margin: "0" }}>
                                                <div style={{ marginTop: '15px' }}>
                                                    <div style={{ fontSize: "14px", fontFamily: "HelveticaNeue", color: '#3A3A3A' }}>Enter current password*</div>
                                                    <div><InputField
                                                        error={(errors?.currentPassword && touched?.currentPassword) || this.state.isCurrentPwdWrong}
                                                        id="currentPassword"
                                                        name="currentPassword"
                                                        fullWidth={true}
                                                        placeholder="Current password"
                                                        variant="outlined"
                                                        value={values.currentPassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                        {errors?.currentPassword && touched?.currentPassword &&
                                                            <div style={webStyle.fieldError}>
                                                                {errors?.currentPassword}

                                                            </div>

                                                        }
                                                        {this.state.isCurrentPwdWrong && <div style={webStyle.fieldError}>Current password is wrong</div>}
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: '15px' }}>
                                                    <div style={{ fontSize: "14px", fontFamily: "HelveticaNeue", color: '#3A3A3A' }}>Enter new password*</div>
                                                    <div><InputField
                                                        error={errors.password && touched.password}
                                                        id="password"
                                                        name="password"
                                                        fullWidth={true}
                                                        placeholder="New password"
                                                        variant="outlined"
                                                        value={values?.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                        {errors?.password && touched?.password &&
                                                            <div style={webStyle.fieldError}>
                                                                {errors?.password}
                                                            </div>
                                                        }</div>
                                                </div>
                                                <div style={{ marginTop: '15px' }}>
                                                    <div style={{ fontSize: "14px", fontFamily: "HelveticaNeue", color: '#3A3A3A' }}>Confirm your new password*</div>
                                                    <div><InputField
                                                        error={errors?.rePassword && touched?.rePassword}
                                                        id="rePassword"
                                                        name="rePassword"
                                                        fullWidth={true}
                                                        placeholder="Confirm password"
                                                        variant="outlined"
                                                        value={values.rePassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                        {errors?.rePassword && touched?.rePassword &&
                                                            <div style={webStyle.fieldError}>
                                                                {errors?.rePassword}
                                                            </div>
                                                        }</div>
                                                </div>

                                                <button type='submit' style={{ border: 'none', margin: '30px 0px 10px 0px', backgroundColor: '#BE9E44', padding: '10px 25px', width: '160px', borderRadius: '4px',cursor:'pointer' }}>
                                                    <div style={{ fontFamily: "HelveticaNeue", fontSize: '14px', color: 'white', textAlign: 'center' }}>Save password</div>
                                                </button>
                                                <div style={{ margin: '15px 0px', cursor: 'pointer' }}>
                                                    <div ><a style={webStyle?.anchorTag} href='/ForgotPassword' target='blank'>Forgot password?</a></div>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>


                            </Box>}
                           





                        </Grid>

                    </Grid>

                </Container>
                <div style={{ marginTop: 'auto' }}>
                    <AppFooter />
                </div>
                <SuccessModal openModal={this.state.openSnackBarState?.open} navigation={this.props?.navigation} handleClose={this.handleClose} message="Password changed successfully"  />
            </>

        )
    }
}
const webStyle = {
    leftArrow: {
        transform: 'matrix(-1, 0, 0, 1, 0, 0)',
        marginRight: '10px'
    },
    fieldError: {
        color: "red",
        fontSize: "14px",
        margin: "5px 5px 5px 0"
    },
    anchorTag: {
        fontFamily: "HelveticaNeue",
        color: '#565656', textDecoration: 'none'
    }
}
const InputField: any = withStyles({
    root: {
        width: '426px',
        paddingTop: '10px',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        },
       
    }
})(TextField);

// Customizable Area End