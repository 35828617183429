import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  selectedTab: any;
  initialCreds: any;
  isLoading: any;
  accountError: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  labelEmail: string;
  labelPassword: string;
  btnTxtSignin: string;
  errorEmailRequired: string;
  errorPasswordRequired: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      selectedTab: 1,
      initialCreds: {
        email: "",
        password: ""
      },
      isLoading: false,
      accountError: ""
    };
    
    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.labelEmail= configJSON.labelEmail;
    this.labelPassword= configJSON.labelPassword;
    this.btnTxtSignin= configJSON.btnTxtSignin;
    this.errorEmailRequired= configJSON.errorEmailRequired;
    this.errorPasswordRequired= configJSON.errorPasswordRequired;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    let getUserType:any = localStorage.getItem('userType');
    if(getUserType !== undefined && getUserType?.length > 0 ){
      if(getUserType === 'student'){
        this.setState({selectedTab: 0})
      }else{
        this.setState({selectedTab: 1})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  handleChangeTab = (event: any, newValue: any) => {
    this.setState({ selectedTab: newValue });
  };

  userLogin = (values: any) => {
    this.setState({isLoading: true})

    const studentData = {
      type: "student_account",
      attributes: { ...values },
    };

    const orgData = {
      type: "organisation_account",
      attributes: { ...values },
    };

    const httpBody = {
      data: this.state.selectedTab === 0 ? studentData : orgData,
    };

    const header = {
      "Content-type": configJSON.loginApiContentType
    }
    let url = configJSON.studentLoginAPiEndPoint;
    const getAccount = apiCall({
      header: header,
      httpBody: httpBody,
      url: url,
      httpMethod: configJSON.studentLoginAPiMethod,
    });
    
    this.apiEmailLoginCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      this.checkStates(countryCode, userName, password);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.checkAPIResponse(apiRequestCallId, this.validationApiCallId, responseJson, this.apiEmailLoginCallId)
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
    // Customizable Area Start

  checkAPIResponse(apiRequestCallId:any, validationApiCallId:any, responseJson:any, apiEmailLoginCallId:any){
    if (apiRequestCallId != null) {
      this.checkApiCallId(apiRequestCallId, validationApiCallId, responseJson);
      
      if (apiRequestCallId === apiEmailLoginCallId) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          this.setState({isLoading: false})
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          this.saveLoggedInUserData(responseJson);
          this.setState({accountError: ""})
          const userDetails=responseJson;
          localStorage.setItem('user_details',JSON.stringify({account_id:userDetails?.meta.account_id,account_type:userDetails?.meta?.account_type,first_name:userDetails?.meta?.first_name,last_name:userDetails?.meta?.last_name,profile_image:userDetails?.meta?.profile_image,job_title:userDetails?.meta?.job_title,name_of_organization:userDetails?.meta?.name_of_organization}))
          localStorage.removeItem("userType");
          this.props.navigation.navigate("FanWall");
        } else {
          //Check Error Response
          this.setState({isLoading: false})
          this.checkError(responseJson);
        }
      }
    }
  }

  checkStates(countryCode:any, userName:any, password:any){
    if (!countryCode) {
      if(userName){
        if(password){
          this.setState({
            email: userName,
            password: password,
            checkedRememberMe: true,
          });
  
          //@ts-ignore
          this.txtInputEmailProps.value = userName;
  
          //@ts-ignore
          this.txtInputPasswordProps.value = password;
  
          this.CustomCheckBoxProps.isChecked = true;
        }
      }
    }
  }

  checkRegex(regexData: any) {
    if (regexData?.email_validation_regexp) {
      this.emailReg = new RegExp(regexData.email_validation_regexp);
    }
  }

  checkArrayLength(arrayholder:any){
    if(arrayholder?.length !== 0){
      let regexData = arrayholder[0];
      
      this.checkRegex(regexData);
    }
  }

  checkResponse(responseJson:any){
    if (responseJson !== undefined) {
      let arrayholder = responseJson?.data;

      this.checkArrayLength(arrayholder);
    }
  }

  checkApiCallId(apiRequestCallId:any, validationApiCallId:any, responseJson:any){
    if (apiRequestCallId === validationApiCallId) {
      this.checkResponse(responseJson)
    }
  }

  checkError(responseJson:any){
    if(responseJson?.account && responseJson?.account.length > 0){
      this.setState({accountError: responseJson.account})
      return false
    }
    if(responseJson?.errors && responseJson?.errors.length > 0 && responseJson?.errors[0]?.failed_login){
      this.setState({accountError: responseJson.errors[0].failed_login})
      return false
    }
    if(responseJson.message && responseJson.message === "You have to fill your profile details"){
      localStorage.setItem("userEmail", responseJson.email)
      localStorage.setItem("userFirstName", responseJson.first_name)
      localStorage.setItem("authToken", responseJson.token)
      this.checkAccountType(responseJson);
    }
  }

  checkAccountType(responseJson:any) {
    if(responseJson.account_type === "student_account"){
      this.props.navigation.navigate("RegisterStudentDetails")
    }
    if(responseJson.account_type === "organisation_account"){
      this.props.navigation.navigate("EmailAccountOrganisationDetails")
    }
  }

  doEmailLogIn(){
    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const studentData = {
      type: "student_account",
      attributes: attrs,
    };

    const orgData = {
      type: "organization_account",
      attributes: attrs,
    };

    
    // const header = getHeaders({token: this.authToken})
    const httpBody = {
      data: this.state.selectedTab === 0 ? studentData : orgData,
    };
    let url = configJSON.studentLoginAPiEndPoint;
    const getAccount = apiCall({
      httpBody: httpBody,
      header: {},
      url: url,
      httpMethod: configJSON.studentLoginAPiMethod,
    });
    
    this.apiEmailLoginCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  checkUserType = () => {
    localStorage.setItem('userType', this.state.selectedTab === 0 ? 'student' : 'organization')
  }
}
  // Customizable Area End