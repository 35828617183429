import React from "react";
import { Modal, Box, Typography, Button } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { background } from "../../blocks/educational-user-profile/src/assets";
const close = require("../src/close.png");

const style: any = {
  // width: "984px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  borderRadius: 14,
  boxShadow: 24,
  p: 3,
  overflow: "auto",
};

const AddCoverImgModal = ({ open, handleClose, handleSaveImgBackground }: any) => {
  const [backgroundImage, setBackgroundImage] = React.useState<
    string | undefined
  >();
  const [backGrounfImageFile, setBackGrounfImageFile] = React.useState(null)


  const handleBackGroundImage = (event: any) => {
    setBackgroundImage(URL.createObjectURL(event.target.files[0]))

    setBackGrounfImageFile(event.target.files[0])
  }

  const addRemoveBackGroundImg = () => {
    handleSaveImgBackground(backGrounfImageFile)
    setBackgroundImage('')
    setBackGrounfImageFile(null)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src={close}
            style={modalContactStyle.closeIcon}
            onClick={handleClose}
          />
          <Typography style={modalContactStyle.contact_textContent}>
            Add background photo
          </Typography>

          <Box style={modalContactStyle.main}>
            <Box>
              <img
                src={backgroundImage ? backgroundImage : background}
                style={modalContactStyle.profileImg}
              />
              <Typography style={modalContactStyle.txt}>
                Add a cover photo for your profile.
              </Typography>
              <Typography style={modalContactStyle.subtxt}>
                A good background photo will help you stand out.
              </Typography>
            </Box>
            <Box style={modalContactStyle.row}>
              <Box style={modalContactStyle.submit}>
                <label htmlFor="contained-button-file">
                  {backgroundImage ? (
                    <>
                      <Button
                        variant="contained"
                        component="span"
                        style={{ ...modalContactStyle.uploadButton, marginRight: '10px' }}
                      >
                        Change
                        <input
                          accept="image/*"
                          style={modalContactStyle.selectProfile}
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(event) => handleBackGroundImage(event)}
                        />
                      </Button>
                      <Button
                        variant="contained"
                        component="span"
                        style={modalContactStyle.uploadButton}
                        onClick={() => addRemoveBackGroundImg()}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      component="span"
                      style={modalContactStyle.uploadButton}
                    >
                      Upload background photo
                      <input
                        accept="image/*"
                        style={modalContactStyle.selectProfile}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(event) => handleBackGroundImage(event)}
                      />
                    </Button>
                  )}
                </label>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddCoverImgModal;

const modalContactStyle: any = {
  contact_textContent: {
    fontSize: "20px",
    textAlign: "center",
    marginBottom: "20px",
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "20px",
    top: "20px",
    height: "20px",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  row: {
    display: "flex",
    gap: "24px",
  },
  txt: {
    textAlign: "center",
  },
  subtxt: {
    color: "#696868",
    fontSize: "14px",
    textAlign: "center",
    marginBottom: "20px",
  },
  profileImg: {
    height: "216px",
    width: "383px",
    marginBottom: "20px",
  },
  selectProfile: {
    display: "none",
  },
  uploadButton: {
    height: "36px",
    border: "1px solid #BE9E44",
    backgroundColor: "#BE9E44",
    borderRadius: "6px",
    color: "#fff",
    textTransform: "none"
  },
  submit: {
    width: "876px",
    display: "flex",
    justifyContent: "end",
  },
};
