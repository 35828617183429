Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FriendList";
exports.labelBodyText = "FriendList Body";

exports.btnExampleTitle = "CLICK ME";

exports.getConnectionRequestsMethod = "GET";
exports.getConnectionRequestsApiEndPoint = "/bx_block_friendlist/connects/connection_requests";

exports.acceptConnectionRequestsMethod = "PATCH";
exports.acceptConnectionRequestsApiEndPoint = "/bx_block_friendlist/connects/accept_connection";

exports.rejectConnectionRequestsMethod = "GET";
exports.rejectConnectionRequestsApiEndPoint = "/bx_block_friendlist/connects/decline_connection";
// Customizable Area End