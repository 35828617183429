import React from 'react'
import { Grid, Modal, Backdrop, Box, Fade, TextField, Card, CardMedia, IconButton, withStyles, styled } from "@material-ui/core";
import { close, emoji, gallery, profile } from '../../blocks/postcreation/src/assets';
import Close from '@material-ui/icons/Close';
import { userAccImg } from '../../blocks/ElasticSearch/src/assets';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ModalProps {
    openCreatePost: any
    closeModal: any
    handlePostText: any
    uploadedImages: any
    handleRemoveImage: any
    hashTags: any
    handleImage: any
    AddPostCreation: any
    imgRef: any
    userAccountObj: any
    loading?: any
}
function CreatePostModal(props: ModalProps) {
    const { openCreatePost, closeModal, handlePostText, uploadedImages, handleRemoveImage, hashTags, handleImage, AddPostCreation, imgRef, userAccountObj, loading } = props
    const { first_name, middle_name, last_name, profile_image, job_title, name_of_organisation } = userAccountObj
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openCreatePost}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openCreatePost}>
                <Box sx={style}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '0.5px solid #D7D7D7', padding: '15px' }}>
                        <div style={{ fontSize: '20px', color: '#1C1C1C', fontFamily: "HelveticaNeue" }}>Create a post</div>
                        <div data-test-id="closePost" style={{ cursor: 'pointer' }} onClick={() => closeModal()}><img src={close} width='24px' /></div>

                    </div>
                    {/* <hr style={{  height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} /> */}

                    <div style={{ display: 'flex', marginTop: '20px', padding: '0px 15px' }}>
                        <div style={{ marginRight: '10px' }}><img src={profile_image !== null ? profile_image : userAccImg} style={{ borderRadius: '100%' }} width='44px' height='44px' /></div>
                        <div style={{ fontSize: '14px', fontFamily: "HelveticaNeue" }}>{first_name} {last_name}</div>

                    </div>
                    <div style={{ marginTop: '15px', padding: '0px 15px' }}>
                        {/* <InputFieldForPost
                            data-test-id="modalInput"
                            multiline
                            minRows={6}
                            placeholder='What do you want to talk about ?'
                            variant="outlined"
                            onChange={(e: any) => {

                                handlePostText(e)
                            }}
                        /> */}
                        <div style={{ height: '128px' }}>
                            <ReactQuill
                                className='ql-container'
                                placeholder='What do you want to talk about ?'
                                modules={{ toolbar: false }}
                                onChange={(e: any) => {

                                    handlePostText(e)
                                }}

                                style={{ height: '100%', border: 'none' }}
                            />
                        </div>
                    </div>
                    <Grid container spacing={3} style={{ padding: '10px 15px' }}>


                        {uploadedImages.map((img: any) => {
                            return <Grid data-test-id="images" item xs={12} sm={6} md={4}>
                                <Card key={img.id} style={{
                                    position: 'relative'
                                }}>
                                    <CardMedia component="img" image={img?.img} />
                                    <IconButton data-test-id="iconButton"
                                        onClick={() => {
                                            handleRemoveImage(img)
                                        }}

                                        style={{ position: 'absolute', top: 5, right: 5, width: '24px', height: '24px', borderRadius: '50%', opacity: '0.74', backgroundColor: 'white' }}
                                    >
                                        <Close />
                                    </IconButton>


                                </Card>
                            </Grid>
                        })}

                    </Grid>
                    {/* related hashtags */}
                    <div style={{ margin: '5px 15px' }}>
                        {hashTags?.map((hashtag: any) => {
                            return <span data-test-id="hashtag" key={hashtag?.id} style={{ fontSize: '14px', color: '#BE9E44', cursor: 'pointer', fontWeight: 'bold', margin: '0px 10px' }}>#{hashtag?.name} </span>
                        })}
                    </div>
                    <div style={{ display: uploadedImages.length < 1 ? 'flex' : 'block', justifyContent: 'space-between', padding: '15px' }}>
                        {uploadedImages.length < 1 && <div style={{ display: 'flex' }}>

                            <div data-test-id="imgClick" onClick={() => imgRef.current.click()} style={{ marginRight: '15px', cursor: 'pointer' }}>
                                <img src={gallery} width='20px' />
                                <input data-test-id="inputBtn" ref={imgRef} type='file' accept="image/*" onChange={(e: any) => {

                                    handleImage(e)
                                }} hidden />
                            </div>

                        </div>}


                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div data-test-id="postButton" onClick={() => { AddPostCreation() }} style={webStyle.postButton}>
                                {loading && <CircularProgress size={14} style={{ padding: '5px', color: 'white' }} />}
                                Post
                            </div>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 556,
    height: 'auto',
    bgcolor: 'background.paper',
    border: '1px solid #979797',
    borderRadius: '10px',
    boxShadow: 24,

};
const webStyle = {
    postButton: {
        fontFamily: "HelveticaNeue",
        backgroundColor: '#BE9E44',
        color: 'white',
        minWidth: '70px',
        width: 'auto',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '14px',
        cursor: 'pointer',
        borderRadius: '6px'
    },
    imgClose: {
        position: 'absolute',
        top: '12%',
        left: '95%',
        width: '24px',
        height: '24px',
        backgroundColor: 'white',
        borderRadius: '50%',
        opacity: '0.74',
        transform: 'translate(-50%,-50%)'
    } as React.CSSProperties
}
const InputFieldForPost: any = withStyles({
    root: {
        width: '100%',
        fontFamily: "HelveticaNeue",
        border: 'none',
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                fontWeight: 400,
                borderWidth: 0,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    }
})(TextField);

export default CreatePostModal