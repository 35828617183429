import * as React from "react";
// import "../../web/src/App.css";
import { Modal, Typography, Button, Box } from "@material-ui/core";
import parse from "html-react-parser";
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 434,
  height: 500,
  bgcolor: "background.paper",
  borderRadius: 12,
  boxShadow: 24,
  padding: "50px",
  "@media (max-width: 576px)": {
    padding: "40px 35px",
    width: "80%"
  }
};

export function TermsConditionsPop({ open, handleModal, data, title }:any) {
  if (data) {
    var dataParse:any = data.replace(/<[^>]*>/g, "");
  }
  return (
      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          
            <Typography style={modalTermsStyle.termsConditions_textStyle}>
              {title}
            </Typography>
          <Box
            // id="modal-modal-title"
            // component="h2"
            className="scroll_bar_hide"
            style={modalTermsStyle.termsConditions_contentWrap}
          >
            <Typography style={modalTermsStyle.termsConditions_content}>
              {dataParse}
            </Typography>
          </Box>
          <Box
          style={{
            width: "100%",
            marginTop: "25px",
            marginBottom: "10px"
          }}
          >
          <Button style={modalTermsStyle.closeButton} onClick={handleModal}>
            Close
          </Button>
          </Box>
        </Box>
      </Modal>
  );
}

const modalTermsStyle:any = {
  termsConditions_textStyle: {
    fontSize: 19,
    fontWeight: 600,
    padding: "7px",
    textAlign: "center",
    marginBottom: "10px"
  },
  termsConditions_contentWrap: {
    height: "366px",
    overflow: "auto",
    marginTop: "10px",
    width: "calc(100% - -20px)"
  },
  termsConditions_content: {
    paddingRight: "20px",
    textAlign: "justify",
  },
  closeButton: {
    backgroundColor: "#BE9E44",
    color: "#FFFFFF",
    width: "100%",
    height: 56,
    textTransform: "capitalize",
    borderRadius: 8,
    fontSize: 16,
    "&:hover": {
      background: "#BE9E44",
    },
  },
};
