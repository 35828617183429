export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const connections_img =require('../assets/connections.png');
export const downArraow_img =require('../assets/downArrow.png');
export const edconnectlogo_img =require('../assets/edconnect_logo.png');
export const home_img =require('../assets/home.png');
export const messages_img =require('../assets/messages.png');
export const notification_img =require('../assets/notification.png');
export const profile_img =require('../assets/profile.png');
export const search_img =require('../assets/search.png');
export const userAccImg =require('../assets/userAccImg.png');
export const darkMsg =require('../assets/darkMsg.png');
export const lightHome =require('../assets/lightHome.png');

