import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall, getHeaders } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  openContactPopup:boolean;
  openPrivacyPolicyPopup:boolean;
  openTermsConditionPopup:boolean;
  termsPolicyData: any;
  mockData: any;
  showMore: any;
  connectionRequests: any;
  anchorEl: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FriendListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getConnectionRequestsCallId: string = "";
  acceptConnectionRequestsCallId: string = "";
  rejectConnectionRequestsCallId: string = "";
  authToken: string;

  async componentDidMount() {
    // Customizable Area Start
    const authToken = localStorage.getItem("authToken");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.getConnectionRequests();
    // Customizable Area End
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      openContactPopup: false,
      openPrivacyPolicyPopup:false,
      openTermsConditionPopup:false,
      termsPolicyData: {},
      mockData: [
        {
            "name": "Pan Hyuk Abcd college of arts & science Abcd college of arts & science",
            "college": "Abcd college of arts & science",
            "connected": "Connected 1 week ago"
        },
        {
            "name": "Pan Hyuk",
            "college": "Abcd college of arts & science",
            "connected": "Connected 1 week ago"
        },
        {
            "name": "Pan Hyuk",
            "college": "Abcd college of arts & science Abcd college of arts & science Abcd college of arts & science",
            "connected": "Connected 1 week ago"
        },
        {
            "name": "Pan Hyuk",
            "college": "Abcd college of arts & science",
            "connected": "Connected 1 week ago"
        },
        {
            "name": "Pan Hyuk",
            "college": "Abcd college of arts & science",
            "connected": "Connected 1 week ago Abcd college of arts & science Abcd college of arts & science"
        },
        {
            "name": "Pan Hyuk",
            "college": "Abcd college of arts & science",
            "connected": "Connected 1 week ago"
        },
      ],
      showMore: false,
      connectionRequests: [],
      anchorEl: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (
      this.checkEnumCondition1(message)
      ) {
       let resJson = message.getData(
         getName(MessageEnum.RestAPIResponceSuccessMessage)
       );
       this.checkResponse1(resJson);
     }

    if (
      this.checkEnumCondition2(message)
      ) {
       let resJson = message.getData(
         getName(MessageEnum.RestAPIResponceSuccessMessage)
       );
       this.checkResponse2(resJson);
     }

    if (
      this.checkEnumCondition3(message)
      ) {
       let resJson = message.getData(
         getName(MessageEnum.RestAPIResponceSuccessMessage)
       );
       this.checkResponse3(resJson);
     }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  checkEnumCondition1(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getConnectionRequestsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition2(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.acceptConnectionRequestsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition3(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.rejectConnectionRequestsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkResponse1(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false, connectionRequests: resJson.data });
    }
  }

  checkResponse2(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
      console.log("Request Accepted")
    }
  }

  checkResponse3(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false});
      console.log("Request Rejected")
    }
  }

  handleOpenContactusPopup = () => {
    this.setState({ openContactPopup: true })
  };

  handleCloseContactusPopup = () => {
    this.setState({ openContactPopup: false })
  };

  handlePrivacyPolicyPopup = () => {
    this.setState({ openPrivacyPolicyPopup: !this.state.openPrivacyPolicyPopup })
  };

  handleTermsConditionPopup = () => {
    this.setState({ openTermsConditionPopup: !this.state.openTermsConditionPopup })
  };

  getConnectionRequests = () => {
    this.setState({isLoading: true});

    const headers = getHeaders({token: this.authToken})
    let url = configJSON.getConnectionRequestsApiEndPoint;

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getConnectionRequestsMethod,
    });
    
    this.getConnectionRequestsCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  acceptConnectionRequest = () => {
    this.setState({isLoading: true});

    const headers = getHeaders({token: this.authToken})
    let url = configJSON.acceptConnectionRequestsApiEndPoint;
    let httpBody:any = {
      "connect_id": 1
    }
    const getAccount = apiCall({
      header: headers,
      httpBody: httpBody  ,
      url: url,
      httpMethod: configJSON.acceptConnectionRequestsMethod,
    });
    
    this.acceptConnectionRequestsCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  rejectConnectionRequest = () => {
    this.setState({isLoading: true});

    const headers = getHeaders({token: this.authToken})
    let url = configJSON.rejectConnectionRequestsApiEndPoint;
     
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.rejectConnectionRequestsMethod,
    });
    
    this.rejectConnectionRequestsCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleCloseMenu = () => {
    this.setState({anchorEl: null})
  }

  handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({anchorEl: event.currentTarget})
  };
  // Customizable Area End
}
