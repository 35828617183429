import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall, getHeaders } from "../../../components/src/common";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  data?: any;
  closeModal: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVisible: boolean;
  apiToken: any;
  newValue: string;
  educationQualification: string[];
  projectList: string[];
  modalProject: any;
  awardList: any;
  patentList: any;
  loadingEQ: boolean;
  activeTab: number;
  loadingProject: boolean;
  loadingAwards: boolean;
  loadingPub: boolean;
  isModalOpen: boolean;
  modalItem: any;
  selectedTab: any;
  openContactPopup:boolean;
  openPrivacyPolicyPopup:boolean;
  openTermsConditionPopup:boolean;
  mockData:any;
  anchorEl:any;
  isOrganiser: boolean;
  isLoading: boolean;
  isAboutMore: boolean;
  termsPolicyData: any;
  orgProfileDetails: any;
  isOwner: any;
  coverImagePopup: boolean;
  coverImageState: any;
  selectedCoverFile: any;
  selectedCoverImage: any;
  coverImageUploaded: any;
  profileDetailsPopup: boolean;
  profileImageState: any;
  selectedProfileFile: any;
  selectedProfileImage: any;
  profileImageUploaded: any;
  aboutPopup: boolean;
  initialProfileDetails: any;
  commentSection: boolean;
  userComment: any;
  isLiked: any;
  isReactionsOpen: boolean;
  postData: any;
  userPost: any;
  userActivityPost: any;
  alumniSearchValue: any;
  alumniList:any;
  startPost: any;
  endPost: any;
  accountError: any;
  removeAlumniPopup: boolean;
  removeCoverImagePopup: boolean;
  removeProfileImagePopup: boolean;
  removeAlumniId: any;
  isRemoveSuccess: boolean;
  openCreatePost: boolean;
  uploadedImages: any;
  hashTags: any;
  userAccountObj: any;
  description: any;
  body: any;
  base64Images: any;
  isActiveFilter: any;
  isAlumni: boolean;
  isFollow: boolean;
  followersCount:any;
  followingCount:any;
  connectionCount:any;
  selectedeAlumniSortByFilter:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EducationalUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelTitle: string = "";
  getEducationCallId: string = "";
  getProjectCallId: string = "";
  getAwardsCallId: string = "";
  getPatentCallId: string = "";
  getTermsPolicyCallId: string = "";
  authToken: string;
  getProfileCallId: string = "";
  getProfileAfterFollowCallId: string = "";
  updateProfileApiCallId: string = "";
  apiPostItemCallId: string = "";
  postListOfAlumniId: string = "";
  getUserAllActivityId: string = "";
  postSearchAlumniId: string = "";
  followOrganizationApiCallId: string = "";
  unfollowOrganizationApiCallId: string = "";
  removeAlumniApiCallId: string = "";
  becomeAlumniApiCallId: string = "";
  addpostApiCallId: string = "";
  getConnectionCountCallId: string = "";
  imgRef: any;
  getFollowersCountApiId:string = "";
  getFollowingCountApiId:string = "";
  addBgPhotoText:string = "";
  addCoverPhotoSubText:string = "";
  addCoverPhotoSubText2:string = "";
  removeCoverPhotoText:string = "";
  removeCoverConfirmText:string = "";
  removeProfilePhotoText:string = "";
  removeProfileConfirmText:string = "";
  addProfilePhotoText:string = "";
  addProfilePhotoSubText:string = "";
  addProfilePhotoSubText2:string = "";
  nameOfOrgLabel:string = "";
  streetNameLabel:string = "";
  postalCodeLabel:string = "";
  cityLabel:string = "";
  countryLabel:string = "";
  openingHoursLabel:string = "";
  closingHoursLabel:string = "";
  saveBtnText:string = "";
  uploadBgPhotoBtnText:string = "";
  uploadProfilePhotoBtnText:string = "";
  changeBtnText: string = "";
  changePhotoBtnText: string = "";
  followerText: string = "";
  followingText: string = "";
  myActivityText: string = "";
  allActivityText: string = "";
  postsText: string = "";
  likedText: string = "";
  commentsText: string = "";
  userNameText: string = "";
  studentatOrgText: string = "";
  notPostedText: string = "";
  notLikedText: string = "";
  notCommentedText: string = "";
  sortByText: string = "";
  recentlyAddedText: string = "";
  firstNameText: string = "";
  lastNameText: string = "";
  noAlumniText: string = "";
  removeAlumniText: string = "";
  confirmRemoveAlumniText: string = "";
  becomeAlumniBtnText: string = "";
  noPostText: string = "";
  recentPostText: string = "";
  seeAllActivityText: string = "";
  startPostText: string = "";
  followerConnectionText: string = "";
  manageConnectionText: string = "";
  viewBtnText: string = "";
  alumniListText: string = "";
  aboutOrgText: string = "";
  aboutText: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getOrganisationProfile = this.getOrganisationProfile.bind(this);
    this.imgRef = React.createRef()
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isVisible: false,
      apiToken: null,
      newValue: "1",
      educationQualification: [],
      projectList: [],
      modalProject: [],
      awardList: [],
      patentList: [],
      loadingEQ: true,
      activeTab: 1,
      loadingProject: true,
      loadingAwards: true,
      loadingPub: true,
      isModalOpen: false,
      modalItem: null,
      selectedTab: 0,
      openContactPopup: false,
      openPrivacyPolicyPopup:false,
      openTermsConditionPopup:false,
      mockData: [
        {
            "name": "Pan Hyuk Abcd college of arts & science Abcd college of arts & science",
            "college": "Abcd college of arts & science",
            "connected": "Connected 1 week ago"
        },
        {
            "name": "Pan Hyuk",
            "college": "Abcd college of arts & science",
            "connected": "Connected 1 week ago"
        },
        {
            "name": "Pan Hyuk",
            "college": "Abcd college of arts & science Abcd college of arts & science Abcd college of arts & science",
            "connected": "Connected 1 week ago"
        },
        {
            "name": "Pan Hyuk",
            "college": "Abcd college of arts & science",
            "connected": "Connected 1 week ago"
        },
        {
            "name": "Pan Hyuk",
            "college": "Abcd college of arts & science",
            "connected": "Connected 1 week ago Abcd college of arts & science Abcd college of arts & science"
        },
        {
            "name": "Pan Hyuk",
            "college": "Abcd college of arts & science",
            "connected": "Connected 1 week ago"
        },
      ],
      anchorEl: null,
      isOrganiser: false,
      isLoading: false,
      isAboutMore: false,
      termsPolicyData: {},
      orgProfileDetails: {},
      isOwner: false,
      coverImagePopup: false,
      coverImageState: "initial",
      selectedCoverFile: null,
      selectedCoverImage: require("../assets/cover_photo.png"),
      coverImageUploaded: 0,
      profileDetailsPopup: false,
      profileImageState: "initial",
      selectedProfileFile: null,
      selectedProfileImage: require("../assets/profile_photo.png"),
      profileImageUploaded: 0,
      aboutPopup: false,
      initialProfileDetails: {
        name_of_organisation: '',
        address: "",
        postal_code: "",
        city: "",
        country: "",
        opening_hour: "",
        closing_hour: "",
      },
      commentSection: false,
      userComment: "",
      isLiked: 0,
      isReactionsOpen: false,
      postData: [],
      userPost: [],
      userActivityPost: [],
      alumniSearchValue: "",
      alumniList: [],
      startPost: 0,
      endPost: 2,
      accountError: "",
      removeAlumniPopup: false,
      removeCoverImagePopup: false,
      removeProfileImagePopup: false,
      removeAlumniId: 0,
      isRemoveSuccess: false,
      openCreatePost: false,
      uploadedImages: [],
      hashTags: [],
      userAccountObj:{
        first_name:'',
        middle_name:'',
        last_name:'',
        profile_image:'',
        job_title:'',
        name_of_organisation:'',
        type:''
      },
      description: "",
      body: "",
      base64Images: [],
      isActiveFilter: 0,
      isAlumni: false,
      isFollow: false,
      followersCount:0,
      followingCount:0,
      connectionCount: 0,
      selectedeAlumniSortByFilter: '0'
    };

    this.labelTitle = configJSON.labelTitle;
    this.addBgPhotoText = configJSON.addBgPhotoText;
    this.addCoverPhotoSubText = configJSON.addCoverPhotoSubText;
    this.addCoverPhotoSubText2 = configJSON.addCoverPhotoSubText2;
    this.removeCoverPhotoText = configJSON.removeCoverPhotoText;
    this.removeCoverConfirmText = configJSON.removeCoverConfirmText;
    this.removeProfilePhotoText = configJSON.removeProfilePhotoText;
    this.removeProfileConfirmText = configJSON.removeProfileConfirmText;
    this.addProfilePhotoText = configJSON.addProfilePhotoText;
    this.addProfilePhotoSubText = configJSON.addProfilePhotoSubText;
    this.addProfilePhotoSubText2 = configJSON.addProfilePhotoSubText2;
    this.nameOfOrgLabel = configJSON.nameOfOrgLabel;
    this.streetNameLabel = configJSON.streetNameLabel;
    this.postalCodeLabel = configJSON.postalCodeLabel;
    this.cityLabel = configJSON.cityLabel;
    this.countryLabel = configJSON.countryLabel;
    this.openingHoursLabel = configJSON.openingHoursLabel;
    this.closingHoursLabel = configJSON.closingHoursLabel;
    this.saveBtnText = configJSON.saveBtnText;
    this.uploadBgPhotoBtnText = configJSON.uploadBgPhotoBtnText;
    this.uploadProfilePhotoBtnText = configJSON.uploadProfilePhotoBtnText;
    this.changeBtnText = configJSON.changeBtnText;
    this.changePhotoBtnText = configJSON.changePhotoBtnText;
    this.followerText = configJSON.followerText;
    this.followingText = configJSON.followingText;
    this.myActivityText = configJSON.myActivityText;
    this.allActivityText = configJSON.allActivityText;
    this.postsText = configJSON.postsText;
    this.likedText = configJSON.likedText;
    this.commentsText = configJSON.commentsText;
    this.userNameText = configJSON.userNameText;
    this.studentatOrgText = configJSON.studentatOrgText;
    this.notPostedText = configJSON.notPostedText;
    this.notLikedText = configJSON.notLikedText;
    this.notCommentedText = configJSON.notCommentedText;
    this.sortByText = configJSON.sortByText;
    this.recentlyAddedText = configJSON.recentlyAddedText;
    this.firstNameText = configJSON.firstNameText;
    this.lastNameText = configJSON.lastNameText;
    this.noAlumniText = configJSON.noAlumniText;
    this.removeAlumniText = configJSON.removeAlumniText;
    this.confirmRemoveAlumniText = configJSON.confirmRemoveAlumniText;
    this.becomeAlumniBtnText = configJSON.becomeAlumniBtnText;
    this.noPostText = configJSON.noPostText;
    this.recentPostText = configJSON.recentPostText;
    this.seeAllActivityText = configJSON.seeAllActivityText;
    this.startPostText = configJSON.startPostText;
    this.followerConnectionText = configJSON.followerConnectionText;
    this.manageConnectionText = configJSON.manageConnectionText;
    this.viewBtnText = configJSON.viewBtnText;
    this.alumniListText = configJSON.alumniListText;
    this.aboutOrgText = configJSON.aboutOrgText;
    this.aboutText = configJSON.aboutText;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const authToken = localStorage.getItem("authToken");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.getFollowersCount();
    this.getFollowingCount()
    this.getOrganisationProfile();
    this.getPostData();
    this.getAlumniByFilter(0);
    this.getUserAllActivity(0);
    this.getConnectionCount();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.checkToken(token);
    } else if (
      this.checkEnumCondition(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse(resJson);
    }

    this.response2(message)
    this.response3(message)
    this.response4(message)
    this.response5(message)
    this.response6(message)
    this.response7(message)
    this.response8(message)

    if (
      this.checkEnumCondition8(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse8(resJson)
    }

    if (
      this.checkEnumCondition9(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse8(resJson)
    }

    if (
      this.checkEnumCondition10(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse10(resJson)
    }

    if (
      this.checkEnumCondition11(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkUnfollowOrganizationResponse(resJson)
    }
   
    if (
      this.checkEnumCondition12(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse12(resJson)
    }

    if (
      this.checkEnumCondition13(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse13(resJson)
    }

    if (
      this.checkEnumCondition14(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse14(resJson)
    }

    if (
      this.checkConnectionCountCondition(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkConnectionCountResponse(resJson)
    }
   
    if (
      this.checkUserAllActivityCondition(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkUserAllActivityResponse(resJson)
    }
    
    if (
      this.checkUnfollowOrganizationCondition(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkUnfollowOrganizationResponse(resJson)
    }
    if (
      this.checkEnumCondition15(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkFollowersCountResponse(resJson)
    }
    if (
      this.checkEnumCondition16(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkFollowingCountResponse(resJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkToken(token: any) {
    if (token) {
      this.setState({ apiToken: token }, () => {
        this.getEducationQualification();
        this.getProjectList();
        this.getAwardList();
        this.getPatentList();
      });
    }
  }

  response2(message: any) {
    if (
      this.checkEnumCondition2(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse2(resJson);
    }
  }

  response3(message: any) {
    if (
      this.checkEnumCondition3(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse3(resJson);
    }
  }

  response4(message: any) {
    if (
      this.checkEnumCondition4(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse4(resJson)
    }
  }

  response5(message: any) {
    if (
      this.checkEnumCondition5(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse5(resJson)
    }
  }

  response6(message: any) {
    if (
      this.checkEnumCondition6(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse6(resJson)
    }
  }
  response7(message: any) {
    if (
      this.checkConditionAfterFollow(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkGetProfileAfterFollow(resJson)
    }
  }
  response8(message: any) {
    if (
      this.checkEnumCondition7(message)
    ) {
      let resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse7(resJson)
    }
  }

  checkEnumCondition(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getEducationCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition2(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProjectCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition3(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAwardsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition4(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPatentCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition5(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTermsPolicyCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition6(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProfileCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkConditionAfterFollow(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProfileAfterFollowCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition7(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateProfileApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition8(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postSearchAlumniId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition9(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postListOfAlumniId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition10(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiPostItemCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition11(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.followOrganizationApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition12(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeAlumniApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition13(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.becomeAlumniApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkEnumCondition14(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addpostApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }
  checkConnectionCountCondition(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getConnectionCountCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }
  checkEnumCondition15(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFollowersCountApiId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }
  checkEnumCondition16(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFollowingCountApiId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }
  checkUserAllActivityCondition(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getUserAllActivityId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }
 
  checkUnfollowOrganizationCondition(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.unfollowOrganizationApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkResponse(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.showAlert("Error", resJson?.errors[0]?.token);
    } else {
      this.setState({
        educationQualification: resJson?.data,
        loadingEQ: false,
      });
    }
  }

  checkResponse2(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.showAlert("Error", resJson?.errors[0]?.token);
    } else {
      this.setState({ projectList: resJson?.data, loadingProject: false });
    }
  }

  checkResponse3(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.showAlert("Error", resJson?.errors[0]?.token);
    } else {
      this.setState({ awardList: resJson?.data, loadingAwards: false });
    }
  }

  checkResponse4(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.showAlert("Error", resJson?.errors[0]?.token);
    } else {
      this.setState({ patentList: resJson?.data, loadingPub: false });
    }
  }

  checkResponse5(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false })
    } else {
      this.setState({ isLoading: false, termsPolicyData: resJson });
    }
  }

  checkResponse6(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
      this.props.navigation.navigate("Login")
    } else {
      if(resJson?.message?.length !== 0 && !resJson?.account){
        this.setState({isLoading: false, accountError: resJson.message, selectedProfileImage: require("../assets/profile_photo.png"), selectedCoverImage: require("../assets/cover_photo.png")});
        let getUserDetails:any = localStorage.getItem("user_details");
        let getUserData:any = JSON.parse(getUserDetails);
        localStorage.setItem('user_details',JSON.stringify({...getUserData, profile_image: this.state.orgProfileDetails?.profile_image}))
        this.getOrganisationProfileAfterFollow();
        return false;
      }
      let openHour:any = new Date(resJson?.profile?.data?.attributes.opening_hour);
      let closeHour:any = new Date(resJson?.profile?.data?.attributes.closing_hour);
      this.setState({ 
        isLoading: false,
        isOwner: resJson?.profile?.data?.attributes.is_owner,
        orgProfileDetails: resJson?.profile?.data?.attributes,
        isFollow: resJson.follow,
        selectedProfileFile: null,
        selectedCoverFile: null
      });
      this.setState(() => ({
        initialProfileDetails: {
          name_of_organisation: resJson?.profile?.data?.attributes.name_of_organisation,
          address: resJson?.profile?.data?.attributes.address,
          postal_code: resJson?.profile?.data?.attributes.postal_code,
          city: resJson?.profile?.data?.attributes.city,
          country: resJson?.profile?.data?.attributes.country,
          opening_hour: moment(openHour, "HH:mm"),
          closing_hour: moment(closeHour, "HH:mm"),
          headline: resJson?.profile?.data?.attributes.headline,
        }
      }))
      this.checkCoverImage(resJson?.profile?.data?.attributes?.photo);
      this.checkProfileImage(resJson?.profile?.data?.attributes?.profile_image);
      
      this.setState({ userAccountObj: { ...this.state.userAccountObj,
         first_name:resJson?.account?.data?.attributes?.first_name, 
         middle_name:resJson?.account?.data?.attributes?.middle_name,
          last_name:resJson?.account?.data?.attributes?.last_name,
           profile_image:resJson?.profile?.data?.attributes?.profile_image,
            job_title:resJson?.profile?.data?.attributes?.job_title,
             name_of_organisation:resJson?.profile?.data?.attributes?.name_of_organisation,
              type: resJson?.account?.data?.type
            } })
    }
  }
 
  checkGetProfileAfterFollow(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.props.navigation.navigate("Login")
    } else {
      let openHour:any = new Date(resJson?.profile?.data?.attributes.opening_hour);
      let closeHour:any = new Date(resJson?.profile?.data?.attributes.closing_hour);
      this.setState({ 
        isOwner: resJson?.profile?.data?.attributes.is_owner,
        orgProfileDetails: resJson?.profile?.data?.attributes,
        isFollow: resJson.follow
      });
      this.setState(() => ({
        initialProfileDetails: {
          name_of_organisation: resJson?.profile?.data?.attributes.name_of_organisation,
          address: resJson?.profile?.data?.attributes.address,
          postal_code: resJson?.profile?.data?.attributes.postal_code,
          city: resJson?.profile?.data?.attributes.city,
          country: resJson?.profile?.data?.attributes.country,
          opening_hour: moment(openHour, "HH:mm"),
          closing_hour: moment(closeHour, "HH:mm"),
          headline: resJson?.profile?.data?.attributes.headline,
        }
      }))
      this.checkCoverImage(resJson?.profile?.data?.attributes?.photo);
      this.checkProfileImage(resJson?.profile?.data?.attributes?.profile_image);
      const { first_name, middle_name, last_name } = resJson?.account?.data?.attributes
      const { name_of_organisation, job_title, profile_image } = resJson?.profile?.data?.attributes
      const { type } = resJson?.account?.data?.type
      this.setState({ userAccountObj: { ...this.state.userAccountObj, first_name, middle_name, last_name, profile_image, job_title, name_of_organisation, type } })
    }
  }

  convert12to24(time12h: any) {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") hours = "00";
    if (modifier === "PM") hours = parseInt(hours) + 12;
    return `${hours}:${minutes}`;
  }

  convertTo12HourFormat(time12h:any) {
    let times:any = new Date(time12h)
    const hour = times.getUTCHours();
    const minute = times.getUTCMinutes();

    let convertedHour = hour % 12;
    if (convertedHour === 0) {
      convertedHour = 12;
    }

    const period = hour < 12 ? 'AM' : 'PM';
    const convertedTime = `${convertedHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;

    return convertedTime;
  }


  checkCoverImage(photo: any) {
    if (photo !== null) {
      this.setState({ coverImageState: "uploaded", selectedCoverImage: photo })
    }
  }

  checkProfileImage(profile_image: any) {
    if (profile_image !== null) {
      this.setState({ profileImageState: "uploaded", selectedProfileImage: profile_image })
    }
  }

  checkResponse7(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
    } else {
      if(resJson?.message?.length !== 0 && !resJson?.account){
        this.setState({isLoading: false, accountError: resJson.message, selectedProfileImage: require("../assets/profile_photo.png"), selectedCoverImage: require("../assets/cover_photo.png")});
        let getUserDetails:any = localStorage.getItem("user_details");
        let getUserData:any = JSON.parse(getUserDetails);
        localStorage.setItem('user_details',JSON.stringify({...getUserData, profile_image: this.state.orgProfileDetails?.profile_image}))
        this.getOrganisationProfileAfterFollow();
        return false;
      }
      let openHour:any = new Date(resJson?.profile?.data?.attributes.opening_hour);
      let closeHour:any = new Date(resJson?.profile?.data?.attributes.closing_hour);
      this.setState({ 
        isLoading: false,
        isOwner: resJson?.profile?.data?.attributes.is_owner,
        orgProfileDetails: resJson?.profile?.data?.attributes,
        selectedProfileFile: null,
        selectedCoverFile: null
      });
      this.setState(() => ({
        initialProfileDetails: {
          name_of_organisation: resJson?.profile?.data?.attributes.name_of_organisation,
          address: resJson?.profile?.data?.attributes.address,
          postal_code: resJson?.profile?.data?.attributes.postal_code,
          city: resJson?.profile?.data?.attributes.city,
          country: resJson?.profile?.data?.attributes.country,
          opening_hour: moment(openHour, "HH:mm"),
          closing_hour: moment(closeHour, "HH:mm"),
          headline: resJson?.profile?.data?.attributes.headline,
        }
      }))

      this.checkCoverImage(resJson?.profile?.data?.attributes?.photo);
      this.checkProfileImage(resJson?.profile?.data?.attributes?.profile_image);
    
      const newObj={
        account_id:resJson?.profile?.data?.attributes?.account_id,
        account_type:resJson?.account?.data?.type,
        name_of_organization:resJson?.profile?.data?.attributes.name_of_organisation,
        job_title:resJson?.profile?.data?.attributes?.job_title,
        profile_image:resJson?.profile?.data?.attributes?.profile_image,
        first_name:resJson?.account?.data?.attributes?.first_name,
        last_name:resJson?.account?.data?.attributes?.last_name
      }
      localStorage.setItem('user_details',JSON.stringify(newObj))
    }
  }

  checkResponse8(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
    } else {
      if(resJson.accounts === undefined){
        this.setState({
          isLoading: false,
          alumniList: resJson,
          isAlumni: resJson.is_alumni
        });
      }
      else {
        this.setState({
          isLoading: false,
          alumniList: resJson.accounts,
          isAlumni: resJson.is_alumni
        });
      }
    }
  }

  checkResponse10(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
      return false;
    } else {
      if(resJson.message){
        this.setState({ isLoading: false, accountError: resJson.message })
        return false;
      }
      this.setState({
        postData: resJson?.post?.data,
      });
      let newUserPostData:any = resJson?.post?.data?.map((item:any) => {
        return item.attributes;
      });
      this.setState({postData: resJson?.post?.data,userPost: newUserPostData, isLoading: false})
    }
  }

  checkResponse12(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
      return false;
    } else {
      this.setState({
        isLoading: false,
      });
      if(resJson.message){
        this.setState({accountError: resJson.message, isRemoveSuccess: true})
        this.getAlumniByFilter(0);
        return false;
      }
    }
  }

  checkResponse13(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
      return false;
    } else {
      this.setState({
        isLoading: false,
        accountError: "Now you are alumni of this organization",
        isRemoveSuccess: true
      });
      this.getAlumniByFilter(0);
    }
  }

  checkResponse14(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
      return false;
    } else {
      this.setState({ isLoading: true });
      this.props.navigation.navigate("FanWall")
    }
  }

  checkConnectionCountResponse(resJson: any) {
    if (resJson?.errors?.length > 0) {
      return false;
    } else {
      if(resJson?.connections_count){
        this.setState({connectionCount: resJson.connections_count })
      }
    }
  }

  checkUserAllActivityResponse(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
      return false;
    } else {
      let newUserActivity:any = resJson?.post?.data?.map((postData:any) => {
        return postData.attributes
      })
      this.setState({ isLoading: false, userActivityPost: newUserActivity });
    }
  }
  checkFollowersCountResponse(responseJson:any){
    if(responseJson?.following_count !== undefined){
      this.setState({followersCount: responseJson?.following_count})
    }
    else{
      this.setState({followersCount: 0})
    }
  }
  checkFollowingCountResponse(responseJson:any){
    if(responseJson?.following_count){
      this.setState({followingCount:responseJson?.following_count})
    }

  }
  checkUnfollowOrganizationResponse(resJson: any) {
    if (resJson?.errors?.length > 0) {
      return false;
    } else {
      this.getOrganisationProfileAfterFollow();
      this.getFollowersCount();
    }
  }

  async getEducationQualification() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getEducationContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEducationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEducationApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getProjectList() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getProjectContextType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProjectApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getAwardList() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getAwardsContextType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAwardsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAwardsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAwardsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getPatentList() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getPatentsContextType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPatentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPatentsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPatentsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  showModal = (modalProject: any) => {
    console.log(modalProject);
    this.setState({ modalProject: modalProject });
    this.setState({ isVisible: !this.state.isVisible });
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({
      newValue: newValue,
    });
  };

  handleMobileModalClose = () => {
    this.setState({
      isModalOpen: false,
      modalItem: null,
    });
  };

  handleTabChange = (event: any, newValue: number) => {
    this.setState({selectedTab: newValue})
  };

  handleOpenContactusPopup = () => {
    this.setState({ openContactPopup: true })
  };

  handleCloseContactusPopup = () => {
    this.setState({ openContactPopup: false })
  };

  handlePrivacyPolicyPopup = () => {
    this.setState({ openPrivacyPolicyPopup: !this.state.openPrivacyPolicyPopup })
  };

  handleTermsConditionPopup = () => {
    this.setState({ openTermsConditionPopup: !this.state.openTermsConditionPopup })
  };

  handleCloseMenu = () => {
    this.setState({anchorEl: null})
  };

  handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({anchorEl: event.currentTarget})
  };

  getTermsPolicy = () => {
    this.setState({isLoading: true});
    const header = {
      "Content-type": configJSON.getEducationContentType
    }
    let url = configJSON.getTermsPolicyApiEndPoint;
    const getAccount = apiCall({
      header: header,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getTermsPolicyMethod,
    });
    
    this.getTermsPolicyCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getOrganisationProfile() {
    this.setState({isLoading: true})
    let viewerId:any = this.props.navigation.getParam("viewerId");
    let getAccountId:any  = localStorage.getItem("account_id");
    const headers = getHeaders({ token: this.authToken })

    let url = viewerId === undefined ? `${configJSON.getProfileByIdApiEndPoint}/${getAccountId}` : `${configJSON.getProfileByIdApiEndPoint}/${viewerId}`;
    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.getProfileMethod,
    });
    
    this.getProfileCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getOrganisationProfileAfterFollow() {
    let viewerId:any = this.props.navigation.getParam("viewerId");
    let getAccountId:any  = localStorage.getItem("account_id");
    const headers = getHeaders({ token: this.authToken })

    let url = viewerId === undefined ? `${configJSON.getProfileByIdApiEndPoint}/${getAccountId}` : `${configJSON.getProfileByIdApiEndPoint}/${viewerId}`;
    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.getProfileMethod,
    });
    
    this.getProfileAfterFollowCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleCoverImagePopupOpen = () => {
    this.setState({coverImagePopup: true});
  }

  handleCoverImagePopupClose = () => {
    this.setState({coverImagePopup: false, selectedCoverImage: this.setSelectedCoverImage(), selectedCoverFile: null});
  }

  setSelectedCoverImage = () => {
    if(this.state.orgProfileDetails.photo){
      return this.state.orgProfileDetails.photo
    }
    else {
      return require("../assets/cover_photo.png")
    }
  }

  handleProfileDetailsPopupOpen = () => {
    this.setState({profileDetailsPopup: true});
  }

  handleProfileDetailsPopupClose = () => {
    this.setState({ profileDetailsPopup: false, selectedProfileImage: this.setSelectedProfileImage(), selectedProfileFile: null });
  }

  setSelectedProfileImage = () => {
    if(this.state.orgProfileDetails.profile_image){
      return this.state.orgProfileDetails.profile_image
    }
    else {
      return require("../assets/profile_photo.png")
    }
  }
  
  handleAboutPopupOpen = () => {
    this.setState({aboutPopup: true});
  }

  handleAboutPopupClose = () => {
    this.setState({aboutPopup: false});
  }

  updateProfileDetails = (values?:any) => {
    this.setState({ isLoading: true, profileDetailsPopup: false, coverImagePopup: false, aboutPopup: false })

    let formData:any = new FormData();
    {this.state.selectedCoverFile !== null && formData.append('data[photo]', this.state.selectedCoverFile)}
    {this.state.selectedProfileFile !== null && formData.append('data[profile_image]', this.state.selectedProfileFile)}
    {values?.updateCoverPhoto === true && formData.append('delete_cover_image', true)}
    {values?.updateProfilePhoto === true && formData.append('delete_profile_image', true)}
    {values?.name_of_organisation && formData.append('data[name_of_organisation]', values?.name_of_organisation)}
    {values?.address && formData.append('data[address]', values?.address)}
    {values?.postal_code && formData.append('data[postal_code]', values?.postal_code)}
    {values?.city && formData.append('data[city]', values?.city)}
    {values?.country && formData.append('data[country]', values?.country)}
    {values?.opening_hour && formData.append('data[opening_hour]', values?.opening_hour)}
    {values?.closing_hour && formData.append('data[closing_hour]', values?.closing_hour)}
    {values?.headline && formData.append('data[headline]', values?.headline)}

    let url:any = ''
    url = `${configJSON.updateProfileApiEndPoint}`
    const token:any = this.authToken;
    const headers:any = {
      "Content-Type": configJSON.exampleApiContentType,
      "token" : token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateProfileMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCoverPhotoUpload = (event:any) => {
    let file:any = event.target.files[0];
    const reader = new FileReader();
    let url:any = reader.readAsDataURL(file);

    reader.onloadend = function() {
      //@ts-ignore
      this.setState({
        selectedCoverImage: [reader.result]
      });
    }.bind(this);
    console.log(url); // Would see a path?

    this.setState({
      coverImageState: "uploaded",
      selectedCoverFile: event.target.files[0],
      selectedCoverImage: event.target.files[0],
      coverImageUploaded: 1,
    });
  };

  handleProfilePhotoUpload = (event:any) => {
    let file:any = event.target.files[0];
    const reader = new FileReader();
    let url:any = reader.readAsDataURL(file);

    reader.onloadend = function() {
      //@ts-ignore
      this.setState({
        selectedProfileImage: [reader.result]
      });
    }.bind(this);
    console.log(url); // Would see a path?

    this.setState({
      profileImageState: "uploaded",
      selectedProfileFile: event.target.files[0],
      selectedProfileImage: event.target.files[0],
      profileImageUploaded: 1,
    });
  };

  redicretAlumniTab() {
    document.getElementById("alumniTabId")?.click();
  }

  handleReaction(num:any) {
    this.setState({isReactionsOpen: false, isLiked: num})
  }

  handleReactionMenuOpen() {
    setTimeout(() => {this.setState({ isReactionsOpen: true })}, 700)
  }

  handleReactionMenuClose() {
    this.setState({ isReactionsOpen: false })
  }

  getPostData = () => {
    let viewerId:any = this.props.navigation.getParam("viewerId");
    let getAccountId:any = localStorage.getItem("account_id");
    this.setState({ isLoading: true });

    let headers:any = getHeaders({token: this.authToken})
    let url = configJSON.getUserPostApiEndPoint;
    let ViewerHttpBody:any = {
      account_id: viewerId
    };
    let accountHttpBody:any = {
      account_id: getAccountId
    };
    const getAccount = apiCall({
      header: headers,
      httpBody: viewerId === undefined ? accountHttpBody : ViewerHttpBody,
      url: url,
      httpMethod: configJSON.getUserPostMethod,
    });

    this.apiPostItemCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getAlumniByFilter = (sortBy?: any) => {
    this.setState({ isLoading: true })
    let userId:any = localStorage.getItem('account_id')
    let viewerId:any = this.props.navigation.getParam("viewerId")

    let formData: any = new FormData();
    {viewerId !== undefined ? formData.append('organistaion_id', viewerId) : formData.append('organistaion_id', userId)}
    { sortBy === 0 && formData.append('sort_by', 'created_at') }
    { sortBy === 1 && formData.append('sort_by', 'first_name') }
    { sortBy === 2 && formData.append('sort_by', 'last_name') }

    let url: any = ''
    url = `${configJSON.getAlumniByFilterApiEndPoint}`
    const token: any = this.authToken;
    const headers: any = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postListOfAlumniId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAlumniByFilterMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAlumniBySearch = (event: any) => {
    this.setState({ isLoading: true })
    let userId:any = localStorage.getItem('account_id')

    let formData: any = new FormData();
    formData.append('organistaion_id', userId)
    {event.length !== 0 ? formData.append('search', event) : formData.append('search', "")}

    let url: any = ''
    url = `${configJSON.getAlumniBySearchApiEndPoint}`
    const token: any = this.authToken;
    const headers: any = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postSearchAlumniId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAlumniBySearchMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  debounce = (func:any, delay:any) => {
    let timer:any;
    return function (...args:any) {
      //@ts-ignore
      const context:any = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, delay);
    };
  };

  handleInputChange = (event: any) => {
    const { value } = event.target;
    this.searchAlumni(value);
    this.setState({ alumniSearchValue: event.target.value });
  }
  
  handleAlumniSearchChange = (value:any) => {
    this.getAlumniBySearch(value);
  };

  searchAlumni = this.debounce(this.handleAlumniSearchChange, 700);

  handleBackPost = () => {
    if (this.state.startPost !== 0) {
      this.setState({startPost: this.state.startPost - 2});
      this.setState({endPost: this.state.endPost - 2});
    }
  }

  handleNextPost = () => {
    if (this.state.endPost < this.state.postData.length) {
      this.setState({startPost: this.state.startPost + 2});
      this.setState({endPost: this.state.endPost + 2});
    }
  }

  followOrganization() {
    let getAuthToken: any = localStorage.getItem("authToken");
    let viewerId:any = this.props.navigation.getParam("viewerId");

    let headers: any = getHeaders({ token: getAuthToken })
    let url = configJSON.followOrganizationApiEndPoint;
    let httpBody: any = {
      data: {
        attributes: {
          account_id: viewerId
        }
      }
    };
    const getAccount = apiCall({
      header: headers,
      httpBody: httpBody,
      url: url,
      httpMethod: configJSON.followOrganizationMethod,
    });

    this.followOrganizationApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  unfollowOrganisation() {
    let getAuthToken: any = localStorage.getItem("authToken");
    let viewerId:any = this.props.navigation.getParam("viewerId")

    let headers: any = getHeaders({ token: getAuthToken })
    let url = configJSON.unfollowOrganizationApiEndPoint + `/${viewerId}`;

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.unfollowOrganizationMethod,
    });

    this.unfollowOrganizationApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleRemoveAlumniPopupOpen = (getRemoveAlumniId: any) => {
    this.setState({removeAlumniPopup: true, removeAlumniId: getRemoveAlumniId});
  }

  handleRemoveAlumniPopupClose = () => {
    this.setState({removeAlumniPopup: false, removeAlumniId: 0});
  }

  handleRemoveCoverImagePopupOpen = () => {
    this.setState({removeCoverImagePopup: true});
  }

  handleRemoveCoverImagePopupClose = () => {
    this.setState({removeCoverImagePopup: false});
  }

  handleRemoveProfileImagePopupOpen = () => {
    this.setState({removeProfileImagePopup: true});
  }

  handleRemoveProfileImagePopupClose = () => {
    this.setState({removeProfileImagePopup: false});
  }

  handleCloseRemoveAlumniSuccess = () => {
    this.setState({isRemoveSuccess: false})
  }

  removeAlumni() {
    this.setState({ isLoading: true });

    let headers:any = getHeaders({token: this.authToken})
    let url = configJSON.removeAlumniApiEndPoint + `?student_id=${this.state.removeAlumniId}`;

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.removeAlumniMethod,
    });

    this.removeAlumniApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
  
  becomeAlumni() {
    let viewerId:any = this.props.navigation.getParam("viewerId")
    this.setState({ isLoading: true });

    let headers:any = getHeaders({token: this.authToken})
    let url = configJSON.becomeAlumniApiEndPoint;
    let httpBody:any = {
      organisation_id: viewerId
    }
    const getAccount = apiCall({
      header: headers,
      httpBody: httpBody,
      url: url,
      httpMethod: configJSON.becomeAlumniMethod,
    });

    this.becomeAlumniApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleRemoveImage(img:any){
    this.setState({
      uploadedImages: this.state.uploadedImages.filter((image: any) => {
          return img?.id !== image?.id
      })
      })
  
  }

  convertImgToBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }

  handleImage(e: any) {
    const obj = {
      filename: e?.target?.files[0]?.name,
      content_type: e?.target?.files[0]?.type

    }
    this.convertImgToBase64(e.target.files[0]).then(base64 => {
      this.setState({ base64Images: [...this.state.base64Images, { data: base64, filename: obj?.filename, content_type: obj?.content_type }] })
    });
    this.setState({ uploadedImages: [...this.state.uploadedImages, { id: uuidv4(), img: URL.createObjectURL(e.target.files[0]) }] })
  }

  AddPostCreation() {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem('authToken')
    };

    const attrs = {
      data: {
        attributes: {
          "name": '',
          "description": this.state.description.includes('\n') ? this.func(this.state.description) : this.state.description,
          "body": this.state?.body,
          "location": "indore",
          "category_id": 2,
          images: this.state?.base64Images
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ uploadedImages: [], base64Images: [] })

    return true;
  }

  func(description: any) {
    let temp: any = []
    let des: any = ''
    const words = description.split('\n');
    words.map((word: any) => {
      if (word.startsWith("#")) {

        temp.push(word)

      }
      else {
        des = word
        return word
        // this.setState({description:word})
      }
    });
    this.setState({ body: temp.join(", ") });
    return des
  }
  getFollowersCount() {
    let viewerId:any = this.props.navigation.getParam("viewerId");
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFollowersCountApiId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.followersCountUrl}?account_id=${viewerId !== undefined ? viewerId : localStorage.getItem('account_id')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.followersCountMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

   

    return true;
  }
  getFollowingCount() {
   
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem('authToken')
    };
    let httpBody:any = {
      'account_id': localStorage.getItem('account_id')
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFollowingCountApiId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followingCountUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.followingCountMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

   

    return true;
  }
  getUserAllActivity = (activityFilter:any) => {
    this.setState({ isLoading: true });

    let formData: any = new FormData();
    { activityFilter === 1 && formData.append('filter', 'posts') }
    { activityFilter === 2 && formData.append('filter', 'likes') }
    { activityFilter === 3 && formData.append('filter', 'comments') }

    let url: any = ''
    url = `${configJSON.getUserAllActivityApiEndPoint}`
    const token: any = this.authToken;
    const headers: any = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserAllActivityId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserAllActivityMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getConnectionCount = () => {
    const header = {
      "Content-type": configJSON.validationApiContentType,
      'token': localStorage.getItem("authToken")
    }
    let url = configJSON.connectionCountUrl;
    
    const getAccount = apiCall({
      header: header,
      httpBody: {},
      url: url,
      httpMethod: configJSON.connectionCountMethod,
    });
    
    this.getConnectionCountCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
