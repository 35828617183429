// Customizable Area Start
// @ts-nocheck
import React from "react";
import {
    Container,
    InputAdornment,
    TextField,
    Toolbar,
    IconButton,
    Drawer,
    Typography,
    Grid,
    Box,
    Menu,
    MenuItem,
    Button

} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
    edconnectlogo_img,
    notification_img,
    messages_img,
    home_img,
    profile_image,
    search_img,
    downArraow_img,
    connections_img,
    userAccImg,
    darkMsg,
    lightHome,
} from "./assets";
import NavBarWithSearchWebController, {
    Props,
} from "./NavBarWithSearchWebController";
import { handleCheckLoggedInUser, isLoggedIn } from "../../../components/src/common";
import AgeConfirmationModal from "../../../components/src/AgeConfirmationModal.web";
import { account, darkBell } from "../../user-profile-basic/src/assets";

const styles = (theme) => ({
    root: {
        height: "48px",
        marginLeft: "30px",
        width: "390px",
        backgroundColor: "#F7F7F7",

        borderRadius: "10px",
        padding: "0 5px",
        [theme.breakpoints.down("sm")]: {
            width: "200px",
            height: "30px",
            marginLeft: "10px",
            marginRight: "10px",
        },
    },
    searchList: {
        border: "1px solid #BE9E44",
        borderRadius: "4px",
        background: "white",
        maxWidth: "100%",
        padding: "4px 0px",
        position: "absolute",
        width: "385px",
        left: '30px',
        top: '50px',
        zIndex: 999,

        [theme.breakpoints.down("sm")]: {
            maxWidth: "90%",
            left: '10px',
            top: '30px'
        },
    },
    filterStyle: {
        display: "flex",
        position: "relative",
        justifyContent: "start",
        alignContent: "center",
        width: "100%",
        padding: "5px 25px",

        [theme.breakpoints.down("sm")]: {
            margin: "0px 0 5px 0px",
            flexWrap: "wrap",
        },
    },
    filterBtn: {
        minWidth: "100px",
        height: "35px",
        color: "#BE9E44",
        borderRadius: "3px",
        border: "1px solid #BE9E44",
        marginRight: "10px",
        background: "#F7F7F7",
        cursor: "pointer",

        [theme.breakpoints.down("sm")]: {
            minWidth: "unset",
            marginTop: "2px",
            height: "25px",
            padding: '5px 8px'
        },
    },
    filterBtnActive: {
        minWidth: "100px",
        height: "35px",
        color: "#F7F7F7",
        borderRadius: "3px",
        border: "1px solid #BE9E44",
        marginRight: "10px",
        background: "#BE9E44",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            minWidth: "80px",
            marginTop: "2px",
            height: "25px",
        },
    },
    profileBox: {
        backgroundColor: "white",
        width: "264px",
        borderRadius: "10px 0px 10px 10px",
        boxShadow: "0px 1px 5px rgba(186, 186, 186, 0.546711)",
        top: '60px !important',
        [theme.breakpoints.down("sm")]: {
            top: "35% !important"
        },
    },
    topBar: {
        minHeight: "80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 20px",
        background: "#FFFFFF",
        width: "auto",
        zIndex: 999,
        borderTop: "0.5px solid rgb(215, 215, 215)",
    },
    topBarWithFilter: {
        minHeight: "110px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 20px",
        background: "#FFFFFF",
        width: "100%",
        zIndex: 999,
        borderTop: "0.5px solid rgb(215, 215, 215)",
        [theme.breakpoints.down("sm")]: {
            minHeight: "110px",
        },
    },
    topBarWithFilterAndGuestUser: {
        minHeight: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 20px",
        background: "#FFFFFF",
        width: "100%",
        zIndex: 999,
        borderTop: "0.5px solid rgb(215, 215, 215)",
        [theme.breakpoints.down("sm")]: {
            minHeight: "150px",
        },
    },
    signInBtn: {
        background: '#BE9E44',
        borderRadius: '10px',
        padding: '5px 10px',
        marginRight: '10px',
        color: "#F9F9F9",
        border: '1px solid #BE9E44'
    },
    joinBtn: {
        background: '#F9F9F9',
        borderRadius: '10px',
        padding: '5px 10px',
        marginRight: '10px',
        color: "#BE9E44",
        border: '1px solid #BE9E44'
    }
});
export class NavBarWithSearch extends NavBarWithSearchWebController {
    constructor(props: Props) {
        super(props);
    }
    onBlureInput = () => {
        setTimeout(this.setFocusTogle.bind(this, false), 200);

    }
    onSelectList = (value: string) => {
        this.setState({ ...this.state, txtInputValue: value, isFilter: { filterToggle: true, filterKey: "" } })
    }
    SearchBar = () => {
        const { classes } = this.props;
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    position: 'relative'
                }}
            >
                <TextField
                    size="medium"
                    // variant='outlined'
                    style={{
                        border: "none",
                    }}
                    placeholder="Search"
                    name="searchField"
                    id="searchField"
                    onClick={this.setFocusTogle.bind(this, true)}
                    onBlur={this.onBlureInput}
                    onChange={this.setInputValue}
                    InputProps={{
                        classes: classes,
                        disableUnderline: true,

                        style: {
                            border: this.state.isFocus ? "1px solid #BE9E44" : "none",
                        },

                        value: this.state.txtInputValue,

                        startAdornment: (
                            <InputAdornment
                                position="start"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <div>
                                    {" "}
                                    <img src={search_img} width="20px" />
                                </div>
                            </InputAdornment>
                        ),
                    }}
                />
                {this.state.isFocus && this.state.txtInputValue === "" && (
                    <div className={classes.searchList}  >
                        <li
                            id="recentSearch"
                            name="recentSearch"
                            style={webStyle.list_Style}
                            onClick={this.onSelectList.bind(this, 'HI')}
                        >
                            HI
                        </li>

                    </div>
                )}
                {this.state.txtInputValue !== "" && this.state.isFocus && (
                    <div className={classes.searchList}>
                        {this.getPostData().post.map((item) => (
                            <li
                                key={item?.id}
                                style={webStyle.list_Style}
                                onClick={this.onSelectList.bind(this, item.name)}
                            >
                                <span>
                                    <img src={search_img} style={webStyle.search_iconStyle} />
                                </span>
                                {item?.name}
                                <span>
                                    <img src={messages_img} style={webStyle.right_img} />
                                </span>
                            </li>
                        ))}

                        <li style={webStyle.all_result}>
                            <a> All Result</a>
                        </li>
                    </div>
                )}
            </div>
        );
    };


    handleSiderbarProfile = () => {
        this.setState({ isProfileOpen: true })
    }
    openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorElMenu: event?.currentTarget })
    }
    openSiderBarProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ sidebarAnchorElMenu: event?.currentTarget })
    }
    topClass = () => {
        const { isFilter } = this.state
        const { classes } = this.props;
        if (isFilter.filterToggle && !isLoggedIn()) {
            return classes.topBarWithFilterAndGuestUser
        } else if (isFilter.filterToggle || !isLoggedIn()) {
            return classes.topBarWithFilter
        } else {
            return classes.topBar

        }
    }


    render() {
        const { classes } = this.props;
        const { isFilter, sidebarAnchorElMenu, openAgeConfirm } = this.state
        let urlRoute: any = this.props.history.location.pathname;
        const userDetails = JSON.parse(localStorage.getItem('user_details'));

        return (

            <Toolbar className={this.topClass()} >
                <Container style={webStyle.container}>
                    <Grid style={{ maxWidth: '1280px', padding: !isLoggedIn() ? '10px 24px' : "0 24px" }} item container justifyContent="center" alignItems="center">
                        <Box
                            sx={{
                                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                                alignSelf: "center",
                                justifyContent: "center",
                                width: "100%",
                            }}
                            className="appBarRoute"
                        >
                            <ResponsiveAppHeader
                                urlRoute={urlRoute}
                                searchBar={this.SearchBar}
                                anchorElMenu={this.state.anchorElMenu}
                                handleCloseMenu={this.handleCloseMenu}
                                openMenu={this.openMenu}
                                classes={classes}
                                onSignOut={this.onSignOut}
                                handleOpenAgeConfirm={this.handleOpenAgeConfirm}
                                userAccountObj={userDetails}
                                navigate={this.props.navigation}
                                getPostData={() => this.props.getPostData()}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
                                width: "100%",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <NavLink to="/">
                                    <img
                                        style={{
                                            ...webStyle.logo,
                                            width: "45px",
                                            marginLeft: "-18px",
                                        }}
                                        className="logo"
                                        src={edconnectlogo_img}
                                    />
                                </NavLink>
                                {/* <NavBarWithSearch /> */}
                                {this.SearchBar()}
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={this.handleOpenNavMenu}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>

                            <Drawer
                                open={Boolean(this.state.AnchorElNav)}
                                anchor={"right"}
                                PaperProps={{ style: { width: "310px" } }}
                                onClose={this.handleCloseNavMenu}
                            >
                                <SideDrawer
                                    urlRoute={urlRoute}
                                    handleCloseNavMenu={this.handleCloseNavMenu}
                                    anchorElMenu={sidebarAnchorElMenu}
                                    handleCloseMenu={this.handleCloseSideBarProfile}
                                    openMenu={this.openSiderBarProfileMenu}
                                    classes={classes}
                                    onSignOut={this.onSignOut}
                                    handleOpenAgeConfirm={this.handleOpenAgeConfirm}
                                    userAccountObj={userDetails}
                                />
                            </Drawer>
                        </Box>

                    </Grid>

                    {isFilter.filterToggle && (
                        <>
                            <div style={webStyle.lineSaperator}>

                            </div>
                            <div style={webStyle.filterWrapper}>

                                <div className={classes.filterStyle}>
                                    <button
                                        id="post"
                                        onClick={this.setFilter.bind(this, true, "post")}
                                        className={
                                            this.state.isFilter.filterKey === "post"
                                                ? classes.filterBtnActive
                                                : classes.filterBtn
                                        }
                                    >
                                        Post
                                    </button>

                                    <button
                                        id="student"
                                        onClick={this.setFilter.bind(this, true, "student")}
                                        className={
                                            this.state.isFilter.filterKey === "student"
                                                ? classes.filterBtnActive
                                                : classes.filterBtn
                                        }
                                    >
                                        Student
                                    </button>
                                    <button
                                        id="organization"
                                        onClick={this.setFilter.bind(this, true, "organization")}
                                        className={
                                            this.state.isFilter.filterKey === "organization"
                                                ? classes.filterBtnActive
                                                : classes.filterBtn
                                        }
                                    >
                                        Acadmic Organization
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    {!isLoggedIn() && (<>
                        <div style={{ ...webStyle.filterWrapper, marginTop: '-7px' }}>
                            <p style={{ marginLeft: '20px', fontSize: "14px" }}>Register now to connect with your classmates, organizations and friends on EDConnect. <NavLink to="/Register" style={{
                                textDecoration: "none",
                                fontSize: "14px",
                                color: "#BE9E44",
                            }}>Join Now</NavLink></p>
                        </div>
                    </>)}


                </Container>
                <AgeConfirmationModal
                    open={openAgeConfirm}
                    handleClose={this.handleCloseAgeConfirm}
                />
            </Toolbar>
        );
    }
}
const NavBarSearch = withStyles(styles, { withTheme: true })(
    withRouter(NavBarWithSearch)
);
export default NavBarSearch;
const navs = [
    { tab: "Home", path: "/fanwall", isActive: true, img: lightHome, darkIcon: home_img },
    {
        tab: "Connections",
        path: "/connections",
        isActive: false,
        img: connections_img,
        darkIcon: account
    },
    { tab: "Messages", path: "/messaging", isActive: true, img: messages_img, darkIcon: darkMsg },
    {
        tab: "Notifications",
        path: "/notifications-page",
        isActive: true,
        img: notification_img,
        darkIcon: darkBell
    },
];
export const ResponsiveAppHeader = ({
    urlRoute,
    searchBar,
    anchorElMenu,
    handleCloseMenu,
    openMenu,
    classes,
    onSignOut,
    handleOpenAgeConfirm,
    userAccountObj,
    getPostData,
    navigate
}: any) => {


    return (
        <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            width="100%"
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <img style={webStyle.logo} className="logo" src={edconnectlogo_img} onClick={() => { handleCheckLoggedInUser(navigate, getPostData) }} />
                {searchBar()}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                {isLoggedIn() ? <>
                    {navs.map(({ tab, path, img, isActive, darkIcon }, index) => (
                        <NavLink
                            style={{
                                textDecoration: "none",
                                fontSize: "14px",
                                color: urlRoute?.toLowerCase() === path ? "black" : "grey",
                            }}
                            onClick={(e) => {
                                if (!isActive) {
                                    e.preventDefault()
                                }
                            }}
                            to={path}
                        >
                            <Typography>
                                <div
                                    key={index}
                                    style={{
                                        margin: "0px 10px 0px 10px",
                                        padding: "10px 15px",
                                        borderBottom: urlRoute?.toLowerCase() === path ? "2px solid black" : "",
                                    }}
                                >
                                    <div style={webStyle.imgContainer}>
                                        <img src={urlRoute?.toLowerCase() === path ? darkIcon : img} style={webStyle.tabImg} />
                                    </div>
                                    <div style={{ ...webStyle.tabs, color: urlRoute?.toLowerCase() === path ? "#1C1C1C" : "#A8A8A8" }}>{tab}</div>
                                </div>
                            </Typography>
                        </NavLink>
                    ))}
                    <Typography>
                        <div
                            style={{
                                margin: "0px 10px 0px 10px",
                                padding: "10px 15px",
                                position: "relative",
                            }}
                        >
                            <div style={webStyle.imgContainer}>
                                <img src={userAccountObj?.profile_image !== null ? userAccountObj?.profile_image : userAccImg} style={webStyle.profileImage} width="24px" height='24px'/>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", position: 'relative' }}>
                                <div style={{ fontSize: "14px", lineHeight: "16.41px", padding: "10px 0px", color: '#A8A8A8' }}>Profile </div>
                                <div style={{ cursor: "pointer" }} onClick={openMenu}>
                                    <img src={downArraow_img} style={webStyle.tabImg} />
                                </div>
                                <Menu
                                    id="basic-menu"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    anchorEl={anchorElMenu}
                                    open={Boolean(anchorElMenu)}
                                    PaperProps={{ className: classes?.profileBox }}
                                    onClose={handleCloseMenu}

                                >
                                    <MenuItem style={{ whiteSpace: "normal" }}>
                                        <div style={{ marginRight: "10px" }}>
                                            <img src={userAccountObj?.profile_image ? userAccountObj?.profile_image : userAccImg} style={webStyle?.profileImage} width="40x" />
                                        </div>
                                        <div>
                                            <div
                                                style={{
                                                    fontSize: "16px",
                                                    fontFamily: "HelveticaNeue",
                                                }}
                                            >
                                                {userAccountObj?.first_name} {userAccountObj?.last_name}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "14px",
                                                    color: "#7C7C7C",
                                                    fontFamily: "HelveticaNeue",

                                                }}
                                            >
                                                {userAccountObj?.job_title} at {userAccountObj?.name_of_organization}
                                            </div>
                                        </div>
                                    </MenuItem>
                                    <MenuItem style={{ borderBottom: "0.5px solid #F0F0F0" }}>
                                        <NavLink to={(userAccountObj?.account_type === 'StudentAccount' || userAccountObj?.account_type === 'student_account') ? '/student-profile' : "AcademicOrganisationProfile"}
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    background: "rgba(190, 158, 68, 0.1)",
                                                    border: "2px solid #BE9E44",
                                                    borderRadius: "6px",
                                                    padding: "5px 25px 5px 25px",
                                                    color: "#BE9E44",
                                                    fontSize: "14px",
                                                    textAlign: "center",
                                                    fontFamily: "HelveticaNeue",
                                                    width: '230px',
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                View Profile
                                            </Button>
                                        </NavLink>

                                    </MenuItem>


                                    <div style={{ fontSize: '14px', margin: '10px 15px 5px 15px' }}>Account</div>

                                    <MenuItem>
                                        <NavLink
                                            style={{
                                                color: "#696868",
                                                textDecoration: "none",
                                                fontSize: "14px",
                                                fontFamily: "HelveticaNeue",

                                            }}
                                            to="/settings"
                                        >
                                            Setting & Privacy
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem style={{ borderBottom: "0.5px solid #F0F0F0", color: "#696868", fontSize: "14px", fontFamily: "HelveticaNeue", }}>
                                        <NavLink
                                            style={{
                                                color: "#696868",
                                                textDecoration: "none",
                                                fontSize: "14px",
                                                fontFamily: "HelveticaNeue",
                                            }}
                                            to="/contact-us"
                                        >
                                            Contact us
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={onSignOut}

                                        style={{
                                            color: "#696868",
                                            fontSize: "14px",
                                            fontFamily: "HelveticaNeue",
                                        }}>
                                        <NavLink to="/"
                                            style={{
                                                textDecoration: "none",
                                                color: "#696868",
                                                fontSize: "14px",
                                                fontFamily: "HelveticaNeue",
                                                cursor: "pointer"
                                            }}
                                        >
                                            Sign Out
                                        </NavLink>
                                    </MenuItem>
                                </Menu>
                            </div>
                            {/* profile section */}
                        </div>
                    </Typography>
                </> :
                    <>
                       {!(urlRoute?.toLowerCase()=='/fanwall' || (urlRoute?.toLowerCase()?.startsWith('/user-details')))   && <Typography style={webStyle.navMenu}
                            onClick={handleOpenAgeConfirm}
                        >
                            Discover
                        </Typography>}
                        <NavLink to="/Login" style={{ textDecoration: "none" }}>
                            <Button

                                className={classes.signInBtn}
                            >
                                Sign In
                            </Button>
                        </NavLink>
                        <NavLink to="/Register" style={{ textDecoration: "none" }}>
                            <Button

                                className={classes.joinBtn}
                            >
                                Join now
                            </Button>
                        </NavLink>
                    </>
                }

            </Box>
        </Box >
    );
};

export const SideDrawer = ({
    urlRoute,
    handleCloseNavMenu,
    anchorElMenu,
    handleCloseMenu,
    openMenu,
    classes,
    onSignOut,
    handleOpenAgeConfirm,
    userAccountObj
}: any) => {

    return (
        <>
            {/* // <CustomGrid container alignItems="center"> */}
            <Box
                sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
            >
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleCloseNavMenu}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            {isLoggedIn() ? <> {navs.map(({ tab, path, img, isActive, darkIcon }, index) => (
                <NavLink
                    style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        color: urlRoute?.toLowerCase() === path ? "black" : "grey",
                    }}
                    onClick={(e) => {
                        if (!isActive) {
                            e.preventDefault()
                        }

                    }}
                    to={path}
                >
                    <Typography>
                        <div
                            key={index}
                            style={{
                                margin: "5px 10px",
                                alignItems: "center",
                                borderBottom: urlRoute?.toLowerCase() === path ? "1px solid black" : "",
                                display: "flex",
                            }}
                        >
                            <div style={webStyle.imgContainer}>

                                <img src={urlRoute?.toLowerCase() === path ? darkIcon : img} style={{ ...webStyle.tabImg, height: '24px' }} />
                            </div>
                            <div style={{ ...webStyle.tabs, marginLeft: "10px", color: urlRoute?.toLowerCase() === path ? "#1C1C1C" : "#A8A8A8" }}>{tab}</div>
                        </div>
                    </Typography>
                </NavLink>
            ))}
                <Typography>
                    <div
                        style={{ margin: "5px 10px", display: "flex", alignItems: "center" }}
                    >
                        <div style={webStyle.imgContainer}>
                            <img
                                src={userAccountObj?.profile_image ? userAccountObj?.profile_image : userAccImg}
                                style={{ ...webStyle.profileImage, width: "25px", height: "25px" }}
                            />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ ...webStyle.tabs, marginLeft: "15px", color: '#A8A8A8' }}>Profile </div>
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={openMenu}
                            >
                                <img src={downArraow_img} style={webStyle.tabImg} />
                            </div>
                            <Menu
                                id="basic-menu"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                anchorEl={anchorElMenu}
                                open={Boolean(anchorElMenu)}
                                PaperProps={{ className: classes?.profileBox }}
                                onClose={handleCloseMenu}

                            >
                                <MenuItem style={{ whiteSpace: "normal" }}>
                                    <div style={{ marginRight: "10px" }}>
                                        <img src={userAccountObj?.profile_image ? userAccountObj?.profile_image : userAccImg} style={webStyle?.profileImage} width="40x" />
                                    </div>
                                    <div>
                                        <div
                                            style={{
                                                fontSize: "16px",
                                                fontFamily: "HelveticaNeue",
                                            }}
                                        >
                                            {userAccountObj?.first_name} {userAccountObj?.last_name}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "14px",
                                                color: "#7C7C7C",
                                                fontFamily: "HelveticaNeue",

                                            }}
                                        >
                                            {userAccountObj?.job_title} at {userAccountObj?.name_of_organization}
                                        </div>
                                    </div>
                                </MenuItem>
                                <MenuItem style={{ borderBottom: "0.5px solid #F0F0F0" }}>
                                    <NavLink to={userAccountObj?.account_type === 'StudentAccount' ? '/student-profile' : "AcademicOrganisationProfile"}
                                        style={{
                                            textDecoration: "none",
                                        }}
                                    >
                                        <Button
                                            style={{
                                                background: "rgba(190, 158, 68, 0.1)",
                                                border: "2px solid #BE9E44",
                                                borderRadius: "6px",
                                                padding: "5px 25px 5px 25px",
                                                color: "#BE9E44",
                                                fontSize: "14px",
                                                textAlign: "center",
                                                fontFamily: "HelveticaNeue",
                                                width: '230px',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            View Profile
                                        </Button>
                                    </NavLink>

                                </MenuItem>
                                <MenuItem>
                                    <NavLink
                                        style={{
                                            color: "#1C1C1C",
                                            textDecoration: "none",
                                            fontSize: "14px",
                                            fontFamily: "HelveticaNeue",
                                            fontStyle: "italic",
                                        }}
                                        to="/"
                                    >
                                        Account
                                    </NavLink>
                                </MenuItem>
                                <MenuItem>
                                    <NavLink
                                        style={{
                                            color: "#696868",
                                            textDecoration: "none",
                                            fontSize: "14px",
                                            fontFamily: "HelveticaNeue",
                                            margin: "7px 0",
                                        }}
                                        to="/settings"
                                    >
                                        Setting & Privacy
                                    </NavLink>
                                </MenuItem>

                                <MenuItem style={{ borderBottom: "0.5px solid #F0F0F0" }}>
                                    <NavLink
                                        style={{
                                            color: "#696868",
                                            textDecoration: "none",
                                            fontSize: "14px",
                                            fontFamily: "HelveticaNeue",
                                        }}
                                        to="/contact-us"
                                    >
                                        Contact us
                                    </NavLink>
                                </MenuItem>

                                <MenuItem
                                    onClick={onSignOut}
                                    style={{
                                        color: "#696868",
                                        fontSize: "14px",
                                        fontFamily: "HelveticaNeue",
                                        cursor: "pointer"
                                    }}>
                                    <NavLink to="/"
                                        style={{
                                            textDecoration: "none",
                                            
                                        }}
                                    >
                                        Sign Out
                                    </NavLink>

                                </MenuItem>
                            </Menu>
                        </div>
                        {/* profile section */}
                    </div>
                </Typography>
            </> :
                <>
               
                    {!(urlRoute?.toLowerCase()=='/fanwall' || (urlRoute?.toLowerCase()?.startsWith('/user-details'))) && <Typography style={webStyle.navMenu}
                        onClick={handleOpenAgeConfirm}
                    >
                        Discover
                    </Typography>}
                    <NavLink to="/Login" style={{ textDecoration: "none", margin: "15px 15px", }}>
                        <Button

                            className={classes?.signInBtn}
                        >
                            Sign In
                        </Button>
                    </NavLink>
                    <NavLink to="/Register" style={{ textDecoration: "none", margin: "15px 15px", }}>
                        <Button

                            className={classes?.joinBtn}
                        >
                            Join now
                        </Button>
                    </NavLink>
                </>
            }

        </>
    );
};

const webStyle = {
    container: {
        display: "flex",
        alignItems: "center",
        position: "fixed",
        flexWrap: 'wrap',
        width: '100%',
        top: 0,
        left: 0,
        background: 'white',
        maxWidth: 'unset',
        justifyContent: 'center',
        padding: 0,
        zIndex: 9999
    },

    logo: {
        width: "66px",
        cursor: "pointer"
    },
    navMenu: {
        margin: "0 15px",
        textDecoration: "none",
        color: "black",
        cursor: "pointer",
    },
    responsiveNavMenu: {
        margin: "15px 15px",
        textDecoration: "none",
        color: "black",
        cursor: "pointer",
    },
    tabs: {
        fontFamily: "HelveticaNeue",
        fontSize: "14px",
        lineHeight: "16.41px",
        padding: "10px 0px",

    },



    tabImg: {
        width: "24px",
    },
    imgContainer: {
        display: "flex",
        justifyContent: "center",
    },


    list_Style: {
        listStyle: "none",
        fontSize: "14px",
        fontFamily: "HelveticaNeue",
        color: "black",
        padding: "2px 10px",
        cursor: "pointer",
    },
    all_result: {
        listStyle: "none",
        fontSize: "14px",
        fontFamily: "HelveticaNeue",
        textAlign: "center",
        display: "flex",
        color: "#BE9E44",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        marginTop: "5px",
        borderTop: "0.5px solid rgb(215, 215, 215)",
        aligntems: "center",
    },

    search_iconStyle: {
        width: "18px",
        height: "18px",
        objectFit: "contain",
        marginRight: "10px",
        alignSelf: "center",
    },
    right_img: {
        width: "24px",
        height: "24px",
        objectFit: "contain",
        right: "8px",
        position: "absolute",
        alignSelf: "center",

    },
    lineSaperator: {
        width: '100%',
        backgroundColor: 'rgb(215, 215, 215)',
        height: '1px'
    },
    filterWrapper: {
        maxWidth: '1280px',
        width: '100%',
        display: "block",
        padding: 0
    } as React.CSSProperties,
    profileImage: {
        borderRadius: '100%'
    }


};
// Customizable Area End