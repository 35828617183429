// Customizable Area Start
import React from 'react';
import { Box, Switch, withStyles,Grid,Container } from "@material-ui/core";
import UserProfileBasicController from './UserProfileBasicController'
import AppFooter from '../../../components/src/AppFooter.web';
import LeftMenu from './LeftMenu.web';
import SettingsHeader from './SettingsHeader.web';
import {  secure, notification, sub, lightAcc, darkBell } from './assets';

export default class Notification extends UserProfileBasicController {
    tabs = [{ 
        img: lightAcc, tab: 'Account preferences',path:'settings',isActive:false }, 
        { img: secure, tab: 'Sign in & security',path:'SignInSecurity',isActive:false }, 
        { img: notification,darkIcon:darkBell, tab: 'Notifications',isActive:true }, 
        { img: sub, tab: 'Manage my subscriptions',path:'ManageSubscription',isActive:false }]
    render() {
        const handleChange = (e: any) => {

            let tempObj = this.state.notificationObj
            if (e.target.checked) {
                tempObj = { ...tempObj, [e.target.name]: 'On' }

                this.setState({ notificationObj: tempObj })
            }
            else {
                tempObj = { ...tempObj, [e.target.name]: 'Off' }

                this.setState({ notificationObj: tempObj })
            }

            this.updateNotificationOption(tempObj)


        }
        const notifiObj = this?.state?.notificationObj;
        const { like_on_post, profile_view, tagged_in_post, comment_on_post, organisation_post, message_notification, like_comment_on_shared_post } = notifiObj
        return (
            <>
                <SettingsHeader navigation={this.props?.navigation} id={''} />
                <Container style={{ marginTop: '25px' }}>
                    <Grid container spacing={3}>
                        <Grid item md={3}>
                            <LeftMenu navigation={this.props?.navigation} id={''} tabs={this.tabs} />

                        </Grid>
                        <Grid item md={8}>
                           <Box sx={{ bgcolor: 'background.paper', height: 'auto', p: 2, borderRadius: '10px' }}>
                                <div style={{ fontSize: '18px', fontFamily: "HelveticaNeue", fontWeight: 'bold' }}>Notification you want to receive</div>
                                <div style={{ marginTop: '25px', fontFamily: "HelveticaNeue" }}>
                                    <div style={webStyles?.row}>
                                        <div style={webStyles?.rowText}>Likes on post</div>
                                        <div style={{ display: 'flex', alignItems: "center" }}>
                                            <div style={{ marginRight: '5px', fontSize: '14px' }}>{like_on_post}</div>
                                            <div><AntSwitch name='like_on_post' data-test-id="switch" onChange={handleChange} checked={like_on_post == 'On' ? true : false} /></div>

                                        </div>
                                    </div>
                                    <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }}  />
                                    <div style={webStyles?.row}>
                                        <div style={webStyles?.rowText}>Comments on post</div>
                                        <div style={{ display: 'flex', alignItems: "center" }}>
                                            <div style={{ marginRight: '5px', fontSize: '14px' }}>{comment_on_post}</div>
                                            <div><AntSwitch name='comment_on_post' onChange={handleChange} checked={comment_on_post == 'On' ? true : false} /></div>

                                        </div>
                                    </div>
                                    <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }}  />
                                    <div style={webStyles?.row}>
                                        <div style={webStyles?.rowText}>Tagged in a post</div>
                                        <div style={{ display: 'flex', alignItems: "center" }}>
                                            <div style={{ marginRight: '5px', fontSize: '14px' }}>{tagged_in_post}</div>
                                            <div><AntSwitch name='tagged_in_post' onChange={handleChange} checked={tagged_in_post == 'On' ? true : false} /></div>

                                        </div>
                                    </div>
                                    <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }}  />
                                    <div style={webStyles?.row}>
                                        <div style={webStyles?.rowText}>Organization posts</div>
                                        <div style={{ display: 'flex', alignItems: "center" }}>
                                            <div style={{ marginRight: '5px', fontSize: '14px' }}>{organisation_post}</div>
                                            <div><AntSwitch name='organisation_post' onChange={handleChange} checked={organisation_post == 'On' ? true : false} /></div>

                                        </div>
                                    </div>
                                    <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }}  />
                                    <div style={webStyles?.row}>
                                        <div style={webStyles?.rowText}>Messages Notifications</div>
                                        <div style={{ display: 'flex', alignItems: "center" }}>
                                            <div style={{ marginRight: '5px', fontSize: '14px' }}>{message_notification}</div>
                                            <div><AntSwitch name='message_notification' onChange={handleChange} checked={message_notification == 'On' ? true : false} /></div>

                                        </div>
                                    </div>
                                    <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }}  />
                                    <div style={webStyles?.row}>
                                        <div style={webStyles?.rowText}>Profile viewed by someone</div>
                                        <div style={{ display: 'flex', alignItems: "center" }}>
                                            <div style={{ marginRight: '5px', fontSize: '14px' }}>{profile_view}</div>
                                            <div><AntSwitch name='profile_view' onChange={handleChange} checked={profile_view == 'On' ? true : false} /></div>

                                        </div>
                                    </div>
                                    <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }}  />
                                    <div style={webStyles?.row}>
                                        <div style={webStyles?.rowText}>Like & comment on a post you shared</div>
                                        <div style={{ display: 'flex', alignItems: "center" }}>
                                            <div style={{ marginRight: '5px', fontSize: '14px' }}>{like_comment_on_shared_post}</div>
                                            <div><AntSwitch name='like_comment_on_shared_post' onChange={handleChange} checked={like_comment_on_shared_post == 'On' ? true : false} /></div>

                                        </div>
                                    </div>


                                </div>

                            </Box>


                        </Grid>

                    </Grid>

                </Container>
                <div style={{ marginTop: 'auto' }}>

                    <AppFooter />
                </div>
            </>
        )
    }
}
const webStyles = {
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: "15px 0px"
    },
    rowText: {
        fontSize: '16px',
        color: '#565656'
    }

}
const AntSwitch = withStyles((theme) => ({
    root: {
        width: 36,
        height: 22,
        padding: 3,
        display: 'flex',
    },
    switchBase: {
        padding: 6,
        color: 'white',
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: '#BD9E44',
                borderColor: 'BD9E44',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 24/2,
        opacity: 1,
        backgroundColor: '#565656',
    },
    checked: {},
}))(Switch);
  // Customizable Area End