import React from "react";
import {
    Modal,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    Button,
    InputLabel
} from "@material-ui/core";
import * as Yup from 'yup'
import { HighlightOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import CirtificationSucessModel from "./CirtificationSucessModel.web";
const close = require("../src/close.png");

const style: any = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    borderRadius: 14,
    boxShadow: 24,
    p: 3,
    overflow: "auto",
};

const CertificationModal = ({ open, handleClose, postCeritificationDetails, postCeritificationDetailsEdit, certificationDetails }: any) => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);
    const year = new Date().getFullYear();

    const contactUsSchema: any = Yup.object().shape({
        year: Yup.string().required('Year is required.'),
        title: Yup.string().required('Title is required.'),
        associated_with: Yup.string().required('Type is required.'),
        Issuing_Institution: Yup.string().required('Issuing Institution is required.'),
        month: Yup.string().required('Month is required.'),
    })

    const successModalClose = () => {
        setIsSuccessModalOpen(false)
    }

    const generateYearOptions = () => {
        const yearOptions = [];
        for (let i = year - 50; i <= year; i++) {
            yearOptions.push(
                <MenuItem key={i} value={i}>{i}</MenuItem>
            );
        }
        return yearOptions;
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img
                        src={close}
                        style={modalContactStyle.closeIcon}
                        onClick={handleClose}
                    />
                    <Typography style={modalContactStyle.contact_textContent}>
                        Certifications/Diplomas
                    </Typography>
                    <Formik
                        initialValues={{
                            year: certificationDetails?.attributes?.issue_year,
                            title: certificationDetails?.attributes?.title,
                            associated_with: certificationDetails?.attributes?.associated_with,
                            Issuing_Institution: certificationDetails?.attributes?.issuer,
                            month: certificationDetails?.attributes?.issue_month
                        }}
                        validationSchema={contactUsSchema}
                        onSubmit={(values: any) => {
                            certificationDetails?.id !== undefined ? postCeritificationDetailsEdit(values, certificationDetails.id) : postCeritificationDetails(values);
                            setIsSuccessModalOpen(true)
                        }
                        }
                    >
                        {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
                            <form onSubmit={handleSubmit}>
                                <Box style={modalContactStyle.main}>
                                    <Box style={modalContactStyle.row}>
                                        <Box style={modalContactStyle.name}>
                                            <label>Title *</label>
                                            <InputField
                                                error={errors.title && touched.title}
                                                testID="contactName"
                                                data-test-id="inputName"
                                                placeholder="Ex. Computer diploma"
                                                fullWidth={true}
                                                id="title"
                                                name="title"
                                                variant="outlined"
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.title && touched.title &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.title}
                                                </div>
                                            }
                                        </Box>
                                        <Box style={modalContactStyle.name}>
                                            <label>Type *</label>
                                            <Select
                                                error={errors.associated_with && touched.associated_with}
                                                id="associated_with"
                                                name="associated_with"
                                                variant="outlined"
                                                fullWidth
                                                style={modalContactStyle.select}
                                                value={values.associated_with}
                                                displayEmpty
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <InputLabel>certificate</InputLabel>

                                                <MenuItem selected value='' disabled>
                                                </MenuItem>
                                                <MenuItem value="certificate">certificate</MenuItem>
                                            </Select>
                                            {errors.associated_with && touched.associated_with &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.associated_with}
                                                </div>
                                            }
                                        </Box>
                                    </Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box style={modalContactStyle.name}>
                                            <label>Issuing Institution *</label>
                                            <InputField
                                                error={errors.Issuing_Institution && touched.Issuing_Institution}
                                                testID="contactName"
                                                data-test-id="inputName"
                                                placeholder="Ex. abcd college"
                                                fullWidth={true}
                                                id="Issuing_Institution"
                                                Issuing_Institution="Issuing_Institution"
                                                variant="outlined"
                                                value={values.Issuing_Institution}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.Issuing_Institution && touched.Issuing_Institution &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.Issuing_Institution}
                                                </div>
                                            }
                                        </Box>
                                        <Box style={modalContactStyle.w201}>
                                            <label>Month *</label>
                                            <Select
                                                error={errors.month && touched.month}
                                                id="month"
                                                name="month"
                                                variant="outlined"
                                                fullWidth
                                                style={modalContactStyle.select}
                                                value={values.month}
                                                displayEmpty
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <InputLabel>Month</InputLabel>
                                                <MenuItem selected value='month' disabled>
                                                </MenuItem>
                                                <MenuItem value="January">January</MenuItem>
                                                <MenuItem value="February">February</MenuItem>
                                                <MenuItem value="March">March</MenuItem>
                                                <MenuItem value="April">April</MenuItem>
                                                <MenuItem value="May">May</MenuItem>
                                                <MenuItem value="June">June</MenuItem>
                                                <MenuItem value="July">July</MenuItem>
                                                <MenuItem value="August">August</MenuItem>
                                                <MenuItem value="September">September</MenuItem>
                                                <MenuItem value="October">October</MenuItem>
                                                <MenuItem value="November">November</MenuItem>
                                                <MenuItem value="December">December</MenuItem>
                                            </Select>
                                            {errors.month && touched.month &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.month}
                                                </div>
                                            }
                                        </Box>
                                        <Box style={modalContactStyle.w201}>
                                            <label>Year *</label>
                                            <Select
                                                error={errors.year && touched.year}
                                                id="year"
                                                name="year"
                                                variant="outlined"
                                                fullWidth
                                                style={modalContactStyle.select}
                                                value={values.year}
                                                displayEmpty
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                MenuProps={MenuProps}
                                            >
                                                <InputLabel>Year</InputLabel>

                                                <MenuItem selected value='year' disabled>
                                                </MenuItem>
                                                {generateYearOptions()}
                                            </Select>
                                            {errors.year && touched.year &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.year}
                                                </div>
                                            }
                                        </Box>
                                    </Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box
                                            style={modalContactStyle.submit}
                                        >
                                            <Button type="submit" variant="contained" style={modalContactStyle.submitButton}>
                                                Save
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
            {isSuccessModalOpen && <CirtificationSucessModel open={isSuccessModalOpen} successModalClose={successModalClose} />}
        </>
    );
}

export default CertificationModal;


const modalContactStyle: any = {
    contact_textContent: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px"
    },
    closeIcon: {
        cursor: "pointer",
        position: "absolute",
        right: "20px",
        top: "20px",
        height: "20px",
    },
    fieldError: {
        color: "#D11024",
        fontSize: "14px",
        margin: "-7px 5px 10px 0"
    },
    main: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    row: {
        display: "flex",
        gap: "24px"
    },
    name: {
        width: "426px"
    },
    w201: {
        width: "201px"
    },
    field: {
        width: "876px",
        marginBottom: "10px"
    },

    select: {
        width: "100%",
        borderRadius: "8px",
        margin: "10px 0"
    },


    submitButton: {
        height: "32px",
        backgroundColor: "#BE9E44",
        borderRadius: "8px",
        color: "#fff",
        textTransform: "none",
    },
    submit: {
        width: "876px",
        display: "flex",
        justifyContent: "end"
    }

};

const InputField: any = withStyles({
    root: {
        padding: '10px 0',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue-medium",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)"
            }
        },
        "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D11024"
            }
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue-Medium",
        }
    }
})(TextField);
