import React from "react";
import {
  Modal,
  Box,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
import { success } from "../../blocks/educational-user-profile/src/assets";
const useStyles = makeStyles((theme) => ({

  imageStyle: {
    // width: "30%",
    // boxShadow: '0px -5px 5px #BE9E44',
  },
  button: {
    background: "#BE9E44",
    color: "#FFFFFF",
    height: "56px",
    width: "100%",
    margin: "auto",
    textTransform: "capitalize",
    borderRadius: "8px",
    fontSize: "16px",
    "&:hover": {
      background: "#BE9E44",
      textTransform: "none",
    },
  },
  contentHead: {
    fontSize: "24px",
    textAlign: "center",
    marginTop: "25px",
  },
  content: {
    fontSize: "14px",
    paddingTop: "3px",
    textAlign: "center",
  },
  contentSpan: {
    color: "#BE9E44",
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 434,
  bgcolor: "background.paper",
  borderRadius: 12,
  boxShadow: 24,
  padding: "50px",
};
const EducationSucessMoedel = ({ open,successModalClose,setIsModalOpen}:any) => {  
  const classes = useStyles();

  const handleModelClose = () => {
    setIsModalOpen(false)
    successModalClose()
  }
  console.log(open,"open--->");
  
  return (
    <Box>
      <Modal
        open={open}
        onClose={successModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              width: "25%",
              margin: "auto",
              marginTop: "10px",
            }}
          >
            <img
              src={success}
              alt="Age_Logo"
              className={classes.imageStyle}
              width="100%"
              height="100%"
            />
          </Box>
          <Box>
            <Typography className={classes.contentHead}>
            Your Education Detail has been added!
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              width: "100%",
              marginTop: "10px",
              justifyContent: "center"
            }}
          >
            <Typography className={classes.content}>
            Your Education Detail has been successfully added to your profile.
            </Typography>
          </Box>
          <Box
            style={{
              width: "100%",
              marginTop: "25px",
              marginBottom: "10px"
            }}
          >
            <Button className={classes.button} onClick={handleModelClose}>
            Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default EducationSucessMoedel;
