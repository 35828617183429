export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const appleLogo = require("../assets/appleLogo.png");
export const googleLogo = require("../assets/googleLogo.png");
export const LandingImg = require("../assets/landing_img.png");
export const microsoftLogo = require("../assets/microsoftLogo.png");
export const edConnectLogo = require("../assets/edConnectLogo.png");
export const EdConnectLogo = require("../assets/edconnect_logo.png");
export const BackArrow = require("../assets/back_arrow.png");
export const AgeConfirmLogo = require("../assets/ageConfirmLogo.png");
export const BlockedWarningLogo = require("../assets/blockedWarningLogo.png");
