// Customizable Area Start
import React from 'react';
import {  Box, Modal, Fade, Backdrop,Typography,styled,Button } from "@material-ui/core";
interface SuccessModalProps{
    openModal:boolean
    handleClose:any
    message:any
    navigation:any
}
function SuccessModal(props:SuccessModalProps) {
    const {openModal,handleClose,message,navigation}=props
    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                          {message}
                        </Typography>
                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                            <StyledOkButton onClick={()=>{
                                handleClose()
                                if(message=='You have successfully deleted your account'){
                                    navigation?.navigate('LandingPage')
                                }
                            }}>Ok</StyledOkButton>


                        </div>
                        
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  const StyledOkButton = styled(Button)({
    backgroundColor: '#BD9E44',
    borderRadius: '6px',
    color: '#F9F9F9',
    padding: '5px 25px',
    '&:hover': {
        backgroundColor: '#BD9E44'

    },
    textTransform: 'capitalize',

});
export default SuccessModal
// Customizable Area End