// Customizable Area Start
import React from 'react'
import { TextField, withStyles, InputAdornment, createStyles } from "@material-ui/core";

interface InputFieldProps {
    commentTxt: any
    setCommentTxt: any
    setEnablePostButton: any
    setOPenGif: any
    setGifElement: any
    width: number
    size?: any


}

const styles = createStyles({
    root: (props: InputFieldProps) => ({
        width: `${props.width}px`,
        backgroundColor: '#F7F7F7',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        }
    })
});
const InputField = withStyles(styles)(TextField);

function InputBoxForComment(props: InputFieldProps) {
    const { commentTxt, setCommentTxt, setEnablePostButton, setOPenGif, setGifElement, size } = props
    return (
        <React.Fragment>
            <InputField
                style={{width: '100%'}}
                size={size}
                value={commentTxt}
                onChange={(e: any) => {
                    setCommentTxt(e.target.value)
                    if (e.target.value.trim() != '') {
                        setEnablePostButton(true)
                    }
                    else {
                        setEnablePostButton(false)
                    }
                }}
                variant='outlined' placeholder="Add a comment..."
                InputProps={{

                    endAdornment: (
                        <InputAdornment
                            position="end"
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                onClick={(e: any) => {
                                    setOPenGif(true);
                                    setGifElement(e.currentTarget)
                                }}
                                style={{ marginRight: '15px', fontFamily: "HelveticaNeue", fontSize: '14px', color: '#565656', cursor: 'pointer' }}>GIF</div>


                        </InputAdornment>
                    ),
                }}
            />
        </React.Fragment>
    )
}

export default InputBoxForComment
// Customizable Area End