import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  InputLabel,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Tabs,
  Tab,
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { BackArrow } from "./assets";
import { NavLink } from "react-router-dom";
import AppHeader from '../../../components/src/AppHeader.web'
import AppFooter from '../../../components/src/AppFooter.web'
import { Formik } from 'formik'
import * as Yup from 'yup'

// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#bd9e44",
      contrastText: "#f9f8f8",
    }
  },
});

export default class ForgotPasswordBlock extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  emailSection() {
    const emailSchema = Yup.object().shape({
      email: Yup.string().email("Invalid email").required(this.errorEmailRequired),
    })
    return <Container maxWidth="sm" style={webStyle.container}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "50px",
          bgcolor: "#ffffff",
          borderRadius: "14px",
          position: 'relative',
          maxWidth: "434px",
          fontFamily: "HelveticaNeue",
        }}
      >
        <Box style={webStyle.pageTitle}>
          <img src={BackArrow} style={webStyle.backArrow} data-test-id="backArrow" onClick={() => { this.props.navigation.navigate("Login") }} />
          <Typography variant="h5" style={{ textAlign: "center" }}>
            Forgot Password?
          </Typography>
          <Typography component="h1" style={webStyle.subTitle}>
            Reset password in two quick steps
          </Typography>
        </Box>

        <Box sx={{ width: "100%", paddingTop: "20px" }}>
          <Formik
            initialValues={this.state.initialEmail}
            validationSchema={emailSchema}
            onSubmit={(values: any) => { this.handleSendOTP(values) }}
            data-test-id="emailFormik"
          >
            {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
              <form onSubmit={handleSubmit} data-test-id="loginForm" style={{ margin: "0" }}>
                <Box sx={{ padding: "10px 0px" }}>
                  <InputLabel style={webStyle.fieldLabel}>{this.labelEmail}</InputLabel>
                  <InputField
                    error={errors.email && touched.email}
                    id="email"
                    name="email"
                    fullWidth={true}
                    placeholder="Email address"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email &&
                    <div style={webStyle.fieldError}>
                      {errors.email}
                    </div>
                  }
                  {this.state.isError && <div style={{fontSize: "14px", color: "#D11024", marginTop: "8px"}}>{this.state.isError}</div>}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "30px 0px 0px",
                  }}
                >
                  <Button
                    type="submit"
                    data-test-id={"btnReset"}
                    variant="contained"
                    color="primary"
                    style={{ height: "56px", fontSize: "16px", textTransform: "none", borderRadius: "8px" }}
                    fullWidth
                  >
                    {this.btnTxtResetPass} {/*UI Engine::From Sketch*/}
                  </Button>
                  <Buttons
                    data-test-id={"btnBack"}
                    variant="outlined"
                    color="primary"
                    style={{ height: "56px", fontSize: "16px", textTransform: "none", borderRadius: "8px" }}
                    fullWidth
                    onClick={() => { this.props.navigation.navigate("Login") }}
                  >
                    {this.btnTxtBack} {/*UI Engine::From Sketch*/}
                  </Buttons>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Container>
  }

  codeSection() {
    const codeSchema = Yup.object().shape({
      otp: Yup.string()
        .required(this.errorCodeRequired)
    })
    return <Container maxWidth="sm" style={webStyle.container} data-test-id="otpContainer">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "50px",
          bgcolor: "#ffffff",
          borderRadius: "14px",
          position: 'relative',
          maxWidth: "434px",
          fontFamily: "HelveticaNeue",
        }}
      >
        <Box style={webStyle.pageTitle}>
          <img src={BackArrow} style={webStyle.backArrow} data-test-id="backArrow1" onClick={() => { this.setState({isEmail: true}) }} />
          <Typography variant="h6" style={{ textAlign: "center", marginBottom: "10px" }}>
            We sent a code to your email!
          </Typography>
          <Typography component="h1" style={{...webStyle.subTitle, letterSpacing: '-0.031rem'}}>
            Enter the 4-digit verification code sent to your email
          </Typography>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Formik
            initialValues={this.state.initialOTP}
            validationSchema={codeSchema}
            onSubmit={(values: any) => { this.handleVerifyCode(values) }}
            data-test-id="otpFormik"
          >
            {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
              <form onSubmit={handleSubmit} data-test-id="loginForm">
                <Box sx={{ padding: "10px 0 20px" }}>
                  <InputLabel style={{ color: "#bd9e44", marginBottom: "25px", cursor: "pointer" }} data-test-id="txtChangeEmail" onClick={() => { this.setState({isEmail: true}) }}>Change</InputLabel>
                  <InputField
                    type="number"
                    error={errors.otp && touched.otp}
                    id="otp"
                    name="otp"
                    fullWidth={true}
                    placeholder="4-digit code"
                    variant="outlined"
                    value={values.otp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.otp && touched.otp &&
                    <div style={webStyle.fieldError}>
                      {errors.otp}
                    </div>
                  }
                  {this.state.isError && <div style={{color: "#D11024", fontSize: "14px", marginTop: "8px"}}>{this.state.isError}</div>}
                  <InputLabel style={{ color: "#bd9e44", marginTop: "15px", cursor: "pointer" }} data-test-id="txtResentCode" onClick={() => {this.handleSendOTP({email: this.state.email})}}>Resend code</InputLabel>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <Button
                    type="submit"
                    data-test-id={"btnSubmit"}
                    variant="contained"
                    color="primary"
                    style={{ height: "56px", fontSize: "16px", textTransform: "none", borderRadius: "8px" }}
                    fullWidth
                  >
                    {this.btnTxtSubmit} {/*UI Engine::From Sketch*/}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Typography component="h1" style={{...webStyle.subTitle, letterSpacing: '-0.031rem'}}>If you didn't received the code in your email inbox, please check your spam folder.</Typography>
        </Box>
      </Box>
    </Container>
  }

  render() {
    // Required for all blocks
    return (
      <ThemeProvider theme={theme}>
        <AppHeader />
        <Loader loading={this.state.isLoading} />
        {
          this.state.isEmail === true ?
            this.emailSection() :
            this.codeSection()
        }
        <AppFooter />
      </ThemeProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle:any = {
  container: {
    minHeight: "calc(100vh - 300px)",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    margin: "25px auto",
    fontFamily: 'HelveticaNeue'
  },
  backArrow: {
    position: "absolute",
    width: "25px",
    left: "0",
    top: "3px",
    cursor: "pointer"
  },
  fieldError: {
    color: "#D11024",
    fontSize: "14px",
    margin: "5px 5px 5px 0"
  },
  subTitle: {
    textAlign: "center",
    color: "#949494",
    fontSize: "16px"
  },
  pageTitle: {
    position: "relative",
    width: "100%",
    textAlign: "center"
  },
  fieldLabel: {
    color: "#1c1c1c",
    fontSize: "14px"
  }
};

const InputField:any = withStyles({
  root: {
      width: '100%',
      paddingTop: '10px',
      borderRadius: "10px",
      fontFamily: "HelveticaNeue",
      "& .MuiOutlinedInput-root": {
          fontWeight: 400,
          fontFamily: "HelveticaNeue",
          fontSize: "16px",
          "& fieldset": {
              fontSize: "16px",
              borderRadius: "10px",
              borderWidth: 1,
              fontWeight: 400,
          },
      },
      "& ::placeholder": {
        opacity: 1,
        color: "#696969",
        fontFamily: "HelveticaNeue",
      },
      "& .Mui-error":{
        "& .MuiOutlinedInput-notchedOutline":{
          borderColor: "#D11024"
        }
      },
  }
})(TextField);

const Buttons:any = withStyles({
  root: {
    width: "100%",
    height: "56px",
    color: "#696969",
    opacity: 1,
    boxShadow: "none",
    borderRadius: "5px",
    border: "none",
    fontSize: '16px',
    marginTop: '10px',
    textTransform: "none",
    fontFamily: "HelveticaNeue",
    "&:hover": {
      backgroundColor: "",
      boxShadow: "none",
    },
  },
})(Button);

// Customizable Area End