import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  studentProfileDetails: any;
  educationDetails: any;
  certificationDetails: any;
  workExperienceDetails:any

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModalOpenCover: boolean;
  isModalOpenProfile: boolean;
  isModalOpenShare: boolean;
  isModalOpenView: boolean;
  copyText: string;
  token: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class GuestProfileTopWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEducationDetailsCallId: string = "";
  getCertificateDetailsCallId: string = "";
  getWorkExperienceCallId: string = "";
  getStudentProfileCallId: string = "";
  getOwnerProfileCallId:string = "";
  patchImageCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    console.log(Message)
    // Customizable Area End
    // Customizable Area Start
    this.state = {
      isModalOpenCover: false,
      isModalOpenProfile: false,
      isModalOpenView: false,
      isModalOpenShare:false,
      copyText: "",
      token:''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End
}
