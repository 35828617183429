Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "bx_block_posts/posts";
exports.postNearUrl = "bx_block_posts/posts/nearest";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";
exports.getProfileEndPoint = "bx_block_profile/profiles/get_profile";
exports.getProfileMethod = "GET";
exports.searchHashTagUrl="bx_block_posts/posts/search_hashtag";
exports.searchHashTagMethod = "GET"
exports.followingCountMethod = "POST"
exports.followingCountUrl = "/bx_block_followers/follows/following_list"
exports.connectionCountMethod = "GET"
exports.connectionCountUrl = "/bx_block_friendlist/connects/connection_list"
// Customizable Area End