import React, { useEffect } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { edConnectLogo } from "../../blocks/email-account-login/src/assets";
import { ContactPop } from "./ContactUsModal.web";
import { TermsConditionsPop } from "./TermsConditionsPop.web";
import "../../web/src/App.css";
import axios from "axios";
const { baseURL: apiBaseUrl } = require('../../framework/src/config.js')

const useStyles = makeStyles(() => ({
    footerMenus: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        marginTop: "15px"
    },
    footerMenu: {
        fontSize: "10px",
        color: "#7c7c7c",
        margin: "5px 10px",
        cursor: "pointer",
        "@media (max-width: 1200px)": {
            margin: "5px 10px",
        }
    },
    footerContent: {
        fontSize: "10px",
        color: "#7c7c7c",
        marginTop: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
}));

export default function CompanyInfo() {
    const classes = useStyles();
    const [openContactPopup, setOpenContactPopup] = React.useState(false);
    const [openTermsConditionPopup, setTermsConditionPopup] = React.useState(false);
    const [openPrivacyPolicyPopup, setPrivacyPolicyPopup] = React.useState(false);
    const [termsData, setTermsData] = React.useState<any>({});

    const handleOpenContactusPopup = () => {
        setOpenContactPopup(true)
    };
    const handleCloseContactusPopup = () => {
        setOpenContactPopup(false)
    };

    const handlePrivacyPolicyPopup = () => {
        setPrivacyPolicyPopup(!openPrivacyPolicyPopup)
    };

    const handleTermsConditionPopup = () => {
        setTermsConditionPopup(!openTermsConditionPopup)
    };

    useEffect(() => {
        getTermsandPolicy();
    }, []);

    const getTermsandPolicy = () => {
        axios.get(`${apiBaseUrl}/bx_block_admin/settings`)
            .then((response: any) => {
                setTermsData(response.data)
            })
    }

    return (
        <>
            <Box className={classes.footerMenus}>
                <CustomTypography className={classes.footerMenu}>
                    <NavLink
                        to={{ pathname: "/About" }}
                        style={{ textDecoration: "none", fontSize: "10px", color: "#7c7c7c" }}
                    >
                        About us
                    </NavLink>
                </CustomTypography>
                <CustomTypography onClick={handleOpenContactusPopup} className={classes.footerMenu}>
                    Contact us
                </CustomTypography>
                <CustomTypography onClick={handlePrivacyPolicyPopup} className={classes.footerMenu}>
                    Privacy Policy
                </CustomTypography>
                <CustomTypography onClick={handleTermsConditionPopup} className={classes.footerMenu}>
                    Terms & Conditions
                </CustomTypography>
            </Box>
            <Box>
                <CustomTypography className={classes.footerContent}>
                    <img src={edConnectLogo} style={{ width: "28px", height: "20px" }} alt="edConnect logo" />
                    Copyright &#169;{new Date().getFullYear()} EDConnect. All rights
                    reserved.
                </CustomTypography>
            </Box>
            <ContactPop
                open={openContactPopup}
                handleClose={handleCloseContactusPopup}
            />
            <TermsConditionsPop
                open={openTermsConditionPopup}
                handleModal={handleTermsConditionPopup}
                data={termsData && termsData?.terms}
                title={"Terms & Conditions"}
            />
            <TermsConditionsPop
                open={openPrivacyPolicyPopup}
                handleModal={handlePrivacyPolicyPopup}
                data={termsData && termsData?.poilicies}
                title={"Privacy Policy"}
            />
        </>
    );
}

const CustomTypography: any = withStyles({
    root: {
        color: "#7c7c7c"
    },
})(Typography);
