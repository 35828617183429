// Customizable Area Start
import React, { useEffect, useState } from 'react'
import { Box, Modal, Fade, Backdrop, IconButton, Button, styled } from "@material-ui/core";
import { close } from '../../blocks/postcreation/src/assets';
import axios from 'axios';
const { baseURL: apiBaseUrl } = require('../../framework/src/config.js');


// images
const right = require("../src/right.png");

interface Props {
    openReportModal: boolean;
    setReportModal: any;
    postId: any;
    commentId: any
}

function ReportPostModal(props: Props) {

    // states
    const [isReportClick, setReportClick] = useState(false);
    const [allReports, setReports] = useState([]);
    const [subReports, setSubReports] = useState([]);
    const [selectedReason, setSelectedReason] = useState('');
    const [reportId, setReportId] = useState('');
    const [reportCategoryId, SetReportCategoryId] = useState('');
    const [subReportHeading,setSubReportHeading] = useState('')

    const handleClose = () => {
        props?.setReportModal(false);
        setReportClick(false);
        setSelectedReason('');
        setReportId('');
        SetReportCategoryId('')
    }
    useEffect(() => {
        getAllReports()

    }, []);
    const getAllReports = async () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };
        try {
            let response = await axios.get(`${apiBaseUrl}/bx_block_contentflag/reports`, {
                headers: header
            })
            setReports(response?.data?.reports)



        }
        catch (error) {

            console.error(error);
        }
    }
    const getSubReports = async (id: any) => {
        const report_id = id

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };
        try {
            let response = await axios.get(`${apiBaseUrl}/bx_block_contentflag/reports/${report_id}/report_wise_sub_report`, {
                headers: header
            })
            setSubReportHeading(response?.data?.heading)
            setSubReports(response?.data?.report_categories)



        }
        catch (error) {

            console.error(error);
        }

    }
    const otherSubReport=async()=>{
        const report_id = localStorage.getItem('otherReportId')

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };
        try {
            let response = await axios.get(`${apiBaseUrl}/bx_block_contentflag/reports/${report_id}/report_wise_other_sub_report`, {
                headers: header
            })
            setSubReportHeading(response?.data?.heading1)
            setSubReports(response?.data?.report_categories)



        }
        catch (error) {

            console.error(error);
        }

    }

    const reportPost = async () => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };
        let attr;
        if (props?.postId !== '') {
            attr = {
                post_id: props?.postId,
                report_id: reportId,
                report_category_id: reportCategoryId

            }
            try {
                let response = await axios.post(`${apiBaseUrl}/bx_block_contentflag/reports/report_post`, attr, {
                    headers: header
                })
                console.log(response)
            }
            catch (error) {

                console.error(error);
            }
        }
        else {
            console.log('else',props?.commentId)
            attr = {
                comment_id: props?.commentId,
                report_id: reportId,
                report_category_id: reportCategoryId

            }
            try {
                let response = await axios.post(`${apiBaseUrl}/bx_block_contentflag/reports/report_comment`, attr, {
                    headers: header
                })
                console.log(response)
            }
            catch (error) {

                console.error(error);
            }
        }






    }
    const StyledSubmitButton = styled(Button)({
        backgroundColor: selectedReason === '' ? 'rgba(190, 158, 68, 0.500273)' : '#BD9E44',
        borderRadius: '10px',
        color: '#F9F9F9',
        padding: '10px 30px',
        '&:hover': {
            backgroundColor: '#BD9E44'

        }

    });
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props?.openReportModal}
                onClose={handleClose}
                closeAfterTransition
                slot={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={props?.openReportModal}>
                    <Box sx={style}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontSize: '20px' }}>Report</div>
                            <IconButton size='small' onClick={() => handleClose()}><img src={close} width='24px' /></IconButton>
                        </div>
                        <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                        {!isReportClick && <>
                            <div>
                                <div style={{ fontSize: '18px', fontWeight: 550, color: '#1C1C1C' }}>Why are you reporting this?</div>
                                {allReports?.map((report: any) => {
                                    return report?.report_type=="type2"?localStorage.setItem('otherReportId',report?.id):<div className='reportLabel'
                                        onClick={() => {
                                            setReportClick(true);
                                            getSubReports(report?.id);
                                            setReportId(report?.id)
                                        }}
                                        style={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0px', padding: '12px 5px' }}>
                                        <div>{report?.reason}</div>
                                        <div><img src={right} width="20px" /></div>

                                    </div>
                                })}
                            </div>

                            <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />

                            <div>
                                <div style={{ fontSize: '18px', fontWeight: 550, color: '#1C1C1C' }}>None of the reporting reasons apply.</div>
                                <div className='reportLabel' style={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0px', padding: '12px 5px' }}>
                                    <div onClick={()=>{
                                        setReportClick(true);
                                        setReportId(localStorage.getItem('otherReportId')||'')
                                        otherSubReport()
                                    }}>I still don't want to see this</div>
                                    <div><img src={right} width="20px" /></div>

                                </div>
                            </div>
                        </>
                        }
                        {isReportClick && <>
                            <div>
                                <div style={{ fontSize: '18px', fontWeight: 550, color: '#1C1C1C' }}>{subReportHeading}</div>

                                {subReports?.map((subReport: any) => {
                                    return <div style={{ display: 'flex', margin: '15px 0px' }}>
                                        <div style={{ marginRight: '10px' }}>
                                            <input type="radio" name={subReport?.name}
                                                checked={selectedReason === subReport?.name}
                                                value={subReport?.name}
                                                onChange={(e: any) => {
                                                    setSelectedReason(e.target.value);
                                                    SetReportCategoryId(subReport?.id)
                                                }} />
                                        </div>
                                        <div>
                                            <div style={{ fontSize: '16px' }}>{subReport?.name}</div>
                                            <div style={{ fontSize: '14px' }}>{subReport?.description}</div>
                                        </div>

                                    </div>
                                })}
                            </div>
                            <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
                                <StyledBackButton
                                    onClick={() => {
                                        setReportClick(false);
                                        setSelectedReason('')
                                    }}>
                                    Back
                                </StyledBackButton>

                                <StyledSubmitButton
                                    onClick={() => {
                                        props?.setReportModal(false)
                                        reportPost();
                                        setReportClick(false);
                                        setSelectedReason('')
                                    }}

                                    disabled={selectedReason === '' ? true : false}>Submit</StyledSubmitButton>
                            </div>

                        </>}

                    </Box>



                </Fade>
            </Modal>
        </div>
    )
}
const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '1px solid #979797',
    boxShadow: 24,
    p: 2,
    borderRadius: '12px',
    overflow: 'auto',
    maxHeight: '80vh'
};
const StyledBackButton = styled(Button)({
    backgroundColor: 'rgba(190, 158, 68, 0.1)',
    border: '1px solid #BE9E44',
    borderRadius: '10px',
    color: '#BE9E44',
    marginRight: '10px',
    padding: '10px 35px'

});


export default ReportPostModal
// Customizable Area End