import React from "react";
import { Modal, Box, Typography, TextField } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { userProfile } from "../../blocks/educational-user-profile/src/assets";
const close = require("../src/close.png");
const defaultUser = require("../../blocks/educational-user-profile/assets/user_icon.png");

const style: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 3,
  overflow: "scroll",
  height: "75%",
  width: "70%",
};
const ViewModal = ({
  open,
  handleClose,
  educationDetails,
  certificationDetails,
  workExperienceDetails,
  studentProfileDetails,
}: any) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <img
          src={close}
            style={modalContactStyle.closeIcon}
            onClick={handleClose}
          />
          <Box style={modalContactStyle.main}>
            <Box style={{display:"flex"}}>
              <Typography style={modalContactStyle.headinDetails}>
                Ed portfolio preview
              </Typography>
            </Box>
            <Box>
              <img
                src={studentProfileDetails?.profile?.data?.attributes?.profile_image !== null ? studentProfileDetails?.profile?.data?.attributes?.profile_image : defaultUser}
                style={{
                  height: "154px",
                  width: "154px",
                  padding: "15px 0 15px",
                  borderRadius: '50%',
                }}
              />
            </Box>
            <Box>
              <Typography style={modalContactStyle.headinDetails}>
                {studentProfileDetails.account &&
                  studentProfileDetails.account.data.attributes.first_name}{" "}
                {studentProfileDetails.account &&
                  studentProfileDetails.account.data.attributes.last_name}
              </Typography>
            </Box>
            <Box>
              <Typography style={modalContactStyle.subHeadingDetails}>student and college</Typography>
              <Typography style={modalContactStyle.subHeadingDetails}>
                {studentProfileDetails.profile &&
                  studentProfileDetails.profile.data.attributes.city}
                ,{" "}
                {studentProfileDetails.profile &&
                  studentProfileDetails.profile.data.attributes.country}
              </Typography>
            </Box>
            <Box>
              <Typography style={{...modalContactStyle.about, marginBottom: '-10px'}}>
                About
              </Typography>
              <Typography style={modalContactStyle.upHeading}>
                Hobbies: {studentProfileDetails?.profile?.data?.attributes?.hobbies} Personality: {studentProfileDetails?.profile?.data?.attributes?.personality} Goal: {studentProfileDetails?.profile?.data?.attributes?.goal} 
              </Typography>
            </Box>

            <Box>
              <Typography style={modalContactStyle.headinDetails}>
                Education details
              </Typography>

              {educationDetails.map((element: any) => {
                return (
                  <>
                    <Box style={modalContactStyle.spaceFlex}>
                      <Box>
                        <img
                          src={userProfile}
                          style={modalContactStyle.profileImage}
                        />
                      </Box>
                      <Box>
                        <Typography style={modalContactStyle.subHeadingDetails}>
                          {element.attributes.school_name}
                        </Typography>
                        <Typography style={modalContactStyle.details}>
                          {
                            element.attributes
                              .educational_qualification_field_studys
                              .field_of_study
                          }
                        </Typography>

                        <Typography style={modalContactStyle.details}>
                          {element.attributes.start_month}{" "}
                          {element.attributes.start_year} -{" "}
                          {element.attributes.end_month}{" "}
                          {element.attributes.end_year}
                        </Typography>
                        {}
                        <Typography style={modalContactStyle.details}>
                          {" "}
                          activities and societies:
                          {element.attributes.activities_and_societies}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                );
              })}
            </Box>
            <Box>
             
                <Typography style={modalContactStyle.headinDetails}>
                  Work experience
                </Typography>
             
              {workExperienceDetails.map((element: any) => {
                return (
                  <Box style={modalContactStyle.spaceFlex}>
                    <Box>
                      <img
                        src={
                          element.attributes.images.length > 0
                            ? element.attributesimages
                            : userProfile
                        }
                        style={modalContactStyle.profileImage}
                      />
                    </Box>
                    <Box style={modalContactStyle.details}>
                      <Typography style={modalContactStyle.subHeadingDetails}>
                        {element.attributes.job_title}
                      </Typography>
                      <Typography style={modalContactStyle.details}>
                        {" "}
                        {element.attributes.company_name} -
                        {element.attributes.experience_type}
                      </Typography>
                      <Typography style={modalContactStyle.details}>
                        {" "}
                        {element.attributes.start_month}{" "}
                        {element.attributes.start_year} -{" "}
                        {element.attributes.end_month}{" "}
                        {element.attributes.end_year}{" "}
                      </Typography>
                      <Typography style={modalContactStyle.details}>
                        Activities and scociteties :
                        {element.attributes.description}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>

            <Box>
              <Typography style={modalContactStyle.headinDetails}>
                Certifications / diplomas
              </Typography>
              {certificationDetails.map((element: any) => {
                return (
                  <Box
                    style={{
                      display: "flex",
                      margin: '10px'
                    }}
                  >
                    <Box>
                      <img
                        src={userProfile}
                        style={modalContactStyle.profileImage}
                      />
                    </Box>
                    <Box>
                      <Typography style={modalContactStyle.subHeadingDetails}>
                        {element.attributes.title} ({" "}
                        {element.attributes.associated_with} )
                      </Typography>
                      <Typography style={modalContactStyle.details}>
                        {element.attributes.issuer}{" "}
                        {element.attributes.issue_month}{" "}
                        {element.attributes.issue_year}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box>
              <Typography style={modalContactStyle.headinDetails}>
                Skills
              </Typography>
              {workExperienceDetails.map((element: any) => {
                return (
                  <Typography style={{...modalContactStyle.upHeading, margin: '10px'}}>
                    {
                      element.attributes.career_experience_system_experiences
                        .system_experience
                    }
                  </Typography>
                );
              })}
            </Box>
            <Box>
              <Typography style={modalContactStyle.headinDetails}>
                My achievements
              </Typography>
              {workExperienceDetails.map((element: any) => {
                return (
                  <Box style={{...modalContactStyle.upHeading, margin: '10px'}}>
                    <Typography style={{ color: "#717171" }}>
                      {element.attributes.add_key_achievements}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ViewModal;

const modalContactStyle: any = {
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "20px",
    top: "70px",
    height: "20px",
  },
  profileImage: {
    width: "44px",
    height: "44px",
    paddingRight: "30px",
  },
  space: {
    padding: "15px",
  },
  main: {
    padding: "24px",
    width: "70%",
    height: "70%",
  },
  spaceFlex: {
    padding: "15px",
    display: "flex",
  },
  profile: {
    width: "44px",
    height: "44px",
    position: "absolute",
    bottom: "-34px",
    left: "23px",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  headinDetails: {
    color: "#00000",
    fontSize: "18px",
  },
  about:{
    color: "#00000",
    fontSize: "18px",
    marginTop:"20px"
  },
  subHeadingDetails: {
    color: "#00000",
    fontSize: "14px",
  },
  upHeading: {
    fontSize: "14px",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  details: {
    color: "#000000",
    fontSize: "10px",
  },
};
