import React from "react";
import {
  Modal,
  Box,
  makeStyles,
  Typography,
  Checkbox,
  Button,
} from "@material-ui/core";
import { AgeConfirmLogo } from "../../blocks/email-account-login/src/assets";
import { NavLink } from 'react-router-dom'
import { TermsConditionsPop } from "./TermsConditionsPop.web";
const useStyles = makeStyles((theme) => ({
  modalBox: {
    backgroundColor: "white",
    border: "2px solid red",
  },
  imageStyle: {
    // width: "30%",
    // boxShadow: '0px -5px 5px #BE9E44',
  },
  button: {
    background: "#BE9E44",
    color: "#FFFFFF",
    height: "56px",
    width: "100%",
    margin: "auto",
    textTransform: "capitalize",
    borderRadius: "8px",
    fontSize: "16px",
    "&:hover": {
      background: "#BE9E44",
    },
  },
  contentHead: {
    fontSize: "24px",
    textAlign: "center",
    marginTop: "25px",
  },
  content: {
    fontSize: "14px",
    paddingTop: "3px",
    textAlign: "center",
    paddingLeft: "-10px",
  },
  contentSpan: {
    color: "#BE9E44",
  },
  container: {
    position: 'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '434px',
    background: "white",
    borderRadius: '12px',
    boxShadow: '24px',
    padding: "50px",
    [theme.breakpoints.down('sm')]:{
      width: '200px',
    }
  }
}));


const AgeConfirmationModal = ({ open, handleOpen, handleClose, data }: any) => {
  const classes = useStyles();
  const [isValid, setIsValid] = React.useState(false);
  const onClsoeBtn = () => {
    handleClose();
  }
  const [openTermsConditionPopup, setOpenTermsConditionPopup] = React.useState(false);
  const [openPrivacyPolicyPopup, setOpenPrivacyPolicyPopup] = React.useState(false);

  const handleTermsConditionPopup = () => {
    setOpenTermsConditionPopup(!openTermsConditionPopup);
  };

  const handlePrivacyPolicyPopup = () => {
    setOpenPrivacyPolicyPopup(!openPrivacyPolicyPopup);
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.container} >
          <Box
            style={{
              width: "25%",
              margin: "auto",
              marginTop: "10px",
            }}
          >
            <img
              src={AgeConfirmLogo}
              alt="Age_Logo"
              className={classes.imageStyle}
              width="100%"
              height="100%"
            />
          </Box>
          <Box>
            <Typography className={classes.contentHead}>
              Are you at least 16 years or more?
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <input type="checkbox" style={{ width: "35px", height: "44px", marginRight: "8px" }} checked={isValid} onClick={() => {setIsValid(!isValid)}}/>
            <Typography className={classes.content}>
              By clicking this box you agree you are at least{" "}
              <span className={classes.contentSpan}>16 years old</span> and
              agree to all{" "}
              <span className={classes.contentSpan} style={{cursor: 'pointer'}} onClick={() => {handleTermsConditionPopup()}}>Terms and Conditions</span> and <span style={{ color: '#bd9e44', fontWeight: 'bold', cursor: "pointer" }} onClick={() => {handlePrivacyPolicyPopup();setOpenPrivacyPolicyPopup(true)}}>Privacy Policy </span> of{" "}
              <span className={classes.contentSpan}>EDConnect</span>
            </Typography>
          </Box>
          <Box
            style={{
              width: "100%",
              marginTop: "25px",
              marginBottom: "10px"
            }}
          >
            <NavLink to="/fanwall" style={{textDecoration: "none",}}>
              <Button className={classes.button} onClick={onClsoeBtn} disabled={!isValid}>
                Close
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Modal>
      <TermsConditionsPop
        open={openTermsConditionPopup}
        handleModal={handleTermsConditionPopup}
        data={data?.terms}
        title={"Terms & Conditions"}
      />
      <TermsConditionsPop
        open={openPrivacyPolicyPopup}
        handleModal={handlePrivacyPolicyPopup}
        data={data?.poilicies}
        title={"Privacy Policy"}
      />
    </Box>
  );
};

export default AgeConfirmationModal;
