import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  initialCreds:any;
  enableField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  selectUserType: string;
  blockedUserModal: boolean;
  sponsersData: any;
  aboutUsData: any;
  termsAndPolices: Object;
  accountError: string;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  sponsorsApiCallId: string = "";
  aboutUsApiCallId: string = "";
  termsAndPolicesApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      initialCreds:{email:"",password:"",},
      enableField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      selectUserType: "student_account",
      blockedUserModal: false,
      sponsersData: [],
      aboutUsData: [],
      termsAndPolices: {},
      accountError: "",
      isLoading: false
    };


    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.callSponserGetApi();
    this.callAboutUsGetApi();
    this.callTermsAndConditonsGetApi();
    // Customizable Area End
  }

  // Customizable Area Start

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };


  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputPasswordProps.secureTextEntry = !this.state.enableField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  // changing the user type

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enableField: !this.state.enableField,
    });
  };

  handleBlockedUserModal = () => {
    this.setState({ blockedUserModal: !this.state.blockedUserModal });
  };
  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  handleChangeUserType = (value: string) => {
    this.setState({ selectUserType: value });
  };
  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
  const userName = message.getData(getName(MessageEnum.LoginUserName));

  const password = message.getData(getName(MessageEnum.LoginPassword));

  const countryCode = message.getData(
    getName(MessageEnum.LoginCountryCode)
  );

  if (!countryCode && userName && password) {
    this.setState({
      email: userName,
      password: password,
      checkedRememberMe: true,
    });

    //@ts-ignore
    this.txtInputEmailProps.value = userName;

    //@ts-ignore
    this.txtInputPasswordProps.value = password;

    this.CustomCheckBoxProps.isChecked = true;
  }
} else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );

  let responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  let errorReponse = message.getData(
    getName(MessageEnum.RestAPIResponceErrorMessage)
  );
  this.checkResponseApiCallId({
    apiRequestCallId,
    responseJson,
    errorReponse,
    apiEmailLoginCallId: this.apiEmailLoginCallId,
    validationApiCallId: this.validationApiCallId,
    sponsorsApiCallId: this.sponsorsApiCallId,
    aboutUsApiCallId: this.aboutUsApiCallId,
    termsAndPolicesApiCallId: this.termsAndPolicesApiCallId
});
}
  }

  checkResponseApiCallId({
    apiRequestCallId,
    responseJson,
    apiEmailLoginCallId,
    validationApiCallId,
    errorReponse,
    sponsorsApiCallId,
    aboutUsApiCallId,
    termsAndPolicesApiCallId,
  }: any) {
    if (apiRequestCallId !== null) {
      this.checkApiCallId(
        apiRequestCallId,
        this.validationApiCallId,
        responseJson
      );
      this.checkLoginApiCallId(apiRequestCallId, responseJson, errorReponse)
      this.checkLoginResponse(responseJson, apiRequestCallId)
    }
  }
  checkResponseId(response: any, requestCallId: any) {
    if (response) {
      if (requestCallId === this.sponsorsApiCallId) {
        this.setState({ sponsersData: response });
      } else if (requestCallId === this.aboutUsApiCallId) {
        this.setState({ aboutUsData: response });
      } else if (requestCallId === this.termsAndPolicesApiCallId) {
        this.setState({ termsAndPolices: response });
      }
    }
  }

  checkAccountType(responseJson:any) {
    if(responseJson.account_type === "student_account"){
      this.props.navigation.navigate("RegisterStudentDetails")
    }
    if(responseJson.account_type === "organisation_account"){
      this.props.navigation.navigate("EmailAccountOrganisationDetails")
    }
  }

  checkApiCallId(
    apiRequestCallId: any,
    validationApiCallId: any,
    responseJson: any
  ) {
    if (apiRequestCallId === validationApiCallId) {
      this.checkResponse(responseJson);
    }
  }
  checkResponse(responseJson: any) {
    if (responseJson !== undefined) {
      let arrayholder = responseJson?.data;

  this.checkArrayLength(arrayholder);
}
  }

  checkArrayLength(arrayholder: any) {
    if (arrayholder?.length !== 0) {
      let regexData = arrayholder[0];

  this.checkRegex(regexData);
}
  }

  checkRegex(regexData: any) {
    if (regexData?.email_validation_regexp) {
      this.emailReg = new RegExp(regexData.email_validation_regexp);
    }
  }

  checkLoginApiCallId(apiRequestCallId:any, responseJson:any, errorReponse:any) {
    if (apiRequestCallId === this.apiEmailLoginCallId) {
      if (this.checkCondition(responseJson)) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveLoggedInUserData(responseJson);
        this.setState({ isLoading: false, accountError: "" });
        const userDetails=responseJson;
        localStorage.setItem('user_details',JSON.stringify({account_id:userDetails?.meta.account_id,account_type:userDetails?.meta?.account_type,first_name:userDetails?.meta?.first_name,last_name:userDetails?.meta?.last_name,profile_image:userDetails?.meta?.profile_image,job_title:userDetails?.meta?.job_title,name_of_organization:userDetails?.meta?.name_of_organization}))
        localStorage.removeItem("userType")
        this.props.navigation.navigate("FanWall");
      } else {
        this.setState({ isLoading: false })
        this.checkCondition2(responseJson)
        this.checkCondition3(responseJson)
        this.checkCondition4(responseJson)
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  checkCondition(responseJson:any){
    if(responseJson && responseJson.meta && responseJson.meta.token){
      return true
    }
  }

  checkCondition2(responseJson:any){
    if (responseJson?.account && responseJson?.account.length > 0) {
      this.setState({ accountError: responseJson.account })
      return false
    }
  }
  checkCondition3(responseJson:any){
    if (responseJson?.errors && responseJson?.errors.length > 0 && responseJson?.errors[0]?.failed_login) {
      this.setState({ accountError: responseJson.errors[0].failed_login })
      return false
    }
  }
  checkCondition4(responseJson:any){
    if (responseJson.message === "You have to fill your profile details") {
      localStorage.setItem("userEmail", responseJson.email)
      localStorage.setItem("userFirstName", responseJson.first_name)
      localStorage.setItem("authToken", responseJson.token)
      this.checkAccountType(responseJson);
    }
  }

  checkLoginResponse(responseJson:any, apiRequestCallId:any) {
    if (responseJson) {
      this.checkResponseId(responseJson, apiRequestCallId);
      this.checkResponseId(responseJson, apiRequestCallId);
      this.checkResponseId(responseJson, apiRequestCallId);
    }
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }


  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(values:any) {
    this.setState({isLoading: true})
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const data = {
      type: this.state.selectUserType,
      attributes: {...values},
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentLoginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.studentLoginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  // get api is calling here........
  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  callSponserGetApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sponsorsApiCallId = getValidationsMsg.messageId;
    console.log(this.sponsorsApiCallId, "sponsors api call id");
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sponsorsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  callAboutUsGetApi() {
    const headers = {
      "Content-Type": configJSON.aboutApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.aboutUsApiCallId = getValidationsMsg.messageId;
    console.log(this.aboutUsApiCallId, "aboutUsapiCallId");
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.aboutUsEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  callTermsAndConditonsGetApi() {
    const headers = {
      "Content-Type": configJSON.TermAndConditonsContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsAndPolicesApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TermAndConditonsEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  checkUserType = () => {
    localStorage.setItem('userType', this.state.selectUserType === "student_account" ? 'student' : 'organization')
  }
}

  // Customizable Area End
