// Customizable Area Start
import React from 'react'
import { Box } from "@material-ui/core";
const scene = require("../src/scene.png");
const userAccImg=require("../src/userAccImg.png")

interface LeftProfileProps{
    userAccountObj:any
}

function LeftProfile(props:LeftProfileProps) {
    const {first_name,last_name,photo,profile_image,job_title,name_of_organisation, connectionCount, followingCount}=props?.userAccountObj
    return (
        <Box sx={{ bgcolor: 'background.paper',width:'290px', borderRadius: '10px',border:'1px solid #F0F0F0',position: 'fixed', }}>
            <div style={{ position: 'relative' }}>
                <img src={photo?photo:scene} style={webStyles.backImg} width="290px" height="55px" />
                <div style={{ position: 'absolute', left: '145px', top: '60px', transform: 'translate(-50%,-50%)' }} >
                    <img src={profile_image?profile_image:userAccImg} style={webStyles.profileImage} width="74px" height="74px" />
                </div>
            </div>
            <div style={{textAlign: 'center',marginTop:'60px',borderBottom:'0.5px solid #F0F0F0' }}>
                <div>{first_name} {last_name}</div>
                <div style={{color:'#7C7C7C',margin:'10px 5px 15px 5px'}}>{job_title} at {name_of_organisation}</div>
            </div>

            <div>
                <div style={webStyles.connection}>
                    <div style={webStyles.textCommonStyle}>Connection</div>
                    <div  style={webStyles.count}>{connectionCount}</div>

                </div>

                <div style={webStyles.following}>
                    <div style={webStyles.textCommonStyle}>Following</div>
                    <div style={webStyles.count}>{followingCount}</div>

                </div>

            </div>
        </Box>
    )
}

const webStyles = {
    backImg: {
        borderRadius: "10px 10px 0px 0px"

    },
    profileImage:{
        borderRadius: "100%"
    },
    connection:{
        display:'flex',
        justifyContent:'space-between',
        padding:'15px 25px 5px 25px'
    },
    following:{
        display:'flex',
        justifyContent:'space-between',
        padding:'15px 25px 15px 25px'
    },
    textCommonStyle:{
        color:'#7C7C7C',
        fontSize:'12px',
        fontFamily: "HelveticaNeue"
    },
    count:{
        color:'#BE9E44',
        fontSize:'12px'
    }
    
}

export default LeftProfile
// Customizable Area End