import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Paper,
  Button,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import {  edit, medal, plus, dummy } from "./assets";

// Customizable Area End

import AddCoverImgModal from "../../../components/src/AddCoverImgModal.web";
import AddProfileImgModal from "../../../components/src/AddProfileImgModal.web";
import RequestAccessViewModel from "../../../components/src/RequestAccessViewModel.web";
import ShareModel from "../../../components/src/ShareModel.web";
import GuestProfileTopWebController,{ Props } from "./GuestProfileTopController.web";

export default class GuestProfileTopWeb extends GuestProfileTopWebController{

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
    // Customizable Area Start
    educationDetailsButton =(educationDetailsButton:any)=>{
        return educationDetailsButton.length > 0 && (
          <Button
            variant="outlined"
            color="primary"
            style={{
              height: "32px",
              fontSize: "16px",
              textTransform: "none",
              borderRadius: "8px",
              marginLeft: 10,
            }}
            data-test-id="share-image"
            onClick={() => {
              this.setState({ isModalOpenShare: true });
            }}
          >
            Share
          </Button>
        )
      }
    
    // Customizable Area End

  render() {
    // Customizable Area Start
   
    const photo =
    this.props.studentProfileDetails?.profile?.data?.attributes?.photo ?? dummy;
    const profile_image =
      this.props.studentProfileDetails?.profile?.data?.attributes?.profile_image ?? dummy;
    const first_name =
      this.props.studentProfileDetails?.account?.data?.attributes?.first_name;
    const last_name =
      this.props.studentProfileDetails?.account?.data?.attributes?.last_name;
    const city = this.props.studentProfileDetails?.profile?.data?.attributes?.city;
    const country = this.props.studentProfileDetails?.profile?.data?.attributes?.country;
  
   
    
    return (
      <>        <CustomPaper style={webStyle.userDetails}>
                <Box style={webStyle.coverImage}>
                  <img
                    src={photo}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="cover image"
                  />
                  <img
                    // src={profileImage ? profileImage : dummy}
                    src={profile_image}
                    style={webStyle.profileImage}
                  />
                  <AddCoverImgModal
                    open={this.state.isModalOpenCover}
                    handleClose={this.handleClose}
                    // handleSaveImgBackground={this.patchProfileBackGroundDetails}
                  />
                  <img
                    src={edit}
                    data-test-id="btnAddCover"
                    style={webStyle.coverImageEditIcon}
                    onClick={() => {
                      this.setState({
                        isModalOpenCover: true,
                      });
                    }}
                  />
                </Box>
                <Box style={webStyle.userDetail}>
                  <Box>
                    <Box>
                      <Typography style={webStyle.stuName}>
                        {first_name} {last_name}
                      </Typography>
                      <Typography style={webStyle.stuAddress}>
                        Student at abcd public college
                      </Typography>
                      <Typography style={webStyle.stuHours}>
                        {city} ,{country}
                      </Typography>
                      <Typography style={webStyle.stuFollowers}>
                        0 Connections
                      </Typography>
                    </Box>

                    <Button
                      variant="contained"
                      color="primary"
                      data-test-id="button-profile"
                      style={{
                        height: "32px",
                        fontSize: "16px",
                        textTransform: "none",
                        borderRadius: "8px",
                        marginRight: "10px",
                      }}
                    >
                      Add profile section
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      data-test-id="button-activity"
                      style={{
                        height: "32px",
                        fontSize: "16px",
                        textTransform: "none",
                        borderRadius: "8px",
                      }}
                    >
                      My activity
                    </Button>
                  </Box>
                  <Box>
                    <img src={medal} style={webStyle.stuImage} />
                  </Box>
                  <AddProfileImgModal
                    open={this.state.isModalOpenProfile}
                    handleClose={this.handleClose}
                    handleSaveImg={this.patchProfileDetails}
                  />
                  <img
                    src={edit}
                    style={webStyle.userDetailsEditIcon}
                    data-test-id="edit-image"
                    onClick={() => {
                      this.setState({
                        isModalOpenProfile: true,
                      });
                    }}
                  />
                </Box>
              </CustomPaper> 
              <CustomPaper style={webStyle.userDetails}>
                <Box style={webStyle.profileAbout}>
                  <Typography style={webStyle.aboutHead} data-test-id="About">
                    About
                  </Typography>
                  <img src={plus} style={webStyle.plus} />
                </Box>
              </CustomPaper>
              <Box style={webStyle.portfolio}>
                <Typography
                  style={webStyle.portfolioHead}
                  data-test-id="portfolio"
                >
                  Ed portfolio
                </Typography>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    data-test-id="button-view"
                    style={{
                      height: "32px",
                      width: "250px",
                      fontSize: "16px",
                      textTransform: "none",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      this.setState({ isModalOpenView: true });
                    }}
                  >
                    Request access to view
                  </Button>
                  <RequestAccessViewModel
                    open={this.state.isModalOpenView}
                    handleClose={this.handleClose}
                    educationDetails={this.props.educationDetails}
                    certificationDetails={this.props.certificationDetails}
                    workExperienceDetails={this.props.workExperienceDetails}
                    studentProfileDetails={this.props.studentProfileDetails}
                  />
                  {this.educationDetailsButton(this.props.educationDetails)}
                  <ShareModel
                    copyText={this.state.copyText}
                    open={this.state.isModalOpenShare}
                    handleClose={this.handleClose}
                  />
                </Box>
              </Box>
              </>     
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
  userDetails: {
    padding: 0,
  },
  coverImage: {
    height: "195px",
    position: "relative",
    background: "lightblue",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  profileImage: {
    width: "154px",
    height: "154px",
    position: "absolute",
    bottom: "-34px",
    left: "23px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  coverImageEditIcon: {
    width: "32px",
    height: "32px",
    position: "absolute",
    right: "23px",
    top: "12px",
    cursor: "pointer",
  },
  userDetail: {
    display: "flex",
    justifyContent: "space-between",
    padding: "55px 80px 26px 24px",
    position: "relative",
  },
  userDetailsEditIcon: {
    width: "32px",
    height: "32px",
    position: "absolute",
    right: "23px",
    top: "12px",
    cursor: "pointer",
  },
  stuImage: {
    width: "40px",
    height: "40px",
  },
  stuName: {
    fontSize: "24px",
    color: "#1C1C1C",
  },
  stuAddress: {
    fontSize: "17px",
    color: "#252525",
  },
  stuHours: {
    fontSize: "17px",
    color: "#7C7C7C",
  },
  stuFollowers: {
    fontSize: "17px",
    color: "#ab9045",
  },
  photo: {
    width: "123px",
    height: "65px",
  },
  awardPhoto: {
    width: "123px",
    height: "60px",
    borderRadius: "5px",
  },
  profileAbout: {
    height: "72px",
    padding: "0px 24px 0px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  aboutHead: {
    fontSize: "20px",
    color: "#1C1C1C",
  },
  plus: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },
  portfolio: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "40px 0px 30px 0px",
  },
  portfolioHead: {
    fontSize: "24px",
    color: "#1C1C1C",
  },
};

const CustomPaper: any = withStyles({
  root: {
    boxShadow: "unset",
    margin: "15px 0",
    border: "1px solid #e2e2e2",
    padding: "15px",
    borderRadius: "10px",
  },
})(Paper);

// Customizable Area End
