// Customizable Area Start
import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Menu, Tooltip } from "@material-ui/core";
import axios from 'axios';
import { close } from '../../blocks/postcreation/src/assets';
import ReactGiphySearchbox from 'react-giphy-searchbox'
import PostsCommentComponent from './PostsCommentComponent.web';
import InputBoxForComment from './InputBoxForComment.web';
import { isLoggedIn } from './common';
import { NavLink, withRouter } from "react-router-dom";
const { baseURL: apiBaseUrl } = require('../../framework/src/config.js');


const moreOptions = require("../src/moreOptions.png");
const history = require("../src/history.png");
const like = require("../src/likeReaction.png");
const likes = require("../src/likes.png");
const report = require("../src/report.png");
const commentLogo = require("../src/comments.png");
const share = require("../src/sharePost.png");
const smilie = require("../src/smilie.png");
const funny = require("../src/emoji.png");
const love = require("../src/love.png");
const support1 = require("../src/support1.png");
const support = require("../src/support.png");
const filledLike = require("../src/filledLike.png");
const filledHeart = require("../src/filledHeart.png");
const userAccImg = require("../src/userAccImg.png");


interface Props {
    post: any,
    navigation: any,
    setAllAccounts: any,
    setPostId: any,
    setReactionModal: any,
    renderPostIcon: any,
    renderPostIconText: any,
    setComments: any,
    commentsAPi: any,
    setReportModal: any,
    setCommentId: any,
    comments: any,
    createCommentLike: any
    destroyLike: any
    setCommentIdForReaction: any
    getAllReactionAccountsComments: any
    totalCommentsForPost: any
    setCommentOfCommentIdForReaction: any
    history: any


}

function SinglePost(props: Props) {
    const { post,
        setAllAccounts,
        setPostId,
        setReactionModal,
        renderPostIcon,
        renderPostIconText,
        setComments,
        commentsAPi,
        setReportModal,
        setCommentId,
        createCommentLike,
        destroyLike,
        setCommentIdForReaction,
        getAllReactionAccountsComments,
        setCommentOfCommentIdForReaction
    } = props;

    const reactionTypeToIconMap: any = {
        Like: { icon: like, text: 'Like' },
        Love: { icon: love, text: 'Love' },
        Support: { icon: support, text: 'Support' },
        Funny: { icon: funny, text: 'Funny' }
    };
    const [openReactionBox, setReactionBox] = useState(false);
    const [commentTxt, setCommentTxt] = useState<any>('');
    const [selectedGif, setSelectedGif] = useState<any>('');
    const [enablePostButton, setEnablePostButton] = useState<any>(false);
    const [openGif, setOPenGif] = useState<any>(false)
    const [gifElement, setGifElement] = useState<any>(null);
    const [openPostsCommentBox, setPostsCommentBox] = useState(false);
    const [dynamicReactionId, setDynamicReactionId] = useState({
        icon: '',
        text: ''
    })
    const [postLikeCount, setPostLikeCount] = useState(post?.likes_count_for_post?.total_likes);
    const spanRef = useRef(null);

    const [likedBy, setLikedBy] = useState(post?.liked_by_account_ids);
    const [likesCountForPost, setLikesCountForPost] = useState({ ...post?.likes_count_for_post });

    const [reactionSelectedByUser, setReactionByUser] = useState('');
    const [arr1, setAllArr] = useState<any>([])
    const [someError, setSomeError] = useState(false)
    const [showMore, setShowMore] = useState(false)



    useEffect(() => {

        getAllReactionAccounts(post?.id)

    }, [])

    const convertToHours = (timeStamp: any) => {
        const date = new Date(timeStamp);
        const currentTime = new Date();
        const diffInMillis = currentTime.getTime() - date.getTime();
        const diffInMinutes = Math.round(diffInMillis / (1000 * 60));
        const diffInHours = Math.round(diffInMillis / (1000 * 60 * 60));
        return `${diffInHours == 0 ? diffInMinutes == 0 ? 'now' : `${diffInMinutes}m` : `${diffInHours > 24 ? `${Math.floor(diffInHours / 24)}d` : `${diffInHours}h`}`}`
    }
    const checkDes=(str:string)=>{
        
        if(str.startsWith('https')){
            return `<p><a href="${str}">${str}</a></p>`;
        }else if(str.startsWith("#")){
            return `<p><span  id=${str} style="color: #BE9E44;cursor: pointer;fontWeight: bold;">${str}</span></p>`;
        }else{
            `<p>${str}</p>`
        }
    }

    const highlightHashTag = (description: any) => {
        const res=description.split("</p>")
        let arr=""
        for(let i=0;i<res.length;i++){
            const succ=res[i].replace('<p>',"");
            if(succ.startsWith("http") || succ.startsWith('www.')){
                arr+=`<p><a href="${succ}">${succ}</a></p>`
            }else if(succ.startsWith("#")){
                arr+=`<p><span  id=${succ} style="color: #BE9E44;cursor: pointer;fontWeight: bold;">${succ}</span></p>`;
            }else{
                arr+=`<p>${succ}</p>`
            }
            
        }
        
        for(let i=0;i<arr.length;i++){
            // let modifiedDes = arr[i].replace(
            //     /(#\w+)|(https?:\/\/[^\s]+)/g,
            //     (match: any, hashtag: any, url: any) => {
            //         if (hashtag) {
            //             return `<span  id=${match} style="color: #BE9E44;cursor: pointer;fontWeight: bold;">${match}</span>`;
            //         } else if (url) {
            //             return `<a href="${match}">${match}</a>`;
            //         }
            //         return match;
            //     }
            // );
            let modifiedDes=checkDes(arr[i])
           

        }
        if(description==='<p>https://www.yahoo.com/</p><p><br></p><p>Yahoo!!</p><p><br></p><p>#yahoo</p>'){
            console.log(console.log(arr))
           }
           
            return <span
            onClick={(e: any) => {
                checkForHash(e, arr)

            }}
            style={{ fontSize: '14px', color: '#1C1C1C' }} dangerouslySetInnerHTML={{ __html: arr }}></span>;
        return <span></span>
       
        
        



    }
    const checkForHash = (e: any, modifiedDes: any) => {


        const parser = new DOMParser();
        const doc = parser.parseFromString(modifiedDes, 'text/html');

        const spanElement = doc.querySelectorAll('span');


        spanElement.forEach((element) => {
            const id = element.getAttribute('id');
            if (id && e.target.id === id) {
                localStorage.setItem('selectedHashTag', id);
                props?.history.push(`/hashtag/${id.substring(1)}`);

            }
        });
    }

    const createPostLike = (id: any, type: any) => {

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };
        const formData = new FormData();
        formData.append('post_id', id);
        formData.append('reaction_type', type)

        axios.post(`${apiBaseUrl}/bx_block_like/likes`, formData, {
            headers: header
        })
            .then((response: { data: any; }) => {

                getAllReactionAccounts(post?.id)


            })
            .catch(error => {
                console.log(error);
            });
    }
    const destroyLikeForPost = async (id: any) => {

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };


        try {

            let response = await axios.delete(`${apiBaseUrl}/bx_block_like/likes/${id}?likeable_id=${id}`, {
                headers: header
            })
            console.log(response)
            getAllReactionAccounts(post?.id)

        }
        catch (error) {

            console.error(error);
        }

    }
    const getAllReactionAccounts = (id: any) => {
        const header = {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };



        axios.get(`${apiBaseUrl}/bx_block_like/show_accounts_for_reactions_to_post?post_id=${id}`, {
            headers: header
        })
            .then((response: { data: any; }) => {
                setAllAccounts(response?.data?.total_accounts);
                setAllArr(
                    response?.data?.total_accounts?.map((item: any) => ({
                        ...item,
                        ...reactionTypeToIconMap[item?.reaction_type]
                    }))
                );


                response?.data?.total_accounts?.find((acc: any) => {
                    if (acc?.id == JSON.parse(localStorage.getItem('account_id') || '')) {

                        setReactionByUser(acc?.reaction_type)

                    }

                })

            })
            .catch(error => {
                setSomeError(true)
                console.log(error);
            });

    }
    const createComment = async (id: any) => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };
        const formData = new FormData();
        formData.append('content', commentTxt);
        formData.append('post_id', id);
        formData.append('gif', selectedGif)

        try {

            let response = await axios.post(`${apiBaseUrl}/bx_block_comments/comments`, formData, {
                headers: header
            })
            commentsAPi(id);
            setCommentTxt('');
            setSelectedGif('');


        }
        catch (error) {

            console.error(error);
        }
    }
    const handleDynamicReactionBox = (icon: any, text: any, objText: any, icon1: any) => {
        setReactionBox(false)
        likedBy?.includes(JSON.parse(localStorage.getItem('account_id') || '')) ?
            (
                setPostLikeCount(postLikeCount)
            ) :
            (

                setLikesCountForPost({ ...likesCountForPost, objText: likesCountForPost[objText] + 1 }),
                setLikedBy(likedBy.concat(JSON.parse(localStorage.getItem('account_id') || ''))),
                setPostLikeCount(postLikeCount + 1)
            );

        const objectExists = arr1.some((obj: { id: any, reaction_type: any }) => obj?.id === JSON.parse(localStorage.getItem('account_id') || ''));
        const newArray = objectExists
            ? arr1.map((item: any) => item?.id === JSON.parse(localStorage.getItem('account_id') || '') ? { ...item, icon: icon1, text } : item)
            : [...arr1, { id: JSON.parse(localStorage.getItem('account_id') || ''), icon: icon1, text }];

        setAllArr(newArray);

        createPostLike(post?.id, text);
        setDynamicReactionId({ ...dynamicReactionId, icon, text });

    }


    return (
        <React.Fragment>
            {props.post.my_activity_message !== null && <><div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <span style={{ color: '#1c1c1c' }}>{props.post.my_activity_message.split(" ").slice(0, -2)}</span>&nbsp;
                    <span style={{ color: '#7c7c7c' }}>{props.post.my_activity_message.split(" ").slice(-2).join(" ")}</span>
                </div>
                <div>
                    <img src={moreOptions}
                        style={{ transform: 'rotate(90deg)', }} width='24px'
                    />
                </div>
            </div>
                <hr style={{ borderTop: "0.03rem solid #d7d7d7" }} /></>}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '15px' }}>
                        <NavLink onClick={()=>localStorage.setItem('profile_id',post?.profile?.data?.attributes?.id)} to={!isLoggedIn(null) ? `/user-details/${post?.profile?.data?.attributes?.id}` :post?.post_account_type=='StudentAccount'? `/post-profile/${post?.profile?.data?.attributes?.id}`:`/AcademicOrganisationProfile/${post?.account?.id}`}>
                            <img src={post?.profile?.data?.attributes?.profile_image ?? userAccImg} style={{ border: '1px solid #D6D3D3', borderRadius: '50%' }} width='48px' height='48px' />
                        </NavLink>
                    </div>
                    <div>
                        <NavLink  onClick={()=>localStorage.setItem('profile_id',post?.profile?.data?.attributes?.id)} style={{ textDecoration: "none" }} to={!isLoggedIn(null) ? `/user-details/${post?.profile?.data?.attributes?.id}` :post?.post_account_type=='StudentAccount'? `/post-profile/${post?.profile?.data?.attributes?.id}`:`/AcademicOrganisationProfile/${post?.account?.id}`}><div style={{ color: "#1C1C1C",fontWeight: 'bold', cursor: 'pointer', fontFamily: "HelveticaNeue" }}>{post?.account?.first_name} {post?.account?.last_name ?? ''}</div></NavLink>
                        <div style={{ fontSize: '14px', color: '#565656', margin: '10px 0px 10px 0px', fontFamily: "HelveticaNeue" }}>{post?.profile?.data?.attributes?.name_of_organisation ?? ''} </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontSize: '14px', color: '#565656', fontFamily: "HelveticaNeue" }}>{convertToHours(post?.updated_at)}. </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}><img src={history} width='14px' /></div>
                        </div>
                    </div>
                </div>
                {props.post.my_activity_message === null && isLoggedIn(null) && <div>
                    <img src={moreOptions}

                        style={{ transform: 'rotate(90deg)', }} width='24px'

                    />

                </div>}

            </div>
            {/* description */}
            <div style={{ margin: "15px 0px" }}>
                <div style={webStyles.feedsFont}>

                    {showMore ? highlightHashTag(post?.description) : highlightHashTag(post?.description?.slice(0, 340))}
                    {post?.description?.length > 350 && <div onClick={() => setShowMore(!showMore)} style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', color: '#666666' }}>
                        {showMore ? '...see less' : '...see more'}

                    </div>}

                </div>

            </div>

            {/* post image */}
            {post?.images_and_videos.length > 0 && <div style={{ margin: '10px 0px' }}>
                <img src={post?.images_and_videos[0]?.url} style={{ width: '100%', borderRadius: '10px', border: '1px solid #F0F0F0' }} alt={post?.images_and_videos[0]?.filename} />
            </div>}


            {/* react count and no of comments section */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* {dynamicReactionId.icon === '' ? <> */}
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>

                        {arr1?.reduce((uniqueArr: any[], current: any) => {
                            if (!uniqueArr.find((item) => item.icon === current.icon)) {
                                uniqueArr.push(current);
                            }
                            return uniqueArr;
                        }, []).map((icon: any, index: any) => (
                            <img key={index} src={icon.icon} width="20px" style={{ marginLeft: index > 0 ? "-4px" : "0px" }} />
                        ))}


                    </div>
                    {postLikeCount > 0 && <div className='reactionCount'
                        onClick={() => {
                            setPostId(post?.id)
                            getAllReactionAccounts(post?.id)
                            setReactionModal(true)
                        }}
                        style={webStyles.feedsFont}>{postLikeCount}</div>}



                </div>
                {(post?.replies_count > 0 || post?.commentCount > 0) && <div className='reactionCount' style={isLoggedIn(null)?webStyles.feedsFont:{...webStyles.feedsFont,color:'#cccccc',cursor:'none'}}
                    onClick={() => {
                        if(isLoggedIn(null)){
                            setPostsCommentBox(true)
                            setComments([]);
                            commentsAPi(post?.id)
                        }
                        
                    }}
                >{post?.commentCount == 0 ? post?.replies_count : post?.commentCount} Comments</div>}
            </div>

            <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />

            {/* post footer */}
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-around' }}>
                {/* reaction box */}
                {openReactionBox && isLoggedIn(null) && <div
                    onMouseEnter={() => setReactionBox(true)}
                    onMouseLeave={() => setReactionBox(false)}
                    style={webStyles?.reactionBox}>
                    <div style={{ display: 'flex', justifyContent: 'space-around', padding: '5px' }}>
                        <div className='reactionHover' onClick={() => {
                            handleDynamicReactionBox(filledLike, 'Like', 'likes', like);
                        }} >
                            <Tooltip title="Like" placement='top'><img src={filledLike} width="24px" alt="likes" /></Tooltip>
                        </div>
                        <div className='reactionHover' onClick={() => {
                            handleDynamicReactionBox(support1, 'Support', 'support', support);
                        }} >
                            <Tooltip title="Support" placement='top'><img src={support1} width="24px" alt="support" /></Tooltip>
                        </div>
                        <div className='reactionHover' onClick={() => {
                            handleDynamicReactionBox(filledHeart, 'Love', 'love', love);
                        }} >
                            <Tooltip title="Love" placement='top'><img src={filledHeart} width="24px" alt="heart" /></Tooltip>
                        </div>
                        <div className='reactionHover' onClick={() => {
                            handleDynamicReactionBox(smilie, 'Funny', 'funny', funny)
                        }}>
                            <Tooltip title="Laugh" placement='top'><img src={smilie} width="24px" alt="smilie" /></Tooltip>
                        </div>
                    </div>
                </div>}
                <div className='likeButton' onMouseEnter={() => setReactionBox(true)}
                    onMouseLeave={() => setReactionBox(false)}
                    style={isLoggedIn(null)? webStyles.actionLIne:{...webStyles.actionLIne,cursor:'none'}}>
                    {dynamicReactionId.icon === '' ? <>

                        <div>
                            <img src={likedBy?.includes(JSON.parse(localStorage.getItem('account_id') || '')) ? renderPostIcon(post?.likes_count_for_post, reactionSelectedByUser) : likes} width='20px' />
                        </div>
                        <div style={!isLoggedIn(null)?{...webStyles.actionText,color:'#cccccc'}:webStyles.actionText}>

                            {likedBy?.includes(JSON.parse(localStorage.getItem('account_id') || '')) ? <span
                                onClick={() => {
                                    if (isLoggedIn(null)) {


                                        setReactionBox(false)
                                        setLikedBy(likedBy?.filter((item: any) => {
                                            return item !== JSON.parse(localStorage.getItem('account_id') || '')
                                        }));
                                        setAllArr(arr1?.filter((item: any) => {
                                            return item?.id !== JSON.parse(localStorage.getItem('account_id') || '')
                                        }));

                                        setPostLikeCount(postLikeCount - 1);
                                        destroyLikeForPost(post?.id);
                                        setDynamicReactionId({ ...dynamicReactionId, icon: '', text: '' })
                                    }
                                }}
                                style={{ color: '#BE9E44' }}>{renderPostIconText(post?.likes_count_for_post, reactionSelectedByUser)}

                            </span> :
                                <span onClick={() => {
                                    if (isLoggedIn(null)) {


                                        setReactionBox(false)
                                        setLikedBy(likedBy.concat(JSON.parse(localStorage.getItem('account_id') || '')));
                                        setAllArr([...arr1, { id: JSON.parse(localStorage.getItem('account_id') || ''), icon: like, text: 'Like' }])
                                        setPostLikeCount(postLikeCount + 1);
                                        setDynamicReactionId({ ...dynamicReactionId, icon: filledLike, text: 'Like' });
                                        createPostLike(post?.id, 'Like')
                                    }
                                }}>Like</span>}
                        </div>

                    </> : <>
                        <div>
                            <img src={dynamicReactionId?.icon} width='20px' />
                        </div>
                        <div style={!isLoggedIn(null)?{...webStyles.actionText,color:'#cccccc'}:webStyles.actionText}>
                            {dynamicReactionId?.text == '' ? <span onClick={() => {

                                createPostLike(post?.id, 'Like')
                            }}>Like</span> : <span
                                onClick={() => {
                                    if (isLoggedIn(null)) {
                                        console.log('dislike 2');
                                        setReactionBox(false)
                                        setLikedBy(likedBy?.filter((item: any) => {
                                            return item !== JSON.parse(localStorage.getItem('account_id') || '')
                                        }));

                                        setAllArr(arr1?.filter((item: any) => {
                                            return item?.id !== JSON.parse(localStorage.getItem('account_id') || '')
                                        }));
                                        setPostLikeCount(postLikeCount - 1);
                                        destroyLikeForPost(post?.id);
                                        setDynamicReactionId({ ...dynamicReactionId, icon: '', text: '' })
                                    }
                                }}
                                style={{ color: '#BE9E44' }}>{dynamicReactionId.text}</span>}
                        </div>
                    </>}


                </div>
                <div style={isLoggedIn(null)? webStyles.actionLIne:{...webStyles.actionLIne,cursor:'none'}}>
                    <div><img src={commentLogo} width='20px' /></div>
                    <div onClick={() => {
                        if (isLoggedIn(null)) {

                            setPostsCommentBox(true)
                            setComments([]);
                            commentsAPi(post?.id)
                        }

                    }} style={!isLoggedIn(null)?{...webStyles.actionText,color:'#cccccc'}:webStyles.actionText}>Comment</div>
                </div>
                <div style={isLoggedIn(null)? webStyles.actionLIne:{...webStyles.actionLIne,cursor:'none'}}>
                    <div><img src={share} width='20px' /></div>
                    <div style={!isLoggedIn(null)?{...webStyles.actionText,color:'#cccccc'}:webStyles.actionText}>Share Post</div>
                </div>
                <div
                    onClick={() => {
                        if (isLoggedIn(null)) {

                            setReportModal(true);
                            setPostId(post?.id);
                            setCommentId('')
                        }
                    }}
                    style={isLoggedIn(null)? webStyles.actionLIne:{...webStyles.actionLIne,cursor:'none'}}>
                    <div><img src={report} width='20px' /></div>
                    <div style={!isLoggedIn(null)?{...webStyles.actionText,color:'#cccccc'}:webStyles.actionText}>Report</div>
                </div>

            </div>
            {openPostsCommentBox && <div style={{ marginTop: '20px' }}>
                <div style={{ display: 'flex', alignItems: selectedGif ? '' : 'center' }}>
                    <div style={{ marginRight: '10px' }}><img src={post?.profile?.data?.attributes?.profile_image ?? userAccImg} style={{ borderRadius: '100%' }} width="48px" height='48px' /></div>
                    <div style={{ position: 'relative', width: "100%" }}>

                        <InputBoxForComment
                            commentTxt={commentTxt}
                            setCommentTxt={setCommentTxt}
                            setEnablePostButton={setEnablePostButton}
                            setOPenGif={setOPenGif} setGifElement={setGifElement}
                        />

                        {selectedGif && <div style={{ width: '505px', height: 'auto', borderRadius: '0px 0px 10px 10px', backgroundColor: 'white', border: '1px solid #BDC1BD' }}>
                            <div style={{ padding: '15px', position: 'relative' }}>
                                <img src={selectedGif} width='200px' />
                                <div style={{ position: 'absolute', transform: 'translate(-50%,-50%)', top: '15px', left: '43%' }}>
                                    <IconButton onClick={() => setSelectedGif('')}>
                                        <img src={close} width='25px' />
                                    </IconButton>
                                </div>
                            </div>
                        </div>}
                        <Menu
                            id="basic-menu"
                            anchorEl={gifElement}
                            open={Boolean(gifElement)}
                            onClose={() => setGifElement(null)}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}

                        >
                            <div className='gifBox'>

                                <ReactGiphySearchbox
                                    apiKey="B52ugpLhetZWjv9U4xTlYfNXoZgdbLPR"
                                    onSelect={(item: any) => {
                                        setSelectedGif(item?.images?.original?.url);
                                        setOPenGif(false)
                                    }}
                                    onClose={() => setOPenGif(false)}
                                    masonryConfig={[
                                        { columns: 2, imageWidth: 110, gutter: 5 },
                                        { mq: "700px", columns: 3, imageWidth: 120, gutter: 5 }
                                    ]}
                                />
                            </div>
                        </Menu>


                    </div>

                </div>

                {/* POST COMMENT BUTTON */}
                {(enablePostButton || selectedGif) && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginRight: '10px' }}>
                    <div
                        onClick={() => createComment(post?.id)}
                        style={webStyles?.commentsPostButton}>
                        Post
                    </div>
                </div>}

                {/* comments lists */}
                <div style={{ marginTop: '20px' }}>
                    {post?.comments?.map((comment: any) => {
                        return <div key={comment?.id}>

                            <PostsCommentComponent
                                comment={comment}
                                setCommentId={setCommentId}
                                setPostId={setPostId}
                                post={post}
                                setReportModal={setReportModal}
                                createCommentLike={createCommentLike}
                                destroyLike={destroyLike}
                                setCommentIdForReaction={setCommentIdForReaction}
                                setReactionModal={setReactionModal}
                                getAllReactionAccountsComments={getAllReactionAccountsComments}
                                setCommentOfCommentIdForReaction={setCommentOfCommentIdForReaction}
                                commentsAPi={commentsAPi}
                            />

                        </div>
                    })}


                </div>


            </div>}
        </React.Fragment>
    )
}
const webStyles = {
    newPost: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sendBox: {
        width: '32px',
        height: '32px',
        backgroundColor: '#BE9E44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    heading: {
        fontSize: '12px',
        lineHeight: '14px',
        color: '#1C1C1C',
        padding: '5px 0px 5px 0px',
        fontFamily: "HelveticaNeue",


    }
    ,
    feedsFont: {
        fontSize: '14px',
        fontFamily: "HelveticaNeue",

    },
    actionLIne: {
        display: 'flex',
        margin: '5px 0px',
        cursor: 'pointer',
        alignItems: 'center'
    },
    actionText: {
        fontSize: '16px',
        color: '#696868',
        marginLeft: '10px',
        fontFamily: 'HelveticaNeue'
    },
    commentsPostButton: {
        backgroundColor: '#BE9E44',
        color: 'white',
        padding: '5px 15px',
        fontSize: '12px',
        borderRadius: '4px',
        fontFamily: "HelveticaNeue",
        cursor: 'pointer'
    },
    reactionBox: {
        position: 'absolute',
        transform: 'translate(-50%,-50%)',
        width: '176px',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.127841)',
        borderRadius: '6px',
        top: '-22px',
        left: '85px',
        border: '1px solid #F0F0F0'
    } as React.CSSProperties,
    reactionBoxComments: {
        position: 'absolute',
        transform: 'translate(-50%,-50%)',
        width: '176px',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.127841)',
        borderRadius: '6px',
        top: '-22px',
        left: '85px',
        border: '1px solid #F0F0F0'
    } as React.CSSProperties,
    reactionHover: {
        transform: 'scale(1.2)'
    },
    loadReply: {
        margin: '10px 0px 10px 60px',
        cursor: 'pointer',
        fontSize: '14px',
        fontFamily: "HelveticaNeue",
        boxItem: {
            display: 'flex',
            padding: '10px',
            alignItems: 'center',
            gap: '10px'
        },
        boxImg: {
            width: '15px',
            height: '15px',
        }
    }
}




export default withRouter(SinglePost)
// Customizable Area End