import React from "react";
import { Box, Grid, Typography, MenuItem } from "@material-ui/core";
const styles:any = {
  sponcersitem: {
    background: "#f8f4ec",
    margin: "50px 0",
    width: "100%",
    padding: "0 5px",
  },
  sponcerLogo: {
    objectFit: "contain",
    marginRight: "10px",
  },
};
export const Sponsers = ({data}:any) => {

  return (
    <Box>
      <Grid style={styles.sponcersitem} container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography component="span">Sponsors -</Typography>
        </Grid>
        {data?.data.map((item:any, key:any) => (
          <Grid item xs={6} md={3} key={key} >
            <a href={item.attributes.company_url.replace(/<\/?p>/gi, "")} style={{ textDecoration:"none",color:"black" }} target="_blank">
            <MenuItem
              style={{
                height: "30px",
                alignSelf: "left",
                justifyContent: "left",
              }}
            >
              <img
                src={`${item.attributes.sponsor_logo}`}
                alt={item.attributes.company_name}
                height={20}
                width={20}
                style={styles.sponcerLogo}
              />
              <Typography component="span">{item.attributes.company_name.replace(/<\/?p>/gi, "")}</Typography>
            </MenuItem>
            </a>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
