// Customizable Area Start
import React from 'react'
import { userAccImg } from '../../ElasticSearch/src/assets'
import { logo } from './assets'
import UserProfileBasicController from './UserProfileBasicController'


export default class SettingsHeader extends UserProfileBasicController {
    render() {
       
        return (
            <div style={{ backgroundColor: 'white', boxShadow: '0px 10px 40px rgba(89, 120, 150, 0.06)' ,padding:'15px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{marginLeft:'100px',cursor:'pointer'}}><img src={logo} alt='edconnect logo' width={'60px'} height={'48px'} onClick={() => {this.props.navigation.navigate("FanWall")}}/></div>
                    <div style={{ display: 'flex',alignItems:'center',marginRight:'100px' }}>
                        <div onClick={()=>this.props?.navigation?.navigate('FanWall') } style={{ marginRight: '10px',cursor:'pointer',color:'#7C7C7C' }}>Back to EDConnect.com</div>
                        <div><img src={this.state?.profileDetailsObj?.profile_image?this.state?.profileDetailsObj?.profile_image:userAccImg} alt='profile' width='32px' height='32px' style={{borderRadius:'100%'}} /></div>
                    </div>
                </div>

            </div>
        )
    }
}
// Customizable Area End