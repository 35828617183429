import React, { useEffect } from "react";
import { Box, Grid, Toolbar, Typography, makeStyles, Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { edConnectLogo as EdConnectLogo } from "../../blocks/email-account-login/src/assets";
import { ContactPop } from "./ContactUsModal.web";
import { TermsConditionsPop } from "./TermsConditionsPop.web";
import { cursorTo } from "readline";
import { Link } from "react-router-dom";
import "../../web/src/App.css";
const configJSON = require("../../blocks/email-account-login/src/config");
const baseURL = require("../../framework/src/config.js");

const useStyles = makeStyles((theme) => ({
  footer: {
    height: "fit-content",
    padding: "50px 0 20px 0",
    backgroundColor: "#1c1c1c",
    marginTop: "98px",
    position: "relative",
  },
  logo: {
    width: "95px",
  },
  navMenu: {
    margin: "0 20px",
    textDecoration: "none",
    color: "#ffffff",
    fontSize: "16px",
    cursor: "pointer",
    fontWeight: "normal",
    alignItems: "center",
  },
  navMenuLogo: {
    margin: "0 20px 0 0",
    textDecoration: "none",
    color: "#ffffff",
    fontSize: "16px",
    cursor: "pointer",
    fontWeight: "normal",
    alignItems: "center",
    "@media (max-width: 540px)": {
      margin: "0 20px 15px 0",
    },
    "@media (max-width: 425px)": {
      margin: "0 0 15px 0",
    },
  },
  footerOptionsCont: {
    display: "flex",
    width: "100%",
    // flexWrap: "wrap",
    alignItems: "center",
    "@media (max-width: 540px)": {
      flexDirection: "column",
      alignItems: "start"
    },
    "@media (max-width: 425px)": {
      alignItems: "center"
    }
  },
  footerContent: {
    color: "#e4e1e1",
    fontSize: "12px",
    float: "right",
    display: "flex",
    alignItems: "flex-end",
    "@media (max-width: 816px)": {
      margin: "10px 0 0 12px"
    },
    "@media (max-width: 425px)": {
      justifyContent: "center"
    }
  },
  footerMenus: {
    "@media (max-width: 775px)": {
      // width: "50%",
    }
  },
  footerMenu: {
    width: "auto",
    "@media (max-width: 775px)": {
      width: "50%",
    },
    "@media (max-width: 540px)": {
      width: "100%",
      padding: "0 !important",
    },
    "@media (max-width: 425px)": {
      textAlign: "center",
    }
  },
  bottomLine: {
    width: "65%",
    borderBottom: "1px solid #68655d",
    marginTop: "50px",
    "@media (max-width: 816px)": {
      width: "45%"
    },
    "@media (max-width: 540px)": {
      margin: "10px 0 0"
    },
    "@media (max-width: 425px)": {
      width: "100%"
    }
  },
  copyrights: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 540px)": {
      flexDirection: "column-reverse"
    }
  }
}));

export default function AppFooter({termsPolicesData}:any) {
  const [termsPolicy, setTermsPolicy] = React.useState();
  const [pageRoute, setPageRoute] = React.useState("");
  //   contact modal
  const [openContactMod, setOpenContactMod] = React.useState(false);
  const handleOpenContactMod = () => setOpenContactMod(true);
  const handleCloseContactMod = () => setOpenContactMod(false);

  // terms and conditions modal
  const [openTermsMod, setOpenTermsMod] = React.useState(false);
  const handleTermsAndConditionsModal = () => {
    setOpenTermsMod(!openTermsMod);
  };
  // Privacy policy modal
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const handlePrivacyPolicyModal = () =>
    setOpenPrivacyPolicy(!openPrivacyPolicy);

  useEffect(() => {
    setPageRoute(window.location.pathname)
    fetch(baseURL.baseURL + configJSON?.TermAndConditonsEndPoint)
      .then((res: any) => {
        return res.json()
      })
      .then((response: any) => {
        setTermsPolicy(response)
      })
  }, []);

  const checkAuth = () => {
    let getToken:any = localStorage.getItem("authToken")
    if(getToken === null){
      return false
    }
    else{
      return true
    }
  }

  const classes = useStyles();
  return (
    <Box className={classes.footer}>
      <Container>
        <CustomGrid
          contaCustomGridiner
          alignItems="center"
          gap={10}
          className={classes.footerOptionsCont}
        >
          <NavLink to={checkAuth() ? pageRoute : "/Login"} className={classes.navMenuLogo} >
            <img className={classes.logo} src={EdConnectLogo} />
          </NavLink>

          <CustomGrid container spacing={2} className={classes.footerMenus}>
            <CustomGrid item className={classes.footerMenu}>
              <Typography className={classes.navMenu}>
                <Link
                  to={{ pathname: "/About" }}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  About us
                </Link>
              </Typography>
            </CustomGrid>
            <CustomGrid item className={classes.footerMenu}>
              <Typography onClick={handleOpenContactMod} className={classes.navMenu}>
                Contact us
              </Typography>
            </CustomGrid>
            <CustomGrid item className={classes.footerMenu}>
              <Typography className={classes.navMenu} onClick={handlePrivacyPolicyModal}>
                Privacy Policy
              </Typography>
            </CustomGrid>
            <CustomGrid item className={classes.footerMenu}>
              <Typography onClick={handleTermsAndConditionsModal} className={classes.navMenu}>
                Terms & Conditions
              </Typography>
            </CustomGrid>
          </CustomGrid>

          {/* modal for contact us  */}
          <ContactPop
            open={openContactMod}
            // handleOpen={handleOpenContactMod}
            handleClose={handleCloseContactMod}
          />
          {/* modal for Terms and conditions */}
          <TermsConditionsPop
            open={openTermsMod}
            handleModal={handleTermsAndConditionsModal}
            //@ts-ignore
            data={termsPolicy?.terms}
            title={"Terms & Conditions"}
          />
          <TermsConditionsPop
            open={openPrivacyPolicy}
            handleModal={handlePrivacyPolicyModal}
            //@ts-ignore
            data={termsPolicy?.poilicies}
            title={"Privacy Policy"}
          />
        </CustomGrid>
        <div
          className={classes.copyrights}
        >
          <div
            className={classes.bottomLine}
          >
          </div>

          <Typography className={classes.footerContent}>
            Copyright &#169;{new Date().getFullYear()} EDConnect. All rights
            reserved.
          </Typography>
        </div>
      </Container>
    </Box>
  );
}


const CustomGrid: any = withStyles({
  root: {
    "& .MuiList-padding": {
      paddingBottom: `0px !important`,
    },
  },
})(Grid);
