// Customizable Area Start
import React from 'react';
import SettingsHeader from './SettingsHeader.web'
import UserProfileBasicController from './UserProfileBasicController'
import { Container, Grid } from "@material-ui/core";
import AppFooter from '../../../components/src/AppFooter.web';
import Accounts from './Accounts.web';
import { isLoggedIn } from '../../../components/src/common';
import LeftMenu from './LeftMenu.web';
import { account, secure, notification, sub, lightAcc } from './assets'



export default class Settings extends UserProfileBasicController {
    tabs = [
        { img: lightAcc,darkIcon:account, tab: 'Account preferences',isActive:true }, 
        { img: secure, tab: 'Sign in & security',path:'SignInSecurity',isActive:false }, 
        { img: notification, tab: 'Notifications',path:'Notification',isActive:false }, 
        { img: sub, tab: 'Manage my subscriptions',path:'ManageSubscription',isActive:false }]
   
    
    render() {
        if(!isLoggedIn(this.props.navigation)){
            return null

        }
        return (
            <>
                <SettingsHeader navigation={this.props?.navigation} id={''} />
                <Container style={{ marginTop: '25px' }}>
                    <Grid container spacing={3}>
                        <Grid item md={3}>
                           <LeftMenu navigation={this.props?.navigation} id={''} tabs={this.tabs}/>

                        </Grid>
                        <Grid item md={8}>
                           <Accounts navigation={this.props?.navigation} id={''} />
                        </Grid>
                    </Grid>
                </Container>
                <div style={{ marginTop: 'auto' }}>
                <AppFooter />
                </div>
            </>
        )
    }
}
// Customizable Area End