// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
// Customizable Area End



export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showMore: boolean;
  isModalOpenView: boolean;
  studentProfileDetails: any;
  copyText: string;
  profileImage: any;
  backGroundImage: any;
  isRequestLoading: boolean;
  approval_data: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class StudentProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStudentProfileCallId: string = "";
  AccessCallId: string = "";
  AccessRequestCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    // Customizable Area End
    // Customizable Area Start
    this.state = {
      showMore: false,
      isModalOpenView: false,
      studentProfileDetails: [],
      copyText: "",
      profileImage: "",
      backGroundImage: "",
      isRequestLoading: true,
      approval_data: []

    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  

  handleClose = () =>
    this.setState({
      isModalOpenView: false,
    });

  async componentDidMount() {
    super.componentDidMount();
    this.setState({ copyText: window.location.href });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    let photo = localStorage.getItem("profileImage");
    if (prevState.profileImage !== photo) {
      this.setState({ profileImage: photo });
    }

    let backGroundPhoto = localStorage.getItem("backGroundImage");
    if (prevState.backGroundImage !== backGroundPhoto) {
      this.setState({ backGroundImage: backGroundPhoto });
    }
  }
  getStudentProfile = (id: string | number) => {
    const urlParams = new URLSearchParams(window.location.search);
    const access_id = urlParams.get('request_access_id');
    const request_access_id = access_id ? `&request_access_id=${access_id}` : "";
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStudentProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/guest_view_profile?profile_id=${id}${request_access_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAccess = (values: any) => {
    let id: any = this.props.navigation.getParam("id")??"null"
    const body = { ...values, profile_id: id }
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/request_access"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.AccessCallId = requestMessage.messageId

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  onHandleRequestResponse = (response: any) => {
    if (response?.message === 'request access is created') {
      const { email_address, id, profile_id } = response?.request_access;
      localStorage.setItem("guest_user", JSON.stringify({ email_address, id, profile_id }))
    } else {
      alert(response?.message)
    }

    this.handleClose()
  }



  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getStudentProfileCallId) {
        this.handleStudentProfileDetails(responseJson);
      }
      if (apiRequestCallId === this.AccessCallId) {
        this.onHandleRequestResponse(responseJson)
      }
      if (apiRequestCallId === this.AccessRequestCallId) {
        alert(responseJson?.message)
        this.props.navigation.navigate('FanWall')
      }
    }
  }

  handleStudentProfileDetails = (responseJson: any) => {

    if (responseJson && !responseJson.errors) {
      this.setState({ studentProfileDetails: responseJson });

    }
  };

  handleApprove = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const request_status = urlParams.get('request_status');
    const request_access_id=this.props.navigation.getParam("access_id");
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/approved_request?request_status=${request_status}&request_access_id=${request_access_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.AccessRequestCallId = requestMessage.messageId

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  executeOnClick(isExpanded: any) {
    console.log(isExpanded);

  }

  // Customizable Area End
}
