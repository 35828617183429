import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  userDetails?: any;
  isBack?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  name: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  initialRegistration: any;
  selectedTab: any;
  initialStudentDetails: any;
  initialPasswordDetails: any;
  initialOrganisationDetails: any;
  mainState: any;
  imageUploaded: any;
  selectedFile: any;
  selectedImage: any;
  isDetails: boolean;
  isLoading: boolean;
  accountError: any;
  userDetails: any;
  registerPopup: boolean;
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  emailVerificationApiCallId: string = "";
  userRegistrationApiCallId: string = "";
  userVerificationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  labelName: string;
  labelEmailAddress: string;
  txtCheckEmail: string;
  txtSentEmail: string;
  btnTxtRegister: string;
  btnTxtClose: string;
  btnTxtContinue: string;
  labelConfirmPassword: string;
  labelMiddleName: string;
  labelDob: string;
  labelLocation: string;
  labelCountry: string;
  labelOrgName: string;
  labelJobTitle: string;
  labelHeadline: string;
  labelProfilePhoto: string;
  lableAdminDetails: string;
  labelPosition: string;
  txtOrgDetails: string;
  labelNameofOrg: string;
  labelTypeofOrg: string;
  txtAddressOrg: string;
  labelStreetName: string;
  labelPincode: string;
  labelCity: string;
  labelOpeningHours: string;
  labelClosingHours: string;
  labelAbout: string;
  errorEmailRequired: string;
  errorNameRequired: string;
  errorPasswordRequired: string;
  errorPasswordLength: string;
  errorPassNumber: string;
  errorPassLower: string;
  errorPassUpper: string;
  errorPassSymbol: string;
  errorConfirmPassRequired: string;
  errorPasswordMatch: string;
  errorFirstnameRequired: string;
  errorDobRequired: string;
  errorLastnameRequired: string;
  errorCityRequired: string;
  errorCountryRequired: string;
  errorHeadlineRequired: string;
  errorAcceptTermsCondition: string;
  errorOrgNameRequired: string;
  errorOrgTypeRequired: string;
  errorStreetnameRequired: string;
  errorPincodeRequired: string;
  errorPincodeNumber: string;
  errorOpeningHoursRequired: string;
  errorClosingHoursRequired: string;
  
  currentCountryCode: any;
  authToken: string;
  titlePasswordChanged: string;
  txtPasswordChanged: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);


    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      name: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      selectedTab: 1,
      initialRegistration: {
        full_name: "",
        email: ""
      },
      initialStudentDetails: {
        first_name: "",
        middle_name: "",
        date_of_birth: "",
        last_name: "",
        email: "",
        city: "",
        country: "",
        name_of_organisation: "",
        job_title: "",
        headline: "",
        profilePic: "",
        studentTermsAgree: false,
      },
      initialPasswordDetails: {
        password: "",
        confirm_password: "",  
      },
      initialOrganisationDetails: {
        first_name: "",
        last_name: "",
        email: "",
        position: "",
        name_of_organisation: "",
        type_of_organisation: "",
        street_name: "",
        postal_code: "",
        city: "",
        country: "",
        opening_hour: "",
        closing_hour: "",
        headline: "",
        orgProfilePic: "",
        orgTermsAgree: false,
      },
      mainState: "initial",
      imageUploaded: 0,
      selectedFile: null,
      isDetails: true,
      isLoading: false,
      accountError: "",
      selectedImage: require("../assets/user_icon.png"),
      userDetails: {},
      registerPopup: true
    };

    // Customizable Area Start
    this.arrayholder = [];

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.labelEmailAddress= configJSON.labelEmailAddress;
    this.labelName= configJSON.labelName;
    this.btnTxtRegister= configJSON.btnTxtRegister;
    this.txtCheckEmail= configJSON.txtCheckEmail;
    this.txtSentEmail= configJSON.txtSentEmail;
    this.btnTxtClose= configJSON.btnTxtClose;
    this.btnTxtContinue= configJSON.btnTxtContinue;
    this.labelConfirmPassword= configJSON.labelConfirmPassword;
    this.labelMiddleName= configJSON.labelMiddleName;
    this.labelDob= configJSON.labelDob;
    this.labelLocation= configJSON.labelLocation;
    this.labelCountry= configJSON.labelCountry;
    this.labelCity= configJSON.labelCity;
    this.labelOrgName= configJSON.labelOrgName;
    this.labelJobTitle= configJSON.labelJobTitle;
    this.labelHeadline= configJSON.labelHeadline;
    this.labelProfilePhoto= configJSON.labelProfilePhoto;
    this.lableAdminDetails= configJSON.lableAdminDetails;
    this.labelPosition= configJSON.labelPosition;
    this.txtOrgDetails= configJSON.txtOrgDetails;
    this.labelNameofOrg= configJSON.labelNameofOrg;
    this.labelTypeofOrg= configJSON.labelTypeofOrg;
    this.txtAddressOrg= configJSON.txtAddressOrg;
    this.labelStreetName= configJSON.labelStreetName;
    this.labelPincode= configJSON.labelPincode;
    this.labelOpeningHours= configJSON.labelOpeningHours;
    this.labelClosingHours= configJSON.labelClosingHours;
    this.labelAbout= configJSON.labelAbout;
    this.errorEmailRequired= configJSON.errorEmailRequired;
    this.errorNameRequired= configJSON.errorNameRequired;
    this.errorPasswordRequired= configJSON.errorPasswordRequired;
    this.errorPasswordLength= configJSON.errorPasswordLength;
    this.errorPassNumber= configJSON.errorPassNumber;
    this.errorPassLower= configJSON.errorPassLower;
    this.errorPassUpper= configJSON.errorPassUpper;
    this.errorPassSymbol= configJSON.errorPassSymbol;
    this.errorConfirmPassRequired= configJSON.errorConfirmPassRequired;
    this.errorPasswordMatch= configJSON.errorPasswordMatch;
    this.errorFirstnameRequired= configJSON.errorFirstnameRequired;
    this.errorDobRequired= configJSON.errorDobRequired;
    this.errorLastnameRequired= configJSON.errorLastnameRequired;
    this.errorCityRequired= configJSON.errorCityRequired;
    this.errorCountryRequired= configJSON.errorCountryRequired;
    this.errorHeadlineRequired= configJSON.errorHeadlineRequired;
    this.errorAcceptTermsCondition= configJSON.errorAcceptTermsCondition;
    this.errorOrgNameRequired= configJSON.errorOrgNameRequired;
    this.errorOrgTypeRequired= configJSON.errorOrgTypeRequired;
    this.errorStreetnameRequired= configJSON.errorStreetnameRequired;
    this.errorPincodeRequired= configJSON.errorPincodeRequired;
    this.errorPincodeNumber= configJSON.errorPincodeNumber;
    this.errorOpeningHoursRequired= configJSON.errorOpeningHoursRequired;
    this.errorClosingHoursRequired= configJSON.errorClosingHoursRequired;
    this.titlePasswordChanged= configJSON.titlePasswordChanged;
    this.txtPasswordChanged= configJSON.txtPasswordChanged;
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          this.checkResponseError(responseJson);

          this.parseApiCatchErrorResponse(errorReponse);
        } else if(apiRequestCallId === this.emailVerificationApiCallId){
          this.checkEmailVerificationResponse(responseJson);

          this.parseApiCatchErrorResponse(errorReponse);
        } else if(apiRequestCallId === this.userVerificationApiCallId){
          this.checkUserVerificationResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if(apiRequestCallId === this.userRegistrationApiCallId){
          this.checkUserRegistrationCallId(responseJson)
        }
      }
    }
    this.checkNavigationPayload(message);

    this.checkCountryCode(message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const authToken = localStorage.getItem("authToken");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    console.log("detailsweb", this.state.initialStudentDetails)
  }

  checkPasswordRules(regexData:any) {
    if (regexData.password_validation_rules) {
      this.setState({
        passwordHelperText: regexData.password_validation_rules
      });
    }
  }

  checkResponseError(responseJson:any) {
    if (!responseJson.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      this.send(msg);
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  checkEmailVerificationResponse(responseJson:any) {
    this.setState({isLoading: false})
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      // this.setState({isLoading: false})
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.props.navigation.navigate("RegisterEmailSent");
    } else {
      //Check Error Response
      this.setState({isLoading: false, accountError: responseJson.errors})
    }
  }

  checkRegAccount(responseJson:any) {
    if(responseJson.type === "StudentAccount"){
      this.props.navigation.navigate("RegisterStudentDetails");
    }
    else if(responseJson.type === "OrganisationAccount"){
      this.props.navigation.navigate("EmailAccountOrganisationDetails");
    }
    else {
      this.props.navigation.navigate("Register");
    }
  }

  checkUserVerificationResponse(responseJson:any) {
    if (responseJson) {
            
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.checkRegAccount(responseJson);
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  checkUserRegistrationCallId(responseJson:any) {
    if (responseJson && !responseJson.errors) {
      this.setState({isLoading: false, registerPopup: false})
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      //Check Error Response
      this.checkAgeError(responseJson);
    }
  }

  checkAgeError(responseJson:any) {
  if(responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0].token){
    this.setState({isLoading: false, accountError: responseJson.errors[0].token})
  }
  else{
      this.setState({isLoading: false, accountError: responseJson.errors})
    }
  }

  checkSelectedCode(selectedCode:any) {
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
  }

  checkCountryCode(message:any) {
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      this.checkSelectedCode(selectedCode);
    }
  }
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  checkOtpToken(otpAuthTkn:any, message:any) {
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  }

  checkNavigationPayload(message:any){
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      this.checkOtpToken(otpAuthTkn, message);
    }
  }
  
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableReTypePasswordField: !this.state.enableReTypePasswordField,
    });
  };

  registerUser(values: any, userDetails:any) {
    this.setState({ isLoading: true })
    let formData = new FormData();
    formData.append('account_type', "student_account");
    formData.append('data[first_name]', userDetails?.first_name);
    formData.append('data[last_name]', userDetails?.last_name);
    formData.append('data[middle_name]', userDetails?.middle_name);
    formData.append('data[date_of_birth]', userDetails?.date_of_birth);
    formData.append('data[email]', userDetails?.email);
    formData.append('data[city]', userDetails?.city);
    formData.append('data[country]', userDetails?.country);
    formData.append('data[headline]', userDetails?.headline);
    formData.append('data[job_title]', userDetails?.job_title);
    formData.append('data[name_of_organisation]', userDetails?.name_of_organisation);
    formData.append('data[password]', values?.password);
    formData.append('data[confirm_password]', values?.confirm_password);
    if(userDetails?.profile_image !== null){
      formData.append('data[profile_image]', userDetails?.profile_image);
    }

    let url:any = ''
    url = `${configJSON.registerUserApiEndpoint}`
    const token:any = this.authToken;
    const headers:any = {
      "Content-Type": configJSON.exampleApiContentType,
      "token" : token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userRegistrationApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.registerUserApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async registerOrgUser(values:any, userDetails:any) {
    this.setState({ isLoading: true })

    let formData:any = new FormData();
    formData.append('account_type', "organisation_account");
    formData.append('data[first_name]', userDetails?.first_name);
    formData.append('data[city]', userDetails?.city);
    formData.append('data[password]', values?.password);
    formData.append('data[country]', userDetails?.country);
    formData.append('data[job_title]', userDetails?.position);
    formData.append('data[name_of_organisation]', userDetails?.name_of_organisation);
    formData.append('data[type_of_organisation]', userDetails?.type_of_organisation);
    formData.append('data[opening_hour]', userDetails?.opening_hour);
    formData.append('data[closing_hour]', userDetails?.closing_hour);
    formData.append('data[headline]', userDetails?.headline);
    formData.append('data[postal_code]', userDetails?.postal_code);
    formData.append('data[address]', userDetails?.street_name);
    if(userDetails?.profile_image !== null){
      formData.append('data[profile_image]', userDetails?.profile_image);
    }

    let url:any = ''
    url = `${configJSON.registerUserApiEndpoint}`
    const token:any = this.authToken;
    const headers:any = {
      "Content-Type": configJSON.exampleApiContentType,
      "token" : token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userRegistrationApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.registerUserApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
