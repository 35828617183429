// Customizable Area Start
import React from 'react'
import { Box, styled, withStyles, TextField,  InputAdornment } from "@material-ui/core";
import { moreOptions, search } from '../../blocks/HashtagGenerator/src/assets';
import { profile } from '../../blocks/postcreation/src/assets';

function ListOfPeopleOnMessagesPage() {
    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 20px 5px 20px' }}>
                <div style={{ fontSize: '18px', color: '#1C1C1C' }}>Messaging</div>
                <div><img src={moreOptions} width="24px" style={{ transform: 'rotate(-90deg)' }} /></div>

            </div>
            <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7', marginLeft: '15px', marginRight: '15px' }} />
            <div style={{ padding: '0px 15px' }}>
                <SearchBox
                    size="small"
                    variant='outlined' placeholder="Search by name"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">

                                <img src={search} width="20px" />

                            </InputAdornment>
                        )
                    }}
                />

            </div>
            <div>
                <StyledDiv>
                    <div style={{ marginRight: '10px', position: 'relative' }}>
                        <img src={profile} width='64px' />
                        <OnlineCircle />

                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ fontSize: '16px',color:'#1C1C1C'}}>Nahia Colunga</div>
                            <div style={{ fontSize: '14px', color:'#7C7C7C' }}>12 jan</div>

                        </div>
                        <div style={webStyles.texts}>
                            Now is the winter of our discontent
                        </div>

                    </div>

                </StyledDiv>
            </div>

        </React.Fragment>
    )
}
const webStyles = {
   
    texts: {
        fontSize: '14px',
        color: '#565656',
        marginTop: '5px',
        
    },
    
   
}
const StyledDiv = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '20px',
    padding: '5px 16px',
    position: 'relative',
    borderLeft:'4px solid #BE9E44',
    '&:hover': {
        backgroundColor: 'rgba(216, 216, 216, 0.356671)'

    },
});
const SearchBox: any = withStyles({
    root: {
        width: '100%',
        borderRadius: "8px",
        backgroundColor: '#F7F7F7',
        fontFamily: "HelveticaNeue",
        border: 'none',
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "8px",
                fontWeight: 400,
                borderWidth: 0,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        }
    }
})(TextField);

const OnlineCircle = styled(Box)({
    width: '7px',
    height: '7px',
    backgroundColor: '#008F48',
    borderRadius: '100%',
    border: '1px solid #008F48',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    top: '84%',
    left: '84%'
});

export default ListOfPeopleOnMessagesPage
// Customizable Area End