import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModalOpen: boolean;
  isModalOpen1: boolean;
  isModalOpenWork: boolean;
  isModalOpenWork1: boolean;
  isModalOpenCertify: boolean;
  isModalOpenAchieve: boolean;
  isAboutModelOpen: boolean;
  isModalOpenAcademic: boolean;
  isModalOpenCover: boolean;
  isModalOpenProfile: boolean;
  educationDetails: any;
  certificationDetails: any;
  workExperienceDetails: any[];
  token: any;
  userID: any;
  showMore: boolean;
  isModalOpenShare: boolean;
  isModalOpenView: boolean;
  studentProfileDetails: any;
  copyText: string;
  profileImage: any;
  backGroundImage: any;
  isModalEditId: any;
  isModalCertifiationId: any;
  isModalAcademicSkillsId: any;
  isModalAchievementId: any;
  isModelWork: any;
  userTrue: boolean;
  ownerProfile:any;
  slideProfile: any;
  editWorkExpId: any;
  isModalOpenCompleteProfile: any;
  openCreatePost: any;
  description: any;
  body: any;
  uploadedImages: any;
  hashTags: any;
  userAccountObj: any;
  isLoading: any;
  base64Images: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class StudentProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEducationDetailsCallId: string = "";
  getCertificateDetailsCallId: string = "";
  updateAboutProfileCallId: string = "";
  getWorkExperienceCallId: string = "";
  getStudentProfileCallId: string = "";
  getOwnerProfileCallId:string = "";
  patchImageCallId: string = "";
  addpostApiCallId: string = "";
  imgRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.imgRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    // Customizable Area End
    // Customizable Area Start
    this.state = {
      isModalOpen: false,
      isModalOpen1: false,
      isModalOpenWork: false,
      isModalOpenWork1: false,
      isModalOpenCertify: false,
      isModalOpenAchieve: false,
      isAboutModelOpen: false,
      isModalOpenAcademic: false,
      isModalOpenCover: false,
      isModalOpenProfile: false,
      educationDetails: [],
      certificationDetails: [],
      workExperienceDetails: [],
      token: "",
      userID: "",
      showMore: false,
      isModalOpenShare: false,
      isModalOpenView: false,
      studentProfileDetails: [],
      copyText: "",
      profileImage: "",
      backGroundImage: "",
      isModalEditId: null,
      isModalCertifiationId: null,
      isModalAcademicSkillsId: null,
      isModalAchievementId: null,
      isModelWork: null,
      ownerProfile:[],
      userTrue: false,
      slideProfile: false,
      editWorkExpId: 0,
      isModalOpenCompleteProfile: false,
      openCreatePost: false,
      description: '',
      body: '',
      uploadedImages: [],
      hashTags: [],
      userAccountObj:{
        first_name:'',
        middle_name:'',
        last_name:'',
        profile_image:'',
        job_title:'',
        name_of_organisation:'',
        type:''
      },
      isLoading: false,
      base64Images: []
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  postWorkDetails = (values: any, image: any, words: any) => {
    this.handleClose();
    const header = {
      // "Content-Type":"multipart/form-data",
      token: this.state.token,
    };

    let formdata: any = new FormData();
    formdata.append("career_experience[job_title]", values.grade);
    formdata.append("career_experience[start_month]", values.start_date);
    formdata.append("career_experience[start_year]", values.start_year);
    formdata.append("career_experience[end_month]", values.end_date);
    formdata.append("career_experience[end_year]", values.end_year);
    image !== undefined && formdata.append("career_experience[images][]", image);
    formdata.append(
      "career_experience[company_name]",
      values.organization_name
    );
    formdata.append("career_experience[description]", values.message);
    formdata.append("career_experience[make_key_achievements_public]", true);
    formdata.append(
      "career_experience[currently_working_here]",
      values.orgTermsAgree
    );
    formdata.append(
      "career_experience[employment_type_name]",
      values.experience_type
    );
    formdata.append(
      "career_experience[employment_type]",
      values.experience_type
    );
    formdata.append(
      "career_experience[experience_type]",
      values.experience_type
    );
    formdata.append("system_experiences[]", words);
    formdata.append("career_experience[add_key_achievements]", "dhdhb");
    formdata.append(
      "career_experience[industry_name]",
      values.organization_name
    );
    formdata.append("career_experience[location]", values.location);
    formdata.append("career_experience[location_type]", values.location_type);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/career_experiences"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    setTimeout(() => {
      this.getWorkExperience();
    }, 1000);
  };

  postWorkDetailsEdit = (values: any, image: any, words: any) => {
    this.handleClose();
    const header = {
      // "Content-Type":"multipart/form-data",
      token: this.state.token,
    };
    console.log("values-=-=>", values)

    let formdata: any = new FormData();
    formdata.append("career_experience[job_title]", values.grade);
    formdata.append("career_experience[start_month]", values.start_date);
    formdata.append("career_experience[start_year]", values.start_year);
    formdata.append("career_experience[end_month]", values.end_date);
    formdata.append("career_experience[end_year]", values.end_year);
    image !== undefined && formdata.append("career_experience[images][]", image);
    formdata.append(
      "career_experience[company_name]",
      values.organization_name
    );
    formdata.append("career_experience[description]", values.message);
    formdata.append("career_experience[make_key_achievements_public]", true);
    formdata.append(
      "career_experience[currently_working_here]",
      values.orgTermsAgree
    );
    formdata.append(
      "career_experience[employment_type_name]",
      values.experience_type
    );
    formdata.append(
      "career_experience[employment_type]",
      values.experience_type
    );
    formdata.append(
      "career_experience[experience_type]",
      values.experience_type
    );
    formdata.append("system_experiences[]", words);
    formdata.append("career_experience[add_key_achievements]", "dhdhb");
    formdata.append(
      "career_experience[industry_name]",
      values.organization_name
    );
    formdata.append("career_experience[location]", values.location);
    formdata.append("career_experience[location_type]", values.location_type);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/career_experiences/${this.state.editWorkExpId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    setTimeout(() => {
      this.getWorkExperience();
    }, 1000);
  };

  handleConnect = () => {
    this.getStudentProfile();
  };

  patchProfileDetails = (image: any) => {
    this.handleClose();
    const header = {
      // "Content-Type":
      // "multipart/form-data; boundary=<calculated when request is sent>",
      token: this.state.token,
    };

    let formData: any = new FormData();
    formData.append("data[profile_image]", image);

    const requestMessageProfile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageProfile.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessageProfile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/update_profile"
    );
    requestMessageProfile.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessageProfile.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.patchImageCallId = requestMessageProfile.messageId;
    runEngine.sendMessage(requestMessageProfile.id, requestMessageProfile);
  };

  patchProfileBackGroundDetails = (image: any) => {
    this.handleClose();
    const header = {
      // "Content-Type":
      // "multipart/form-data; boundary=<calculated when request is sent>",
      token: this.state.token,
    };

    let formdata: any = new FormData();
    formdata.append("data[photo]", image);

    const requestMessageBackGround = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageBackGround.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessageBackGround.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/update_profile"
    );
    requestMessageBackGround.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessageBackGround.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    runEngine.sendMessage(
      requestMessageBackGround.id,
      requestMessageBackGround
    );
    setTimeout(() => {
      this.getStudentProfile();
    }, 1000);
  };

  postCeritificationDetails = (values: any) => {
    this.handleClose();
    const httpBody = {
      award: {
        title: values.title,
        associated_with: values.associated_with,
        issuer: values.Issuing_Institution,
        issue_month: values.month,
        issue_year: values.year,
        description: null,
        make_public: true,
      },
    };

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/awards"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    setTimeout(() => {
      this.getCertificateDetails();
    }, 1000);
  };
  postCeritificationDetailsEdit = (values: any, getId: any) => {
    this.handleClose();
    const httpBody = {
      award: {
        title: values.title,
        associated_with: values.associated_with,
        issuer: values.Issuing_Institution,
        issue_month: values.month,
        issue_year: values.year,
        description: null,
        make_public: true,
      },
    };

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/awards/${getId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    setTimeout(() => {
      this.getCertificateDetails();
    }, 1000);
  };

  handleClose = () =>
    this.setState({
      isModalOpen: false,
      isModalOpen1: false,
      isModalOpenWork: false,
      isModalOpenWork1: false,
      isModalOpenCertify: false,
      isModalOpenAchieve: false,
      isAboutModelOpen: false,
      isModalOpenAcademic: false,
      isModalOpenCover: false,
      isModalOpenProfile: false,
      isModalOpenShare: false,
      isModalOpenView: false,
      isModalOpenCompleteProfile: false
    });

  async componentDidMount() {
    super.componentDidMount();
    let token = localStorage.getItem("authToken");
    let userID = localStorage.getItem("account_id");
    this.setState({ token: token, userID: userID }, async () => {
      this.getStudentProfile();
      this.getEducationDetails();
      this.getWorkExperience();
      this.getCertificateDetails();
      this.setState({ copyText: window.location.href });
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    let photo = localStorage.getItem("profileImage");
    if (prevState.profileImage !== photo) {
      this.setState({ profileImage: photo });
    }

    let backGroundPhoto = localStorage.getItem("backGroundImage");
    if (prevState.backGroundImage !== backGroundPhoto) {
      this.setState({ backGroundImage: backGroundPhoto });
    }
  }
  getStudentProfile = () => {
    const header = {
      token: this.state.token,
      "Content-Type": "application/json",
    };

    let userAuthID = this.state.userID;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStudentProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/${userAuthID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOwnerProfile = () => {
    const header = {
      token: this.state.token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOwnerProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/62"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getEducationDetails = () => {
    const header = {
      token: this.state.token,
      "Content-Type": "application/json",
    };

    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEducationDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/educational_qualifications/user_educational_details"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCertificateDetails = () => {
    const header = {
      token: this.state.token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCertificateDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/awards/user_awards"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getWorkExperience = () => {
    const header = {
      token: this.state.token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWorkExperienceCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/career_experiences/user_working_experience"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getStudentProfileCallId) {
        this.handleStudentProfileDetails(responseJson);
      }
      if (apiRequestCallId === this.getEducationDetailsCallId) {
        this.handleEducationDetailsRes(responseJson);
      }
      if (apiRequestCallId === this.getCertificateDetailsCallId) {
        this.handleCertificateDetailsRes(responseJson);
      }
      if (apiRequestCallId === this.getWorkExperienceCallId) {
        this.handleWorkExperienceRes(responseJson);
      }
      if (apiRequestCallId === this.patchImageCallId) {
        this.handlePatchImgPro(responseJson);
      }
      if(apiRequestCallId === this.getOwnerProfileCallId ){
        this.handleOwnerProfileRes(responseJson)
      }
      this.chechUpdateAbout(apiRequestCallId, responseJson);
      this.checkPostCreations(apiRequestCallId, responseJson);
    }
  }

  chechUpdateAbout(apiRequestCallId:any, responseJson:any) {
    if (apiRequestCallId === this.updateAboutProfileCallId) {
      return this.handleUpdateAboutRes(responseJson)
    }
  }

  checkPostCreations(apiRequestCallId:any, responseJson:any) {
    if (apiRequestCallId === this.addpostApiCallId) {
      return this.handlePostCreationRes(responseJson)
    }
  }

  handleStudentProfileDetails = (responseJson: any) => {

    if (responseJson && !responseJson.errors) {
      this.setState({
        studentProfileDetails: responseJson,
        userTrue: responseJson?.profile?.data?.attributes?.is_owner,
      });
      localStorage.setItem('profile_id', responseJson?.profile?.data?.id)
      this.setState({
        userAccountObj: {
          ...this.state.userAccountObj,
          first_name: responseJson?.account?.data?.attributes?.first_name,
          middle_name: responseJson?.account?.data?.attributes?.middle_name,
          last_name: responseJson?.account?.data?.attributes?.last_name,
          profile_image: responseJson?.profile?.data?.attributes?.profile_image,
          job_title: responseJson?.profile?.data?.attributes?.job_title,
          name_of_organisation: responseJson?.profile?.data?.attributes?.name_of_organisation,
          type: responseJson?.account?.data?.type
        }
      })
      const newObj={
        account_id:responseJson?.profile?.data?.attributes?.account_id,
        account_type:responseJson?.account?.data?.type,
        name_of_organization:responseJson?.profile?.data?.attributes.name_of_organisation,
        job_title:responseJson?.profile?.data?.attributes?.job_title,
        profile_image:responseJson?.profile?.data?.attributes?.profile_image,
        first_name:responseJson?.account?.data?.attributes?.first_name,
        last_name:responseJson?.account?.data?.attributes?.last_name
      }
      localStorage.setItem('user_details',JSON.stringify(newObj))
    }
  };

  handleEducationDetailsRes = (responseJson: any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ educationDetails: responseJson.data });
    }
  };

  handleCertificateDetailsRes = (responseJson: any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ certificationDetails: responseJson.data });
    }
  };

  handleWorkExperienceRes = (responseJson: any) => {
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({ workExperienceDetails: responseJson.data });
      localStorage.setItem('otherId', responseJson.data[0])
    }
  };

  handlePatchImgPro = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ studentProfileDetails: responseJson });
    }  
  };

  handleOwnerProfileRes= (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ ownerProfile: responseJson });
    }  
  };
  executeOnClick(isExpanded: any) {
    console.log(isExpanded);
  }

  handleSlideRight() {
    let getEle:any = document.getElementById('comp_profile');
    console.log('getEle-=-=-=', getEle)
    if(this.state.slideProfile === true) {
      getEle.style.transform = "translate(-34%)"
    }
    if(this.state.slideProfile === false) {
      getEle.style.transform = "translate(0)"
    }
  }

  handleModalClose() {
    this.setState({isModalOpen: false, isModalOpen1: false})
  }

  handleUpdateAboutRes(responseJson:any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ studentProfileDetails: responseJson });
    } 
  }
  
  handlePatchProfileDetails(values: any) {
    this.handleClose();
    let token = localStorage.getItem('authToken')

    const header = {
        // "Content-Type": "application/json",
        token: token
    };

    let formData: any = new FormData();
    formData.append("data[hobbies]", values.hobbies);
    formData.append("data[personality]", values.personality);
    formData.append("data[goal]", values.goal);

    const requestMessageProfile = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateAboutProfileCallId = requestMessageProfile.messageId;

    requestMessageProfile.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
    );
    requestMessageProfile.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateStudentProfileApiEndPoint
    );
    requestMessageProfile.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateStudentProfileMethod
    );
    requestMessageProfile.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
    );
    runEngine.sendMessage(requestMessageProfile.id, requestMessageProfile);
}

  handleRemoveImage(img: any) {
    this.setState({
      uploadedImages: this.state.uploadedImages.filter((image: any) => {
        return img?.id !== image?.id
      })
    })
  }

  handleImage(e: any) {
    const obj = {
      filename: e?.target?.files[0]?.name,
      content_type: e?.target?.files[0]?.type

    }
    this.convertImgToBase64(e.target.files[0]).then(base64 => {
      this.setState({ base64Images: [...this.state.base64Images, { data: base64, filename: obj?.filename, content_type: obj?.content_type }] })
    });
    this.setState({ uploadedImages: [...this.state.uploadedImages, { id: uuidv4(), img: URL.createObjectURL(e.target.files[0]) }] })
  }

  convertImgToBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }

    func(description: any) {
    let temp: any = []
    let des: any = ''
    const words = description.split('\n');
    words.map((word: any) => {
      if (word.startsWith("#")) {
        temp.push(word)
      }
      else {
        des = word
        return word
      }
    });
    this.setState({ body: temp.join(", ") });
    return des
  }

  AddPostCreation() {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem('authToken')
    };

    const attrs = {
      data: {
        attributes: {
          "name": '',
          "description": this.state.description.includes('\n') ? this.func(this.state.description) : this.state.description,
          "body": this.state?.body,
          "location": "indore",
          "category_id": 2,
          images: this.state?.base64Images
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ uploadedImages: [], base64Images: [] })

    return true;
  }

  handlePostCreationRes(resJson: any) {
    if (resJson?.errors?.length > 0) {
      this.setState({ isLoading: false });
      return false;
    } else {
      this.setState({ isLoading: true });
      this.props.navigation.navigate("FanWall")
    }
  }

  // Customizable Area End
}
