import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import {imgPasswordVisible , imgPasswordInVisible } from "./assets"
import { apiCall, getHeaders } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  enableConfirmPasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  initialEmail: any;
  initialOTP: any;
  email: any;
  initialPasswordDetails: any;
  isPassword: any;
  isLoading: boolean;
  isError: any;
  isEmail: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  sendOTPApiCallId: any;
  verifyOTPApiCallId: any;
  setNewPassApiCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible : any = imgPasswordInVisible ;
  imgPasswordVisible : any = imgPasswordVisible ;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  errorEmailRequired:string= configJSON.errorEmailRequired;
  errorCodeRequired:string= configJSON.errorCodeRequired;
  errorNumberOnly:string= configJSON.errorNumberOnly;
  labelEmail:string= configJSON.labelEmail;
  btnTxtResetPass:string= configJSON.btnTxtResetPass;
  btnTxtSubmit:string= configJSON.btnTxtSubmit;
  btnTxtBack:string= configJSON.btnTxtBack;
  btnTxtConfirmPass:string= configJSON.btnTxtConfirmPass;
  errorPasswordRequired: string;
  errorPasswordLength: string;
  errorPassNumber: string;
  errorPassLower: string;
  errorPassUpper: string;
  errorPassSymbol: string;
  errorConfirmPassRequired: string;
  errorPasswordMatch: string;
  labelPassword: string;
  labelConfirmPassword: string;
  txtPasswordChanged: string;
  btnTxtContinueSignin: string;
  titlePasswordChanged: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired)
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired)
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired)
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          )
        })
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema, 
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      btnConfirmPasswordShowHide: true,
      initialEmail: {
        email: ""
      },
      initialOTP: {
        otp: ""
      },
      email: "",
      initialPasswordDetails: {
        new_password: "",
        confirm_password: ""
      },
      isPassword: true,
      isLoading: false,
      isError: "",
      isEmail: true,
    };

    this.errorEmailRequired = configJSON.errorEmailRequired;
    this.errorCodeRequired = configJSON.errorCodeRequired;
    this.errorNumberOnly = configJSON.errorNumberOnly;
    this.labelEmail = configJSON.labelEmail;
    this.btnTxtSubmit = configJSON.btnTxtSubmit;
    this.btnTxtBack = configJSON.btnTxtBack;
    this.btnTxtConfirmPass = configJSON.btnTxtConfirmPass;
    this.errorPasswordRequired= configJSON.errorPasswordRequired;
    this.errorPasswordLength= configJSON.errorPasswordLength;
    this.errorPassNumber= configJSON.errorPassNumber;
    this.errorPassLower= configJSON.errorPassLower;
    this.errorPassUpper= configJSON.errorPassUpper;
    this.errorPassSymbol= configJSON.errorPassSymbol;
    this.errorConfirmPassRequired= configJSON.errorConfirmPassRequired;
    this.errorPasswordMatch= configJSON.errorPasswordMatch;
    this.labelPassword = configJSON.labelPassword;
    this.labelConfirmPassword = configJSON.labelConfirmPassword;
    this.txtPasswordChanged = configJSON.txtPasswordChanged;
    this.btnTxtContinueSignin = configJSON.btnTxtContinueSignin;
    this.titlePasswordChanged = configJSON.titlePasswordChanged;
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      console.log("");
    } else if (
      this.checkMessageEnumCondition1(message)
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkRegex(responseJson);
    } else if (
      this.checkMessageEnumCondition2(message)
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
 
      this.checkOtpToken(responseJson, message);
    } else if (
      this.checkMessageEnumCondition3(message)
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

        this.checkTokenForPhone(responseJson, message)
    } else if (
      this.checkMessageEnumCondition4(message)
    ) {
      // console.log("entered 3");
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkAccountConfirmation(responseJson, message);
    } else if (
     this.checkMessageEnumCondition5(message)
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.checkOtpConfirmation(responseJson)
        
    } else if (
      this.checkMessageEnumCondition6(message)
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        this.checkEmailConfirmation(responseJson);
        
    } else if (
      this.checkMessageEnumCondition7(message)
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.checkPasswordConfirmation(responseJson, message);
      
    }  else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      this.checkSelectedCode(selectedCode);
    }
    else {
      console.log("");
    }
  }
  // Customizable Area End

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail"
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : ""
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : ""
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : ""
    });

    // const data = {
    //   type: "sms_account",
    //   attributes: {
    //     full_phone_number: this.state.phoneValue
    //   }
    // };

    const httpBody = {
      data: {
        type: "sms_account",
        attributes: {
          full_phone_number: this.state.phoneValue
        }
      }
    };

    const getAccount = apiCall({
      httpBody: httpBody,
      header: header,
      url: configJSON.passwordRecoveryStartOtpAPiEndPoint,
      httpMethod: configJSON.httpPostMethod,
    });

    this.requestPhoneOtpCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : ""
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  // Customizable Area Start

  checkSelectedCode(selectedCode: any) {
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
  }

  checkSendOTPCondition(message: any) {
    if (this.sendOTPApiCallId !== null &&
      this.sendOTPApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkGetResponseCondition(responseJson: any) {
    if (responseJson !== undefined && responseJson.data) {
      return true
    }
  }

  checkValidationCallIDCondition(message: any) {
    if (this.validationAPICallId &&
      this.validationAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkResponsePassCondition(responseJson: any) {
    if (responseJson && responseJson.data[0]) {
      return true
    }
  }

  checkPasswordSchema(val: any) {
    if (val?.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  checkRequestEmailCondition(message: any) {
    if (this.requestEmailOtpCallId !== null &&
      this.requestEmailOtpCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkResponseMetaCondition(responseJson: any) {
    if (responseJson.meta && responseJson.meta.token) {
      return true
    }
  }

  checkResponseError(responseJson: any) {
    if (responseJson && responseJson.errors) {
      return true
    }
  }

  checkRequestPhoneCondition(message: any) {
    if (this.requestPhoneOtpCallId !== null &&
      this.requestPhoneOtpCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkRequestConfirmationCondition(message: any) {
    if (this.requestGoToConfirmationCallId !== null &&
      this.requestGoToConfirmationCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkResponseErrorCondition(responseJson: any) {
    if (responseJson !== undefined && responseJson.errors) {
      return true
    }
  }

  checkVerifyOtpCondition(message: any) {
    if (this.verifyOTPApiCallId !== null &&
      this.verifyOTPApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkResponseMessageCondition(responseJson: any) {
    if (responseJson !== undefined && responseJson.messages) {
      return true
    }
  }

  checkNewPasswordCondition(message: any) {
    if (this.setNewPassApiCallId !== null &&
      this.setNewPassApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      return true
    }
  }

  checkMessageEnumCondition1(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.checkValidationCallIDCondition(message)) {
      return true
    }
  }

  checkMessageEnumCondition2(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.checkRequestEmailCondition(message)) {
      return true
    }
  }

  checkMessageEnumCondition3(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.checkRequestPhoneCondition(message)) {
      return true
    }
  }
  checkMessageEnumCondition4(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.checkRequestConfirmationCondition(message)) {
      return true
    }
  }
  checkMessageEnumCondition5(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.checkSendOTPCondition(message)) {
      return true
    }
  }
  checkMessageEnumCondition6(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.checkVerifyOtpCondition(message)) {
      return true
    }
  }
  checkMessageEnumCondition7(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.checkNewPasswordCondition(message)) {
      return true
    }
  }

  checkResponseCondition(responseJson: any) {
    if (responseJson !== undefined && this.checkResponseMetaCondition(responseJson)) {
      return true
    }
  }

  checkRegex(responseJson: any) {
    if (this.checkResponsePassCondition(responseJson)) {
      const passRegex = RegExp(
        responseJson.data[0].password_validation_regexp
      );
      const emailRegex = RegExp(responseJson.data[0].email_validation_regexp);
      const passwordRulesFromValidation =
        responseJson.data[0].password_validation_rules;

      this.setState({
        //email schema
        emailSchema: {
          email: Yup.string()
            .email(configJSON.pleaseEnterAValidEmail)
            .required(configJSON.emailIsRequired)
            .matches(emailRegex, configJSON.invalidEmailAddress)
        },
        //password schema
        passwordSchema: {
          password: Yup.string()
            .required(configJSON.pleaseEnterAPassword)
            .matches(passRegex, configJSON.invalidPassword),

          confirmPassword: Yup.string()
            .required(configJSON.pleaseConfirmYourPassword)
            .when("password", {
              is: val => (this.checkPasswordSchema(val)),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                configJSON.passwordsMustMatch
              )
            })
        },
        passwordRules: passwordRulesFromValidation
      });
    }
  }

  checkOtpToken(responseJson: any, message: any) {
    if (
      this.checkResponseCondition(responseJson)
    ) {
      this.otpToken = responseJson.meta.token;

      this.setState({ token: this.otpToken });

      //navigate to OTP page
      const msg: Message = new Message(
        getName(MessageEnum.NavigationMobilePhoneOTPMessage)
      );

      msg.addData(
        getName(MessageEnum.AuthTokenDataMessage),
        this.state.token
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      msg.addData(
        getName(MessageEnum.AuthTokenEmailMessage),
        this.state.emailValue
      );

      msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

      this.send(msg);
    }
    //error handling
    else if (this.checkResponseError(responseJson)) {
      this.parseApiErrorResponse(responseJson);
    } else {
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  checkTokenForPhone(responseJson: any, message: any) {
    if (
      this.checkResponseCondition(responseJson)
    ) {
      this.otpToken = responseJson.meta.token;
      this.setState({ token: this.otpToken });

      const msg: Message = new Message(
        getName(MessageEnum.NavigationMobilePhoneOTPMessage)
      );
      msg.addData(
        getName(MessageEnum.AuthTokenDataMessage),
        this.state.token
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      msg.addData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage),
        this.state.phoneValue
      );

      msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

      this.send(msg);
    }
    //error handling
    else if (this.checkResponseError(responseJson)) {
      this.parseApiErrorResponse(responseJson);
    } else {
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  checkAccountConfirmation(responseJson: any, message: any) {
    if (this.checkGetResponseCondition(responseJson)) {
      this.setState({
        accountStatus: "Confirmation"
      });
    } else if (this.checkResponseErrorCondition(responseJson)) {
      this.parseApiErrorResponse(responseJson);
    } else {
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  checkOtpConfirmation(responseJson: any) {
    if (this.checkGetResponseCondition(responseJson)) {
      this.setState({ isEmail: false, isLoading: false, isError: "" })

      //IF SUCCESS

    } else if (this.checkResponseErrorCondition(responseJson)) {
      this.setState({ isLoading: false, isError: responseJson.errors[0].otp })

    }
    else {
      console.log("");
    }
  }

  checkEmailConfirmation(responseJson: any) {
    if (this.checkResponseMessageCondition(responseJson)) {
      this.setState({ isLoading: false, isError: "" })
      this.setState({
        accountStatus: "Confirmation"
      });
      localStorage.setItem("authToken", responseJson.messages[0].token)
      this.props.navigation.navigate("NewPassword");

      //IF SUCCESS

    } else if (this.checkResponseErrorCondition(responseJson)) {
      this.setState({ isLoading: false, isError: responseJson.errors[0].otp })
    }
    else {
      console.log("")
    }
  }

  checkPasswordConfirmation(responseJson: any, message: any) {
    if (this.checkGetResponseCondition(responseJson)) {
      this.setState({ isLoading: false, isPassword: false })
      this.setState({
        accountStatus: "Confirmation"
      });
    } else if (this.checkResponseErrorCondition(responseJson)) {
      this.setState({ isLoading: false, isError: responseJson.errors[0].otp })
    } else {
      console.log("")
    }
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField
    });
  };

  handleSendOTP(values:any) {
    this.setState({isLoading: true, email: values.email})
    const emailData = {
      data: {
        attributes: {...values},
      }
    };

    const header = {
      "Content-type": configJSON.forgotPasswordAPiContentType
    }
    const httpBody = {...emailData};
    let url = configJSON.sendOTPApiEndpoint;
    const getAccount = apiCall({
      httpBody: httpBody,
      header: header,
      url: url,
      httpMethod: configJSON.sendOTPApiMethod,
    });

    this.sendOTPApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleVerifyCode(values:any) {
    this.setState({isLoading: true})
    const otpData = {
      data: {
        attributes: {
          email: this.state.email,
          ...values,
        }
      }
    };

    const header = {
      "Content-type": configJSON.forgotPasswordAPiContentType
    }
    const httpBody = {...otpData};
    let url = configJSON.verifyOTPApiEndpoint;
    const getAccount = apiCall({
      httpBody: httpBody,
      header: header,
      url: url,
      httpMethod: configJSON.verifyOTPApiMethod,
    });

    this.verifyOTPApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleNewPassword(values:any) {
    this.setState({isLoading: true})
    const passData = {
      data: {
        ...values,
      }
    };

    let getAuth:any = localStorage.getItem("authToken");

    const header = getHeaders({token: getAuth})
    const httpBody = {...passData};
    let url = configJSON.setNewPassApiEndpoint;
    const getAccount = apiCall({
      httpBody: httpBody,
      header: header,
      url: url,
      httpMethod: configJSON.setNewPassApiMethod,
    });

    this.setNewPassApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
  // Customizable Area End
}
