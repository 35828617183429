import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
  Checkbox,
  Grid,
  Select,
  MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { BackArrow, Calender } from "./assets";
import AppHeader from '../../../components/src/AppHeader.web'
import AppFooter from '../../../components/src/AppFooter.web'
import { Formik } from 'formik'
import * as Yup from 'yup'
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#bd9e44",
      contrastText: "#f9f8f8",
    }
  },
});
import { TermsConditionsPop } from "../../../components/src/TermsConditionsPop.web";
import { ConnectedFocusError } from "focus-formik-error";
// Customizable Area End

import EmailAccountRegistrationController, {
  configJSON,
  Props
} from "./EmailAccountRegistrationController";
import Loader from "../../../components/src/Loader.web";
import StudentSetPasswordWeb from "./StudentSetPassword.web";

export default class EmailAccountStudentDetailsWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 // Customizable Area Start
 async componentDidMount() {
  this.getOrganisationList();
  this.setState({isLoading: true})
  this.autoPopulateEmail();
}
  defaultProfile() {
    return (
      <Grid container alignItems="center">
        <Grid item style={webStyle.profile}>
          <img
            width="100%"
            style={webStyle.beforeSelect}
            src={this.state.selectedImage}
          />
        </Grid>
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={webStyle.uploadButton}>
            Upload photo
            <input
              accept="image/*"
              style={webStyle.selectProfile}
              id="contained-button-file"
              multiple
              type="file"
              onChange={this.handleUploadClick}
            />
          </Button>
        </label>
      </Grid>
    );
  }

  selectedProfile() { 
    return (
      <Grid container alignItems="center"> 
        <Grid item style={webStyle.profile}>
          <img
            width="100%" 
            style={webStyle.profileImg}
            src={this.state.selectedImage} 
          />
        </Grid>
        <label htmlFor="contained-button-file"> 
          <Button variant="contained" component="span" style={webStyle.uploadButton}>
            Upload Photo
            <input
              accept="image/*"
              style={webStyle.selectProfile}
              id="contained-button-file"
              multiple
              type="file"
              onChange={this.handleUploadClick}
            /> 
          </Button>
        </label> 
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={webStyle.changeProfile}>
            Change
            <input
              accept="image/*"
              style={webStyle.selectProfile}
              id="contained-button-file" 
              multiple
              type="file" 
              onChange={this.handleUploadClick}
            />
          </Button>
        </label> 
      </Grid> 
    );
  }

  studentDetailsSection = () => {
    const StudentDetailsSchema = Yup.object().shape({
      first_name: Yup.string().required(this.errorFirstnameRequired),
      date_of_birth: Yup.string().required(this.errorDobRequired).test(
        "date_of_birth",
        "You must be at least 16 years old to use this platform.",
        value => {
          return moment().diff(moment(value),'years') >= 16;
        }
      ),
      last_name: Yup.string().required(this.errorLastnameRequired),
      email: Yup.string().email("Invalid email").required(this.errorEmailRequired),
      city: Yup.string().required(this.errorCityRequired),
      country: Yup.string().required(this.errorCountryRequired),
      studentTermsAgree: Yup.bool().oneOf([true], this.errorAcceptTermsCondition),
    })
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps:any = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      getContentAnchorEl: null
    };



    return <Formik
      initialValues={this.state.initialStudentDetails}
      validationSchema={StudentDetailsSchema}
      onSubmit={(values: any) => { this.continueToSetPassword({...values, profile_image: this.state.selectedFile}) }}
      data-test-id="formStudentDetails"
    >
      {({ errors, values, touched, handleChange, handleBlur, handleSubmit, setFieldValue }: any) => (
        <form onSubmit={(e: any) => {
          handleSubmit();
          e.preventDefault();
          if (values.date_of_birth === "" || errors.date_of_birth) {
            document?.getElementsByClassName("firstName")[0].scrollIntoView(true)
          }
        }} style={{ margin: "0" }}>
          <ConnectedFocusError />
          <Container maxWidth="sm" style={webStyle.container}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "50px",
                bgcolor: "#ffffff",
                borderRadius: "14px",
                position: 'relative',
                margin: "50px auto 100px",
                maxWidth: "434px",
                fontFamily: "HelveticaNeue",
              }}
            >
              <Box style={webStyle.pageTitle}>
                <img src={BackArrow} style={webStyle.backArrow} data-test-id="backArrow" onClick={() => {this.props.navigation.navigate("Register")}}/>
                <Typography variant="h5" component="h1">
                  Set up your student profile
                </Typography>
              </Box>

              <Box sx={{ width: "100%", paddingTop: "20px"}}>
                <Grid container spacing={2}> 
                  <Grid item xs={6} style={webStyle.fieldSet}> 
                    <InputLabel style={webStyle.fieldLabel}>{this.labelFirstName} <span>*</span></InputLabel>
                    <InputField
                      error={errors.first_name && touched.first_name}
                      id="first_name" 
                      name="first_name"
                      fullWidth={true}
                      placeholder="First name"
                      variant="outlined"
                      value={values.first_name}
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      className="firstName"
                    />
                    {this.finalErrorMessage(errors.first_name, touched.first_name)}
                  </Grid> 
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={{ display: "flex", justifyContent: "space-between", ...webStyle.fieldLabel }}>
                      <span>{this.labelMiddleName}</span>
                      <span style={{ color: "#bd9e44", fontSize: "14px", letterSpacing: "-1" }}>(Optional)</span>
                    </InputLabel>
                    <InputField
                      id="middle_name" 
                      name="middle_name" 
                      fullWidth={true}
                      placeholder="Middle name"
                      variant="outlined"
                      value={values.middle_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={6} style={webStyle.fieldSet} className="openTime">
                    <InputLabel style={webStyle.fieldLabel}>{this.labelDob}</InputLabel>
                    <div style={{ position: 'relative', paddingTop: "10px" }}>
                      <DatePicker
                        id="date_of_birth"
                        name="date_of_birth"
                        onChange={(value: any) => { setFieldValue("date_of_birth", value) }}
                        value={values.date_of_birth}
                        className={errors.date_of_birth && touched.date_of_birth ? "dobCalErr dobCalender" : "dobCal dobCalender"}
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        format="dd/MM/yyyy"
                      />
                      <img src={Calender} style={{ width: "22px", position: "absolute", right: "10px", top: "25px", pointerEvents: 'none' }} />
                    </div>
                    {this.finalErrorMessage(errors.date_of_birth, touched.date_of_birth)}
                  </Grid>
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.lastName} <span>*</span></InputLabel>
                    <InputField
                      error={errors.last_name && touched.last_name} 
                      id="last_name"
                      name="last_name"
                      fullWidth={true} 
                      placeholder="Last name"
                      variant="outlined"
                      value={values.last_name}
                      onChange={handleChange} 
                      onBlur={handleBlur}
                    /> 
                    {this.finalErrorMessage(errors.last_name, touched.last_name)}
                  </Grid>
                  <Grid item xs={12} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelEmailAddress} <span>*</span></InputLabel>
                    <InputField
                      error={errors.email && touched.email}
                      id="email"
                      name="email"
                      fullWidth={true}
                      placeholder="Email address"
                      variant="outlined"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                    {this.finalErrorMessage(errors.email, touched.email)}
                  </Grid>
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelLocation} <span>*</span></InputLabel>
                    <InputField
                      error={errors.city && touched.city}
                      id="city"
                      name="city"
                      fullWidth={true}
                      placeholder="City *"
                      variant="outlined"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {this.finalErrorMessage(errors.city, touched.city)}
                  </Grid>
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={{ ...webStyle.fieldLabel, visibility: 'hidden' }}>{this.labelCountry}</InputLabel>
                    <InputField
                      error={errors.country && touched.country}
                      id="country"
                      name="country"
                      fullWidth={true}
                      placeholder="Country *"
                      variant="outlined"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {this.finalErrorMessage(errors.country, touched.country)}
                  </Grid>
                  <Grid item xs={12} style={webStyle.fieldSet}>
                    <InputLabel style={{ display: "flex", justifyContent: "space-between", ...webStyle.fieldLabel }}>
                      <span>{this.labelOrgName}</span>
                      <span style={{ color: "#bd9e44", fontSize: "14px", letterSpacing: "-1"}}>(Optional)</span>
                    </InputLabel>
                     <Select
                      id="name_of_organisation"
                      name="name_of_organisation"
                      variant="outlined"
                      fullWidth
                      style={webStyle.select}
                      value={values.name_of_organisation}
                      displayEmpty
                      onChange={handleChange}
                      onBlur={handleBlur}
                      MenuProps={MenuProps}
                    >
                      <MenuItem selected value='' disabled style={{color: "#565656"}}>Academic Organization</MenuItem>
                      {this.state.organisationList?.map((orgList:any) => {
                        return <MenuItem value={orgList.name_of_organisation} key={orgList.id}>{orgList.name_of_organisation}</MenuItem>
                      })}
                      {this.state.organisationList.length === 0 && 
                       <MenuItem disabled>No data</MenuItem>
                       }
                    </Select>
                  </Grid>
                  <Grid item xs={12} style={webStyle.fieldSet}>
                    <InputLabel style={{ display: "flex", justifyContent: "space-between", ...webStyle.fieldLabel }}>
                      <span>{this.labelJobTitle}</span>
                      <span style={{ color: "#bd9e44", fontSize: "14px", letterSpacing: "-1" }}>(Optional)</span>
                    </InputLabel>
                    <InputField
                      id="job_title"
                      name="job_title"
                      fullWidth={true}
                      placeholder="Job title"
                      variant="outlined"
                      value={values.job_title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelHeadline}</InputLabel>
                    <TextArea
                      id="headline"
                      name="headline"
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth={true}
                      placeholder="Headline"
                      value={values.headline}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelProfilePhoto}</InputLabel>
                    {(this.state.mainState == "initial" && this.defaultProfile()) ||
                      (this.state.mainState == "uploaded" && this.selectedProfile())}
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <Checkbox
                      id="studentTermsAgree"
                      name="studentTermsAgree"
                      style={{ color: "#bd9e44", padding: "9px 5px 9px 0" }}
                      checked={values.studentTermsAgree}
                      value={values.studentTermsAgree}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography style={{ fontSize: "14px", letterSpacing: '-0.031rem', fontFamily: "HelveticaNeue" }}>
                      By clicking this box you agree you are at least <span style={{ color: '#bd9e44', fontWeight: 'bold' }}>16 years old</span> and agree to all <span style={{ color: '#bd9e44', fontWeight: 'bold', cursor: "pointer" }} onClick={() => {this.getTermsPolicy(); this.setState({openTermsConditionPopup: true})}}>Terms and Conditions </span> and <span style={{ color: '#bd9e44', fontWeight: 'bold', cursor: "pointer" }} onClick={() => {this.getTermsPolicy();this.setState({openPrivacyPolicyPopup: true})}}>Privacy Policy </span> of <span style={{ color: '#bd9e44', fontWeight: 'bold' }}>EDConnect.</span>
                    </Typography>
                  </Grid>
                  {errors.studentTermsAgree && touched.studentTermsAgree &&
                    <div style={{ ...webStyle.fieldError, margin: "0px 5px 5px 22px" }}>
                      {errors.studentTermsAgree}
                    </div>
                  }
                  <Grid item xs={12}>
                    <Box
                      sx={{ 
                        display: "flex",
                        flexDirection: "column", 
                        alignItems: "center",
                        padding: "10px 0px",
                      }}
                    >
                      <Button 
                        type="submit" 
                        data-test-id={"btnEmailLogIn"}
                        variant="contained"
                        color="primary"
                        style={{ height: "56px", fontSize: "16px", textTransform: "none", borderRadius: "8px" }}
                        fullWidth 
                      >
                        {this.btnTxtRegister}
                      </Button>
                    </Box>
                  </Grid>

                </Grid> 
              </Box>
              <TermsConditionsPop
                open={this.state.openTermsConditionPopup}
                handleModal={this.handleTermsConditionPopup}
                data={this.state.termsPolicyData?.terms}
                title={"Terms & Conditions"}
              />
              <TermsConditionsPop
                open={this.state.openPrivacyPolicyPopup}
                handleModal={this.handlePrivacyPolicyPopup}
                data={this.state.termsPolicyData?.poilicies}
                title={"Privacy Policy"}
              />
            </Box>
          </Container>
        </form>
      )}
    </Formik>
  }

  finalErrorMessage(error: any, touch: any) {
    if (error && touch) { 
        return <div style={webStyle.fieldError}>
          {error}
        </div>
    }
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppHeader /> 
        <Loader loading={this.state.isLoading}/>
        {
          this.state.isDetails === true ?
            !this.state.isLoading && this.studentDetailsSection() : 
            <StudentSetPasswordWeb navigation={this.props.navigation} id={""} userDetails={this.state.userDetails} isBack={() => this.handleBack()} />
        }
        <AppFooter /> 
      </ThemeProvider>
    );
  }
// Customizable Area End
}
// Customizable Area Start
const webStyle: any = { 
  container: {
    minHeight: "calc(100vh - 250px)", 
    display: 'flex',
    flexDirection: "column", 
    justifyContent: 'center',
    fontFamily: "HelveticaNeue",
  }, 
  profileImg: { 
    width: "100px",
    height: "100px", 
    borderRadius: "50%"
  }, 
  backArrow: {
    position: "absolute",
    width: "25px", 
    left: "0",
    top: "3px",
    cursor: "pointer"
  },
  beforeSelect: {
    width: "30px", 
    height: "30px",
  }, 
  selectProfile: {
    display: "none"
  },
  profile: {
    width: "100px",
    height: "100px", 
    border: "2px solid #bd9e44",
    borderRadius: "50%",
    display: "flex", 
    justifyContent: "center",
    alignItems: "center", 
    margin: "10px 15px 0 0"
  }, 
  uploadButton:{
    width: "144px",
    height: "46px",
    borderRadius: "8px", 
    backgroundColor: "#bd9e44",
    color: "#f9f8f8",
    letterSpacing: 0,
    textTransform: "unset", 
    marginRight: "10px"
  },
  changeProfile: {
    width: "144px",
    height: "46px", 
    borderRadius: "8px",
    backgroundColor: "#fffaec",
    color: "#bd9e44",
    letterSpacing: 0,
    textTransform: "unset", 
    border: "2px solid #bd9e44",
    fontWeight: "bold"
  },
  pageTitle: {
    position: "relative",
    width: "100%",
    textAlign: "center",
  },
  fieldError: {
    color: "#D11024",
    fontSize: "14px", 
    margin: "5px 5px 5px 0"
  },
  fieldLabel: {
    marginBottom: "2px",
    color: "#1c1c1c",
    fontSize: "14px"
  },
  fieldSet: {
    padding: "12px 8px"
  },
  select: { 
    width: "100%",
    borderRadius: "8px",
    margin: "10px 0",
    color: "#565656"
  }, 
};


const InputField:any = withStyles({ 
  root: {
      width: '100%',
      paddingTop: '10px',
      borderRadius: "10px",
      fontFamily: "HelveticaNeue",
      "& .MuiOutlinedInput-root": {
          fontWeight: 400,
          fontFamily: "HelveticaNeue-Medium",
          fontSize: "16px",
          "& fieldset": {
              fontSize: "16px",
              borderRadius: "10px",
              borderWidth: 1,
              fontWeight: 400,
          },
      },
      "& ::placeholder": {
        opacity: 1,
        color: "#565656",
        fontFamily: "HelveticaNeue",
      },
      "& .Mui-error":{
        "& .MuiOutlinedInput-notchedOutline":{
          borderColor: "#D11024"
        }
      },
  }
})(TextField);

const TextArea:any = withStyles({ 
    root: {
        width: '100%',
        paddingTop: '10px',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400, 
            fontFamily: "HelveticaNeue-Medium",
            fontSize: "16px", 
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
          opacity: 1,
          color: "#565656",
          fontFamily: "HelveticaNeue", 
        },
        "& .Mui-error":{
          "& .MuiOutlinedInput-notchedOutline":{
            borderColor: "#D11024"
          }
        },
    }
})(TextField);

// Customizable Area End
