
// Customizable Area Start
// Customizable Area End

import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
   
    // Customizable Area End
  }
  
  interface S {
    // Customizable Area Start
    PostData: any;
    isLoading:boolean;
    
    // Customizable Area End
  }
  
  interface SS {
    id: any;
  }

export default class SearchResultController<T>  extends BlockComponent<Props, S, SS>  {
  // Customizable Area Start
  constructor(props:Props){
    super(props)
        this.state={
        PostData: [{
          "id": "2",
          "type": "post",
          "attributes": {
            "id": 2,
            "name": "hello",
            "description": "helii post",
            "body": "#textt",
            "location": null,
            "account_id": 108,
            "created_at": "17 days ago",
            "updated_at": "2023-03-14T12:09:31.680Z",
            "model_name": "BxBlockPosts::Post",
            "images_and_videos": [
              {
                "id": 14,
                "filename": "Screenshot from 2023-02-18 11-14-23.png",
                "url": "https://linked-291430-ruby.b291430.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBFdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e7d44b090d1c79a5c82d4b4941bfa83eb2c67de0/Screenshot%20from%202023-02-18%2011-14-23.png",
                "type": "image"
              }
            ],
            "media": []
          }
        },
        {
          "id": "2",
          "type": "post",
          "attributes": {
            "id": 2,
            "name": "hello",
            "description": "helii post",
            "body": "#textt",
            "location": null,
            "account_id": 108,
            "created_at": "17 days ago",
            "updated_at": "2023-03-14T12:09:31.680Z",
            "model_name": "BxBlockPosts::Post",
            "images_and_videos": [
              {
                "id": 14,
                "filename": "Screenshot from 2023-02-18 11-14-23.png",
                "url": "https://linked-291430-ruby.b291430.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBFdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e7d44b090d1c79a5c82d4b4941bfa83eb2c67de0/Screenshot%20from%202023-02-18%2011-14-23.png",
                "type": "image"
              }
            ],
            "media": []
          }
        },
        
      ],
        isLoading:false,
    }
  }
  // Customizable Area End

  async componentDidMount() {
    // super.componentDidMount();
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End
}
