// Customizable Area Start
import React from 'react';
import { Box, styled } from "@material-ui/core";
import MessageChatBox from './MessageChatBox.web';


const profile = require("../src/profile.png");
interface Props{
    openChat:boolean
    setOpenChat:any
}
function MessagingPeoplesList(props:Props) {
   
    const {openChat,setOpenChat}=props

  

    return (<>
        <StyledDiv onClick={() => setOpenChat(true)}>
            <div style={{ marginRight: '10px', position: 'relative' }}>
                <img src={profile} width='50px' />
                <OnlineCircle />

            </div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '14px', fontFamily: "HelveticaNeue" }}>Nahia Colunga</div>
                    <div style={{ fontSize: '10px', fontFamily: "HelveticaNeue" }}>12 jan</div>

                </div>
                <div style={webStyles.texts}>
                    Now is the winter of our discontent
                </div>

            </div>

        </StyledDiv>
        {openChat && <MessageChatBox setOpenChat={setOpenChat}/>}
    </>
    )
}

const webStyles = {
   
    texts: {
        fontSize: '12px',
        color: '#7C7C7C',
        marginTop: '5px',
        fontFamily: "HelveticaNeue"
    },
    
   
}
const StyledDiv = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '10px',
    padding: '5px 16px',
    position: 'relative',
    '&:hover': {
        backgroundColor: 'rgba(216, 216, 216, 0.356671)'

    },
});
const OnlineCircle = styled(Box)({
    width: '7px',
    height: '7px',
    backgroundColor: '#008F48',
    borderRadius: '100%',
    border: '1px solid #008F48',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    top: '84%',
    left: '84%'
});

export default MessagingPeoplesList
// Customizable Area End