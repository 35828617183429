import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#be9e44",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import PostProfileGuestsController, { Props } from "./PostProfileGuestsController.web";
import AppFooter from "../../../components/src/AppFooter.web";
import GuestProfileTopWeb from "./GuestProfileTop.web";
import GuestprofileWorkEqu2 from "./GuestporfileWorkEqu2.web";
import GuestProfileSkillAfter from "./GuestProfileSkillAfter.web";

export default class PostProfileGuestsWeb extends PostProfileGuestsController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    // Customizable Area Start
    // Customizable Area End
  

  render() {
    // Customizable Area Start
   

    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyle.container}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
             <GuestProfileTopWeb
               navigation={'top'}
               studentProfileDetails={this.state.studentProfileDetails}
            educationDetails={this.state.educationDetails}
            certificationDetails={this.state.certificationDetails}
            workExperienceDetails={this.state.workExperienceDetails}
            />
           
           <GuestprofileWorkEqu2 
           navigation={'workequ'}
           educationDetails={this.state.educationDetails}
           workExperienceDetails={this.state.workExperienceDetails}
            />

          <GuestProfileSkillAfter
            navigation={'gueastafter'}
            certificationDetails={this.state.certificationDetails}
            workExperienceDetails={this.state.workExperienceDetails}
            getWorkExperience={this.getWorkExperience}
          />
            </Grid>
            <Grid item xs={4}>
              <Box style={webStyle.footerMenus}>
                <Typography style={webStyle.footerMenu}>
                  <NavLink
                    to={{ pathname: "/About" }}
                    style={{
                      textDecoration: "none",
                      fontSize: "10px",
                      color: "#7c7c7c",
                    }}
                  >
                    About us
                  </NavLink>
                </Typography>
                <Typography style={webStyle.footerMenu}>Contact us</Typography>
                <Typography style={webStyle.footerMenu}>
                  Privacy Policy
                </Typography>
                <Typography style={webStyle.footerMenu}>
                  Terms & Conditions
                </Typography>
              </Box>
              <Box>
                <Typography style={webStyle.footerContent}>
                  Copyright &#169;{new Date().getFullYear()} Edconnect. All
                  right reserved.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <AppFooter />
      </ThemeProvider>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
  container: {
    minHeight: "calc(100vh - 270px)",
  },
 footerMenus: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: "15px",
  },
  footerMenu: {
    fontSize: "10px",
    color: "#7c7c7c",
    margin: "5px 35px",
    cursor: "pointer",
  },
 footerContent: {
    fontSize: "10px",
    color: "#7c7c7c",
    marginTop: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

};



// Customizable Area End
