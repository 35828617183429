// Customizable Area Start
import React, { useEffect, useState } from 'react'
import { Box, Modal, Fade, Backdrop } from "@material-ui/core";
import { profile } from '../../blocks/postcreation/src/assets';
import axios from 'axios'
const { baseURL: apiBaseUrl } = require('../../framework/src/config.js')


const Like = require("../src/likeReaction.png");
const Support = require("../src/support.png");
const Funny = require("../src/emoji.png");
const Love = require("../src/love.png");
const close = require("../src/close.png");
const userAccImg = require("../src/userAccImg.png");



interface ReactionModalProps {
    openReactionModal: boolean;
    setReactionModal: any;
    allAccounts: any;
    postId: any;
    commentId: any;
    setPostId: any;
    setCommentId: any;
    commentOfCommentId: any;
    setCommentOfCommentIdForReaction: any
}

function ReactionModal(props: ReactionModalProps) {

    const arr = [{ icon: 'All', alt: 'All', isSelected: true }, { icon: Like, alt: "Like", isSelected: false }, { icon: Support, alt: "Support", isSelected: false }, { icon: Love, alt: "Love", isSelected: false }, { icon: Funny, alt: "Funny", isSelected: false }]
    const tempArr = ['Like', 'Support', 'Love', 'Funny']
    const [reactionArray, setArray] = useState<any>([])
    const [supportReactionAccounts, setSupportReactionAccounts] = useState<any>([])
    const [likeReactionAccounts, setLikeReactionAccounts] = useState<any>([])
    const [loveReactionAccounts, setLoveReactionAccounts] = useState<any>([])
    const [funnyReactionAccounts, setFunnyReactionAccounts] = useState<any>([]);
    const [obj, setObj] = useState<any>({})

    const handleClose = () => {
        props?.setReactionModal(false);
        props?.setPostId('');
        props?.setCommentId('');
        props?.setCommentOfCommentIdForReaction('')
    }
    const renderIcons = (text: any) => {
        switch (text) {
            case 'Like': return Like;
            case 'Love': return Love;
            case 'Support': return Support
            case 'Funny': return Funny
            default:
                break;
        }
    }
    useEffect(() => {
        setArray(arr);
        if (props?.commentId != '') {
            tempArr.forEach(element => {

                props?.openReactionModal && getSpecificReactionAccountsComments(props?.commentId, element);

            });
        }
        if (props?.commentOfCommentId != '') {
            tempArr.forEach(element => {

                props?.openReactionModal && getSpecificReactionAccountsComments(props?.commentOfCommentId, element);

            });
        }
        if (props?.postId != '') {
            tempArr.forEach(element => {

                props?.openReactionModal && getSpecificReactionAccounts(props?.postId, element);

            });
        }
        setObj({ ...obj, All: true, Like: false, Support: false, Funny: false, Love: false })
    }, [props?.openReactionModal]);


    const getSpecificReactionAccountsComments = (id: any, type: any) => {
        console.log(id, type);
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };

        axios.get(`${apiBaseUrl}/bx_block_like/show_accounts_for_reactions_to_comment?comment_id=${id}&reaction_type=${type}`, {
            headers: header
        })
            .then((response: { data: any; }) => {
                if (type == 'Support') {
                    console.log('support');
                    console.log(response?.data['accounts_for_support'])

                    setSupportReactionAccounts(response?.data['accounts_for_support'])
                }
                else if (type == 'Like') {
                    setLikeReactionAccounts(response?.data[`accounts_for_${type}`.toLowerCase()])
                }
                else if (type == 'Love') {
                    setLoveReactionAccounts(response?.data[`accounts_for_${type}`.toLowerCase()])
                }
                else if (type == 'Funny') {
                    setFunnyReactionAccounts(response?.data[`accounts_for_${type}`.toLowerCase()])
                }



            })
            .catch(error => {
                console.log(error);
            });


    }
    const getSpecificReactionAccounts = (id: any, type: any) => {
        console.log(id, type);
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };

        axios.get(`${apiBaseUrl}/bx_block_like/show_accounts_for_reactions_to_post?post_id=${id}&reaction_type=${type}`, {
            headers: header
        })
            .then((response: { data: any; }) => {
                if (type == 'Support') {
                    console.log('support');
                    console.log(response?.data['accounts_for_support'])

                    setSupportReactionAccounts(response?.data['accounts_for_support'])
                }
                else if (type == 'Like') {
                    setLikeReactionAccounts(response?.data[`accounts_for_${type}`.toLowerCase()])
                }
                else if (type == 'Love') {
                    setLoveReactionAccounts(response?.data[`accounts_for_${type}`.toLowerCase()])
                }
                else if (type == 'Funny') {
                    setFunnyReactionAccounts(response?.data[`accounts_for_${type}`.toLowerCase()])
                }



            })
            .catch(error => {
                console.log(error);
            });

    }

    const handleEachReactionClick = (element: any) => {
        if (element?.alt == 'Like') {
            setObj({ ...obj, Like: true, Support: false, Funny: false, Love: false, All: false })
        }
        else if (element?.alt == 'Support') {
            setObj({ ...obj, Like: false, Support: true, Funny: false, Love: false, All: false })
        }
        else if (element?.alt == 'Funny') {
            setObj({ ...obj, Like: false, Support: false, Funny: true, Love: false, All: false })
        }
        else if (element?.alt == 'Love') {
            setObj({ ...obj, Like: false, Support: false, Funny: false, Love: true, All: false })
        }
        else {
            setObj({ ...obj, Like: false, Support: false, Funny: false, Love: false, All: true })
        }
    }
    const renderCounts = (element: any) => {
        if (element?.alt == 'Like') {
            return likeReactionAccounts?.length
        }
        else if (element?.alt == 'Support') {
            return supportReactionAccounts?.length
        }
        else if (element?.alt == 'Funny') {
            return funnyReactionAccounts?.length
        }
        else if (element?.alt == 'Love') {
            return loveReactionAccounts?.length
        }
        else {

        }
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props?.openReactionModal}
                onClose={handleClose}
                closeAfterTransition
                slot={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={props?.openReactionModal}>
                    <Box sx={style}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ color: '#1C1C1C', fontSize: '20px', fontWeight: 'bold' }}>Reactions</div>
                            <div onClick={handleClose}><img src={close} width='24px' /></div>
                        </div>

                        <div style={{ marginTop: '10px' }}>
                            <div style={{ borderBottom: '0.5px solid #D7D7D7', display: 'flex' }}>

                                {reactionArray?.map((element: any) => {
                                    return <div
                                        onClick={() => {
                                            handleEachReactionClick(element)
                                            getSpecificReactionAccounts(props?.postId, element?.alt)
                                            setArray(reactionArray?.map((item: any) => {
                                                if (item?.icon == element?.icon) {
                                                    return { ...item, isSelected: true }
                                                }
                                                else {
                                                    return { ...item, isSelected: false }
                                                }
                                            }))
                                        }}
                                        style={{
                                            display: 'flex',
                                            marginRight: '20px',
                                            cursor: 'pointer',
                                            borderBottom: element?.isSelected ? '4px solid #BE9E44' : '4px solid white',
                                            padding: '0px 10px',
                                            alignItems: 'center'
                                        }}>
                                        <div style={{ display: 'flex', marginBottom: '5px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
                                                {element?.icon == 'All' ? <span style={{ color: '#BE9E44' }}>All {props?.allAccounts?.length}</span> : <img src={element?.icon} width='20px' />}
                                            </div>

                                            {element?.icon == 'All' ? '' : <div style={webStyle?.count}>{renderCounts(element)}</div>}
                                        </div>
                                    </div>
                                })}



                            </div>

                        </div>

                        <div style={{ overflow: 'auto', height: props?.allAccounts?.length > 6 ? '470px' : 'auto' }}>

                            {obj['All'] && props?.allAccounts?.map((account: any) => {
                                return <>
                                    <div style={{ margin: '10px 0px' }}>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ position: 'relative', marginRight: '10px' }}>
                                                <img src={account?.profile_image ? account?.profile_image : userAccImg} style={webStyle?.accImg} />
                                                <div style={{ position: 'absolute', transform: 'translate(-50%,-50%)', left: '85%', top: '85%' }}>
                                                    <img src={renderIcons(account?.reaction_type)} width='15px' />
                                                </div>
                                            </div>
                                            <div>

                                                <div style={{ fontSize: '14px', color: '#1C1C1C' }}>{account?.first_name} {account?.last_name}</div>
                                                <div style={{ fontSize: '12px', color: '#7C7C7C' }}>{account?.name_of_organisation}</div>

                                            </div>

                                        </div>
                                    </div>
                                    <hr style={{ marginLeft: '55px', height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                                </>
                            })}
                            {obj['Support'] && supportReactionAccounts?.map((account: any) => {
                                return <>
                                    <div style={{ margin: '10px 0px' }}>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ position: 'relative', marginRight: '10px' }}>
                                                <img src={account?.profile_image ? account?.profile_image : userAccImg} style={webStyle?.accImg} />
                                                <div style={{ position: 'absolute', transform: 'translate(-50%,-50%)', left: '85%', top: '85%' }}>
                                                    <img src={renderIcons(account?.reaction_type)} width='15px' />
                                                </div>
                                            </div>
                                            <div>

                                                <div style={{ fontSize: '14px', color: '#1C1C1C' }}>{account?.first_name} {account?.last_name}</div>
                                                <div style={{ fontSize: '12px', color: '#7C7C7C' }}>{account?.name_of_organisation}</div>

                                            </div>

                                        </div>
                                    </div>
                                    <hr style={{ marginLeft: '55px', height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                                </>
                            })}
                            {obj['Like'] && likeReactionAccounts?.map((account: any) => {
                                return <>
                                    <div style={{ margin: '10px 0px' }}>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', marginRight: '10px' }}>
                                                <img src={account?.profile_image ? account?.profile_image : userAccImg} style={webStyle?.accImg} />
                                                <div style={{ position: 'absolute', transform: 'translate(-50%,-50%)', left: '85%', top: '85%' }}>
                                                    <img src={renderIcons(account?.reaction_type)} width='15px' />
                                                </div>
                                            </div>
                                            <div>

                                                <div style={{ fontSize: '14px', color: '#1C1C1C' }}>{account?.first_name} {account?.last_name}</div>
                                                <div style={{ fontSize: '12px', color: '#7C7C7C' }}>{account?.name_of_organisation}</div>

                                            </div>

                                        </div>
                                    </div>
                                    <hr style={{ marginLeft: '55px', height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                                </>
                            })}
                            {obj['Funny'] && funnyReactionAccounts?.map((account: any) => {
                                return <>
                                    <div style={{ margin: '10px 0px' }}>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', marginRight: '10px' }}>
                                                <img src={account?.profile_image ? account?.profile_image : userAccImg} style={webStyle?.accImg} />
                                                <div style={{ position: 'absolute', transform: 'translate(-50%,-50%)', left: '85%', top: '85%' }}>
                                                    <img src={renderIcons(account?.reaction_type)} width='15px' />
                                                </div>
                                            </div>
                                            <div>

                                                <div style={{ fontSize: '14px', color: '#1C1C1C' }}>{account?.first_name} {account?.last_name}</div>
                                                <div style={{ fontSize: '12px', color: '#7C7C7C' }}>{account?.name_of_organisation}</div>

                                            </div>

                                        </div>
                                    </div>
                                    <hr style={{ marginLeft: '55px', height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                                </>
                            })}
                            {obj['Love'] && loveReactionAccounts?.map((account: any) => {
                                return <>
                                    <div style={{ margin: '10px 0px' }}>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', marginRight: '10px' }}>
                                                <img src={account?.profile_image ? account?.profile_image : userAccImg} style={webStyle?.accImg}  />
                                                <div style={{ position: 'absolute', transform: 'translate(-50%,-50%)', left: '85%', top: '85%' }}>
                                                    <img src={renderIcons(account?.reaction_type)} width='15px' />
                                                </div>
                                            </div>
                                            <div>

                                                <div style={{ fontSize: '14px', color: '#1C1C1C' }}>{account?.first_name} {account?.last_name}</div>
                                                <div style={{ fontSize: '12px', color: '#7C7C7C' }}>{account?.name_of_organisation}</div>

                                            </div>

                                        </div>
                                    </div>
                                    <hr style={{ marginLeft: '55px', height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                                </>
                            })}




                        </div>


                    </Box>



                </Fade>
            </Modal>
        </div>
    )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '1px solid #979797',
    boxShadow: 24,
    p: 4,
    borderRadius: '12px',
    overflow: 'auto',
    maxHeight: '80vh'
};
const webStyle = {
    count: {
        fontSize: '14px',
        color: '#565656'
    },
    eachReaction: {
        display: 'flex',
        marginRight: '30px',
        // borderBottom: '4px solid #BE9E44',
        padding: '0px 10px',
        alignItems: 'center',
        cursor: 'pointer'
    },
    accImg:{
        borderRadius:'100%',
        width:'50px',
        height:'50px',
        border:'1px solid #f0f0f0'
    }
}
export default ReactionModal
// Customizable Area End