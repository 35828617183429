import React from "react";
// Customizable Area Start
import { Box, Typography, Container } from "@material-ui/core";
import LandingPageController from "./LandingPageController";
import { Sponsers } from "../../../components/src/Sponsers.web";
import { edConnectLogo } from "./assets";
import AppFooter from "../../../components/src/AppFooter.web";
import { handleBackAboutPage } from "../../../components/src/common";
// Customizable Area End
export default class AboutPage extends LandingPageController {
  render() {
    // Customizable Area Start
    let data = this.state.aboutUsData?.data?.data?.attributes;

    return (
      <Box sx={aboutStyle.container}>
        <Container style={{ padding: "15px 10px" }}>
            <img src={edConnectLogo} alt='logo' style={aboutStyle.logo} data-test-id="edconnectLogo" onClick={()=>{handleBackAboutPage(this.props.navigation)}}/>
        </Container>
        <Box sx={aboutStyle.content_containerWrap}>
          <Box sx={aboutStyle.content_container}>
            <Typography style={aboutStyle.headings} data-test-id="aboutHeading">
              About <span style={{ color: "rgb(190,158,68)" }} data-test-id="subHeading">EDConnect</span>
            </Typography>
            <Box sx={aboutStyle.content}>
              <Box style={{ textAlign: "center" }}>
                Welcome to EDConnect the educational community, where everyone
                connect. 10 million members in more that 200 countries and
                territories worlwide.
              </Box>
              <hr style={{ borderTop: "0.5px solid lightgrey", marginTop: "15px" }} />
            </Box>
          </Box>
          <Box sx={aboutStyle.content_container}>
            <Typography style={aboutStyle.headings} data-test-id="missionHeading">Mission</Typography>
            <Box sx={aboutStyle.content}>
              <Box style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: data?.custom_data?.mission }}></Box>
            </Box>
            <hr style={{ borderTop: "0.5px solid lightgrey", marginTop: "15px" }} />
          </Box>
          <Box sx={aboutStyle.content_container}>
            <Typography style={aboutStyle.headings} data-test-id="visionHeading">Vision</Typography>
            <Box sx={aboutStyle.content} dangerouslySetInnerHTML={{ __html: data?.custom_data?.vision }}></Box>
            <hr style={{ borderTop: "0.5px solid lightgrey", marginTop: "15px" }} />
          </Box>
          <Box sx={aboutStyle.content_container}>
            <Typography style={aboutStyle.headings} data-test-id="whoWeAreHeading">Who are we?</Typography>
            <Box sx={aboutStyle.content} dangerouslySetInnerHTML={{ __html: data?.custom_data?.who_are_we }}></Box>
          </Box>
          <Sponsers data={this.state.sponsersData.data} />
        </Box>
        <AppFooter termsPolicesData={this.state.termsAndPolices} />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const aboutStyle = {
  container: {
    // border: "1px solid black",
  },
  nav: {
    width: "80%",
    margin: "auto",
    marginBottom: "6vh",
    padding: "10px",
    textDecoration: "none"
    // border: "2px solid green",
  },
  line: {
    color: "grey",
  },
  logo: {
    width: "85px",
    // width: "80%",
    margin: "auto",
    marginBottom: "6vh",
    padding: "10px",
    textDecoration: "none",
    cursor: "pointer"
  },
  content_containerWrap: {
    gap: "10px",
    width: "1280px",
    maxWidth: "100%",
    margin: "auto",
  },
  headings: {
    fontSize: "32px",
    // paddingTop:"5px"
    // fontWeight: "500",
  },
  content_container: {
    width: "576px",
    maxWidth: "100%",
    margin: "auto",
    textAlign: "center",
    paddingBottom: "10px",
    // borderBottom: "1px solid grey",
    lineHeight: "20px",
  },
  content: {
    padding: "0px 20px",
    color: "grey",
    fontSize: "14px",
    fontWeight: "normal",
  },
};
  // Customizable Area End
