import React from 'react'
import { Formik } from 'formik'
// Customizable Area Start
import {
    Container,
    Box,
    Button,
    InputLabel,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
  } from "@material-ui/core";
  import { withStyles } from "@material-ui/core/styles";
  import { BackArrow, TickIcon } from "./assets";
import * as Yup from 'yup';
import SetPasswordController, {
    Props
  } from "./SetPasswordController";
// Customizable Area End

export default class StudentSetPassword extends SetPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    finalErrorMessage(error: any, touch: any) {
        if (error && touch) {
            return <div style={webStyle.fieldError}>
                {error}
            </div>
        }
    }
    setPasswordSection() {
        const setPasswordSchema = Yup.object().shape({
            password: Yup
                .string()
                .required(this.errorPasswordRequired)
                .min(6, this.errorPasswordLength)
                .matches(/\d/, this.errorPassNumber)
                .matches(/[a-z]/, this.errorPassLower)
                .matches(/[A-Z]/, this.errorPassUpper)
                .matches(/[^\w]/, this.errorPassSymbol),
            confirm_password: Yup
                .string()
                .required(this.errorConfirmPassRequired)
                .oneOf([Yup.ref('password'), null], this.errorPasswordMatch)
        })
        return (<Formik
            initialValues={this.state.initialPasswordDetails}
            validationSchema={setPasswordSchema}
            onSubmit={(values: any) => { this.registerUser(values, this.props.userDetails) }}
            data-test-id="formSetPassword"
        >
            {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
                <form onSubmit={handleSubmit} data-test-id="passwordForm" style={{ margin: "0" }}>
                    <Container maxWidth="sm" style={webStyle.container}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "50px",
                                bgcolor: "#ffffff",
                                borderRadius: "14px",
                                position: 'relative',
                                margin: "10px 0",
                                maxWidth: "434px",
                                fontFamily: "HelveticaNeue",
                            }}
                        >
                            <Box style={webStyle.pageTitle}>
                                <img src={BackArrow} style={webStyle.backArrow} data-test-id="backArrow" onClick={() => { this?.props?.isBack() }} />
                                <Typography variant="h5" component="h1">
                                    Set a password!
                                </Typography>
                            </Box>

                            <Box sx={{ width: "100%", paddingTop: "20px" }}>
                                <Box sx={{ padding: "20px 0px" }}>
                                    <InputLabel style={{ display: "flex", justifyContent: "space-between", ...webStyle.fieldLabel }}>
                                        <span>{this.labelPassword}</span>
                                        <span style={{ color: "#bd9e44", fontSize: "14px", letterSpacing: "-1" }}>(6 or more characters)</span>
                                    </InputLabel>
                                    <InputField
                                        error={errors.password && touched.password}
                                        data-test-id="txtInputPassword"
                                        type={this.state.enablePasswordField ? "password" : "text"}
                                        id="password"
                                        name="password"
                                        fullWidth={true}
                                        placeholder="Password"
                                        variant="outlined"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {this.state.enablePasswordField ? (
                                                            <Typography style={{ color: "#bd9e44", fontSize: "14px" }}>Show</Typography>
                                                        ) : (
                                                            <Typography style={{ color: "#bd9e44", fontSize: "14px" }}>Hide</Typography>
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {this.finalErrorMessage(errors.password, touched.password)}
                                </Box>
                                <Box sx={{ padding: "20px 0px" }}>
                                    <InputLabel style={{ display: "flex", justifyContent: "space-between", ...webStyle.fieldLabel }}>
                                        <span>{this.labelConfirmPassword}</span>
                                        <span style={{ color: "#bd9e44", fontSize: "14px", letterSpacing: "-1" }}>(6 or more characters)</span>
                                    </InputLabel>
                                    <InputField
                                        error={errors.confirm_password && touched.confirm_password}
                                        data-test-id="txtInputPassword"
                                        type={this.state.enableReTypePasswordField ? "password" : "text"}
                                        id="confirm_password"
                                        name="confirm_password"
                                        fullWidth={true}
                                        placeholder="Confirm password"
                                        variant="outlined"
                                        value={values.confirm_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowConfirmPassword}
                                                        edge="end"
                                                    >
                                                        {this.state.enableReTypePasswordField ? (
                                                            <Typography style={{ color: "#bd9e44", fontSize: "14px" }}>Show</Typography>
                                                        ) : (
                                                            <Typography style={{ color: "#bd9e44", fontSize: "14px" }}>Hide</Typography>
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}

                                    />
                                    {this.finalErrorMessage(errors.confirm_password, touched.confirm_password)}
                                    {this.state.accountError && <div style={{ color: "#D11024", fontSize: '14px', marginTop: "8px" }}>{this.state.accountError}</div>}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        padding: "10px 0px",
                                    }}
                                >
                                    <Button
                                        type="submit"
                                        data-test-id={"btnEmailLogIn"}
                                        variant="contained"
                                        color="primary"
                                        style={{ height: "56px", fontSize: "16px", borderRadius: "8px", textTransform: "none" }}
                                        fullWidth
                                    >
                                        {this.btnTxtContinue}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </form>
            )}
        </Formik>)
    }

    PasswordChangedSection = () => {
        return <Container maxWidth="sm" style={webStyle.container}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "50px",
                    bgcolor: "#ffffff",
                    borderRadius: "14px",
                    position: 'relative',
                    maxWidth: "434px",
                    fontFamily: "HelveticaNeue",
                }}
            >

                <Box sx={{ width: "100%", paddingTop: "20px", textAlign: "center" }}>
                    <img src={TickIcon} style={webStyle.tickIcon} />
                    <Typography variant="h5" style={{ margin: "15px 0 25px" }}>{this.titlePasswordChanged}</Typography>
                    <Typography variant="subtitle1" style={{ color: '#7c7c7c', margin: "10px 0" }}>{this.txtPasswordChanged}</Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "10px 0px",
                        }}
                    >
                        <Button
                            data-test-id={"btnEmailLogIn"}
                            variant="contained"
                            color="primary"
                            style={{ height: "56px", fontSize: "16px", textTransform: "none", borderRadius: "8px" }}
                            fullWidth
                            onClick={() => { localStorage.setItem("userType", "student");this.props.navigation.navigate("Login") }}
                        >
                            Sign in
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    }
    
    render() {
        return (
            this.state.registerPopup === true ? 
            this.setPasswordSection() : 
            this.PasswordChangedSection()
        );
    }
    // Customizable Area End
}

const webStyle: any = { 
    container: {
      minHeight: "calc(100vh - 250px)", 
      display: 'flex',
      flexDirection: "column", 
      justifyContent: 'center',
      fontFamily: "HelveticaNeue",
    }, 
    profileImg: { 
      width: "100px",
      height: "100px", 
      borderRadius: "50%"
    }, 
    backArrow: {
      position: "absolute",
      width: "25px", 
      left: "0",
      top: "3px",
      cursor: "pointer"
    },
    beforeSelect: {
      width: "30px", 
      height: "30px",
    }, 
    selectProfile: {
      display: "none"
    },
    profile: {
      width: "100px",
      height: "100px", 
      border: "2px solid #bd9e44",
      borderRadius: "50%",
      display: "flex", 
      justifyContent: "center",
      alignItems: "center", 
      margin: "10px 15px 0 0"
    }, 
    uploadButton:{
      width: "144px",
      height: "46px",
      borderRadius: "8px", 
      backgroundColor: "#bd9e44",
      color: "#f9f8f8",
      letterSpacing: 0,
      textTransform: "unset", 
      marginRight: "10px"
    },
    changeProfile: {
      width: "144px",
      height: "46px", 
      borderRadius: "8px",
      backgroundColor: "#fffaec",
      color: "#bd9e44",
      letterSpacing: 0,
      textTransform: "unset", 
      border: "2px solid #bd9e44",
      fontWeight: "bold"
    },
    pageTitle: {
      position: "relative",
      width: "100%",
      textAlign: "center",
    },
    fieldError: {
      color: "#D11024",
      fontSize: "14px", 
      margin: "5px 5px 5px 0"
    },
    fieldLabel: {
      marginBottom: "2px",
      color: "#1c1c1c",
      fontSize: "14px"
    },
    fieldSet: {
      padding: "12px 8px"
    },
    tickIcon: {
        width: '85px'
    },
  };

const InputField: any = withStyles({
    root: {
        width: '100%',
        paddingTop: '10px',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue-Medium",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        },
        "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D11024"
            }
        },
    }
})(TextField);
