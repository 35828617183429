// Customizable Area Start
import React, { useEffect, useState } from 'react'
import { TextField, Box, withStyles, InputAdornment } from "@material-ui/core";

import { BottomPagination } from './BottomPagination.web';

const profile = require("../src/profile.png");





interface Props {
    studentsData: any;
    isLoad: any;
}
function StudentList(props: Props) {



    return (
        <>

            <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: '10px 10px 0 0', border: '1px solid #F0F0F0' }}>
                <div style={{ fontSize: "20px", fontFamily: "HelveticaNeue", marginBottom: "10px", color: "#1C1C1C", fontStyle: "italic" }}>Students</div>
                {props?.studentsData?.map((student: any) => {
                    return <div key={student?.id} style={{ padding: '5px 0' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: '15px' }}>
                                    <img src={profile} style={{ border: '1px solid #D6D3D3', borderRadius: '50%' }} width='48px' />
                                </div>
                                <div>
                                    <div style={{ color: "#1C1C1C", fontFamily: "HelveticaNeue", fontSize: '16px', fontStyle: "italic" }}>Allicia Puma</div>
                                    <div style={{ fontSize: '14px', color: '#1C1C1C', margin: '2px 0', fontFamily: "HelveticaNeue", }}>Ottawa, Canada</div>
                                    <div style={{ fontSize: '14px', color: '#7C7C7C', fontFamily: "HelveticaNeue", fontStyle: "italic" }}>222k followers.</div>
                                </div>
                            </div>
                            <div style={{ display: 'flext', justifyContent: "center", alignItems: "center" }}>
                                <button style={{ background: '#BE9E44', border: 'none', color: '#F9F9F9', textAlign: 'center', borderRadius: "4px", fontSize: '14px', fontStyle: "italic", fontFamily: "HelveticaNeue", width: "100px", height: "38px" }}>Connect</button>
                            </div>
                        </div>
                        <div style={{ margin: '10px 17% 15px 11%', fontSize: "14px", fontFamily: "HelveticaNeue", color: '#6A6A6A', lineHeight: "21px" }}>
                            222K Five years since opening our doors as  Canada’s first fully digital hospital and we remain unwavering in our belief that we can change the hospital…
                        </div>

                        <hr style={{ border: '0.5px solid #D7D7D7' }} />
                    </div>

                })}



            </Box>
            {props.studentsData.length > 2 && <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", bgcolor: 'background.paper', p: 2, margin: "10px 0", borderRadius: '0 0 10px 10px', border: '1px solid #F0F0F0' }}>
                <BottomPagination count={4} handleChange={(e: any) => console.log(e)} page={2} />
            </Box>}

        </>
    )
}

const webStyles = {
    newPost: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sendBox: {
        width: '32px',
        height: '32px',
        backgroundColor: '#BE9E44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    heading: {
        fontSize: '12px',
        lineHeight: '14px',
        color: '#1C1C1C',
        padding: '5px 0px 5px 0px',
        fontFamily: "HelveticaNeue",


    }
    ,
    feedsFont: {
        fontSize: '14px',
        fontFamily: "HelveticaNeue",
        color: '#565656'
    },
    actionLIne: {
        display: 'flex',
        margin: '5px 0px',
        cursor: 'pointer'
    },
    actionText: {
        fontSize: '16px',
        color: '#696868',
        marginLeft: '10px',
        fontFamily: 'HelveticaNeue'
    }
}
export default StudentList

// Customizable Area End