// Customizable Area Start
import React from 'react';
import Loader from '../../../components/src/Loader.web';
import NavBarWithSearch from '../../ElasticSearch/src/NavBarWithSearch.web';
import HashtagGeneratorController, { Props } from './HashtagGeneratorController';
import { Container, Grid, Modal, Backdrop, Box, Fade, styled, Button} from "@material-ui/core";
import RightSection from './RightSection.web';
import { connections, following } from './assets';
import { close } from '../../postcreation/src/assets';

export default class ManageHashtags extends HashtagGeneratorController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        return (
            <>
                <Loader loading={this.state?.isLoading} />
                <NavBarWithSearch navigation={this.props.navigation} id={''} />
                <Container style={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                        <Grid item md={3}>
                            <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0' }}>
                                <div style={{ fontSize: '16px', color: '#1C1C1C', fontWeight: 'bold' }}>Manage my network</div>
                                <div style={{ display: 'flex', margin: '20px 0px' }}>
                                    <div style={{ marginRight: '15px' }}><img src={connections} width="20px" /></div>
                                    <div data-test-id="connection"
                                    onClick={()=>this.props?.navigation?.navigate('Connections')}
                                    style={{ color: '#A8A8A8', fontSize: '16px',cursor:'pointer' }}>Connections</div>

                                </div>
                                <div style={{ display: 'flex', margin: '20px 0px' }}>
                                    <div style={{ marginRight: '15px' }}><img src={following} width="20px" /></div>
                                    <div data-test-id="following"
                                    onClick={()=>this.props?.navigation?.navigate('Following')}
                                    style={{ color: '#A8A8A8', fontSize: '16px',cursor:'pointer' }}>Following</div>

                                </div>
                                <div style={{ display: 'flex', margin: '20px 0px' }}>
                                    <div style={{ marginRight: '15px',fontWeight:'bold',fontSize:'20px' }}>#</div>
                                    <div style={{ color: '#1C1C1C', fontSize: '16px', borderBottom: '1px solid #1C1C1C' }}>Hashtags</div>

                                </div>

                            </Box>

                        </Grid>
                        <Grid item md={6}>
                            <Box sx={{ bgcolor: 'background.paper', borderRadius: '10px', border: '1px solid #F0F0F0' }}>
                                <div style={{ borderBottom: '0.5px solid #D7D7D7', padding: '15px', fontSize: '18px', color: '#1C1C1C', fontWeight: 'bold' }}>{this.state?.totalHashtagFollowedCount} {this.state?.totalHashtagFollowedCount>1?'Hashtags':'Hashtag'}</div>
                                {this.state?.followedHashTagsList?.map((hashtag: any, index: any) => {
                                    return <React.Fragment key={hashtag?.id}>
                                        <div data-test-id="followedHashTags" style={{ padding: '15px', display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <div style={{ color: '#1C1C1C', fontSize: '16px', fontWeight: 'bold',marginBottom:'5px' }}>#{hashtag?.name}</div>
                                                <div style={{ color: '#565656', fontSize: '14px' }}>{hashtag?.followers_count} {hashtag?.followers_count>1?'followers':'follower'}</div>
                                            </div>
                                            <div>
                                                <StyledUnFollowButton
                                                data-test-id="unfollow"
                                                onClick={() => {
                                                    this.setState({ hashtagId:hashtag?.id,openUnfollowModal: true,hashtagName:hashtag?.name})

                                                }}>Unfollow</StyledUnFollowButton>
                                            </div>

                                        </div>
                                        {this.state?.followedHashTagsList?.length - 1 != index && <hr style={{ margin: '0px 15px', height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />}
                                    </React.Fragment>
                                })}
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <RightSection />


                        </Grid>

                    </Grid>

                </Container>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state?.openUnfollowModal}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={this.state?.openUnfollowModal}>
                        <Box sx={style}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '0.5px solid #D7D7D7', padding: '15px' }}>
                                <div style={{ fontSize: '18px', color: '#1C1C1C', fontWeight: 'bold' }}>Unfollow</div>
                                <div data-test-id="close" style={{cursor:'pointer'}} onClick={()=>{
                                    this.setState({openUnfollowModal:false})
                                }}><img src={close} width="24px" /></div>
                            </div>
                            <div style={{ padding: '15px', borderBottom: '0.5px solid #D7D7D7' }}>
                                <div style={{ fontSize: '16px', color: '#1C1C1C' }}>You are about to unfollow #{this.state?.hashtagName}</div>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end',padding:'15px' }}>
                                <StyledCancelButton onClick={()=>this.setState({openUnfollowModal:false})}>
                                    Cancel
                                </StyledCancelButton>

                                <StyledUnfollowFilledButton data-test-id="unFollowFromModal" onClick={()=>this.unfollowHashTag(this.state?.hashtagId)}>Unfollow</StyledUnfollowFilledButton>

                            </div>

                        </Box>
                    </Fade>
                </Modal>

            </>
        )
    }
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '14px',
    boxShadow: 24,

};
const StyledUnFollowButton = styled(Button)({
    backgroundColor: 'rgba(190, 158, 68, 0.1)',
    border: '1px solid #BE9E44',
    borderRadius: '6px',
    color: '#BE9E44',
    marginRight: '10px',
    padding: '5px 15px',
    textTransform: 'capitalize',
    fontSize: '14px'

});
const StyledCancelButton = styled(Button)({
    backgroundColor: 'rgba(190, 158, 68, 0.1)',
    border: '1px solid #BE9E44',
    borderRadius: '6px',
    color: '#BE9E44',
    marginRight: '10px',
    padding: '5px 25px',
    textTransform: 'capitalize',

});
const StyledUnfollowFilledButton = styled(Button)({
    backgroundColor: '#BD9E44',
    borderRadius: '6px',
    color: '#F9F9F9',
    padding: '5px 25px',
    '&:hover': {
        backgroundColor: '#BD9E44'

    },
    textTransform: 'capitalize',

});
// Customizable Area End
