import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";

interface IApiCall {
  header: any;
  httpBody: any;
  url: string;
  httpMethod: string;
}

export function getHeaders(obj: Record<string, string>): Headers {
  const headers: any = {};
  headers["Content-Type"] = "application/json";
  for (let key in obj) {
    headers[key] = obj[key];
  }
  return headers;
}

export function handleCheckLoggedInUser(navigation: any, getPostData:any) {
  navigation.navigate("FanWall");
  window. scrollTo(0, 0);
  getPostData();
}

export function handleBackAboutPage(navigation: any) {
  let getToken: any = localStorage.getItem("authToken")
  if (getToken === null) {
    navigation.navigate("LandingPage");
  } else {
    navigation.navigate("FanWall");
  }
}

export function apiCall({ header, httpBody, url, httpMethod }: IApiCall) {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  if (Object.keys(httpBody).length > 0) {
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    httpMethod
  );

  return requestMessage;
}

export function isLoggedIn(navigation: any): boolean | string {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      if (navigation) {
        navigation.navigate("Login");
        return false;
      } else {
        return false;
      }
    } else {
      return authToken;
    }
  }  
