import React ,  { useState } from "react";
import { Modal, Box, Typography, TextField, Button ,InputAdornment} from "@material-ui/core";
import * as Yup from "yup";
import { HighlightOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
const close = require("../src/close.png");
   

const style: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 3,
  overflow: "auto",
  borderRadius: '8px'
};

const ShareModel = ({ open, handleClose,copyText }: any) => {
  const [data, setData] = React.useState({ achievements: "" });

  const contactUsSchema: any = Yup.object().shape({
    achievements: Yup.string().required("achievements is required."),
  });

//  onst [copyText,setCopyText]=useState('') c
  
  const handleCopy=()=>{
      navigator.clipboard.writeText(copyText)
      alert("Copied")
  }

  // const handleModelClose = (value:any) => {
  //     const header = {
  //         // "Content-Type": "application/json",
  //         token: "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6Ik82UlU4OWNJamY2Y3ZTR1VKaW5PU2d0dCIsImV4cCI6MTY4MTk3NDQ1MywidG9rZW5fdHlwZSI6ImxvZ2luIn0.oe-NDvgvNGOw3vnNeBvgZuHog60T3egtr-tUSFYllTkqJXPGdHoyGbs5dXjHEpWjgMAXwt7rnCv0jr6JpauunA"
  //     };

  //     let formdata = new FormData();
  //     formdata.append("career_experience[add_key_achievements][]", value.achievements);
  //     //@ts-ignore
  //     formdata.append("id", 11)

  //     const requestMessage = new Message(
  //         getName(MessageEnum.RestAPIRequestMessage)
  //     );
  //     requestMessage.addData(
  //         getName(MessageEnum.RestAPIRequestHeaderMessage),
  //         JSON.stringify(header)
  //     );
  //     requestMessage.addData(
  //         getName(MessageEnum.RestAPIResponceEndPointMessage),
  //         "bx_block_profile/career_experiences/add_achievement"
  //     );
  //     requestMessage.addData(
  //         getName(MessageEnum.RestAPIRequestMethodMessage),
  //         "PATCH"
  //     );
  //     requestMessage.addData(
  //         getName(MessageEnum.RestAPIRequestBodyMessage),
  //         formdata

  //     );

  //     runEngine.sendMessage(requestMessage.id, requestMessage);
  //     handleClose()
  //     getWorkExperience()

  //   }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box style={{height:"70%"}}>
          <img
          src={close}
            style={modalContactStyle.closeIcon}
            onClick={handleClose}
          />
          <Typography style={modalContactStyle.contact_textContent}>
            Share
          </Typography>
          <Formik
            initialValues={data}
            validationSchema={contactUsSchema}
            onSubmit={(values: any) => console.log(values)}
          >
            {({
              errors,
              values,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }: any) => (
              <form onSubmit={handleSubmit}>
                <Box style={modalContactStyle.main}>
                  <Box style={modalContactStyle.row}>
                    <Box style={modalContactStyle.field}>
                      <InputField
                      value={copyText} 
                      onChange={(e)=>setCopyText(e.target.value)}
                        error={errors.achievements && touched.achievements}
                        testID="contactName"
                        data-test-id="inputName"
                        placeholder="Ex. Man of the match"
                        fullWidth={true}
                        id="achievements"
                        name="achievements"
                        variant="outlined"
                        onBlur={handleBlur}

                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button variant="contained" color="primary" onClick={handleCopy} style={{textTransform: 'none'}}> Copy link</Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                     
                    </Box>
                  </Box>
                  
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ShareModel;

const modalContactStyle: any = {
  contact_textContent: {
    fontSize: "20px",
    textAlign: "center",
    marginBottom: "20px",
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "20px",
    top: "20px",
    height: "20px",
  },
  fieldError: {
    color: "#D11024",
    fontSize: "14px",
    margin: "-7px 5px 10px 0",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    gap: "24px",
  },
  name: {
    width: "426px",
  },
  field: {
    width: "876px",
    marginBottom: "10px",
  },

  submitButton: {
    height: "32px",
    backgroundColor: "#BE9E44",
    borderRadius: "8px",
    color: "#fff",
  },
  submit: {
    width: "876px",
    display: "flex",
    justifyContent: "end",
  },
};

const InputField: any = withStyles({
  root: {
    padding: "10px 0",
    borderRadius: "10px",
    fontFamily: "HelveticaNeue-medium",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontFamily: "HelveticaNeue",
      fontSize: "16px",
      "& fieldset": {
        fontSize: "16px",
        borderRadius: "10px",
        borderWidth: 1,
        fontWeight: 400,
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
    "& .Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D11024",
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#565656",
      fontFamily: "HelveticaNeue-Medium",
    },
  },
})(TextField);
