import React from "react";

// Customizable Area Start
import { Box, Typography, MenuItem, Select, Paper, Button, Modal } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#be9e44",
            contrastText: "#fff",
        },
    },
});
import { RemoveIcon, SearchIcon, CloseIcon, UserIcon } from "./assets";
const modalStyle: any = {
    position: "absolute",
    top: "49%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxWidth: "100%",
    maxHeight: "700px",
    bgcolor: "#fff",
    borderRadius: 12,
    boxShadow: 24,
    padding: "0",
    overflowY: "auto",
    "@media (max-width: 500px)": {
        padding: "20px",
        width: "75%"
    }
};
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
import EducationalUserProfileController, {
    Props,
} from "./EducationalUserProfileController";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

export class AlumniTab extends EducationalUserProfileController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;

        const MenuProps:any = {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 200,
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        };

        const open: any = Boolean(this.state.anchorEl);
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                {this.state.isLoading ? <div style={{textAlign: 'center'}}><CircularProgress color="primary" /></div> : 
                <CustomPaper style={webStyle.about}>
                    <CustomTypography style={webStyle.title}>{this.checkAlumniCount()}</CustomTypography>
                    <Box className={classes.alumniHeader}>
                    <CustomTypography className={classes.sortFilter}>
                            {this.sortByText}
                            <Select
                                id="sortby"
                                name="sortby"
                                style={webStyle.sortbyFilter}
                                disableUnderline
                                defaultValue={"0"}
                                MenuProps={MenuProps}
                                value={this.state.selectedeAlumniSortByFilter}
                            >
                                <MenuItem value="0" data-test-id="sortByRecent" selected={true} onClick={() => {this.getAlumniByFilter(0);this.setState({selectedeAlumniSortByFilter: '0'})}}>{this.recentlyAddedText}</MenuItem>
                                <MenuItem value="1" data-test-id="sortByFirstame" onClick={() => {this.getAlumniByFilter(1);this.setState({selectedeAlumniSortByFilter: '1'})}}>{this.firstNameText}</MenuItem>
                                <MenuItem value="2" data-test-id="sortByLastname" onClick={() => {this.getAlumniByFilter(2);this.setState({selectedeAlumniSortByFilter: '2'})}}>{this.lastNameText}</MenuItem>
                            </Select>
                        </CustomTypography>
                        <Box className={classes.alumniBtn}>
                            {this.checkBecomeAlumni(classes)}
                            <div className={classes.searchInput}>
                                <input
                                    type="text"
                                    id="search"
                                    name="search"
                                    placeholder="Search by name"
                                    className={classes.searchField}
                                    value={this.state.alumniSearchValue}
                                    onChange={(event) => this.handleInputChange(event)}
                                />
                                <img src={SearchIcon} style={webStyle.searchIcon} alt="search icon" />
                            </div>
                        </Box>
                    </Box>

                    {this.state.alumniList?.length !== 0 ? this.state.alumniList?.map((userData: any, index: any) => {
                        return (<>
                            <hr style={{ borderTop: "0.03rem solid #d7d7d7", margin: "15px 0" }} key={index}/>
                            <Box className={classes.alumniDetails} key={index}>
                                <Box style={{ display: "flex", width: "75%" }}>
                                    <div
                                        className={classes.userImage}
                                    >
                                        <img src={this.checkUserdataProfile(userData)} style={userData.profile_image === null ? webStyle.defaultProfileImage : webStyle.profileImage} alt="profile image"/>
                                    </div>
                                    <Box style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                        <CustomTypography style={webStyle.userName}>{userData.first_name} {userData.last_name}</CustomTypography>
                                        <CustomTypography style={webStyle.userClg}>{userData.organisation_name}</CustomTypography>
                                        {this.checkConnectedTime(userData)}
                                    </Box>
                                </Box>
                                <Box className={classes.actionButtons}>
                                    {this.checkIsOwner(userData, classes)}
                                    {this.checkRemoveAlumniIcon(userData, classes)}
                                </Box>
                            </Box>
                        </>)
                    }) : <Box style={{ marginTop: "15px", textAlign: 'center' }}>
                        <hr style={{ borderTop: "0.03rem solid #d7d7d7", margin: "15px 0" }} />
                        {this.noAlumniText}
                    </Box>}
                </CustomPaper>}
                <Modal  //modal for remove alumni
                    open={this.state.removeAlumniPopup}
                    onClose={this.handleRemoveAlumniPopupClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <img
                            src={CloseIcon}
                            className={classes.closeIcon}
                            onClick={this.handleRemoveAlumniPopupClose}
                        />
                        <CustomTypography className={classes.modalTitle}>
                            {this.removeAlumniText}
                        </CustomTypography>
                        <hr/>
                        <CustomTypography className={classes.coverTitle}>
                            {this.confirmRemoveAlumniText}
                        </CustomTypography>
                        <hr/>
                        <div style={{display: 'flex',justifyContent: "end", alignItems: "center", padding: "10px 24px 15px 0"}}>
                        <Button className={classes.cancelBtn} onClick={()=>{this.handleRemoveAlumniPopupClose()}}>Cancel</Button>
                        <Button className={classes.removeBtn} onClick={()=>{this.removeAlumni();this.setState({removeAlumniPopup: false})}}>Remove</Button>
                        </div>
                    </Box>
                </Modal>
                <Snackbar open={this.state.isRemoveSuccess} autoHideDuration={3000} onClose={() => this.handleCloseRemoveAlumniSuccess()}>
                    <Alert onClose={() => {this.handleCloseRemoveAlumniSuccess()}} severity="success">
                        {this.state.accountError}
                    </Alert>
                </Snackbar>
            </ThemeProvider>
        );
        // Customizable Area End
    }
    // Customizable Area Start
    checkAlumniCount() {
        if(this.state.isOwner){
            return "Alumni list"
        }
        else {
            return `${this.state.alumniList?.length} Alumni`
        }
    }

    checkBecomeAlumni(classes:any) {
        let getUserDetails:any = localStorage.getItem("user_details");
        let getType:any = JSON.parse(getUserDetails)?.account_type;
        if(!this.state.isOwner && !this.state.isAlumni && (getType === "StudentAccount" || getType === "student_account")) {
            return <Button style={webStyle.becomeAlumniBtn} className={classes.becomeAlumniBtn} onClick={() => {this.becomeAlumni()}}>{this.becomeAlumniBtnText}</Button>
        }
    }

    checkConnectedTime(userData:any) {
        if(this.state.isOwner) {
            return <CustomTypography style={webStyle.connectTime}>Connected&nbsp;{userData.connected_at}</CustomTypography>
        }
    }

    checkConnectButton(userData:any, classes:any) {
        if((Number(localStorage.getItem("account_id")) !== userData.id)) {
            return <Button className={classes.connectBtn}>Connect</Button>
        }
    }

    checkRemoveAlumniIcon(userData:any, classes:any) {
        if(this.state.isOwner) {
            return <img src={RemoveIcon} className={classes.removeIcon} alt="remove icon" onClick={()=> {this.handleRemoveAlumniPopupOpen(userData.id)}}/>
        }
    }

    checkUserdataProfile(userData:any) {
        if(userData.profile_image === null){
            return UserIcon
        }
        else {
            return userData.profile_image
        }
    }

    checkIsOwner(userData:any, classes:any) {
        if(this.state.isOwner){
            return <CustomButton>Message</CustomButton>
        }
        else {
            return this.checkMessageButton(userData, classes)
        }
    }

    checkMessageButton(userData:any, classes:any) {
        if(userData.is_connected === true) {
            return <CustomButton>Message</CustomButton>
        }
        else {
            return this.checkConnectButton(userData, classes)
        }
    }
    // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
    about: {
        position: 'relative'
    },
    title: {
        fontSize: "22px",
        fontWeight: "normal",
        color: "#1c1c1c"
    },
    sortbyFilter: {
        minWidth: '120px',
        marginLeft: "10px"
    },
    userName: {
        fontSize: "16px",
        color: "#1c1c1c",
        fontWeight: "normal"
    },
    userClg: {
        fontSize: "14px",
        color: "#1c1c1c",
        fontWeight: "normal"
    },
    connectTime: {
        fontSize: "12px",
        color: "#6a6a6a",
        fontWeight: "normal"
    },
    removeIcon: {
        width: "24px",
        height: "24px",
    },
    becomeAlumniBtn: {
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "6px",
        textTransform: "inherit",
        margin: "0 10px"
    },
    searchIcon: {
        width: "20px",
        height: "20px",
        position: "absolute",
        left: "11px",
        top: "11px"
    },
    defaultProfileImage: {
        width: "30px",
        height: "30px"
    },
    profileImage: {
        width: "100%",
        height: "100%",
        borderRadius: "50%"
    }
};

const styles:any = () => ({
    becomeAlumniBtn: {
        "@media (max-width: 1200px)": {
            position: "absolute",
            top: "15px",
            right: "5px"
        }
    },
    searchInput: {
        position: "relative",
        "@media (max-width: 576px)": {
            width: "100%"
        }
    },
    searchField: {
        width: "315px",
        height: "42px",
        border: "none",
        borderRadius: '6px',
        backgroundColor: "#f7f7f7",
        color: "#1c1c1c",
        paddingLeft: "40px",
        "&:focus": {
            outline: "none"
        },
        "@media (max-width: 576px)": {
            width: "100%"
        }
    },
    alumniHeader: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "10px",
        "@media (max-width: 576px)": {
            flexDirection: "column-reverse",
            alignItems: "end",
        }
    },
    sortFilter: {
        display: "flex",
        alignItems: "center",
        color: "#565656",
        "@media (max-width: 576px)": {
            marginTop: "10px"
        }
    },
    alumniBtn: {
        display: "flex",
        "@media (max-width: 576px)": {
            width: "100%"
        }
    },
    actionButtons: {
        display: "flex",
        alignItems: "center",
        "@media (max-width: 576px)": {
            alignItems: "center",
            justifyContent: "start",
            marginLeft: "60px"
        }
    },
    userImage: {
        width: "64px",
        minWidth: "64px",
        height: "64px",
        minHeight: "64px",
        border: "1px solid black",
        borderRadius: "50%",
        marginRight: "10px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "@media (max-width: 576px)": {
            width: "50px",
            minWidth: "50px",
            height: "50px",
            minHeight: "50px"
        }
    },
    removeIcon: {
        width: "24px",
        height: "24px",
        cursor: "pointer",
        "@media (max-width: 576px)": {
            position: "absolute",
            top: "0",
            right: "0"
        }
    },
    alumniDetails: {
        display: 'flex',
        justifyContent: "space-between",
        position: "relative",
        "@media (max-width: 576px)": {
            flexDirection: "column",
        }
    },
    cancelBtn: {
        width: "100px",
        color: "#be9e44",
        background: "#f8f8f8",
        borderRadius: "4px",
        textTransform: "capitalize",
        border: '1px solid #be9e44',
        marginRight: '5px'
    },
    removeBtn: {
        width: "100px",
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "4px",
        textTransform: "capitalize",
        marginLeft: '5px',
        fontSize: "14px",
        "&:hover": {
            color: "#f8f8f8",
            background: "#be9e44",
        },
    },
    closeIcon: {
        position: "absolute",
        right: "24px",
        top: "24px",
        width: "24px",
        height: "24px",
        cursor: "pointer"
    },
    modalTitle: {
        padding: '20px 0 5px 24px',
        fontSize: "18px",
        fontWeight: "600",
    },
    coverTitle: {
        padding: "8px 24px"
    },
    connectBtn: {
        width: '100px',
        background: "#be9e44",
        color: "#f8f8f8",
        borderRadius: "6px",
        textTransform: "capitalize",
        margin: "0 10px",
        "&:hover": {
            color: "#f8f8f8",
            background: "#be9e44",
        },
    }
})

const CustomTypography: any = withStyles({
    root: {
        color: "#1c1c1c"
    },
})(Typography);

const CustomPaper: any = withStyles({
    root: {
        boxShadow: "unset",
        margin: "15px 0",
        border: "1px solid #e2e2e2",
        padding: "15px",
        borderRadius: "10px"
    }
})(Paper);

const CustomButton: any = withStyles({
    root: {
        width: "100px",
        height: "36px",
        opacity: 1,
        boxShadow: "none",
        border: "1px solid #be9e44",
        background: "rgba(190, 158, 68, 0.1)",
        borderRadius: "6px",
        fontSize: '16px',
        textTransform: "none",
        color: "#be9e44",
        margin: "0 10px",
        "@media (max-width: 576px)": {
            width: "unset",
            margin: "0",
            fontSize: "14px",
            marginRight: "10px",
        }
    },
})(Button);


const CustomMenuItem: any = withStyles({
    root: {
        color: "#1c1c1c"
    },
})(MenuItem);

export default withStyles(styles)(AlumniTab)

// Customizable Area End
