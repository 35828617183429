// Customizable Area Start
import React from 'react'
import { Container, Grid, Modal, Box, Fade, Backdrop, TextField, withStyles, styled, Button } from "@material-ui/core";
import UserProfileBasicController from './UserProfileBasicController';
import { bin, close, right } from './assets';
import { Formik } from 'formik'
import * as Yup from 'yup'
import CommonLoader from '../../../components/src/CommonLoader.web';
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import SuccessModal from '../../../components/src/SuccessModalOne.web';

import ModalR from 'react-modal';


export default class Accounts extends UserProfileBasicController {
    handleClose = () => {
        this.setState({ openSuccessModal: false })
    }

    render() {
        const updateProfileObjSChema = Yup.object().shape({
            first_name: Yup.string().required('First name is required'),
            last_name: Yup.string().required('Last name is required'),
            date_of_birth: Yup.date()
                .required('Date of birth is required')
                .max(new Date(new Date().setFullYear(new Date().getFullYear() - 17)), 'You must be at least 16yrs old to use this platform').nullable(),
            city: Yup.string().required('City is required'),
            country: Yup.string().required('Country is required'),
            name_of_organisation: Yup.string().required('Name of organization is required').nullable(),
            job_title: Yup.string().required('Job title is required').nullable()


        })





        return (
            <>


                <Box data-test-id="accBox" sx={{ bgcolor: 'background.paper', height: '100px', borderRadius: '10px', p: 2 }}>

                    <div style={{ fontSize: '18px', fontFamily: "HelveticaNeue", marginTop: '10px' }}>
                        Profile information
                    </div>

                    <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontSize: '16px', fontFamily: "HelveticaNeue", color: '#565656' }}>Name, location, and school</span>
                        <div data-test-id="editButton" onClick={() => {
                            this.setState({ isEdit: true })
                        }} style={{ cursor: 'pointer' }}><img src={right} width='24px' /></div>
                    </div>



                </Box>
                <Box data-test-id="accBox1" sx={{ bgcolor: 'background.paper', height: '150px', marginTop: '15px', borderRadius: '10px', p: 2 }}>
                    <div style={{ fontSize: '18px', fontFamily: "HelveticaNeue", marginTop: '10px' }}>Manage account</div>

                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px', alignItems: 'center' }}>
                            <div style={{ color: '#F45B5B', fontFamily: "HelveticaNeue", fontWeight: 'bold', cursor: 'pointer' }}>Delete account</div>
                            <div data-test-id="deleteAccount" onClick={() => this.setState({ openDeleteConfirmationModal: true })} style={{ cursor: 'pointer' }}><img src={bin} width='24px' /></div>
                        </div>
                        <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px', alignItems: 'center' }}>
                            <div style={{ color: '#565656', fontFamily: "HelveticaNeue", cursor: 'pointer' }}>Sign out</div>
                           
                                <div onClick={()=>this.setState({openSignOutConfirmationModal:true})} style={{ cursor: 'pointer' }}><img src={right} width='24px' /></div>
                            
                        </div>

                    </div>


                </Box>

                <ModalR
                    ariaHideApp={false}
                    isOpen={this.state?.isEdit}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEsc={false}
                    className="ReactModal__Overlay"
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                        }

                    }}

                >

                    <Container maxWidth="sm">
                        <CommonLoader isLoading={this.state.isLoading} />
                        <Box sx={style}>
                            <div style={{ position: 'relative' }}>
                                <div style={{ fontSize: '20px', fontFamily: "HelveticaNeue", display: 'flex', justifyContent: 'center', color: '#1C1C1C', fontWeight: 'bold' }}>Edit profile information</div>
                                <div data-test-id="close" onClick={(() => this.setState({ isEdit: false }))}
                                    style={{
                                        cursor: 'pointer', border: '2px solid black', borderRadius: '50%',
                                        position: 'absolute', transform: 'translate(-50%,-50%)', top: '11px', right: '-15px'
                                    }}>
                                    <img src={close} width='24px' />
                                </div>
                                <Formik
                                    initialValues={this.state.profileDetailsObj}
                                    validationSchema={updateProfileObjSChema}
                                    onSubmit={(values: any) => { this.updateProfileInfo(values) }}
                                    data-test-id="updateProfileFormik"
                                >
                                    {({ errors, values, touched, handleSubmit, handleChange, handleBlur, setFieldValue }: any) => (
                                        <form onSubmit={handleSubmit} data-test-id="updateProfile" style={{ margin: "0" }}>
                                            <Container style={{ marginTop: '25px' }}>

                                                <Grid container spacing={3}>
                                                    <Grid item md={6}>
                                                        <div style={webStyles?.labels}>First name *</div>

                                                        <InputField1 variant='outlined' value={values?.first_name}
                                                            name='first_name'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="First name *"

                                                        />
                                                        {errors?.first_name && touched?.first_name &&
                                                            <div style={webStyles.fieldError}>
                                                                {errors?.first_name}
                                                            </div>
                                                        }

                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <div style={webStyles?.labels}>Last name *</div>

                                                        <InputField1 variant='outlined' value={values?.last_name}
                                                            name='last_name'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Last name *"
                                                        />
                                                        {errors?.last_name && touched?.last_name &&
                                                            <div style={webStyles.fieldError}>
                                                                {errors?.last_name}
                                                            </div>
                                                        }
                                                    </Grid>

                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item md={6} >
                                                        <div style={webStyles?.labels}>Date of birth *</div>


                                                        <DatePicker
                                                            name='date_of_birth'
                                                            onChange={(value: any) => { console.log(value); value ? setFieldValue("date_of_birth", value) : setFieldValue("date_of_birth", '') }}
                                                            value={values.date_of_birth}
                                                            className={(errors.date_of_birth && touched.date_of_birth) ? "dobCalErr" : "dobCal"}
                                                            dayPlaceholder="DD"
                                                            monthPlaceholder="MM"
                                                            yearPlaceholder="YYYY"
                                                            format="dd/MM/yyyy"
                                                        />


                                                        {errors?.date_of_birth && touched?.date_of_birth &&
                                                            <div style={webStyles.fieldError}>
                                                                {errors?.date_of_birth}
                                                            </div>
                                                        }

                                                    </Grid>


                                                    <Grid item md={3}>
                                                        <div style={webStyles?.labels}>Location *</div>

                                                        <InputField2 variant='outlined' value={values?.city}
                                                            name='city'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="City *"
                                                        />
                                                        {errors?.city && touched?.city &&
                                                            <div style={webStyles.fieldError}>
                                                                {errors?.city}
                                                            </div>
                                                        }

                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <div style={webStyles?.labels}> </div>


                                                        <InputField2 variant='outlined' value={values?.country}
                                                            name="country"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            margin="normal"
                                                            placeholder="Country *"
                                                        />
                                                        {errors?.country && touched?.country &&
                                                            <div style={webStyles.fieldError}>
                                                                {errors?.country}
                                                            </div>
                                                        }


                                                    </Grid>


                                                </Grid>

                                                <Grid container spacing={3} >
                                                    <Grid item md={12}>
                                                        <div style={webStyles?.labels}>Headline</div>

                                                        <InputField variant='outlined' value={values?.headline}
                                                            name="headline"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="A short sentence about yourself"
                                                        />
                                                        {errors?.headline && touched?.headline &&
                                                            <div style={webStyles.fieldError}>
                                                                {errors?.headline}
                                                            </div>
                                                        }
                                                    </Grid>

                                                </Grid>


                                                <Grid container spacing={3} >
                                                    <Grid item md={12}>
                                                        <div style={webStyles?.labels}>Name of current Academic Organization *</div>

                                                        <InputField variant='outlined' value={values?.name_of_organisation}
                                                            name="name_of_organisation"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Name of current Academic Organization *"
                                                        />
                                                        {errors?.name_of_organisation && touched?.name_of_organisation &&
                                                            <div style={webStyles.fieldError}>
                                                                {errors?.name_of_organisation}
                                                            </div>
                                                        }

                                                    </Grid>


                                                </Grid>

                                                <Grid container spacing={3} >
                                                    <Grid item md={12}>
                                                        <div style={webStyles?.labels}>Job title *</div>

                                                        <InputField variant='outlined' value={values?.job_title}
                                                            name="job_title"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Job title *"
                                                        />
                                                        {errors?.job_title && touched?.job_title &&
                                                            <div style={webStyles.fieldError}>
                                                                {errors?.job_title}
                                                            </div>
                                                        }

                                                    </Grid>


                                                </Grid>


                                                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0px 5px 0px' }}>

                                                    <button type='submit'
                                                        style={{ padding: '10px 25px', color: 'white', backgroundColor: '#BE9E44', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                                                    >Save</button>
                                                </div>
                                            </Container>
                                        </form>
                                    )}
                                </Formik>






                            </div>
                        </Box>
                    </Container>
                    {/* </Fade> */}
                </ModalR>



                {/* delete account confirmation modal */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state?.openDeleteConfirmationModal}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={this.state?.openDeleteConfirmationModal}>
                        <Box sx={deleteModalStyle}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '0.5px solid #D7D7D7', padding: '15px' }}>
                                <div style={{ fontSize: '18px', color: '#1C1C1C', fontWeight: 'bold' }}>Delete Account</div>
                                <div data-test-id="closeDeleteModal" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.setState({ openDeleteConfirmationModal: false })
                                }}><img src={close} width="24px" /></div>
                            </div>
                            <div style={{ padding: '15px', borderBottom: '0.5px solid #D7D7D7' }}>
                                <div style={{ fontSize: '16px', color: '#1C1C1C' }}>Are you sure to delete the account</div>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '15px' }}>
                                <StyledCancelButton data-test-id="deleteAccountNo" onClick={() => this.setState({ openDeleteConfirmationModal: false })}>
                                    No
                                </StyledCancelButton>

                                <StyledYesButton data-test-id="deleteAccountYes" onClick={() => this.deleteAccount()}>Yes</StyledYesButton>

                            </div>

                        </Box>
                    </Fade>
                </Modal>

                {/* signout confirmation modal */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state?.openSignOutConfirmationModal}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={this.state?.openSignOutConfirmationModal}>
                        <Box sx={deleteModalStyle}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '0.5px solid #D7D7D7', padding: '15px' }}>
                                <div style={{ fontSize: '18px', color: '#1C1C1C', fontWeight: 'bold' }}>Sign out</div>
                                <div data-test-id="closeDeleteModal" style={{ cursor: 'pointer' }} onClick={() => {
                                    this.setState({ openSignOutConfirmationModal: false })
                                }}><img src={close} width="24px" /></div>
                            </div>
                            <div style={{ padding: '15px', borderBottom: '0.5px solid #D7D7D7' }}>
                                <div style={{ fontSize: '16px', color: '#1C1C1C' }}>Are you sure you want to sign out ?</div>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '15px' }}>
                                <StyledCancelButton data-test-id="deleteAccountNo" onClick={() => this.setState({ openSignOutConfirmationModal: false })}>
                                    No
                                </StyledCancelButton>

                                <StyledYesButton data-test-id="deleteAccountYes" onClick={() => this.onSignOut()}>Yes</StyledYesButton>

                            </div>

                        </Box>
                    </Fade>
                </Modal>
                <SuccessModal openModal={this.state.openSuccessModal} handleClose={this.handleClose} navigation={this.props?.navigation} message="You have successfully deleted your account" />


            </>
        )
    }
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '1px solid #979797',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};
const deleteModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '14px',
    boxShadow: 24,

};
const webStyles = {
    labels: {
        marginBottom: '10px',
        fontSize: '14px',
        fontFamily: "HelveticaNeue",
        color: '#3A3A3A',
        fontWeight: 'bold'

    },
    fieldError: {
        color: "red",
        fontSize: "14px",
        margin: "5px 5px 5px 0"
    },
}

const InputField: any = withStyles({
    root: {
        width: '870px',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        },
        "@media (min-width: 320px)": {
            width: '100%',
        }

    }
})(TextField);

const InputField1: any = withStyles({
    root: {
        width: '426px',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        },
        "@media (min-width: 320px)": {
            width: '100%',
        }

    }
})(TextField);
const InputField2: any = withStyles({
    root: {
        width: '200px',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        },
        "@media (min-width: 320px)": {
            width: '100%',
        }

    }
})(TextField);
const StyledCancelButton = styled(Button)({
    backgroundColor: 'rgba(190, 158, 68, 0.1)',
    border: '1px solid #BE9E44',
    borderRadius: '6px',
    color: '#BE9E44',
    marginRight: '10px',
    padding: '5px 25px',
    textTransform: 'capitalize',

});
const StyledYesButton = styled(Button)({
    backgroundColor: '#BD9E44',
    borderRadius: '6px',
    color: '#F9F9F9',
    padding: '5px 25px',
    '&:hover': {
        backgroundColor: '#BD9E44'

    },
    textTransform: 'capitalize',

});

// Customizable Area End