// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import { RouteComponentProps } from 'react-router-dom';
export type Props = RouteComponentProps & {
  navigation: any;
  id: string;
  getPostData?: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  isFocus:boolean;
  isProfileOpen:boolean;
  isFilter:{filterToggle:boolean,filterKey:string};
  activeId:number;
  AnchorElNav:React.MouseEvent<HTMLElement> |null;
  anchorElMenu:React.MouseEvent<HTMLElement> |null;
  sidebarAnchorElMenu:React.MouseEvent<HTMLElement> |null;
  openAgeConfirm:boolean;
}

interface SS {
  id: any;

}

export default class NavBarWithSearchWebController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isProfileOpen:false,
      isFocus:false,
      isFilter:{
        filterToggle:false,
        filterKey:""
      },
      activeId:1,
      AnchorElNav:null,
      anchorElMenu:null,
      sidebarAnchorElMenu:null,
      openAgeConfirm:false,
    };

  }
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
  };

  setInputValue = (event: any) => {
    this.setState({ txtInputValue: event.target.value,isFilter:{filterToggle:false,filterKey:""} });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setToggleProfile=()=>{
    this.setState({isProfileOpen:!this.state.isProfileOpen})
  }
  setFocusTogle=(value:boolean)=>{
    this.setState({...this.state,isFocus:value})
  }
  setFilter=(value:boolean,keys:string)=>{
    if(keys===this.state.isFilter.filterKey){
      this.setState({...this.state,isFilter:{filterToggle:value,filterKey:""},isFocus:false,})
    }else{
      this.setState({...this.state,isFilter:{filterToggle:value,filterKey:keys},isFocus:false,})
    }
   
  }
  
  getPostData=()=>{
    const data= {post: [
      {
          "id": 1,
          "name": "hello",
          "description": "helii post",
          "category_id": 6,
          "created_at": "2023-03-22T09:48:57.067Z",
          "updated_at": "2023-03-22T10:13:34.217Z",
          "body": "#textt",
          "location": "indore",
          "account_id": 1,
          "shareable_link": "aHR0cDovL2xvY2FsaG9zdDozMDAwL2J4X2Jsb2NrX3Bvc3RzL3Bvc3RzLzM=\n"
      },
      {
          "id": 2,
          "name": "hello",
          "description": "helii post",
          "category_id": 6,
          "created_at": "2023-03-22T10:10:15.498Z",
          "updated_at": "2023-03-22T10:13:34.241Z",
          "body": "#textt",
          "location": "indore",
          "account_id": 1,
          "shareable_link": "aHR0cDovL2xvY2FsaG9zdDozMDAwL2J4X2Jsb2NrX3Bvc3RzL3Bvc3RzLzM=\n"
      },
      {
          "id": 3,
          "name": "hello",
          "description": "helii post",
          "category_id": 6,
          "created_at": "2023-03-22T10:13:11.890Z",
          "updated_at": "2023-03-22T10:13:34.263Z",
          "body": "#textt",
          "location": "indore",
          "account_id": 1,
          "shareable_link": "aHR0cDovL2xvY2FsaG9zdDozMDAwL2J4X2Jsb2NrX3Bvc3RzL3Bvc3RzLzM=\n"
      },
      {
          "id": 4,
          "name": "hello",
          "description": "helii post",
          "category_id": 6,
          "created_at": "2023-03-22T10:15:15.247Z",
          "updated_at": "2023-03-22T10:15:58.714Z",
          "body": "#textt",
          "location": "indore",
          "account_id": 1,
          "shareable_link": "aHR0cDovL2xvY2FsaG9zdDozMDAwL2J4X2Jsb2NrX3Bvc3RzL3Bvc3RzLzQ=\n"
      },
      {
          "id": 5,
          "name": "hello",
          "description": "helii post",
          "category_id": 6,
          "created_at": "2023-03-22T10:20:43.215Z",
          "updated_at": "2023-03-22T10:20:55.691Z",
          "body": "#textt",
          "location": "indore",
          "account_id": 1,
          "shareable_link": "aHR0cDovL2xvY2FsaG9zdDozMDAwL2J4X2Jsb2NrX3Bvc3RzL3Bvc3RzLzU=\n"
      }
  ]}
    return data
  }
  onSignOut=()=>{
    localStorage.removeItem('authToken');
    localStorage.removeItem("account_id")
    localStorage.removeItem('otherReportId')
    localStorage.removeItem('userEmail')
    localStorage.removeItem('userFirstName')
    localStorage.removeItem('user_details')
  }
   handleOpenNavMenu = (event: any) => {
    this.setState({...this.state,AnchorElNav:event?.target})
  };

   handleCloseNavMenu = () => {
    this.setState({...this.state,AnchorElNav:null})
  };
  handleCloseMenu = () => {
    this.setState({anchorElMenu:null});
  };
  handleCloseSideBarProfile = () => {
    this.setState({sidebarAnchorElMenu:null});
  };
  handleCloseAgeConfirm=()=>{
    this.setState({openAgeConfirm:false});
  }
  handleOpenAgeConfirm=()=>{
    this.setState({openAgeConfirm:true});
  }
}
// Customizable Area End