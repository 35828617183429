import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
  Checkbox,
  Grid,
  Select,
  MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { BackArrow } from "./assets";
import AppHeader from '../../../components/src/AppHeader.web'
import AppFooter from '../../../components/src/AppFooter.web'
import { Formik } from 'formik'
import * as Yup from 'yup'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#bd9e44",
      contrastText: "#f9f8f8",
    }
  },
});
import { TermsConditionsPop } from "../../../components/src/TermsConditionsPop.web";
import { ConnectedFocusError } from "focus-formik-error";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import Loader from "../../../components/src/Loader.web";
import OrgnaisationSetPassword from "./OrganisationSetPassword.web";

export default class EmailAccountOrganisationDetailsWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    async componentDidMount() {
      this.setState({isLoading: true})
      this.autoPopulateOrgEmail();
    }
  defaultProfile() {
    return (
      <Grid container alignItems="center"> 
        <Grid item style={webStyle.profile}>
          <img
            width="100%"
            style={webStyle.beforeSelect}
            src={this.state.selectedImage}
          />
        </Grid> 
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={webStyle.uploadButton}>
            Upload photo
            <input
              accept="image/*" 
              style={webStyle.selectProfile}
              id="contained-button-file"
              multiple
              type="file"
              onChange={this.handleUploadClick}
            />
          </Button> 
        </label>
      </Grid>
    );
  }
  

  selectedProfile() {
    return ( 
      <Grid container alignItems="center">
        <Grid item style={webStyle.profile}>
          <img
            width="100%"
            style={webStyle.profileImg}
            src={this.state.selectedImage}
          />
        </Grid> 
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={webStyle.uploadButton}>
            Upload Photo
            <input
              accept="image/*"
              style={webStyle.selectProfile}
              id="contained-button-file"
              multiple 
              type="file"
              onChange={this.handleUploadClick}
            />
          </Button>
        </label> 
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={webStyle.changeProfile}>
            Change
            <input
              accept="image/*"
              style={webStyle.selectProfile}
              id="contained-button-file" 
              multiple
              type="file"
              onChange={this.handleUploadClick}
            /> 
          </Button>
        </label>
      </Grid> 
    );
  }

  organisationDetailsSection = () => {
    const OrganisationDetailsSchema = Yup.object().shape({
      first_name: Yup.string().required(this.errorFirstnameRequired),
      last_name: Yup.string().required(this.errorLastnameRequired),
      email: Yup.string().email("Invalid email").required(this.errorEmailRequired),
      name_of_organisation: Yup.string().required(this.errorOrgNameRequired),
      type_of_organisation: Yup
        .string()
        .required(this.errorOrgTypeRequired),
      street_name: Yup.string().required(this.errorStreetnameRequired),
      postal_code: Yup
        .string()
        .required(this.errorPincodeRequired)
        .matches(/^[0-9]*$/, this.errorPincodeNumber),
      city: Yup.string().required(this.errorCityRequired),
      country: Yup.string().required(this.errorCountryRequired),
      opening_hour: Yup.string().required(this.errorOpeningHoursRequired),
      closing_hour: Yup.string().required(this.errorClosingHoursRequired),
      orgTermsAgree: Yup.bool().oneOf([true], this.errorAcceptTermsCondition),
    });

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps:any = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 200,
        },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      getContentAnchorEl: null
    };

    return <Formik
      initialValues={this.state.initialOrganisationDetails}
      validationSchema={OrganisationDetailsSchema}
      onSubmit={(values:any) => { this.continueToSetPassword({...values, profile_image: this.state.selectedFile}) }}
      data-test-id="formStudentDetails"
    >
      {({ errors, values, touched, handleChange, handleBlur, handleSubmit ,setFieldValue}: any) => (
        <form onSubmit={handleSubmit} style={{margin: "0"}} data-test-id="formStudent">
          <ConnectedFocusError />
          <Container maxWidth="sm" style={webStyle.container}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "50px",
                bgcolor: "#ffffff",
                borderRadius: "14px",
                position: 'relative',
                marginTop: "50px",
                maxWidth: "434px",
                fontFamily: "HelveticaNeue",
              }}
            >
              <Box style={{...webStyle.pageTitle, marginBottom: "10px"}}>
                <img src={BackArrow} style={webStyle.backArrow} data-test-id="backArrow" onClick={() => {this.props.navigation.navigate("Register")}}/>
                <Typography variant="h5" component="h1">
                  Set up your organization profile
                </Typography>
              </Box>

              <Box sx={{ width: "100%", paddingTop: "20px" }}>
                <Typography variant="h6" style={webStyle.subTitle}>{this.lableAdminDetails} (Admin)</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelFirstName} <span>*</span></InputLabel>
                    <InputField
                      error={this.checkError(errors.first_name, touched.first_name)}
                      id="first_name"
                      name="first_name"
                      fullWidth={true} 
                      placeholder="First name"
                      variant="outlined"
                      value={values.first_name}
                      onChange={handleChange} 
                      onBlur={handleBlur}
                    />
                    {this.finalErrorMessage(errors.first_name, touched.first_name)}
                  </Grid>
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.lastName} <span>*</span></InputLabel>
                    <InputField 
                      error={this.checkError(errors.last_name, touched.last_name)}
                      id="last_name"
                      name="last_name" 
                      fullWidth={true}
                      placeholder="Last name"
                      variant="outlined"
                      value={values.last_name} 
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {this.finalErrorMessage(errors.last_name, touched.last_name)}
                  </Grid> 
                  <Grid item xs={12} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelEmailAddress} <span>*</span> (Admin)</InputLabel>
                    <InputField
                      error={this.checkError(errors.email, touched.email)}
                      id="email"
                      name="email"
                      fullWidth={true}
                      placeholder="Email address"
                      variant="outlined"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                    {this.finalErrorMessage(errors.email, touched.email)}
                  </Grid>
                  <Grid item xs={12} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelPosition}</InputLabel>
                    <InputField
                      id="position"
                      name="position"
                      fullWidth={true}
                      placeholder="Position"
                      variant="outlined"
                      value={values.position}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "50px",
                bgcolor: "#ffffff",
                borderRadius: "14px",
                position: 'relative',
                marginTop: "25px",
                marginBottom: "100px",
                maxWidth: "434px",
                fontFamily: "HelveticaNeue",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography variant="h6" style={webStyle.subTitle}>{this.txtOrgDetails}</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelNameofOrg} <span>*</span></InputLabel>
                    <InputField
                      error={errors.name_of_organisation && touched.name_of_organisation}
                      id="name_of_organisation"
                      nmae="name_of_organisation"
                      fullWidth={true}
                      placeholder="Name of organization"
                      variant="outlined"
                      value={values.name_of_organisation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {this.finalErrorMessage(errors.name_of_organisation, touched.name_of_organisation)}
                  </Grid>
                  <Grid item xs={12} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelTypeofOrg} <span>*</span></InputLabel>
                    <Select
                      error={errors.type_of_organisation && touched.type_of_organisation}
                      id="type_of_organisation"
                      name="type_of_organisation"
                      variant="outlined"
                      fullWidth
                      style={webStyle.select}
                      value={values.type_of_organisation}
                      displayEmpty
                      onChange={handleChange}
                      onBlur={handleBlur}
                      MenuProps={MenuProps}
                    >
                      <MenuItem selected value='' disabled>
                        <em style={webStyle.typePlaceHolder}>Type of organization</em>
                      </MenuItem>
                      <MenuItem value="School">School</MenuItem>
                      <MenuItem value="University">University</MenuItem>
                    </Select>
                    {this.finalErrorMessage(errors.type_of_organisation, touched.type_of_organisation)}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{...webStyle.subTitle, marginBottom: "0px"}}>{this.txtAddressOrg} <span>*</span></Typography>
                  </Grid>
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelStreetName} <span>*</span></InputLabel>
                    <InputField
                      error={errors.street_name && touched.street_name}
                      id="street_name"
                      name="street_name"
                      fullWidth={true}
                      placeholder="Street name"
                      variant="outlined"
                      value={values.street_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {this.finalErrorMessage(errors.street_name, touched.street_name)}
                  </Grid>
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelPincode} <span>*</span></InputLabel>
                    <InputField
                      error={errors.postal_code && touched.postal_code}
                      // type="number"
                      id="postal_code"
                      name="postal_code"
                      fullWidth={true}
                      placeholder="Pincode"
                      variant="outlined"
                      value={values.postal_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {this.finalErrorMessage(errors.postal_code, touched.postal_code)}
                  </Grid>

                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelCity} <span>*</span></InputLabel>
                    <InputField
                      error={errors.city && touched.city}
                      id="city"
                      name="city"
                      fullWidth={true}
                      placeholder="City"
                      variant="outlined"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {this.finalErrorMessage(errors.city, touched.city)}
                  </Grid>
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelCountry}<span>*</span></InputLabel>
                    <InputField
                      error={errors.country && touched.country}
                      id="country"
                      name="country"
                      fullWidth={true}
                      placeholder="Country"
                      variant="outlined"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {this.finalErrorMessage(errors.country, touched.country)}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6" style={{...webStyle.subTitle, marginBottom: "0px"}}>Timings <span>*</span></Typography>
                  </Grid>
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelOpeningHours} <span>*</span></InputLabel>
                    <TimePicker
                      id="opening_hour"
                      name="opening_hour"
                      showSecond={false}
                      placeholder="00:00 AM"
                      format={"h:mm A"}
                      use12Hours
                      className={touched.opening_hour && errors.opening_hour ? "errorHour" : "closingHour"}
                      onChange={(value: any) => setFieldValue('opening_hour', value.format("h:mm a"))}
                      data-test-id="opening_hour"
                    />
                    {this.finalErrorMessage(errors.opening_hour, touched.opening_hour)}
                  </Grid>
                  <Grid item xs={6} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelClosingHours} <span>*</span></InputLabel>
                    <TimePicker
                      id="closing_hour"
                      name="closing_hour"
                      showSecond={false}
                      placeholder="00:00 PM"
                      format={"h:mm A"}
                      use12Hours
                      className={touched.closing_hour && errors.closing_hour ? "errorHour" : "closingHour"}
                      onChange={(value: any) => setFieldValue('closing_hour', value.format("h:mm a"))}
                      data-test-id="closing_hour"
                    />
                    {this.finalErrorMessage(errors.closing_hour, touched.closing_hour)}
                  </Grid>

                  <Grid item xs={12} style={webStyle.fieldSet}>
                    <InputLabel style={{ display: "flex", justifyContent: "space-between", ...webStyle.fieldLabel }}>
                      <span>{this.labelAbout}</span>
                      <span style={{ color: "#bd9e44", fontSize: "14px", letterSpacing: "-1" }}>(Optional)</span>
                    </InputLabel>
                    <TextArea
                      id="headline"
                      name="headline"
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth={true}
                      placeholder="A short sentence about organization."
                      value={values.headline}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                  </Grid>
                  <Grid item xs={12} style={webStyle.fieldSet}>
                    <InputLabel style={webStyle.fieldLabel}>{this.labelProfilePhoto}</InputLabel>
                    {(this.state.mainState == "initial" && this.defaultProfile()) ||
                      (this.state.mainState == "uploaded" && this.selectedProfile())}
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <Checkbox
                      id="orgTermsAgree"
                      name="orgTermsAgree"
                      style={{ color: "#bd9e44",padding: "9px 5px 9px 0" }}
                      checked={values.orgTermsAgree}
                      value={values.orgTermsAgree}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography style={{ fontSize: "14px", letterSpacing: '-0.031rem', fontFamily: "HelveticaNeue" }}>
                      By clicking this box you agree you are at least <span style={{ color: '#bd9e44', fontWeight: 'bold' }}>16 years old</span> and agree to all <span style={{ color: '#bd9e44', fontWeight: 'bold', cursor: "pointer" }} onClick={() => {this.getTermsPolicy();this.setState({openTermsConditionPopup: true})}}>Terms and Conditions </span> and <span style={{ color: '#bd9e44', fontWeight: 'bold', cursor: "pointer" }} onClick={() => {this.getTermsPolicy();this.setState({openPrivacyPolicyPopup: true})}}>Privacy Policy </span> of <span style={{ color: '#bd9e44', fontWeight: 'bold' }}>EDConnect.</span>
                    </Typography>
                  </Grid>
                  {errors.orgTermsAgree && touched.orgTermsAgree &&
                    <div style={{ ...webStyle.fieldError, margin: "0px 5px 5px 22px" }}>
                      {errors.orgTermsAgree}
                    </div>
                  }
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "10px 0px",
                      }}
                    >
                      <Button
                        type="submit"
                        data-test-id={"btnEmailLogIn"} 
                        variant="contained" 
                        color="primary"
                        style={{ height: "56px", fontSize: "16px", textTransform: "none", borderRadius: "8px" }}
                        fullWidth
                      >
                        {this.btnTxtRegister}
                      </Button> 
                    </Box>
                  </Grid>

                </Grid> 
              </Box>
              <TermsConditionsPop
                open={this.state.openTermsConditionPopup}
                handleModal={this.handleTermsConditionPopup}
                data={this.state.termsPolicyData?.terms}
                title={"Terms & Conditions"}
              />
              <TermsConditionsPop
                open={this.state.openPrivacyPolicyPopup}
                handleModal={this.handlePrivacyPolicyPopup}
                data={this.state.termsPolicyData?.poilicies}
                title={"Privacy Policy"}
              />
            </Box>
          </Container>
        </form>
      )}
    </Formik>
  }

  finalErrorMessage(error: any, touch: any) { 
    if (error) {
      if (touch) {
        return <div style={webStyle.fieldError}>
          {error}
        </div>
      }
      else {
        return null
      }
    }
    else {
      return;
    }
  } 

  checkError(error:any, touch:any){
    if(error && touch){
      return true
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}> 
        <AppHeader />
        <Loader loading={this.state.isLoading}/> 
        {
          this.state.isDetails === true ? 
          !this.state.isLoading && this.organisationDetailsSection() :
          <OrgnaisationSetPassword navigation={this.props.navigation} id={""} userDetails={this.state.userDetails} isBack={() => this.handleBack()}/>
         } 
        <AppFooter />
      </ThemeProvider>
    );
  }
  // Customizable Area End

}
// Customizable Area Start
const webStyle: any = {
  container: {
    minHeight: "calc(100vh - 250px)",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    fontFamily: "HelveticaNeue",
  },
  Tab1: {
    background: '#bd9e44',
    color: '#f9f8f8'
  }, 
  Tab2: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: '#fffaec', 
    color: "#bd9e44",
  },
  activeTransferredTickets: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    background: '#fffaec',
    color: "#bd9e44",
  }, 
  tab3: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  backArrow: {
    position: "absolute",
    width: "25px",
    left: "0",
    top: "3px",
    cursor: "pointer"
  },
  profileImg: {
    width: "100px",
    height: "100px", 
    borderRadius: "50%"
  },
  beforeSelect: {
    width: "30px",
    height: "30px",
  },
  profile: {
    width: "100px",
    height: "100px",
    border: "2px solid #bd9e44",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",  
    alignItems: "center",
    margin: "10px 15px 0 0"
  },
  selectProfile: {
    display: "none" 
  },
  uploadButton: {
    width: "144px",
    height: "46px",
    borderRadius: "8px",
    backgroundColor: "#bd9e44",
    color: "#f9f8f8", 
    letterSpacing: 0, 
    textTransform: "unset",
    marginRight: "10px"
  },
  changeProfile: { 
    width: "144px",
    height: "46px",
    borderRadius: "8px",
    backgroundColor: "#fffaec", 
    color: "#bd9e44",
    letterSpacing: 0,
    textTransform: "unset",
    border: "2px solid #bd9e44",
    fontWeight: "bold" 
  },
  select: { 
    width: "100%",
    borderRadius: "8px",
    margin: "10px 0"
  }, 
  fieldError: {
    color: "#D11024",
    fontSize: "14px",
    margin: "5px 5px 5px 0"
  }, 
  typePlaceHolder: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#565656", 
  },
  pageTitle: {
    position: "relative",
    width: "100%",
    textAlign: "center" 
  },
  fieldLabel: {
    marginBottom: "2px",
    color: "#1c1c1c", 
    fontSize: "14px"
  },
  subTitle: {
    margin: "0 0 20px", 
    fontSize: "18px"
  },
  fieldSet: {
    padding: "12px 8px" 
  }
};

const InputField:any = withStyles({
  root: {
      width: '100%',
      paddingTop: '10px',
      borderRadius: "10px",
      fontFamily: "HelveticaNeue",
      "& .MuiOutlinedInput-root": {
          fontWeight: 400, 
          fontFamily: "HelveticaNeue",
          fontSize: "16px",
          "& fieldset": {
              fontSize: "16px",
              borderRadius: "10px",
              borderWidth: 1,
              fontWeight: 400,
          },
      },
      "& ::placeholder": {
        opacity: 1,
        color: "#565656", 
        fontFamily: "HelveticaNeue",
      },
      "& .Mui-error":{
        "& .MuiOutlinedInput-notchedOutline":{
          borderColor: "#D11024"
        }
      },
  }
})(TextField);

const TextArea:any = withStyles({
    root: {
        width: '100%',
        paddingTop: '10px',
        borderRadius: "10px", 
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px", 
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
          opacity: 1,
          color: "#565656",
          fontFamily: "HelveticaNeue", 
        },
        "& .Mui-error":{
          "& .MuiOutlinedInput-notchedOutline":{
            borderColor: "#D11024"
          }
        },
    }
})(TextField);

// Customizable Area End
