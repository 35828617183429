// Customizable Area Start
import React, { useEffect, useState } from 'react'
import NavBarWithSearch from '../../blocks/ElasticSearch/src/NavBarWithSearch.web';
import { Container, Grid, Box, } from "@material-ui/core";
import MessagingFooter from './MessagingFooter.web';
import ChatBoxForMessagesPage from './ChatBoxForMessagesPage.web';
import ListOfPeopleOnMessagesPage from './ListOfPeopleOnMessagesPage.web';

import axios from "axios";
const { baseURL: apiBaseUrl } = require('../../framework/src/config.js');




function MessagesPage() {
  const [userAccountObj, setUserAccount] = useState<any>({
    first_name:'',middle_name:'',last_name:'',profile_image:'',job_title:'',name_of_organisation:'',type:''
  })

  useEffect(() => {
    getProfileDetails();

  }, [])
  const getProfileDetails = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('authToken')
    };

    try {
      const responseJson = await axios.get(`${apiBaseUrl}/bx_block_profile/profiles/get_profile`, {
        headers: header
      })
      console.log(responseJson)
      const { first_name, middle_name, last_name } = responseJson?.data?.account?.data?.attributes
      const { name_of_organisation, job_title, profile_image } = responseJson?.data?.profile?.data?.attributes
      const { type } = responseJson?.data?.account?.data?.type
      const userAccountObj = {
        first_name, middle_name, last_name, profile_image, job_title, name_of_organisation, type
      }
      setUserAccount({...userAccountObj,first_name,middle_name,last_name,profile_image,job_title,name_of_organisation,type})



    } catch (error) {

      console.error(error);
    }

  }

  return (
    <React.Fragment>
      <NavBarWithSearch navigation={undefined} id={''}
        
      />
      <Container style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item md={9} sm={12}>
            <Grid container>
              <Grid item md={4} sm={12}>
                <Box sx={{ bgcolor: 'background.paper', border: '1px solid #CDCDCD', borderRadius: '10px', height: '100vh' }}>
                  <ListOfPeopleOnMessagesPage />

                </Box>
              </Grid>
              <Grid item md={7}>
                <ChatBoxForMessagesPage setOpenChat={undefined} />
              </Grid>

            </Grid>

          </Grid>

          <Grid item md={3}>
            <MessagingFooter />
          </Grid>

        </Grid>
      </Container>

    </React.Fragment>
  )
}

export default MessagesPage
// Customizable Area End