Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelConfirmPassword = "Confirm password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.labelName = "Name";
exports.labelEmailAddress = "Email address";
exports.btnTxtRegister = "Register";
exports.txtCheckEmail = "Check your e-mail";
exports.txtSentEmail = "We have sent registration link on your e-mail. Please check your e-mail box.";
exports.btnTxtClose = "Close";
exports.btnTxtContinue = "Continue";
exports.labelMiddleName = "Middle name";
exports.labelDob = "Date of birth";
exports.labelLocation = "Location";
exports.labelCountry = "Country";
exports.labelOrgName = "Name of current Academic Organization";
exports.labelJobTitle = "Job title";
exports.labelHeadline = "Headline";
exports.labelProfilePhoto = "Profile photo";
exports.lableAdminDetails = "Administrator details";
exports.labelPosition = "Position";
exports.txtOrgDetails = "Organization details";
exports.labelNameofOrg = "Name of organization";
exports.labelTypeofOrg = "Type of organization";
exports.txtAddressOrg = "Full address of organization";
exports.labelStreetName = "Street name";
exports.labelPincode = "Pincode";
exports.labelCity = "City";
exports.labelOpeningHours = "Opening hours";
exports.labelClosingHours = "Closing hours";
exports.labelAbout = "About";
exports.titlePasswordChanged = "Registration Successful";
exports.txtPasswordChanged = "Your account was registered successfully!";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorNameRequired = "Name is required.";
exports.errorEmailRequired = "Email is required.";

exports.errorPasswordRequired = "Password is required.";
exports.errorPasswordLength = "Password must be at least 6 characters.";
exports.errorPassNumber = "Password must be at least 6 characters with numbers";
exports.errorPassLower = "Password must be at least 6 characters with one special character, one capital or small letter.";
exports.errorPassUpper = "Password must be at least 6 characters with one special character, one capital letter.";
exports.errorPassSymbol = "Password must be at least 6 characters with one special character";
exports.errorConfirmPassRequired = "Confirm password is required.";
exports.errorPasswordMatch = "Those passwords didn't match. Please try again.";
exports.errorFirstnameRequired = "Firstname is required.";
exports.errorDobRequired = "DOB is required.";
exports.errorLastnameRequired = "Lastname is required.";
exports.errorCityRequired = "City is required.";
exports.errorCountryRequired = "Country is required.";
exports.errorHeadlineRequired = "Headline is required.";
exports.errorAcceptTermsCondition = "You must accept Terms and Conditions to proceed.";
exports.errorOrgNameRequired = "Name of Organisation is required.";
exports.errorOrgTypeRequired = "Type of Organisation is required.";
exports.errorStreetnameRequired = "Street name is required.";
exports.errorPincodeRequired = "Pincode is required.";
exports.errorPincodeNumber = "Please enter numbers only";
exports.errorOpeningHoursRequired = "Opening hours is required.";
exports.errorClosingHoursRequired = "Closing hours is required.";

exports.errorTitle = "Error";

exports.errorEnterName = "Please Enter your name."
exports.errorEnterFirstName = "Please Enter First name."
exports.errorEnterDob = "Please Enter Dob."
exports.errorEnterLastname = "Please Enter Lastname."
exports.errorEnterEmail = "Please Enter Email address."
exports.errorEnterCity = "Please Enter City."
exports.errorEnterCountry = "Please Enter Country."
exports.errorEnterHeadline = "Please Enter Headline."
exports.errorCheckTermsCondition = "Please Accept Terms & condition."
exports.errorEnterPass = "Please Enter password."
exports.errorPasswordNotMatch = "Please Enter password."
exports.errorPasswordNotStrong = "Your Password is not strong enough."
exports.emailVerificationApiCallId = "Your password and confirm password must be same."

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.emailSentApiEndpoint = "account/accounts"
exports.emailSentApiMethod = "POST"

exports.registerUserApiEndpoint = "bx_block_profile/profiles"
exports.registerUserApiMethod = "POST"
exports.verifyUserApiEndpoint = "account/accounts/verify_account"
exports.verifyUserApiMethod = "POST"

exports.getTermsPolicyMethod = "GET";
exports.getTermsPolicyApiEndPoint = "bx_block_admin/settings";

exports.getOrganisationListApiEndpoint = "/bx_block_profile/profiles"
exports.getOrganisationListApiMethod = "GET"

exports.validationApiMethodType = "GET";
// Customizable Area End
