// Customizable Area Start
import React, { Component } from 'react'
import {  Box } from "@material-ui/core";

interface LeftMenuProps{
    tabs:any;
    navigation:any;
    id:any
}
export default class LeftMenu extends Component<LeftMenuProps> {
   

    
    render() {
        return (
            <React.Fragment>
                <Box sx={{ bgcolor: 'background.paper', height: '260px', borderRadius: '10px', p: 2 }}>
                    <div style={{ fontSize: '24px', marginTop: '20px', fontFamily: "HelveticaNeue" }}>
                        Settings
                    </div>
                    <div>

                        {this.props?.tabs?.map((item: any, index: any) => {
                            return <div style={{ display: 'flex',alignItems:'center',margin: '25px 0px', cursor: 'pointer' }}>
                                <div style={{ marginRight: '15px' }}><img src={item?.isActive?item?.darkIcon:item?.img} alt='text' width='20px' height='20px' /></div>
                                <div data-test-id={item?.tab} onClick={() => {
                                    this.setState({ index });
                                    
                                    item?.path&&this.props?.navigation?.navigate(item?.path)
                                }} style={{ fontSize: '18px', fontWeight: 'bold', fontFamily: "HelveticaNeue", borderBottom: item?.isActive ? '1px solid black' : '', color: item?.isActive ? "#1C1C1C" : "#A8A8A8" }}>{item?.tab}</div>
                            </div>
                        })}

                    </div>


                </Box>
            </React.Fragment>
        )
    }
}
// Customizable Area End