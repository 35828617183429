import React from "react";
import {
  Modal,
  Box,
  makeStyles,
  Typography,
  Checkbox,
  Button,
} from "@material-ui/core";
import { AgeConfirmLogo, BlockedWarningLogo } from "../../blocks/email-account-login/src/assets";
const useStyles = makeStyles((theme) => ({
  modalBox: {
    backgroundColor: "white",
    border: "2px solid red",
  },
  imageStyle: {
    // width: "30%",
    // boxShadow: '0px -5px 5px #BE9E44',
  },
  button: {
    background: "#BE9E44",
    color: "#FFFFFF",
    height: "50px",
    width: "100%",
    margin: "auto",
    textTransform: "capitalize",
    borderRadius: "8px",
    fontSize: "16px",
    "&:hover": {
      background: "#BE9E44",
    },
  },
  contentHead: {
    fontSize: "18px",
    textAlign: "center",
    marginTop: "25px",
  },
  content: {
    fontSize: "14px",
    paddingTop: "3px",
    textAlign: "center",
    paddingLeft: "-10px",
    color:"grey"
  },
  contentSpan: {
    color: "#BE9E44",
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  borderRadius: 12,
  boxShadow: 24,
  p: 3,
};
const BlockedUserModal = ({ open, handleBlockUserModal }) => {
  const classes = useStyles();

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleBlockUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              width: "25%",
              margin: "auto",
              marginTop: "10px",
            }}
          >
            <img
              src={BlockedWarningLogo}
              alt="Age_Logo"
              className={classes.imageStyle}
              width="100%"
              height="100%"
            />
          </Box>
          <Box>
            <Typography className={classes.contentHead}>
              You have been temporarily blocked !
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              width: "100%",
              margin: "auto",
              marginTop: "10px",
            }}
          >
            <Typography className={classes.content}>
              You have been temporarily blocked from EDConnect Check back in 7
              days
            </Typography>
          </Box>
          <Box
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "25px",
              marginBottom: "20px",
            }}
          >
            <Button className={classes.button} onClick={handleBlockUserModal}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default BlockedUserModal;
