export const userProfileImg = require("../assets/user-profile.png");

export const userProfile = require("../assets/profile.png");
export const plus = require("../assets/plus.png");
export const edit = require("../assets/edit.png");
export const medal = require("../assets/medal.png");
export const success = require("../assets/success.png");
export const camrow = require("../assets/camrow.png");
export const background = require("../assets/background.png");
export const image = require("../assets/profile_edit.png");
export const book = require("../assets/book.png");
export const dummy =require("../assets/index.jpeg")
export const bags =require("../assets/bag.png")


export const collegeImage = require("../assets/default_profile_image.png");
export const edConnectLogo = require("../assets/edConnect_logo.png");
export const MenuIcon = require("../assets/menu_icon.png");
export const SearchIcon = require("../assets/search_icon.png");
export const EditIcon = require("../assets/edit_icon.png");
export const UserIcon = require("../assets/user_icon.png");
export const UserIcon2 = require("../assets/user_icon2.png");
export const CoverImage = require("../assets/default_cover_image.png");
export const CoverPhoto = require("../assets/cover_photo.png");
export const ProfilePhoto = require("../assets/profile_photo.png");
export const CloseIcon = require("../assets/close_icon.png");
export const RemoveIcon = require("../assets/remove_icon.png");
export const ClockIcon = require("../assets/clock_icon.png");
export const LikeIconGroup = require("../assets/like_group.png");
export const LikeIcon = require("../assets/like_icon2.png");
export const LikedIcon = require("../assets/like_icon.png");
export const SupportIconGroup = require("../assets/support_icon1.png");
export const SupportIcon = require("../assets/support_icon2.png");
export const LoveIconGroup = require("../assets/love_icon2.png");
export const LoveIcon = require("../assets/love_icon3.png");
export const SmileIconGroup = require("../assets/smile_icon.png");
export const SmileIcon = require("../assets/smile_icon2.png");
export const CommentIcon = require("../assets/comment_icon.png");
export const ShareIcon = require("../assets/share_icon.png");
export const FlagIcon = require("../assets/flag_icon.png");
export const ArrowIcon = require("../assets/arrow.png");