import React from "react";
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
} from "@material-ui/core";
import * as Yup from 'yup'
import { HighlightOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import AcademicSkillsModal from "./AcademicSkillsModal.web";
const close = require("../src/close.png");

const style: any = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    borderRadius: 14,
    boxShadow: 24,
    p: 3,
    overflow: "auto",
};

const AcademicModal = ({ open, handleClose,getWorkExperience,workExperienceDetails }: any) => {    
    const [data, setData] = React.useState({ academic_skills: "" });
    const [isModalOpen,setIsModalOpen]=React.useState(false)

    const contactUsSchema: any = Yup.object().shape({
        academic_skills: Yup.string().required('Academic skills is required.'),
    })

    const handleNext = (values:any) => {
        handleClose()
        setIsModalOpen(true)        
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <img
          src={close}
                        style={modalContactStyle.closeIcon}
                        onClick={handleClose}
                    />
                    <Typography style={modalContactStyle.contact_textContent}>
                        Academic skills
                    </Typography>
                    <Formik
                        initialValues={data}
                        validationSchema={contactUsSchema}
                        onSubmit={(values: any) => handleNext(values)
                        }
                    >
                        {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
                            <form onSubmit={handleSubmit}>
                                <Box style={modalContactStyle.main}>
                                    <Box style={modalContactStyle.row}>
                                        <Box style={modalContactStyle.field}>
                                            <label>Academic skills</label>
                                            <InputField
                                                error={errors.academic_skills && touched.academic_skills}
                                                testID="contactName"
                                                data-test-id="inputName"
                                                placeholder="Name"
                                                fullWidth={true}
                                                id="academic_skills"
                                                name="academic_skills"
                                                variant="outlined"
                                                value={values.academic_skills}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.academic_skills && touched.academic_skills &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.academic_skills}
                                                </div>
                                            }
                                            <Typography style={modalContactStyle.txtgap}>
                                                Suggested based on your profile
                                            </Typography>
                                            <Box>
                                                <Button variant="contained" component="span" style={modalContactStyle.uploadButton}>
                                                    java
                                                </Button>
                                                <Button variant="contained" component="span" style={modalContactStyle.uploadButton}>
                                                    css
                                                </Button>
                                                <Button variant="contained" component="span" style={modalContactStyle.uploadButton}>
                                                    copywriting
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box
                                            style={modalContactStyle.submit}
                                        >
                                            <Button type = "submit" variant="contained" style={modalContactStyle.submitButton}>
                                                Next
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
            {isModalOpen && <AcademicSkillsModal open={isModalOpen} setIsModalOpen = {setIsModalOpen} getWorkExperience={getWorkExperience} workExperienceDetails={workExperienceDetails}/>}
        </>
    );
}

export default AcademicModal;


const modalContactStyle: any = {
    contact_textFelid: {
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "20px",
    },
    contact_textContent: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px"
    },
    closeIcon: {
        cursor: "pointer",
        position: "absolute",
        right: "20px",
        top: "20px",
        height: "20px",
    },
    fieldError: {
        color: "#D11024",
        fontSize: "14px",
        margin: "-7px 5px 10px 0"
    },
    main: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    row: {
        display: "flex",
        gap: "24px"
    },
    name: {
        width: "426px"
    },

    field: {
        width: "876px",
        marginBottom: "18px"
    },
    uploadButton: {
        height: "32px",
        border: "1px solid #696868",
        backgroundColor: "#fff",
        borderRadius: "8px",
        marginRight: "5px",
        color: "#696868",
    },
    submitButton: {
        height: "32px",
        backgroundColor: "#BE9E44",
        borderRadius: "8px",
        color: "#fff",
    },
    submit: {
        width: "876px",
        display: "flex",
        justifyContent: "end"
    },
    txtgap: {
        marginBottom: "10px"
    },
    space: {
        marginBottom: "14px",
    },
};

const InputField: any = withStyles({
    root: {
        padding: '10px 0',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue-medium",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)"
            }
        },
        "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D11024"
            }
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue-Medium",
        }
    }
})(TextField);
