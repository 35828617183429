import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { EmailIcon } from "./assets";
import AppHeader from '../../../components/src/AppHeader.web'
import AppFooter from '../../../components/src/AppFooter.web'

const theme = createTheme({
  palette: {
    primary: {
      main: "#bd9e44",
      contrastText: "#f9f8f8",
    }
  },
});
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import Loader from "../../../components/src/Loader.web";

export default class EmailAccountRegistrationLinkSentWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppHeader />
        <Loader loading={this.state.isLoading}/>
        <Container maxWidth="sm" style={webStyle.container}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "50px",
              bgcolor: "#ffffff",
              borderRadius: "14px",
              position: 'relative',
              maxWidth: "434px",
              fontFamily: "HelveticaNeue",
            }}
          >

            <Box sx={{ width: "100%", paddingTop: "20px", textAlign: "center" }}>
              <img src={EmailIcon} style={webStyle.emailLogo} />
              <Typography variant="h5" style={{ margin: "25px 0", fontFamily: "HelveticaNeue" }}>{this.txtCheckEmail}</Typography>
              <Typography variant="subtitle1" style={{ color: '#7c7c7c', margin: "10px 0 20px", fontFamily: "HelveticaNeue" }}>{this.txtSentEmail}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px 0px",
                }}
              >
                <Button
                  data-test-id={"btnClose"}
                  variant="contained"
                  color="primary"
                  style={{ height: "56px", fontSize: "16px", textTransform: "none", borderRadius: "8px", fontFamily: "HelveticaNeue" }}
                  fullWidth
                  onClick={() => {this.props.navigation.navigate("Register")}}
                >
                  {this.btnTxtClose}
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
        <AppFooter />
      </ThemeProvider>
    );
  }
// Customizable Area End
}
// Customizable Area Start
const webStyle: any = {
  container: {
    minHeight: "calc(100vh - 250px)",
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    fontFamily: "HelveticaNeue",
  },
  Tab1: {
    background: '#bd9e44',
    color: '#f9f8f8'
  },
  Tab2: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: '#fffaec',
    color: "#bd9e44",
  },
  activeTransferredTickets: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    background: '#fffaec',
    color: "#bd9e44",
  },
  tab3: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  emailLogo: {
    width: '85px'
  }
};


// Customizable Area End
