export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/edconnect_logo.png");
export const profile = require("../assets/profile.png");
export const sub = require("../assets/sub.png");
export const secure = require("../assets/secure.png");
export const bin = require("../assets/delete.png");
export const notification = require("../assets/notification.png");
export const right = require("../assets/right.png");
export const account = require("../assets/account.png");
export const close = require("../assets/close.png");
export const dob = require("../assets/dob.png");
export const lightAcc = require("../assets/lightAcc.png");
export const darkBell = require("../assets/darkBell.png");
export const darkLock = require("../assets/darkLock.png");
export const darkSub = require("../assets/darkSub.png");