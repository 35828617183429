import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  certificationDetails: any;
  workExperienceDetails: any[];
  getWorkExperience: ()=> void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModalOpenCertify: boolean;
  isModalOpenAchieve: boolean;
  isModalOpenAcademic: boolean;
  token: any;
  isModalCertifiationId:any,
  isModalAcademicSkillsId:any,
  isModalAchievementId:any,
 
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class GuestProfileSkillAfterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEducationDetailsCallId: string = "";
  getCertificateDetailsCallId: string = "";
  getWorkExperienceCallId: string = "";
  getStudentProfileCallId: string = "";
  getOwnerProfileCallId:string = "";
  patchImageCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    // Customizable Area End
    // Customizable Area Start
    this.state = {
      isModalOpenCertify: false,
      isModalOpenAchieve: false,
      isModalOpenAcademic: false,
      token: "",
      isModalCertifiationId:null,
      isModalAcademicSkillsId:null,
      isModalAchievementId:null,
  
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End
}
