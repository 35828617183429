import React from "react";
import { Modal, Box, Typography, TextField, Button } from "@material-ui/core";
import * as Yup from "yup";
import { HighlightOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import AddEducationModal from "./AddEducationModal.web";
const close = require("../src/close.png");

const style: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  borderRadius: 14,
  boxShadow: 24,
  p: 3,
  overflow: "auto",
};

const AddEducationModelSelect = ({
  open,
  handleClose,
  getEducationDetails,
  educationDetails,
}: any) => {
  const [data, setData] = React.useState({ achievements: "" });
  const [select, setSelect] = React.useState(false);

  const contactUsSchema: any = Yup.object().shape({
    achievements: Yup.string().required("achievements is required."),
  });

  const handleModelClose = (value: any) => {
    handleClose();
    setSelect(true);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
          src={close}
            style={modalContactStyle.closeIcon}
            onClick={handleClose}
          />
          <Typography style={modalContactStyle.contact_textContent}>
            Add Education Details
          </Typography>
          <Formik
            initialValues={data}
            validationSchema={contactUsSchema}
            onSubmit={(values: any) => handleModelClose(values)}
          >
            {({
              errors,
              values,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }: any) => (
              <form onSubmit={handleSubmit}>
                <Box style={modalContactStyle.main}>
                  <Box style={modalContactStyle.row}>
                    <Box style={modalContactStyle.field}>
                      <label>Achievements *</label>
                      <InputField
                        error={errors.achievements && touched.achievements}
                        testID="contactName"
                        data-test-id="inputName"
                        placeholder="School/University"
                        fullWidth={true}
                        id="achievements"
                        name="achievements"
                        variant="outlined"
                        value={values.achievements}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.achievements && touched.achievements && (
                        <div style={modalContactStyle.fieldError}>
                          {errors.achievements}
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box style={modalContactStyle.row}>
                    <Box style={modalContactStyle.submit}>
                      <Button
                        variant="contained"
                        type="submit"
                        style={modalContactStyle.submitButton}
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <AddEducationModal
        educationDetails={educationDetails}
        open={select}
        setSelect={setSelect}
        getEducationDetails={getEducationDetails}
      />
    </>
  );
};

export default AddEducationModelSelect;

const modalContactStyle: any = {
  contact_textContent: {
    fontSize: "20px",
    textAlign: "center",
    marginBottom: "20px",
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "20px",
    top: "20px",
    height: "20px",
  },
  fieldError: {
    color: "#D11024",
    fontSize: "14px",
    margin: "-7px 5px 10px 0",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    gap: "24px",
  },
  name: {
    width: "426px",
  },
  field: {
    width: "876px",
    marginBottom: "10px",
  },

  submitButton: {
    height: "32px",
    backgroundColor: "#BE9E44",
    borderRadius: "8px",
    color: "#fff",
    textTransform: "none",
  },
  submit: {
    width: "876px",
    display: "flex",
    justifyContent: "end",
  },
};

const InputField: any = withStyles({
  root: {
    padding: "10px 0",
    borderRadius: "10px",
    fontFamily: "HelveticaNeue-medium",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontFamily: "HelveticaNeue",
      fontSize: "16px",
      "& fieldset": {
        fontSize: "16px",
        borderRadius: "10px",
        borderWidth: 1,
        fontWeight: 400,
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
    "& .Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D11024",
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#565656",
      fontFamily: "HelveticaNeue-Medium",
    },
  },
})(TextField);
