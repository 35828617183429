// Customizable Area Start
import React from 'react'
import { Container, Grid, Box, TextField, withStyles,styled } from "@material-ui/core";
import LeftProfile from '../../../components/src/LeftProfile.web';
import Messaging from '../../../components/src/Messaging.web';
import PostCreationCommonController, { Props } from './PostCreationCommonController';
import {  gallery, send } from './assets';
import NavBarWithSearch from '../../ElasticSearch/src/NavBarWithSearch.web';
import Feeds1 from '../../../components/src/Feeds1.web';
import CreatePostModal from '../../../components/src/CreatePostModal.web';

import { isLoggedIn } from '../../../components/src/common';
import CommonLoader from '../../../components/src/CommonLoader.web';




export default class FanWall extends PostCreationCommonController {
    constructor(props: Props) {
        super(props);
    }
    
    closeModal = () => {
        this.setState({ openCreatePost: false });

    }
  
    handlePostText = (value: any) => {
        this.setState({ description: value});
        let temp: any = []
        const words = value.split(/<\/?p>| /);
        words.map((word: any) => {
            if (word.startsWith("#")) {
                this.searchHashTag(word)
                temp.push(word)

            }
        });
        
        this.setState({ body: temp.join(", ") })
    }


    validationApiCallId: string;
    apiEmailLoginCallId: string;
    CustomCheckBoxProps: any;
    txtInputPasswordProps: any;
    btnPasswordShowHideProps: any;
    btnSocialLoginProps: any;
    onOpenCreatePost=()=>{
        this.setState({ openCreatePost: true })
    }
    render() {
        return (
            <>
                <CommonLoader isLoading={this.state?.isLoading} />
                <NavBarWithSearch navigation={this.props.navigation} id={''} getPostData={() => {this.getPostData({ langitude: null, longitude: null })}} />
                <Container style={{ marginTop: '40px' }}>
                    <Grid container spacing={3} >
                        <StyledLeftGrid item lg={3} md={3} sm={12}>
                            {isLoggedIn(null) && <LeftProfile userAccountObj={this.state?.userAccountObj} />}
                        </StyledLeftGrid>
                        <Grid item lg={6} md={6} sm={6}>
                            {/* new post cntainer */}


                           { isLoggedIn(null) && <Box sx={{ bgcolor: 'background.paper', height: '120px', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0' }}>
                                <div style={webStyles.heading}>New post</div>
                                <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
                                <div style={webStyles.newPost}>
                                    <div style={{ padding: '15px 0px' }}>
                                        <InputField
                                            data-test-id="inputPost"
                                            placeholder='What&apos;s in your mind ?'
                                            variant='outlined'
                                            onClick={this.onOpenCreatePost}
                                        />
                                    </div>

                                    <div>
                                        <img src={gallery} width="24px" />
                                    </div>

                                    <div style={webStyles.sendBox}>
                                        <img src={send} width="16px" alt='send' />
                                    </div>

                                </div>

                            </Box>}

                            {/* Feeds component */}
                            <Feeds1 navigation={this.props?.navigation} postData={this.state.PostData} isLoad={this.state.isLoading} getPostData={this.getPostData} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={12}>
                            <Messaging />
                        </Grid>

                    </Grid >

                </Container >

                {/* Modal for post creation */}
               
                <CreatePostModal 
                openCreatePost={this.state.openCreatePost}
                 closeModal={this.closeModal}
                 handlePostText={this.handlePostText}
                 uploadedImages={this.state.uploadedImages}
                 handleRemoveImage={this.handleRemoveImage.bind(this)}
                 hashTags={this.state.hashTags}
                 handleImage={this.handleImage.bind(this)}
                 AddPostCreation={this.AddPostCreation.bind(this)}
                 imgRef={this.imgRef}
                 userAccountObj={this.state?.userAccountObj}


                 />


            </>
        )
    }
}
const webStyles = {
    newPost: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sendBox: {
        width: '32px',
        height: '32px',
        backgroundColor: '#BE9E44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    heading: {
        fontSize: '12px',
        lineHeight: '14px',
        color: '#1C1C1C',
        padding: '5px 0px 5px 0px',
        fontFamily: "HelveticaNeue",


    }
    ,
    feedsFont: {
        fontSize: '14px',
        fontFamily: "HelveticaNeue",
        color: '#565656'
    },
    actionLIne: {
        display: 'flex',
        margin: '5px 0px'
    },
    actionText: {
        fontSize: '16px',
        color: '#696868',
        marginLeft: '10px',
        fontFamily: 'HelveticaNeue'
    }
}
const InputField: any = withStyles({
    root: {
        width: '460px',
        paddingTop: '10px',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        },

    }
})(TextField);


const StyledLeftGrid = styled(Grid)({
    "@media (max-width: 1194px)": {
        display: "none"
    }
});


// Customizable Area End
