// Customizable Area Start
import React, { useState } from 'react';
import { Box, styled, Typography, Tooltip, Button } from "@material-ui/core";
import { moreOptions } from '../../blocks/HashtagGenerator/src/assets';

const smilie = require("../src/smilie.png");
const support1 = require("../src/support1.png");
const filledLike = require("../src/filledLike.png");
const filledHeart = require("../src/filledHeart.png");
const addEmoji = require("../src/addEmoji.png");
interface Props {
    chatItem: any
}
function ChatsList(props: Props) {
    const { chatItem } = props;
    const [openReactionBox, setReactionBox] = useState(false)


    return (
        <React.Fragment>
            <div style={{ display: 'flex', alignItems: 'center' }}>

                <hr style={webStyles?.customHr1} />
                <span style={{ fontSize: '12px', color: '#565656' }}>Feb 10, 2023</span>
                <hr style={webStyles?.customHr2} />
            </div>
            <ChatContainer>
                <ChatProfileImg><img src={chatItem?.profileImg} width="40px" style={{ borderRadius: '100%' }} /></ChatProfileImg>
                <MessageContainer>
                    <PersonName>{chatItem?.name}  <span style={{ fontSize: '12px', color: '#565656' }}>1:21 AM</span></PersonName>
                </MessageContainer>
                {openReactionBox && <div
                    onMouseEnter={() => setReactionBox(true)} onMouseLeave={() => setReactionBox(false)}
                    style={webStyles.reactionBox}>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div className='chatReactionHover'  ><Tooltip title="like" placement='bottom-start'><img src={filledLike} width="16px" alt="likes" /></Tooltip></div>
                        <div className='chatReactionHover'  ><Tooltip title="support" placement='bottom-start'><img src={support1} width="16px" alt="support" /></Tooltip></div>
                        <div className='chatReactionHover' ><Tooltip title="love" placement='bottom-start'><img src={filledHeart} width="16px" alt="heart" /></Tooltip></div>
                        <div className='chatReactionHover' ><Tooltip title="laugh" placement='bottom-start'><img src={smilie} width="16px" alt="smilie" /></Tooltip></div>
                        <div className='chatReactionHover' ><Tooltip title="Open emoji keyboard" placement='bottom-start'><img src={addEmoji} width="16px" alt="add emoji" /></Tooltip></div>
                        <div style={{ display: 'flex', alignItems: 'center', paddingRight: '5px' }}><img src={moreOptions} style={{ transform: ' rotate(-90deg)' }} width="16px" alt="more options" /></div>
                    </div>
                </div>}
            </ChatContainer>
            <TextContainer onMouseEnter={() => setReactionBox(true)} onMouseLeave={() => setReactionBox(false)}>
                How are you doing their?
                I love to guide you! Do yo like to tell me more specific about your career  and what are your future plans. So, I can help you as per that!
            </TextContainer>
            <LikeButtonContainer>
                <StyledLikeCountButton startIcon={<img src={filledLike} width="16px" alt="Image" />}>1</StyledLikeCountButton>

            </LikeButtonContainer>
        </React.Fragment>
    )
}
const webStyles = {

    customHr1: {
        height: '1px',
        border: 'none',
        backgroundColor: '#D7D7D7',
        width: '199px',


    },
    customHr2: {
        height: '1px',
        border: 'none',
        backgroundColor: '#D7D7D7',
        width: '199px',


    },
    reactionBox: {
        position: 'absolute',
        transform: 'translate(-50%,-50%)',
        width: '176px',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.127841)',
        borderRadius: '6px',
        top: '23px',
        left: '75%',
        border: '1px solid #F0F0F0'
    } as React.CSSProperties,
}
const ChatContainer = styled(Box)({
    display: 'flex',
    padding: '15px 50px 0px 15px',
    position: 'relative'



});

const ChatProfileImg = styled(Box)({
    borderRadius: '100%',
    border: '1px solid #F0F0F0',
    marginRight: '15px',
    zIndex: 2
})

const MessageContainer = styled(Box)({

});
const TextContainer = styled(Box)({

    fontSize: '14px',
    color: '#565656',

    padding: '10px 73px',
    position: 'relative',
    top: '-20px',
    border: 'none',
    outline: 'none',
    zIndex: 1,
    '&:hover': {
        backgroundColor: '#F7F7F7'

    },
    '&::before': {
        content: "",
        position: 'absolute',
        top: '-10px',
        left: '-10px',
        right: '-10px',
        bottom: '-10px',
        zindex: -1,
        border: '3px solid transparent'
    },

});
const PersonName = styled(Typography)({
    fontSize: '14px',
    color: '#1C1C1C'

});
export default ChatsList
export const StyledLikeCountButton = styled(Button)({
    backgroundColor: '#F7F7F7',
    border: '1px solid #BE9E44',
    borderRadius: '4px',
    marginRight: '10px',
    width: '44px',
    height: '24px'

});
export const LikeButtonContainer = styled(Box)({
    position: 'relative',
    top: '-13px',
    left: '15%',
    width:'60px'


});
// Customizable Area End