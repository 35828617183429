// Customizable Area Start
import React, { useEffect, useState } from 'react'
import { Box } from "@material-ui/core";
import axios from 'axios';
import ReactionModal from './ReactionModal.web';
import ReportPostModal from './ReportPostModal.web';
import CommonLoader from './CommonLoader.web';
import SinglePost from './SinglePost.web';

const { baseURL: apiBaseUrl } = require('../../framework/src/config.js')


const funny = require("../src/emoji.png");

const support = require("../src/support.png");

const filledLike = require("../src/filledLike.png");
const filledHeart = require("../src/filledHeart.png");





interface Props {
    navigation: any;
    postData: any;
    isLoad: any;
    getPostData: any
}




function Feeds1(props: Props) {
    const [posts, setData] = useState<any>([]);
    const [comments, setComments] = useState<any>([])

    const [commentId, setCommentId] = useState('');
    const [openReactionModal, setReactionModal] = useState(false);
    const [allAccounts, setAllAccounts] = useState([]);
    const [postId, setPostId] = useState('');
    const [commentIdForReaction, setCommentIdForReaction] = useState('');
    const [commentOfCommentId, setCommentOfCommentIdForReaction] = useState('');

    const [openReportModal, setReportModal] = useState(false);

    const [loader, setLoader] = useState(false);
    const [totalCommentsForPost, setCommentsCount] = useState(0);




    useEffect(() => {
       console.log(props?.postData?.data)
        // if (props?.postData?.data?.length !== 0) {
            setData(props?.postData?.map((post: any) => {
                return { ...post?.attributes??post, commentsOPen: false, likeHovered: false, modalHovered: false, commentCount: 0 }
            }))
        // }
    }, [props?.postData])



    const commentsAPi = async (id: any) => {

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };

        try {
            const response = await axios.get(`${apiBaseUrl}/bx_block_comments/comments?post_id=${id}`, {
                headers: header
            })
            console.log(response)
            if (response?.data?.message == 'No comments found for this post') {
                setComments([]);
               
               
            }
            else {
                setCommentsCount(response?.data?.total_comment_count)
                setData(posts?.map((item: any) => {
                    if (id == item?.id) {
                        return { ...item, comments: response?.data?.comments, commentCount: response?.data?.total_comment_count }
                    }
                    else {
                        return item
                    }
                }))


                

            }
        } catch (error) {
          
            setComments([])
            console.error(error);
        }

    }
    
    const createCommentLike = (postId: any, id: any, type: any) => {
    
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };
        const formData = new FormData();
        formData.append('comment_id', id);
        formData.append('reaction_type', type)

        axios.post(`${apiBaseUrl}/bx_block_like/likes`, formData, {
            headers: header
        })
            .then((response: { data: any; }) => {
                let obj = response?.data?.data[0];
               
                localStorage.setItem('likeById', obj?.attributes?.like_by_id);
                commentsAPi(postId)


            })
            .catch(error => {
                console.log(error);
            });
    }


    const getAllReactionAccountsComments = (id: any) => {
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };



        axios.get(`${apiBaseUrl}/bx_block_like/show_accounts_for_reactions_to_comment?comment_id=${id}`, {
            headers: header
        })
            .then((response: { data: any; }) => {
                setAllAccounts(response?.data?.total_accounts)
                console.log(response?.data?.total_accounts)

            })
            .catch(error => {
                console.log(error);
            });

    }


    const renderPostIcon = (obj: any, reactionSelectedByUser: any) => {
        let reactionText = reactionSelectedByUser.toLowerCase();
        if (reactionText == 'like') {
            reactionText = 'likes'
        }
        let tempArr: any = ''
        Object.keys(obj)?.map((item: any) => {
            if (item != 'total_likes') {
                if (item == reactionText) {
                    tempArr = item

                }

            }

        })
        switch (tempArr) {
            case 'likes': return filledLike;
            case 'love': return filledHeart;
            case 'support': return support
            case 'funny': return funny
            default:
                break;
        }


    }

    const renderPostIconText = (obj: any, reactionSelectedByUser: any) => {
       
        let reactionText = reactionSelectedByUser.toLowerCase();
        if (reactionText == 'like') {
            reactionText = 'likes'
        }
        let tempArr: any = ''
        Object.keys(obj)?.map((item: any) => {
            if (item != 'total_likes') {
                if (item == reactionText) {
                    tempArr = item

                }

            }

        })
        switch (tempArr) {
            case 'likes': return 'Like';
            case 'love': return 'Love';
            case 'support': return 'Support'
            case 'funny': return 'Funny'
            default:
                break;
        }

    }
    const destroyLike = async (pid: any,id:any) => {

        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken')
        };
        try {
            let response = await axios.delete(`${apiBaseUrl}/bx_block_like/likes/${id}?likeable_id=${id}`, {
                headers: header
            })
            commentsAPi(pid)


        }
        catch (error) {

            console.error(error);
        }

    }



    return (
        <>


            <CommonLoader isLoading={loader} />
            <div style={{ marginTop: '25px' }}>
                {posts?.length>0?posts?.map((post: any) => {
                    return <Box key={post?.id} sx={{ bgcolor: 'background.paper', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0', marginTop: '15px' }}>
                        <SinglePost
                            post={post?.attributes??post}
                            navigation={props?.navigation}
                            setAllAccounts={setAllAccounts}
                            setPostId={setPostId}
                            setReactionModal={setReactionModal}
                            renderPostIcon={renderPostIcon}
                            renderPostIconText={renderPostIconText}
                            setComments={setComments}
                            commentsAPi={commentsAPi}
                            setReportModal={setReportModal}
                            setCommentId={setCommentId}
                            comments={comments}
                            getAllReactionAccountsComments={getAllReactionAccountsComments}
                            createCommentLike={createCommentLike}
                            destroyLike={destroyLike}
                            setCommentIdForReaction={setCommentIdForReaction}
                            totalCommentsForPost={totalCommentsForPost}
                            setCommentOfCommentIdForReaction={setCommentOfCommentIdForReaction}
                            
                        />

                        {/* comments section */}

                    </Box>

                }):<Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0', marginTop: '15px',textAlign:'center' }}>No post available</Box>}

                <ReactionModal
                    openReactionModal={openReactionModal}
                    setReactionModal={setReactionModal}
                    allAccounts={allAccounts}
                    postId={postId}
                    commentId={commentIdForReaction}
                    setPostId={setPostId}
                    setCommentId={setCommentIdForReaction}
                    commentOfCommentId={commentOfCommentId}
                    setCommentOfCommentIdForReaction={setCommentOfCommentIdForReaction}

                />
                <ReportPostModal openReportModal={openReportModal} setReportModal={setReportModal} postId={postId} commentId={commentId} />

            </div>

        </>
    )
}




export default Feeds1
// Customizable Area End