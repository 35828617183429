import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { apiCall } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  open: any;
  setOpen: any;
  closeModal: any;
  addPost: any,


  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: any;
  token: string;
  name: string;
  description: string;
  price: any;
  currency: string;
  category_id: string;
  image: any;
  uploadedImages: any;
  base64Images: any;
  AllCategory: any[];
  id: any;
  refresh: boolean;
  file: any;
  profileImageData: any;
  selectedCategory: any;
  allCategories: any[];
  isLoading: boolean;
  openCreatePost: boolean;
  body: string;
  hashTags: any;
  userAccountObj:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationCommonController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: any;
  addpostApiCallId: any;
  updatePostApiCallId: any;
  getFollowingCountCallId: any;
  getConnectionCountCallId: any;
  imgRef: any;
  getProfileApi: any;
  searchHashTagApi: any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.imgRef = React.createRef()
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      base64Images: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: {},
      selectedCategory: "",
      allCategories: [],
      isLoading: false,
      openCreatePost: false,
      body: '',
      hashTags: [],
      userAccountObj:{
        first_name:'',
        middle_name:'',
        last_name:'',
        profile_image:'',
        job_title:'',
        name_of_organisation:'',
        type:'',
        photo: '',
        connectionCount: 0,
        followingCount: 0
      }

    };
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start


    if (localStorage.getItem('authToken')) {
      let authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
      this.send(authTokenReq);
      this.getPostData({ langitude: null, longitude: null });
      this.getProfileDetails()
    } else {
      localStorage.setItem("account_id",'null')
      window?.navigator?.geolocation?.getCurrentPosition(pos => {
        this.getPostData({ latitude: pos.coords.latitude, longitude: pos.coords.longitude });
      })
    }
    this.getFollowingCount();
    this.getConnectionCount();
    // Customizable Area End

  }


  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    switch (message.id) {
      case getName(MessageEnum.NavigationPayLoadMessage):
        const item = message.getData(getName(MessageEnum.PostDetailDataMessage));
        if (item) {
          this.setState({
            name: item.data.attributes.name,
            description: item.data.attributes.description,
            price: item.data.attributes.price.toString(),
            currency: "$",
            category_id: item.data.attributes.category.data.attributes.id,
            id: item.data.attributes.id,
            profileImageData: {
              data: item.data.attributes.product_image,
              content_type: "image/jpeg",
              filename: "image.jpeg"
            },
            image: ""
          });
        }
        break;
      case getName(MessageEnum.SessionResponseMessage):
        let token = message.getData(getName(MessageEnum.SessionResponseToken));
        console.log(token)
        this.setState({ token: token }, () => {
          this.getAllCategory();
        });
        break;
      case getName(MessageEnum.RestAPIResponceMessage):
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        runEngine.debugLog("API Message Recived", message);
        if (responseJson && responseJson.errors) {
          console.log(responseJson.errors)
          this.setState({ refresh: false });
        } else if (responseJson) {
          switch (apiRequestCallId) {
            case this.apiPostItemCallId: this.getPostResponse( responseJson,errorReponse)
              this.setState({ isLoading: false })

              break;
            case this.addpostApiCallId: this.addPostResponse(responseJson);
              break;
            case this.getProfileApi: this.handleGetProfileInfoResponse(responseJson);
            // case this.getProfileApi: localStorage.setItem('account_id', responseJson?.profile?.data?.attributes?.account_id ? responseJson?.profile?.data?.attributes?.account_id : 'null');
              break;
            case this.DeleteApiCallId: this.getPostData({ latitude: null, longitude: null });
              break;
            case this.searchHashTagApi: this.handleSearchHashTagResponse(responseJson)
              break;
            case this.getFollowingCountCallId: this.getFollowingCountResponse(responseJson)
              break;
            case this.getConnectionCountCallId: this.getConnectionCountResponse(responseJson)
              break;

            default:
              break;
          }


        }
        else if (errorReponse) {
          this.setState({ refresh: false });
        }
        break;
      default:
        break;
    }

  }
  handleMessage = (message: any) => {

  }

  createPostCreation() {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert("Error", "Please enter all mandatory fields");
    } else {
      this.AddPostCreation();
    }
  }

  goToItemDetails(item: any, isEdit: boolean) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), isEdit ? "PostCreation" : "PostDetails");

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg);
  }

  editNavigation = (item: any) => {
    this.goToItemDetails(item, true);
  };

  navigateToDetails = (item: any) => {
    this.goToItemDetails(item, false);
  };

  AddPostCreation() {
  
console.log(this.state.description)
   this.setState({isLoading:true})
    
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem('authToken')
    };

    const attrs = {
      data: {
        attributes: {
          "name": '', 
          "description": this.state.description.includes('\n')?this.func(this.state.description):this.state.description,
          "body": this.state?.body,
          "location": "",
          images: this.state?.base64Images
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ openCreatePost: false ,uploadedImages:[],base64Images:[]})
   
    return true;
  }
  func(description:any){
   
    let temp:any=[]
    let des:any=''
    const words = description.split('\n');
   
    words.map((word: any) => {
        if (word.startsWith("#")) {
            
            temp.push(word);
            des+=''+word

        } 
        else{
          des+=''+word
         
          return word
         
        }
    });
   
    this.setState({ body: temp.join(", ") });
    
    return des
  }
  searchHashTag(word:any) {
    // remove # and add %23 as # is replaced with %23
    let newWord = word.substring(1, word.length)

    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem('authToken')
    };



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchHashTagApi = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/search_hashtag?hashtag=%23${newWord}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.searchHashTagMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllCategory() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);

    this.apiGetCategoryCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCatergoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPostData({ latitude, longitude }: any): boolean {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem('authToken')
    };
    const headerWithoutToken = {
      "Content-Type": configJSON.postContentType,
    };
    console.log("header  ", JSON.stringify(header));

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);

    this.apiPostItemCallId = requestMessage.messageId;
    console.log(
      "requestMessage, ",
      getName(MessageEnum.RestAPIResponceEndPointMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      localStorage.getItem('authToken') ? configJSON.postGetUrl : configJSON.postNearUrl + `?latitude=${latitude}&longitude=${longitude}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(localStorage.getItem('authToken') ? header : headerWithoutToken)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCreatePostData(Id: any) {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert("Error", "Please enter all mandatory fields")
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token
      };
      const attrs = {
        data: {
          attributes: {
            name: this.state.name,
            description: this.state.description,
            price: this.state.price,
            currency: "$",
            category_id: this.state.category_id,
            image: this.state.profileImageData
          }
        }
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePostApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGetUrl + "/" + `${Id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchPostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }


  delete(Id: any) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getPostData({ langitude: null, longitude: null });
    return true;
  }

  txtInputProductNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });
      //@ts-ignore
      this.txtInputProductNameProps.value = text;
    }
  };

  txtInputProductDiscripationProps = {
    onChangeText: (text: string) => {
      this.setState({ description: text });
      //@ts-ignore
      this.txtInputProductDiscripationProps.value = text;
    }
  };

  ImageData = {
    onChangeText: (text: string) => {
      this.setState({ image: text });
      //@ts-ignore
      this.ImageData.value = text;
    }
  };

  DropDownProps = {
    onChangeText: (text: string) => {
      this.setState({ category_id: text });
      //@ts-ignore
      this.DropDownProps.value = text;
    }
  };

  txtInputProductPriceProps = {
    onChangeText: (text: string) => {
      this.setState({ price: text });
      //@ts-ignore
      this.txtInputProductPriceProps.value = text;
    }
  };

  chooseImage = () => {
    this.showAlert("Error", "Image Picker Not Implemented")
  };

  valueExtractor1 = (val: { data: { attributes: { id: any } } }): any => {
    return val.data.attributes.id;
  };

  onValueHanndler = (val: { data: { attributes: { name: any } } }): any => {
    return val.data.attributes.name;
  };
  handleCatChangeChange = (selectedOption: any) => {
    this.setState({ selectedCategory: selectedOption, category_id: selectedOption.value });
  };
  addPostResponse = (response: any) => {
    console.log(response)
    this.setState({ refresh: true, isLoading: true,hashTags:[] });
    this.getPostData({ langitude: null, longitude: null })
  }
  handleSearchHashTagResponse = (response: any) => {
    this.setState({ hashTags: response?.hashtag })

}
getPostResponse=(responseJson:any,error:any)=>{
 
  if(responseJson.length>0){
    this.setState({PostData:responseJson});
  }
  else{

    this.setState({PostData:[]});
  }
}
getProfileDetails() {
  
   const header = {
     "Content-Type": configJSON.postContentType,
     token: localStorage.getItem('authToken')
   };
   console.log("header  ", JSON.stringify(header));

   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
   console.log("requestMessage, ", requestMessage);

   this.getProfileApi = requestMessage.messageId;


   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.getProfileEndPoint
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.getProfileMethod
   );
   
   runEngine.sendMessage(requestMessage.id, requestMessage);
   
 }
 handleRemoveImage(img:any){
  this.setState({
    uploadedImages: this.state.uploadedImages.filter((image: any) => {
        return img?.id !== image?.id
    })
    })
    this.setState({base64Images:[]})

 }
 convertImgToBase64(file: any){
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
          resolve(reader.result);
      };
      reader.readAsDataURL(file);
  });
}
handleImage(e:any){
  const obj = {
    filename: e?.target?.files[0]?.name,
    content_type: e?.target?.files[0]?.type

}
this.convertImgToBase64(e.target.files[0]).then(base64 => {
    this.setState({ base64Images: [...this.state.base64Images, { data: base64, filename: obj?.filename, content_type: obj?.content_type }] })
});
this.setState({ uploadedImages: [...this.state.uploadedImages, { id: uuidv4(), img: URL.createObjectURL(e.target.files[0]) }] })
 }
  handleGetProfileInfoResponse(responseJson: any) {
    localStorage.setItem('account_id', responseJson?.profile?.data?.attributes?.account_id);
    const { first_name, middle_name, last_name } = responseJson?.account?.data?.attributes
    const { name_of_organisation, job_title, profile_image, photo } = responseJson?.profile?.data?.attributes
    const { type } = responseJson?.account?.data?.type
    this.setState({ userAccountObj: { ...this.state.userAccountObj, first_name, middle_name, last_name, profile_image, photo, job_title, name_of_organisation, type } })
  }

  getFollowingCount = () => {
    const header = {
      "Content-type": configJSON.validationApiContentType,
      'token': localStorage.getItem("authToken")
    }
    let httpBody:any = {
      'account_id': localStorage.getItem('account_id')
    }
    let url = configJSON.followingCountUrl;
    const getAccount = apiCall({
      header: header,
      httpBody: httpBody,
      url: url,
      httpMethod: configJSON.followingCountMethod,
    });
    
    this.getFollowingCountCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getFollowingCountResponse(responseJson:any) {
    if(responseJson?.following_count){
      this.setState({ userAccountObj: { ...this.state.userAccountObj, followingCount: responseJson?.following_count } })
    }
  }
  
  getConnectionCount = () => {
    const header = {
      "Content-type": configJSON.validationApiContentType,
      'token': localStorage.getItem("authToken")
    }
    let url = configJSON.connectionCountUrl;
    const getAccount = apiCall({
      header: header,
      httpBody: {},
      url: url,
      httpMethod: configJSON.connectionCountMethod,
    });
    
    this.getConnectionCountCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getConnectionCountResponse = (responseJson:any) => {
    if(responseJson?.connections_count){
      this.setState({ userAccountObj: { ...this.state.userAccountObj, connectionCount: responseJson.connections_count } })
    }
  }
  // Customizable Area End
}
