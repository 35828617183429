import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    Button,
    InputLabel,
    Tooltip,
    IconButton
} from "@material-ui/core";
import * as Yup from 'yup'
import { HighlightOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import EducationSucessMoedel from "./EducationSucessMoedel.web";
// import InputLabel from "@mui/material/InputLabel";
const close = require("../src/close.png");
const info = require("../src/icon.png");
import EditIcon from "@material-ui/icons/Edit";

const style: any = {
    height: 600,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    borderRadius: 14,
    boxShadow: 24,
    p: 3,
    overflow: "auto",
};


const AddEducationModal = ({open, handleModalClose,getEducationDetails,educationDetails, isModalEditId, isEdit, studentProfileDetails }: any) => {
const [image, setImage] = React.useState<string | undefined>();
const [previewImage, setPreviewImage] = React.useState<string | undefined>();
const [characterCount1000, setCharacterCount1000] = React.useState(0)
const [characterCount1500, setCharacterCount1500] = React.useState(0)
const [inputValue, setInputValue] = React.useState('');
const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);
  const [myInputValue, setmyInputValue] = React.useState('');
  const [confirmOpen, setConfirmOpen] = useState(false)

    const contactUsSchema: any = Yup.object().shape({
        name: Yup.string().required('Name is required.'),
        grade: Yup.string().required('Grade is required.'),
        year: Yup.string().required('Year is required.'),
        start_date: Yup.string().required('Start month is required.'),
        start_year: Yup.string().required('Start year is required.'),
        end_date: Yup.string().required('End month is required.'),
        end_year: Yup.string().required('End year is required.'),
        field_of_study: Yup.string().required('Field of study is required.'),
    })

    const handleInputChange = (event:any) => {
        const value = event.target.value;
        setInputValue(value);
        setCharacterCount1000(value.length);
        
      };

      const handleMyInputChnage =(event:any)=>{
        const value1 = event.target.value;
        setmyInputValue(value1)
        setCharacterCount1500(value1.length);
      }
    const postEducationDetails = (values: any) => {
        let token = localStorage.getItem('authToken')
        const header = {
          token: token,
        };
    
        let formdata = new FormData();
        formdata.append("educational_qualification[school_name]", values.name);
        formdata.append("educational_qualification[grades]", values.grade);
        formdata.append("educational_qualification[description]", values.description);
        image !== undefined && formdata.append("educational_qualification[images][]", image);
        formdata.append(
          "educational_qualification[activities_and_societies]",
          values.activities_and_societies
        );
        formdata.append(
          "educational_qualification[start_month]",
          values.start_date
        );
        formdata.append("educational_qualification[start_year]", values.start_year);
        formdata.append("educational_qualification[end_month]", values.end_date);
        formdata.append("educational_qualification[end_year]", values.end_year);
        formdata.append("field_of_study", values.field_of_study);
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        // this.getEducationNewId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_profile/educational_qualifications"
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // setIsSuccessModalOpen(true)
        handleModalClose();
        setConfirmOpen(true)
        setTimeout(() => {
            getEducationDetails()
        }, 3000);
    };
    const postEducationDetailsEdit = (values: any) => {
        let token = localStorage.getItem('authToken')
        const header = {
          token: token,
        };
    
        let formdata = new FormData();
        formdata.append("educational_qualification[school_name]", values.name);
        formdata.append("educational_qualification[grades]", values.grade);
        formdata.append("educational_qualification[description]", values.description);
        image !== undefined && formdata.append("educational_qualification[images][]", image);
        formdata.append(
          "educational_qualification[activities_and_societies]",
          values.activities_and_societies
        );
        formdata.append(
          "educational_qualification[start_month]",
          values.start_date
        );
        formdata.append("educational_qualification[start_year]", values.start_year);
        formdata.append("educational_qualification[end_month]", values.end_date);
        formdata.append("educational_qualification[end_year]", values.end_year);
        formdata.append("field_of_study", values.field_of_study);
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        // this.getEducationNewId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_profile/educational_qualifications/${isModalEditId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PATCH"
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // setIsSuccessModalOpen(true)
        handleModalClose();
        setConfirmOpen(true)

        setTimeout(() => {
            getEducationDetails()
        }, 3000);
    };



    const currentYear = new Date().getFullYear();
    const startYear = 2000;


    const handleAddImage = (event: any) => {        
        setPreviewImage(URL.createObjectURL(event.target.files[0]))
        setImage(event.target.files[0])
    }

    const handleClose = () => {
        // setSelect(false)
        setConfirmOpen(false)
        handleModalClose();
    }

    const successModalClose = () => {     
        // setIsSuccessModalOpen(false)
        setConfirmOpen(false)
    }  
    
    return (
        <>
            <Modal
                open={open}
                onClose={() => {handleClose();setPreviewImage(undefined)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <img
                    src={close}
                        style={modalContactStyle.closeIcon}
                        onClick={() => {handleClose();setPreviewImage(undefined)}}
                    />
                    <Typography style={modalContactStyle.contact_textContent}>
                        Add education details
                    </Typography>
                    <Formik
                        initialValues={{ 
                            name: educationDetails?.attributes?.school_name , 
                            grade: educationDetails?.attributes?.grades , 
                            year:  educationDetails?.attributes?.end_year, 
                            start_date: educationDetails?.attributes?.start_month, 
                            start_year: educationDetails?.attributes?.start_year, 
                            end_date: educationDetails?.attributes?.end_month, 
                            end_year: educationDetails?.attributes?.end_year, 
                            field_of_study: educationDetails?.attributes?.educational_qualification_field_studys[0]?.field_of_study,
                            activities_and_societies: educationDetails?.attributes?.activities_and_societies,
                            description: educationDetails?.attributes?.description
                        }}
                        validationSchema={contactUsSchema}
                        onSubmit={(values: any) => {Object.keys(educationDetails).length !== 0 ? postEducationDetailsEdit(values) : postEducationDetails(values) }}
                    >
                        {({ errors, values, touched, handleSubmit, handleChange, handleBlur, setFieldValue }: any) => (
                            <form onSubmit={handleSubmit}>
                                <Box style={modalContactStyle.main}>
                                    <Box style={modalContactStyle.row}>
                                        <Box style={modalContactStyle.name}>
                                            <label>School name *</label>
                                            <InputField
                                                error={errors.name && touched.name}
                                                data-test-id="inputName"
                                                placeholder="Ex. abcd school"
                                                fullWidth={true}
                                                id="name"
                                                name="name"
                                                variant="outlined"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.name && touched.name &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.name}
                                                </div>
                                            }
                                        </Box>
                                        <Box style={modalContactStyle.w202}>
                                            <Box style={{display:"flex" ,marginBottom:"7px"}}>
                                            <label>Grade </label>
                                            <Tooltip title="British system" placement="right-start" >
                                             <img  style={{ cursor: "pointer",}} src={info}/> 
                                            </Tooltip>
                                            </Box>
                                            {/* <InputLabel>Grade</InputLabel> */}
                                            <Select
                                                error={errors.grade && touched.grade}
                                                id="grade"
                                                name="grade"
                                                variant="outlined"
                                                fullWidth
                                                style={modalContactStyle.select}
                                                value={values.grade}
                                                displayEmpty
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <MenuItem selected value='' disabled>
                                                </MenuItem>
                                                <MenuItem value="A">A</MenuItem>
                                                <MenuItem value="B">B</MenuItem>
                                                <MenuItem value="C">C</MenuItem>
                                            </Select>
                                            {errors.grade && touched.grade &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.grade}
                                                </div>
                                            }
                                        </Box>
                                        <Typography style={{paddingTop:"50px",marginRight:"-19px"}}>or</Typography>
                                        <Box style={modalContactStyle.w201}> 
                                            <Box style={{display:"flex",marginBottom:"7px"}}>
                                            <label>Year </label>
                                            <Tooltip title="US system" placement="right-start">
                                             <img style={{ cursor: "pointer",}} src={info}/> 
                                            </Tooltip>
                                            </Box>
                                            <Select
                                                error={errors.year && touched.year}
                                                id="year"
                                                name="year"
                                                variant="outlined"
                                                fullWidth
                                                style={modalContactStyle.select}
                                                value={values.year}
                                                displayEmpty
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <MenuItem selected value='' disabled>
                                                </MenuItem>
                                                {Array.from(new Array(currentYear - startYear + 1), (v, i) => (
                                                    <MenuItem key={i} value={currentYear - i}>{currentYear - i}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.year && touched.year &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.year}
                                                </div>
                                            }
                                        </Box>
                                    </Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box style={modalContactStyle.w201}>
                                            <label>Start date *</label>
                                            <Select
                                                error={errors.start_date && touched.start_date}
                                                id="start_date"
                                                name="start_date"
                                                variant="outlined"
                                                fullWidth
                                                style={modalContactStyle.select}
                                                value={values.start_date}
                                                displayEmpty
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                
                                                >
                                                                   
                                                <InputLabel>Month</InputLabel>

                                                <MenuItem value="january">January</MenuItem>
                                                <MenuItem value="february">February</MenuItem>
                                                <MenuItem value="march">March</MenuItem>
                                                <MenuItem value="april">April</MenuItem>
                                                <MenuItem value="may">May</MenuItem>
                                                <MenuItem value="june">June</MenuItem>
                                                <MenuItem value="july">July</MenuItem>
                                                <MenuItem value="august">August</MenuItem>
                                                <MenuItem value="september">September</MenuItem>
                                                <MenuItem value="october">October</MenuItem>
                                                <MenuItem value="november">November</MenuItem>
                                                <MenuItem value="december">December</MenuItem>
                                            </Select>
                                            {errors.start_date && touched.start_date &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.start_date}
                                                </div>
                                            }
                                        </Box>
                                        <Box style={modalContactStyle.combine}>
                                            <Select
                                                error={errors.start_year && touched.start_year}
                                                id="start_year"
                                                name="start_year"
                                                variant="outlined"
                                                fullWidth
                                                style={{...modalContactStyle.select, margin: "18px 0 8px"}}
                                                value={values.start_year}
                                                displayEmpty
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                >
                                                <InputLabel>Year</InputLabel>
                                                <MenuItem selected value='' disabled>
                                                </MenuItem>
                                                {Array.from(new Array(currentYear - startYear + 1), (v, i) => (
                                                    <MenuItem key={i} value={currentYear - i}>{currentYear - i}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.start_year && touched.start_year &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.start_year}
                                                </div>
                                            }
                                        </Box>
                                        <Box style={modalContactStyle.w201}>
                                            <label>End date (or expected) *</label>
                                            <Select
                                                error={errors.end_date && touched.end_date}
                                                id="end_date"
                                                name="end_date"
                                                variant="outlined"
                                                fullWidth
                                                style={modalContactStyle.select}
                                                value={values.end_date}
                                                displayEmpty
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                >
                                                <InputLabel>Month</InputLabel>
                                               
                                                <MenuItem value="january">January</MenuItem>
                                                <MenuItem value="february">February</MenuItem>
                                                <MenuItem value="march">March</MenuItem>
                                                <MenuItem value="april">April</MenuItem>
                                                <MenuItem value="may">May</MenuItem>
                                                <MenuItem value="june">June</MenuItem>
                                                <MenuItem value="july">July</MenuItem>
                                                <MenuItem value="august">August</MenuItem>
                                                <MenuItem value="september">September</MenuItem>
                                                <MenuItem value="october">October</MenuItem>
                                                <MenuItem value="november">November</MenuItem>
                                                <MenuItem value="december">December</MenuItem>
                                            </Select>
                                            {errors.end_date && touched.end_date &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.end_date}
                                                </div>
                                            }
                                        </Box>
                                        <Box style={modalContactStyle.combine}>
                                            <Select
                                                error={errors.end_year && touched.end_year}
                                                id="end_year"
                                                name="end_year"
                                                variant="outlined"
                                                fullWidth
                                                style={{...modalContactStyle.select, margin: "18px 0 8px"}}
                                                value={values.end_year}
                                                displayEmpty
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                >
                                                <InputLabel>Year</InputLabel>
                                                <MenuItem selected value='' disabled>
                                                </MenuItem>
                                                {Array.from(new Array(currentYear - startYear + 1), (v, i) => (
                                                    <MenuItem key={i} value={currentYear - i}>{currentYear - i}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.end_year && touched.end_year &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.end_year}
                                                </div>
                                            }
                                        </Box>
                                    </Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box style={modalContactStyle.field}>
                                            <label>Field of study *</label>
                                            <InputField
                                                error={errors.field_of_study && touched.field_of_study}
                                                data-test-id="inputName"
                                                placeholder="Ex. Arts & Design"
                                                fullWidth={true}
                                                id="field_of_study"
                                                name="field_of_study"
                                                variant="outlined"
                                                value={values.field_of_study}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.field_of_study && touched.field_of_study &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.field_of_study}
                                                </div>
                                            }
                                        </Box>
                                    </Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box
                                            style={modalContactStyle.field}
                                        >
                                            <Box style={{display:"flex" ,justifyContent: "space-between"}}>
                                            <label>Activities and societies</label>
                                            <p style={{color:"#be9e44" ,marginTop:"1px"}}>{characterCount1000}/1000</p>
                                            </Box>
                                            <TextArea
                                                type="text"
                                                id="activities_and_societies"
                                                name="activities_and_societies"
                                                value={values.activities_and_societies}
                                                data-test-id="inputMessage"
                                                onBlur={handleBlur}
                                                multiline
                                                rows={3}
                                                variant="outlined"
                                                fullWidth={true}
                                                onChange={handleChange}
                                                maxLength={1000}
                                                style={{ marginTop: "-15px" }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box style={modalContactStyle.field}>
                                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                                <label>Description</label>
                                                <p style={{ color: "#be9e44", marginTop: "1px" }}>{characterCount1500}/1500</p>
                                            </Box>
                                            <TextArea
                                                type="text"
                                                id="description"
                                                name="description"
                                                value={values.description}
                                                data-test-id="inputMessage"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                multiline
                                                rows={3}
                                                variant="outlined"
                                                fullWidth={true}
                                                maxLength={1500}
                                                style={{ marginTop: "-15px" }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box
                                            style={modalContactStyle.field}
                                        >
                                            <label>Media</label>
                                            <Typography style={modalContactStyle.subMedia}>Add or link to external documents,photos,sites,video and presentations.</Typography>
                                            <label htmlFor="images">
                                                <Button variant="contained" component="span" style={modalContactStyle.uploadButton}>
                                                   + Add media
                                                    <input
                                                        accept="image/*"
                                                        style={modalContactStyle.selectProfile}
                                                        id="images"
                                                        name="images"
                                                        multiple
                                                        type="file"
                                                        onChange={event => handleAddImage(event)}
                                                    />
                                                </Button>
                                            </label>
                                        </Box>
                                    </Box>
                                    {previewImage ?
                                        <Box style={modalContactStyle.row}>
                                            <Box
                                                style={modalContactStyle.mainPhoto}
                                            >
                                                <label htmlFor="images" style={modalContactStyle.editMedia}>
                                                    <div style={modalContactStyle.uploadButton1}>
                                                        <EditIcon style={modalContactStyle.editIcon} />
                                                        <input
                                                            accept="image/*"
                                                            style={modalContactStyle.selectProfile}
                                                            id="images"
                                                            name="images"
                                                            multiple
                                                            type="file"
                                                            onChange={event => handleAddImage(event)}
                                                        />
                                                    </div>
                                                </label>
                                                <img
                                                    src={previewImage}
                                                    style={modalContactStyle.photo}
                                                    id="images"
                                                />
                                            </Box>
                                            <Box
                                                style={modalContactStyle.info}
                                            >
                                                <Typography style={modalContactStyle.medaihtxt}>My skill reward</Typography>
                                                <Typography style={modalContactStyle.medaitxt}>Hobbies: {studentProfileDetails?.profile?.data?.attributes?.hobbies} Personality: {studentProfileDetails?.profile?.data?.attributes?.personality} Goal: {studentProfileDetails?.profile?.data?.attributes?.goal} </Typography>
                                            </Box>
                                        </Box> :
                                        educationDetails?.attributes?.images[0]?.url ?
                                            <Box style={modalContactStyle.row}>
                                                <Box
                                                    style={modalContactStyle.mainPhoto}
                                                >
                                                    <label htmlFor="images" style={modalContactStyle.editMedia}>
                                                        <div style={modalContactStyle.uploadButton1}>
                                                            <EditIcon style={modalContactStyle.editIcon} />
                                                            <input
                                                                accept="image/*"
                                                                style={modalContactStyle.selectProfile}
                                                                id="images"
                                                                name="images"
                                                                multiple
                                                                type="file"
                                                                onChange={event => handleAddImage(event)}
                                                            />
                                                        </div>
                                                    </label>
                                                    <img
                                                        src={educationDetails?.attributes?.images[0]?.url}
                                                        style={modalContactStyle.photo}
                                                        id="images"
                                                    />
                                                </Box>
                                                <Box
                                                    style={modalContactStyle.info}
                                                >
                                                    <Typography style={modalContactStyle.medaihtxt}>My skill reward</Typography>
                                                    <Typography style={modalContactStyle.medaitxt}>Hobbies: {studentProfileDetails?.profile?.data?.attributes?.hobbies} Personality: {studentProfileDetails?.profile?.data?.attributes?.personality} Goal: {studentProfileDetails?.profile?.data?.attributes?.goal} </Typography>
                                                </Box>
                                            </Box> : <></>
                                    }
                                    <Box style={modalContactStyle.row}>
                                        <Box
                                            style={modalContactStyle.submit}
                                        >
                                            <Button type="submit" variant="contained" style={modalContactStyle.submitButton}>
                                                Save
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
            {confirmOpen && <EducationSucessMoedel open={confirmOpen} successModalClose={successModalClose} setIsModalOpen={setConfirmOpen}/>}

        </>
    );
}

export default AddEducationModal;


const modalContactStyle: any = {
    contact_textContent: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px"
    },
    closeIcon: {
        cursor: "pointer",
        position: "absolute",
        right: "20px",
        top: "20px",
 height: "20px",
    },
    fieldError: {
        color: "#D11024",
        fontSize: "14px",
        margin: "-7px 5px 10px 0"
    },
    main: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    row: {
        display: "flex",
        gap: "24px"
    },
    name: {
        width: "426px"
    },
    w201: {
        width: "201px"

    },
    w202: {
        width: "201px",
        marginRight:"-20px"

    },
    field: {
        width: "876px",
        marginBottom: "10px",
        marginTop: "14px",
    },
    combine: {
        width: "201px",
        flexWrap: "wrap",
        display: "flex",
        alignItems: "end"
    },
    select: {
        width: "100%",
        borderRadius: "8px",
        margin: "0 0 8px"
    },
    subMedia: {
        fontSize: "16px",
        color: "#565656",
        marginBottom: "14px"
    },
    selectProfile: {
        display: "none"
    },
    uploadButton: {
        height: "32px",
        border: "1px solid #BE9E44",
        backgroundColor: "#fff",
        borderRadius: "8px",
        color: "#BE9E44",
        textTransform: "none",
    },
    uploadButton1: {
        color: "#111a5d",
    },
    mainPhoto: {
        width: "123px",
        height: "65px",
        borderRadius: '8px',
        position: 'relative'
    },
    editMedia: {
        width: "30px",
        height: "20px",
        borderTopRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        position: 'absolute',
        right: '0',
        background: 'lightgrey',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    editIcon: {
        width: '14px'
    },
    photo: {
        width: "123px",
        height: "65px",
        borderRadius: '8px'
    },
    info: {
        width: "737px",
        height: "65px"
    },
    medaihtxt: {
        fontSize: "14px",
        color: "#454545"
    },
    medaitxt: {
        fontSize: "14px",
        color: "#1C1C1C"
    },
    submitButton: {
        height: "32px",
        backgroundColor: "#BE9E44",
        borderRadius: "8px",
        color: "#fff",
        textTransform: "none",
    },
    submit: {
        width: "876px",
        display: "flex",
        justifyContent: "end"
    }

};

const InputField: any = withStyles({
    root: {
        padding: '10px 0',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue-medium",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)"
            }
        },
        "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D11024"
            }
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue-Medium",
        }
    }
})(TextField);

const TextArea: any = withStyles({
    root: {
        width: '100%',
        paddingTop: '10px',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue-Medium",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
        },
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)"
            }
        },
        "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D11024"
            }
        },
    }
})(TextField);
