// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.userName = "User name"
exports.location = "Location"
exports.url = "Location"
exports.educationList = "/profile/educational_qualifications"
exports.projectList = "/profile/projects"
exports.awardList = "/profile/awards"
exports.patentList = "/profile/publication_patents"

exports.educationalTitle = "Educational Qualification";
exports.projectLabel = "Project";
exports.awardsLabel = "Awards";
exports.publicationPatentLabel = "Publication Patents";
exports.showMore = "Show More";
exports.noProjectText = "No project...";
exports.noQualiText = "No qualifications";
exports.noAwards = "No Award...";
exports.noPublicationPatents = "No Publication Patents...";
exports.closeText = "Close";

exports.addBgPhotoText = "Add background photo";
exports.addCoverPhotoSubText = "Add a cover photo for your profile.";
exports.addCoverPhotoSubText2 = "A good background photo will help you stand out.";
exports.removeCoverPhotoText = "Remove cover photo";
exports.removeCoverConfirmText = "Are you sure you want to remove this cover photo?";
exports.removeProfilePhotoText = "Remove profile photo";
exports.removeProfileConfirmText = "Are you sure you want to remove this profile photo?";
exports.addProfilePhotoText = "Add profile photo";
exports.addProfilePhotoSubText = "Add a profile photo for your profile.";
exports.addProfilePhotoSubText2 = "A profile photo of yourself will help your friends to recognize you.";
exports.nameOfOrgLabel = "Name of organisation";
exports.streetNameLabel = "Street name";
exports.postalCodeLabel = "Postal code";
exports.cityLabel = "City";
exports.countryLabel = "Country";
exports.openingHoursLabel = "Opening hours";
exports.closingHoursLabel = "Closing hours";
exports.saveBtnText = "Save";
exports.uploadBgPhotoBtnText = "Upload background photo";
exports.uploadProfilePhotoBtnText = "Upload profile photo";
exports.changeBtnText = "Change";
exports.changePhotoBtnText = "Change photo";

exports.followerText = "Followers";
exports.followingText = "Followings";
exports.myActivityText = "My activity";
exports.allActivityText = "All activity";
exports.postsText = "Posts";
exports.likedText = "Likes";
exports.commentsText = "Comments";
exports.userNameText = "User name";
exports.studentatOrgText = "Student or Organization";
exports.notPostedText = "You haven't posted anything yet.";
exports.notLikedText = "You haven't liked any post yet.";
exports.notCommentedText = "You haven't commented on any post yet.";

exports.sortByText = "Sort by :";
exports.recentlyAddedText = "Recently added";
exports.firstNameText = "First name";
exports.lastNameText = "Last name";
exports.noAlumniText = "No alumni were found";
exports.removeAlumniText = "Remove alumni";
exports.confirmRemoveAlumniText = "Are you sure you want to remove this alumni?";
exports.becomeAlumniBtnText = "Become an alumni";

exports.noPostText = "No post available";

exports.recentPostText = "Recent posts you like, share or comment on will be displayed here";
exports.seeAllActivityText = "See all activity";
exports.startPostText = "Start a post";
exports.followerConnectionText = "Followers & connections";
exports.manageConnectionText = "See and manage you Ed connections and following.";
exports.viewBtnText = "View";
exports.alumniListText = "Alumni list";
exports.aboutOrgText = "About organization";
exports.aboutText = "About";

exports.getEducationContentType = "application/json";
exports.getEducationApiMethod = "GET";
exports.getEducationApiEndPoint = "profile/educational_qualifications";

exports.getProjectContextType = "application/json";
exports.getProjectApiMethod = "GET";
exports.getProjectApiEndPoint = "profile/projects";

exports.getAwardsContextType = "application/json";
exports.getAwardsApiMethod = "GET";
exports.getAwardsApiEndPoint = "profile/awards";

exports.getPatentsContextType = "application/json";
exports.getPatentsApiMethod = "GET";
exports.getPatentsApiEndPoint = "profile/publication_patents";

exports.getTermsPolicyMethod = "GET";
exports.getTermsPolicyApiEndPoint = "bx_block_admin/settings";

exports.getProfileMethod = "GET";
exports.getProfileApiEndPoint = "bx_block_profile/profiles/get_profile";
exports.getProfileByIdApiEndPoint = "bx_block_profile/profiles";

exports.updateProfileMethod = "PATCH";
exports.updateProfileApiEndPoint = "bx_block_profile/profiles/update_profile";

exports.getPostMethod = "GET";
exports.getPostApiEndPoint = "bx_block_posts/posts";

exports.getUserPostMethod = "POST";
exports.getUserPostApiEndPoint = "/bx_block_posts/posts/user_posts";

exports.getAlumniBySearchMethod = "POST";
exports.getAlumniBySearchApiEndPoint = "bx_block_profile/profiles/search_alumni_name";

exports.getUserAllActivityMethod = "POST";
exports.getUserAllActivityApiEndPoint = "/bx_block_posts/posts/my_activity";

exports.getAlumniByFilterMethod = "POST";
exports.getAlumniByFilterApiEndPoint = "bx_block_profile/profiles/list_of_alumni";

exports.followOrganizationMethod = "POST";
exports.followOrganizationApiEndPoint = "/bx_block_followers/follows";

exports.unfollowOrganizationMethod = "DELETE";
exports.unfollowOrganizationApiEndPoint = "/bx_block_followers/follows";

exports.removeAlumniMethod = "GET";
exports.removeAlumniApiEndPoint = "/bx_block_profile/profiles/remove_from_alumni";

exports.becomeAlumniMethod = "POST";
exports.becomeAlumniApiEndPoint = "/bx_block_profile/profiles/become_an_alumni";

exports.updateStudentProfileMethod = "PATCH";
exports.updateStudentProfileApiEndPoint = "bx_block_profile/profiles/update_profile";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "bx_block_posts/posts";
exports.postNearUrl = "bx_block_posts/posts/nearest";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";
exports.getProfileEndPoint = "bx_block_profile/profiles/get_profile";
exports.getProfileMethod = "GET";
exports.searchHashTagUrl = "bx_block_posts/posts/search_hashtag";
exports.searchHashTagMethod = "GET"
exports.PostAPiMethod = "POST"
exports.followersCountUrl = "bx_block_followers/follows/followers"
exports.followingCountUrl = "/bx_block_followers/follows/following_list"
exports.followersCountMethod = "POST"
exports.followingCountMethod = "POST"
exports.connectionCountMethod = "GET"
exports.connectionCountUrl = "/bx_block_friendlist/connects/connection_list"
// Customizable Area End