// Customizable Area Start
import React, { useState } from 'react'
import { Box, styled, IconButton, Typography, withStyles, TextField, Button, Menu, MenuItem } from "@material-ui/core";
import { close } from '../../blocks/user-profile-basic/src/assets';
import { moreOptions, profile1 } from '../../blocks/HashtagGenerator/src/assets';
import ChatsList from './ChatsList.web';

const profile = require("../src/profile.png");

interface Props {
    setOpenChat: any
}
function MessageChatBox(props: Props) {
    const chatsArr=[{id:'1',name:'Mariana Rasogado',profileImg:profile,message:''},{id:'2',name:'Nahia Colunga',profileImg:profile1,message:''},{id:'3',name:'Mariana Rasogado',profileImg:profile,message:''}]
    const { setOpenChat } = props;
    const [chatOptionEl, setChatOptionEl] = useState(null)
    const handleOverlayScroll = (event: any) => {

        if ((event.target === document.getElementById('header')) || (event.target === document.getElementById('footer')) || (event.target === document.getElementById('textarea'))) {

            event.preventDefault();
        }


    }
    return (
        <ChatBox onWheel={handleOverlayScroll}>
            <StyledHeaderContainer id='header'>
                <ProfileAndNameContainer>
                    <ImageContainer><img src={profile} width='34px' /></ImageContainer>
                    <PersonName>Nahia Colunga</PersonName>
                </ProfileAndNameContainer>
                <MoreAndCloseContainer>
                    <MoreOptionButton onClick={(e: any) => setChatOptionEl(e.currentTarget)}><img src={moreOptions} width="20px" /></MoreOptionButton>
                    <IconButton size='small' onClick={() => setOpenChat(false)}><img src={close} width="20px" /></IconButton>
                </MoreAndCloseContainer>
                <Menu
                    id="basic-menu"
                    anchorEl={chatOptionEl}
                    open={Boolean(chatOptionEl)}
                    onClose={() => setChatOptionEl(null)}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{ style: { boxShadow: "0px 0px 2px rgba(156, 156, 156, 0.169471)", border: '2px solid #F0F0F0', borderRadius: '12px 0px 12px 12px' } }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                > <MenuItem>
                        Delete
                    </MenuItem>
                    <MenuItem>
                        Mark as unread
                    </MenuItem>
                    <MenuItem>
                        Manage settings
                    </MenuItem>
                    <MenuItem>
                        Report/Block
                    </MenuItem>
                </Menu>
            </StyledHeaderContainer>
            <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />
            <div style={{ maxHeight: '400px', overflowY: 'scroll', scrollbarWidth: 'none' }}>

                <ProfileInfoContainer>
                    <ProfileImage><img src={profile} width="60px" /></ProfileImage>
                    <PersonName>Nahia Colunga</PersonName>
                    <OrgName>Student at abcd college</OrgName>

                </ProfileInfoContainer>
                {/* this needs to be in map */}
             
                {chatsArr?.map((chatItem:any)=>{
                    return  <ChatsList chatItem={chatItem} />
                })}
                {/* this needs to be in map */}
            </div>



            <hr style={{ height: '1px', border: 'none', backgroundColor: '#D7D7D7' }} />


            <TextFieldContainer id='footer'>
                <InputArea id="textarea" variant="outlined" placeholder="Write a message..." multiline rows={3} />

            </TextFieldContainer>
            <StyledButtonContainer>
                <SendButton>Send</SendButton>

            </StyledButtonContainer>


        </ChatBox>
    )
}

const ChatBox = styled(Box)({
    width: '470px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #F0F0F0',
    boxShadow: '0px 0px 2px rgba(156, 156, 156, 0.169471)',
    borderRadius: '12px 0px 12px 12px',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    zIndex: 1,
    top: '64%',
    right: '20%'
});
const StyledHeaderContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px 0px 15px'

})
const ProfileAndNameContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center'


})
const ImageContainer = styled(Box)({
    borderRadius: '100%',
    border: '1px solid #F0F0F0',
    marginRight: '15px'

});


const PersonName = styled(Typography)({
    fontSize: '14px',
    color: '#1C1C1C'

});

const MoreAndCloseContainer = styled(Box)({
    display: 'flex',

});

const MoreOptionButton = styled(Box)({
    marginRight: '15px',
    transform: 'rotate(-90deg)',
    cursor: 'pointer'
});

const ProfileInfoContainer = styled(Box)({
    padding: '10px 15px'

});

const ProfileImage = styled(Box)({
    borderRadius: '100%',
    border: '1px solid #F0F0F0',


});

const OrgName = styled(Typography)({
    fontSize: '14px',
    color: '#565656'


});

const ChatContainer = styled(Box)({
    display: 'flex',
    padding: '15px 50px 0px 15px'



});

const ChatProfileImg = styled(Box)({
    borderRadius: '100%',
    border: '1px solid #F0F0F0',
    marginRight: '15px'
})

const MessageContainer = styled(Box)({

})
const TextContainer = styled(Box)({
    fontSize: '14px',
    color: '#565656',
    margin: '10px 0px'

})
const TextFieldContainer = styled(Box)({
    padding: '5px 22px'

})
const InputArea: any = withStyles({
    root: {
        width: '423px',
        borderRadius: "6px",
        backgroundColor: '#F7F7F7',
        fontFamily: "HelveticaNeue",
        border: 'none',
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "14px",
                borderRadius: "8px",
                fontWeight: 400,
                borderWidth: 0,
            },
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue",
            fontSize: "14px",
        }
    }
})(TextField);

const StyledButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '5px 25px'
});

const SendButton = styled(Button)({
    backgroundColor: '#BE9E44',
    border: '1px solid #BE9E44',
    borderRadius: '4px',
    color: 'white',
    textTransform: 'capitalize',
    width: '70px',
    height: '30px',
    '&:hover': {
        backgroundColor: '#BD9E44'

    }
});
export default MessageChatBox
// Customizable Area End