// Customizable Area Start
import React from 'react'

const EdConnectLogo = require("../src/edconnect_logo.png");

function MessagingFooter() {
    return (
        <div >

            <div style={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}>
                <div style={webStyles.footer}>About Us</div>
                <div style={webStyles.footer}>Privacy Policy</div>
                <div style={webStyles.footer}>Contact Us</div>

            </div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0px' }}>
                <div style={webStyles.footer}>Terms & Conditions</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '10px 0px' }}>
                <img src={EdConnectLogo} width="28px" />
                <div style={webStyles.footer}>Copyright @2023 EdConnect.  All right reserved</div>

            </div>
        </div>
    )
}
const webStyles = {
    
    footer: {
        fontSize: '10px',
        color: '#7C7C7C',
        fontFamily: "HelveticaNeue"
    }
}
export default MessagingFooter
// Customizable Area End