// Customizable Area Start
import React from 'react'
import { Box } from "@material-ui/core";
const moreOptions = require("../src/moreOptions.png");
const profile = require("../src/profile.png");
const history = require("../src/history.png");
const like = require("../src/like.png");
const likes = require("../src/likes.png");
const report = require("../src/report.png");
const comments = require("../src/comments.png");
const share = require("../src/sharePost.png");





interface Props {
    postData: any;
    isLoad: any;
}
function PostList(props: Props) {



    return (
        <>

            

            <div >
                {props?.postData?.map((post: any,index:number) => {
                    return <Box key={post?.id} sx={{ bgcolor: 'background.paper', p: 2, borderRadius: '10px', border: '1px solid #F0F0F0',marginBottom:props.postData.length-1===index?"0px":"10px" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: '15px' }}>
                                    <img src={profile} style={{ border: '1px solid #D6D3D3', borderRadius: '50%' }} width='48px' />
                                </div>
                                <div>
                                    <div style={{ color: "#1C1C1C", fontFamily: "HelveticaNeue" }}>Allicia Puma</div>
                                    <div style={{ fontSize: '14px', color: '#565656', margin: '10px 0px 10px 0px', fontFamily: "HelveticaNeue" }}>Abcd city public school</div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ fontSize: '14px', color: '#565656', fontFamily: "HelveticaNeue" }}>12h.</div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><img src={history} width='14px' /></div>
                                    </div>
                                </div>

                            </div>

                            <div>
                                <img src={moreOptions} style={{ transform: 'rotate(90deg)' }} width='24px' />

                            </div>

                        </div>

                        <div style={{ marginTop: "15px" }}>
                            <div style={webStyles.feedsFont}>
                                {post?.attributes?.description}

                            </div>
                            <div style={webStyles.postImgContainer}>
                                {post?.attributes?.images_and_videos?.map((im:any)=><img style={webStyles.postImgStyle} src={im.url}/>)}
                            </div>

                        </div>

                        <div style={{ margin: '15px 0px' }}>
                            <div style={{ fontSize: '14px', color: '#BE9E44', fontFamily: "HelveticaNeue" }}>#students #remote #education #career #school</div>

                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '20px', height: '20px', justifyContent: "center", borderRadius: '50%', backgroundColor: '#B69D55', marginRight: '5px' }}>
                                    <img src={like} width='12px' />
                                </div>
                                <div style={webStyles.feedsFont}>75</div>
                            </div>
                            <div style={webStyles.feedsFont}>66 Comments</div>
                        </div>
                        <hr style={{ border: '0.5px solid #D7D7D7' }} />

                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div style={webStyles.actionLIne}>
                                <div><img src={likes} width='20px' /></div>
                                <div style={webStyles.actionText}>Like</div>
                            </div>
                            <div style={webStyles.actionLIne}>
                                <div><img src={comments} width='20px' /></div>
                                <div style={webStyles.actionText}>Comment</div>
                            </div>
                            <div style={webStyles.actionLIne}>
                                <div><img src={share} width='20px' /></div>
                                <div style={webStyles.actionText}>Share Post</div>
                            </div>
                            <div style={webStyles.actionLIne}>
                                <div><img src={report} width='20px' /></div>
                                <div style={webStyles.actionText}>Report</div>
                            </div>

                        </div>

                        
                    </Box>

                })}



            </div>

        </>
    )
}

const webStyles = {
    newPost: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sendBox: {
        width: '32px',
        height: '32px',
        backgroundColor: '#BE9E44',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    heading: {
        fontSize: '12px',
        lineHeight: '14px',
        color: '#1C1C1C',
        padding: '5px 0px 5px 0px',
        fontFamily: "HelveticaNeue",


    }
    ,
    feedsFont: {
        fontSize: '14px',
        fontFamily: "HelveticaNeue",
        color: '#565656'
    },
    actionLIne: {
        display: 'flex',
        margin: '5px 0px',
        cursor: 'pointer'
    },
    actionText: {
        fontSize: '16px',
        color: '#696868',
        marginLeft: '10px',
        fontFamily: 'HelveticaNeue'
    },
    postImgContainer:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginTop:"15px"
    },
    postImgStyle:{
        width:"580px",
        height:'332px',
        borderRadius:'10px',
        border:"none"
        // objectFit:'contain'
        
    }
}
export default PostList

// Customizable Area End