import React from "react";
import {
    Modal,
    Box,
    Typography,
    TextField,
    Checkbox,
    Button,
} from "@material-ui/core";
import * as Yup from 'yup'
import { HighlightOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import SuccessModal from "./SuccessModal.web";
const close = require("../src/close.png");

const style: any = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    borderRadius: 14,
    boxShadow: 24,
    p: 3,
    overflow: "auto",
};
const CheckboxStyle = withStyles({
root: {
    '&$checked':{
        color:'#be9e44'
    },
},
checked:{},
})(Checkbox)
const AcademicSkillsModal = ({ open, setIsModalOpen,getWorkExperience,workExperienceDetails }: any) => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);

    const contactUsSchema: any = Yup.object().shape({
        academic_skills: Yup.string().required('Academic skills is required.'),
    })

    const handleSuccessModel = (value: any) => {
        let token = localStorage.getItem('authToken')

        const header = {
            // "Content-Type": "application/json",
            token: token
        };

        let formdata = new FormData();
        formdata.append("system_experiences[]", value.academic_skills);
        // @ts-ignore
        formdata.append("id", workExperienceDetails.id)

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_profile/career_experiences/add_academic_skill"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PATCH"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        setIsSuccessModalOpen(true)
        setTimeout(() => {
            getWorkExperience()
          }, 3000);
    }
   const handleClose=()=>{
        setIsModalOpen(false)
    }

    const successModalClose = () => {
        setIsSuccessModalOpen(false)
    }    
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <img
src={close}

                        style={modalContactStyle.closeIcon}
                        onClick={handleClose}
                    />
                    <Typography style={modalContactStyle.contact_textContent}>
                        Academic skills
                    </Typography>
                    <Formik
                        initialValues={{academic_skills: workExperienceDetails?.attributes?.career_experience_system_experiences[0]?.system_experience ,
                            orgTermsAgree1:workExperienceDetails?.attributes?.make_key_achievements_public,
                            orgTermsAgree2:workExperienceDetails?.attributes?.currently_working_here}}
                        validationSchema={contactUsSchema}
                        onSubmit={(values: any) => handleSuccessModel(values)
                        }
                    >
                        {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
                            <form onSubmit={handleSubmit}>
                                <Box style={modalContactStyle.main}>
                                    <Box style={modalContactStyle.row}>
                                        <Box style={modalContactStyle.field}>
                                            <label>Academic skills *</label>
                                            <InputField
                                                error={errors.academic_skills && touched.academic_skills}
                                                testID="contactName"
                                                data-test-id="inputName"
                                                placeholder="Skill"
                                                fullWidth={true}
                                                id="academic_skills"
                                                name="academic_skills"
                                                variant="outlined"
                                                value={values.academic_skills}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.academic_skills && touched.academic_skills &&
                                                <div style={modalContactStyle.fieldError}>
                                                    {errors.academic_skills}
                                                </div>
                                            }
                                            <Typography style={modalContactStyle.space}>
                                                Tell us where you put this skill to use
                                            </Typography>
                                            <Typography style={modalContactStyle.spacetext}>
                                                Select any item where this skill applies
                                            </Typography>
                                            <Typography>
                                                Experience
                                            </Typography>
                                            <Box style={modalContactStyle.check}>
                                                <CheckboxStyle
                                                    id="orgTermsAgree1"
                                                    name="orgTermsAgree1"
                                                    style={{padding: "9px 5px 9px 0"}}
                                                    checked={values.orgTermsAgree1}   
                                                    value={values.orgTermsAgree1}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <Typography style={{ fontSize: "14px", color: "#696868", letterSpacing: '-0.031rem', fontFamily: "HelveticaNeue" }}>
                                                    Head cashier
                                                </Typography>
                                            </Box>
                                            <Typography>
                                                Education
                                            </Typography>
                                            <Box style={modalContactStyle.check}>
                                                <CheckboxStyle
                                                    id="orgTermsAgree2"
                                                    name="orgTermsAgree2"
                                                    style={{padding: "9px 5px 9px 0"}}
                                                    checked={values.orgTermsAgree2}
                                                    value={values.orgTermsAgree2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <Typography style={{ fontSize: "14px", color: "#696868", letterSpacing: '-0.031rem', fontFamily: "HelveticaNeue" }}>
                                                    Abcd college
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box style={modalContactStyle.row}>
                                        <Box
                                            style={modalContactStyle.submit}
                                        >
                                            <Button type="submit" variant="contained" style={modalContactStyle.submitButton}>
                                                Save
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
            {isSuccessModalOpen && <SuccessModal open={isSuccessModalOpen} successModalClose={successModalClose} setIsModalOpen={setIsModalOpen}/>}
        </>
    );
}

export default AcademicSkillsModal;



const modalContactStyle: any = {
    formStyle: {
        width: "90%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "350px",
        gap: 10,
    },
    contact_textContent: {
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "20px"
    },
    textArea: {
        marginBottom: "20px",
        justifyContent: "flex-start",
        outlineStyle: "none",
    },
    closeIcon: {
        cursor: "pointer",
        position: "absolute",
        right: "20px",
        top: "20px",
        height: "20px",
    },
    fieldError: {
        color: "#D11024",
        fontSize: "14px",
        margin: "-7px 5px 10px 0"
    },
    main: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    row: {
        display: "flex",
        gap: "24px"
    },
    name: {
        width: "426px"
    },
    field: {
        width: "876px",
        marginBottom: "18px"
    },
    submitButton: {
        height: "32px",
        backgroundColor: "#BE9E44",
        borderRadius: "8px",
        color: "#fff",
        textTransform: "none"
    },
    submit: {
        width: "876px",
        display: "flex",
        justifyContent: "end"
    },
    check: {
        display: "flex",
        alignItems: "center"
    },
    space: {
        marginBottom: "14px",
    },
    spacetext: {
        marginBottom: "14px",
        fontSize: "14px",
        color: "#696868"
    }

};

const InputField: any = withStyles({
    root: {
        padding: '10px 0',
        borderRadius: "10px",
        fontFamily: "HelveticaNeue-medium",
        "& .MuiOutlinedInput-root": {
            fontWeight: 400,
            fontFamily: "HelveticaNeue",
            fontSize: "16px",
            "& fieldset": {
                fontSize: "16px",
                borderRadius: "10px",
                borderWidth: 1,
                fontWeight: 400,
            },
        },
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23)"
            }
        },
        "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D11024"
            }
        },
        "& ::placeholder": {
            opacity: 1,
            color: "#565656",
            fontFamily: "HelveticaNeue-Medium",
        }
    }
})(TextField);
