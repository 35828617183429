import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Checkbox,
 InputLabel 
} from "@material-ui/core";
import * as Yup from "yup";
import { HighlightOff } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import TagModal from "./TagModel.web";
import { event } from "react-native-reanimated";
import WorkSucessModel from "./WorkSucessModel.web";
const close = require("../src/close.png");
import EditIcon from "@material-ui/icons/Edit";

const style: any = {
  height: 600,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  borderRadius: 14,
  boxShadow: 24,
  p: 3,
  overflow: "auto",
};

const tag: any = {
  height: 200,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  borderRadius: 14,
  boxShadow: 24,
  p: 3,
  overflow: "auto",
};

const WorkModal = ({ open, handleClose, postWorkDetails, workExperienceDetails, postWorkDetailsEdit, studentProfileDetails }: any) => {
  const [data, setData] = React.useState();
  const [image, setImage] = React.useState<string | undefined>();
  const [previewImage, setPreviewImage] = React.useState<string | undefined>();
  const [showInput, setShowInput] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [words, setWords] = React.useState([]);
  const [myInputValue, setmyInputValue] = React.useState('');
  const [characterCount, setCharacterCount] = React.useState(0)
const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);


  const handleInputChange = (event:any) => {
    const value = event.target.value;
    setmyInputValue(value);
    setCharacterCount(value.length);
  };

   const contactUsSchema: any = Yup.object().shape({
    grade: Yup.string().required("Tittle / Grade is required."),
    experience_type: Yup.string().required("Experience type is required."),
    organization_name: Yup.string().required("Organization name is required."),
    location: Yup.string().required("Location is required."),
    location_type: Yup.string().required("Location type is required."),
    start_date: Yup.string().required("Start month is required."),
    start_year: Yup.string().required("Start year is required."),
    end_date: Yup.string().required("End month is required."),
    end_year: Yup.string().required("End year is required."),
  });
  const year = new Date().getFullYear();
  const currentYear = new Date().getFullYear();
  const startYear = 2000;

  const handleButtonClick = () => {
    setShowInput(true);
  };

  const handleInputChanges = (event: any) => {
    setInputValue(event.target.value);
  };


  const successModalClose = () => {
    setIsSuccessModalOpen(false)
} 

  const handleAddImage = (event: any) => {
    setPreviewImage(URL.createObjectURL(event.target.files[0]))
    setImage(event.target.files[0])
  };

  const handleWordClose = (index: any) => {
    setWords(words.filter((_, i) => i !== index));
  };

  const addTags = () => {
    inputValue.trim().length > 0 ? 
    //@ts-ignore
    setWords([...words, inputValue.trim()])
    :"";
    setInputValue("");
    modelClose();
  };
  const modelClose = () => {
    setShowInput(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {handleClose();setPreviewImage(undefined)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <img
          src={close}
            style={modalContactStyle.closeIcon}
            onClick={() => {handleClose();setPreviewImage(undefined)}}
          />
          <Typography style={modalContactStyle.contact_textContent}>
            Add Work Experience
          </Typography>
          <Formik
            initialValues={{
              grade: workExperienceDetails?.attributes?.job_title,
              experience_type: workExperienceDetails?.attributes?.experience_type,
              organization_name: workExperienceDetails?.attributes?.company_name,
              location: workExperienceDetails?.attributes?.location,
              location_type: workExperienceDetails?.attributes?.location_type,
              start_date: workExperienceDetails?.attributes?.start_month,
              start_year: workExperienceDetails?.attributes?.start_year,
              end_date: workExperienceDetails?.attributes?.end_month,
              end_year: workExperienceDetails?.attributes?.end_year,
              message:workExperienceDetails?.attributes?.description,
              orgTermsAgree:workExperienceDetails?.attributes?.currently_working_here,
              skill:workExperienceDetails?.attributes?.career_experience_system_experiences?.system_experience
            }}
            validationSchema={contactUsSchema}
            onSubmit={(values: any) => {
              workExperienceDetails !== null ? postWorkDetailsEdit(values,image,words) : postWorkDetails(values,image,words);
              setIsSuccessModalOpen(true)
            }}
          >
            {({
              errors,
              values,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }: any) => (
              <form onSubmit={handleSubmit}>
                <Box style={modalContactStyle.main}>
                  <Box style={modalContactStyle.row}>
                    <Box style={modalContactStyle.name}>
                      <label>Title / Grade *</label>
                      <InputField
                        error={errors.grade && touched.grade}
                        testID="contactName"
                        data-test-id="inputName"
                        placeholder="Ex. Cashier"
                        fullWidth={true}
                        id="grade"
                        name="grade"
                        variant="outlined"
                        value={values.grade}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.grade && touched.grade && (
                        <div style={modalContactStyle.fieldError}>
                          {errors.grade}
                        </div>
                      )}
                    </Box>
                    <Box style={modalContactStyle.name}>
                      <label>Experience type *</label>
                      <Select
                        error={errors.experience_type && touched.experience_type}
                        id="experience_type"
                        name="experience_type"
                        variant="outlined"
                        fullWidth
                        style={modalContactStyle.select}
                        value={values.experience_type !== undefined ? values.experience_type : '' }
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem selected value="" disabled >
                          <em style={modalContactStyle.typePlaceHolder}>Ex. Intern</em>
                        </MenuItem>
                        <MenuItem value="Intern">Intern</MenuItem>
                        <MenuItem value="Scholar">Scholar</MenuItem>
                        <MenuItem value="Freelancer">Freelancer</MenuItem>
                        <MenuItem value="Entrepreneur">Entrepreneur</MenuItem>
                      </Select>
                      {errors.experience_type && touched.experience_type && (
                        <div style={modalContactStyle.fieldError}>
                          {errors.experience_type}
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box style={modalContactStyle.row}>
                    <Box style={modalContactStyle.name}>
                      <label>Organization name *</label>
                      <InputField
                        error={
                          errors.organization_name && touched.organization_name
                        }
                        testID="contactName"
                        data-test-id="inputName"
                        placeholder="Ex. Ekart store"
                        fullWidth={true}
                        id="organization_name"
                        name="organization_name"
                        variant="outlined"
                        value={values.organization_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.organization_name &&
                        touched.organization_name && (
                          <div style={modalContactStyle.fieldError}>
                            {errors.organization_name}
                          </div>
                        )}
                    </Box>
                    <Box style={modalContactStyle.w201}>
                      <label>Location *</label>
                      <Select
                        error={errors.location && touched.location}
                        id="location"
                        name="location"
                        variant="outlined"
                        fullWidth
                        style={modalContactStyle.select}
                        value={values.location}
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                     <InputLabel style={{color: "#606060"}}>Ex. London, UK</InputLabel>

                        <MenuItem selected value="" disabled />
                        <MenuItem value="Canada">Canada</MenuItem>
                        <MenuItem value="India">India</MenuItem>
                        <MenuItem value="United States">United States</MenuItem>
                        <MenuItem value="United Kingdom">
                          United Kingdom
                        </MenuItem>
                      </Select>
                      {errors.location && touched.location && (
                        <div style={modalContactStyle.fieldError}>
                          {errors.location}
                        </div>
                      )}
                    </Box>
                    <Box style={modalContactStyle.w2012}>
                      <label>Location type *</label>
                      <Select
                        error={errors.location_type && touched.location_type}
                        id="location_type"
                        name="location_type"
                        variant="outlined"
                        fullWidth
                        style={modalContactStyle.select}
                        value={values.location_type}
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                     <InputLabel style={{color: "#606060"}} >Please select</InputLabel>

                        <MenuItem selected value="" disabled />
                        <MenuItem value="Remote">Remote</MenuItem>
                        <MenuItem value="Onsite">Onsite</MenuItem>
                        <MenuItem value="Hybrid">Hybrid</MenuItem>
                      </Select>
                      {errors.location_type && touched.location_type && (
                        <div style={modalContactStyle.fieldError}>
                          {errors.location_type}
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box style={modalContactStyle.check}>
                    <Checkbox
                      id="orgTermsAgree"
                      name="orgTermsAgree"
                      style={{ color: "#bd9e44", padding: "9px 5px 9px 0" }}
                      checked={values.orgTermsAgree}
                      value={values.orgTermsAgree}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography
                      style={{
                        fontSize: "14px",
                        letterSpacing: "-0.031rem",
                        fontFamily: "HelveticaNeue",
                        color: "#5c5c5c",
                      }}
                    >
                      I am currently working in this role.
                    </Typography>
                  </Box>
                  <Box style={modalContactStyle.row}>
                    <Box style={modalContactStyle.w201}>
                      <label>Start date *</label>
                      <Select
                        error={errors.start_date && touched.start_date}
                        id="start_date"
                        labelId="demo-simple-select-helper-label"
                        name="start_date"
                        variant="outlined"
                        fullWidth
                        style={modalContactStyle.select}
                        value={values.start_date}
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                     <InputLabel style={{color: "#606060"}}>Month</InputLabel>
                        <MenuItem value="January">January</MenuItem>
                        <MenuItem value="February">February</MenuItem>
                        <MenuItem value="March">March</MenuItem>
                        <MenuItem value="April">April</MenuItem>
                        <MenuItem value="May">May</MenuItem>
                        <MenuItem value="June">June</MenuItem>
                        <MenuItem value="July">July</MenuItem>
                        <MenuItem value="August">August</MenuItem>
                        <MenuItem value="September">September</MenuItem>
                        <MenuItem value="October">October</MenuItem>
                        <MenuItem value="November">November</MenuItem>
                        <MenuItem value="December">December</MenuItem>
                      </Select>
                      {errors.start_date && touched.start_date && (
                        <div style={modalContactStyle.fieldError}>
                          {errors.start_date}
                        </div>
                      )}
                    </Box>
                    <Box style={modalContactStyle.combine}>
                      <Select
                        error={errors.start_year && touched.start_year}
                        id="start_year"
                        name="start_year"
                        variant="outlined"
                        fullWidth
                        style={{...modalContactStyle.select, margin: "26px 0 10px"}}
                        value={values.start_year}
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                         <InputLabel style={{color: "#606060"}}>Year</InputLabel>
                        <MenuItem selected value="" disabled />
                        {Array.from(new Array(currentYear - startYear + 1), (v, i) => (
                            <MenuItem key={i} value={currentYear - i}>{currentYear - i}</MenuItem>
                        ))}
                      </Select>
                      {errors.start_year && touched.start_year && (
                        <div style={modalContactStyle.fieldError}>
                          {errors.start_year}
                        </div>
                      )}
                    </Box>
                    <Box style={modalContactStyle.w201}>
                      <label>End date *</label>
                      <Select
                        error={errors.end_date && touched.end_date}
                        id="end_date"
                        name="end_date"
                        variant="outlined"
                        fullWidth
                        style={modalContactStyle.select}
                        value={values.end_date}
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                     <InputLabel style={{color: "#606060"}}>Month</InputLabel>
                       
                        <MenuItem value="January">January</MenuItem>
                        <MenuItem value="February">February</MenuItem>
                        <MenuItem value="March">March</MenuItem>
                        <MenuItem value="April">April</MenuItem>
                        <MenuItem value="May">May</MenuItem>
                        <MenuItem value="June">June</MenuItem>
                        <MenuItem value="July">July</MenuItem>
                        <MenuItem value="August">August</MenuItem>
                        <MenuItem value="September">September</MenuItem>
                        <MenuItem value="October">October</MenuItem>
                        <MenuItem value="November">November</MenuItem>
                        <MenuItem value="December">December</MenuItem>
                      </Select>
                      {errors.end_date && touched.end_date && (
                        <div style={modalContactStyle.fieldError}>
                          {errors.end_date}
                        </div>
                      )}
                    </Box>
                    <Box style={modalContactStyle.combine}>
                      <Select
                        error={errors.end_year && touched.end_year}
                        id="end_year"
                        name="end_year"
                        variant="outlined"
                        fullWidth
                        style={{...modalContactStyle.select, margin: "26px 0 10px"}}
                        value={values.end_year}
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                     <InputLabel style={{color: "#606060"}}>Year</InputLabel>

                        <MenuItem selected value="" disabled />
                        {Array.from(new Array(currentYear - startYear + 1), (v, i) => (
                            <MenuItem key={i} value={currentYear - i}>{currentYear - i}</MenuItem>
                        ))}
                      </Select>
                      {errors.end_year && touched.end_year && (
                        <div style={modalContactStyle.fieldError}>
                          {errors.end_year}
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box style={modalContactStyle.row}>
                    <Box style={modalContactStyle.field}>
                      <Box style={{ display: "flex", justifyContent: "space-between" }}>

                        <label>Description</label>
                        <p style={{ color: "#be9e44", marginTop: "1px" }}>{characterCount}/1000</p>
                      </Box>
                      <TextArea type="text" 
                      value={values.message}
                        data-test-id="inputMessage"
                        onBlur={handleBlur}
                        multiline
                        id="message"
                        name="message"
                        rows={3}
                        variant="outlined"
                        fullWidth={true}
                        onChange={handleChange}
                        maxLength={1000}
                        style={{ marginTop: "-15px" }} />
                    </Box>
                  </Box>
                  <Box style={modalContactStyle.row}>
                    <Box style={modalContactStyle.field}>
                      <label>Skills</label>
                      <Typography style={modalContactStyle.subMedia}>
                        We recommend adding your top 5 used in this role.
                        They’ll also appear in your Skills section.
                      </Typography>
                      <label htmlFor="contained">
                        <Button
                          variant="contained"
                          component="span"
                          style={modalContactStyle.uploadButton}
                          onClick={handleButtonClick}
                        >
                          + Add skill
                        </Button>
                      </label>
                      <Box style={{display:"flex",gap:"10px"}}>
                        {words.map((word, index) => (
                          <p key={index} style={modalContactStyle.addSkills}>
                            {word}
                            {
                              <HighlightOff style={{paddingLeft:"5px"}}
                                onClick={() => handleWordClose(index)}
                              />
                            }
                          </p>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box style={modalContactStyle.row}>
                    <Box style={modalContactStyle.field}>
                      <label>Media</label>
                      <Typography style={modalContactStyle.subMedia}>
                        Add or link to external Documenets,photos,sites,video and presentations.
                      </Typography>
                      <label htmlFor="images">
                        <Button variant="contained" component="span" style={modalContactStyle.uploadButton}>
                          + Add media
                          <input
                            accept="image/*"
                            style={modalContactStyle.selectProfile}
                            id="images"
                            name="images"
                            multiple
                            type="file"
                            onChange={event => handleAddImage(event)}
                          />
                        </Button>
                      </label>
                    </Box>
                  </Box>
                  {previewImage ?
                    <Box style={modalContactStyle.row}>
                      <Box
                        style={modalContactStyle.mainPhoto}
                      >
                        <label htmlFor="images" style={modalContactStyle.editMedia}>
                          <div style={modalContactStyle.uploadButton1}>
                            <EditIcon style={modalContactStyle.editIcon} />
                            <input
                              accept="image/*"
                              style={modalContactStyle.selectProfile}
                              id="images"
                              name="images"
                              multiple
                              type="file"
                              onChange={event => handleAddImage(event)}
                            />
                          </div>
                        </label>
                        <img
                          src={previewImage}
                          style={modalContactStyle.photo}
                          id="images"
                        />
                      </Box>
                      <Box
                        style={modalContactStyle.info}
                      >
                        <Typography style={modalContactStyle.medaihtxt}>My skill reward</Typography>
                        <Typography style={modalContactStyle.medaitxt}>Hobbies: {studentProfileDetails?.profile?.data?.attributes?.hobbies} Personality: {studentProfileDetails?.profile?.data?.attributes?.personality} Goal: {studentProfileDetails?.profile?.data?.attributes?.goal} </Typography>
                      </Box>
                    </Box> :
                    workExperienceDetails?.attributes?.images[0]?.url ?
                    <Box style={modalContactStyle.row}>
                      <Box
                        style={modalContactStyle.mainPhoto}
                      >
                        <label htmlFor="images" style={modalContactStyle.editMedia}>
                          <div style={modalContactStyle.uploadButton1}>
                            <EditIcon style={modalContactStyle.editIcon} />
                            <input
                              accept="image/*"
                              style={modalContactStyle.selectProfile}
                              id="images"
                              name="images"
                              multiple
                              type="file"
                              onChange={event => handleAddImage(event)}
                            />
                          </div>
                        </label>
                        <img
                          src={workExperienceDetails?.attributes?.images[0]?.url}
                          style={modalContactStyle.photo}
                          id="images"
                        />
                      </Box>
                      <Box
                        style={modalContactStyle.info}
                      >
                        <Typography style={modalContactStyle.medaihtxt}>My skill reward</Typography>
                        <Typography style={modalContactStyle.medaitxt}>Hobbies: {studentProfileDetails?.profile?.data?.attributes?.hobbies} Personality: {studentProfileDetails?.profile?.data?.attributes?.personality} Goal: {studentProfileDetails?.profile?.data?.attributes?.goal} </Typography>
                      </Box>
                    </Box> : <></>
                  }
                  <Box style={modalContactStyle.row}>
                    <Box style={modalContactStyle.submit}>
                      <Button
                        type="submit"
                        variant="contained"
                        style={modalContactStyle.submitButton}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      {showInput && (
        <Modal
          open={open}
          onClose={modelClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={tag}>
            <HighlightOff
              style={modalContactStyle.closeIcon}
              onClick={modelClose}
            />
            <Typography style={modalContactStyle.contact_textContent}>
              My Skill
            </Typography>
            <Formik
              validationSchema={contactUsSchema}
              onSubmit={addTags} initialValues={[]}            >
              {({
                errors,
                values,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
              }: any) => (
                <form onSubmit={handleSubmit}>
                  <Box style={modalContactStyle.main}>
                    <Box style={modalContactStyle.row}>
                      <Box style={modalContactStyle.field}>
                        <label>Add Skills</label>
                        <InputField
                          error={errors.skill && touched.skill}
                          testID="contactName"
                          data-test-id="inputName"
                          placeholder="Ex. Cricker"
                          fullWidth={true}
                          id="skill"
                          name="skill"
                          variant="outlined"
                          value={inputValue}
                          onChange={handleInputChanges}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Box>
                    <Box style={modalContactStyle.row}>
                      <Box style={modalContactStyle.submit}>
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={addTags}
                          style={modalContactStyle.submitButton}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Modal>
      )}
            {isSuccessModalOpen && <WorkSucessModel open={isSuccessModalOpen} successModalClose={successModalClose} setIsModalOpen={handleClose}/>}

    </>
  );
};

export default WorkModal;

const modalContactStyle: any = {
  contact_textContent: {
    fontSize: "20px",
    textAlign: "center",
    marginBottom: "20px",
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "20px",
    top: "20px",
    height: "20px",
  },
  fieldError: {
    color: "#D11024",
    fontSize: "14px",
    margin: "-7px 5px 10px 0",
  },
  addSkills: {
    backgroundColor: "#be9e44",
    width: "fit-content" ,
    padding: "5px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    flexWrap: "block",
    color: "#FFFFFF",
    // paddingLeft:"5px",
  },
    typePlaceHolder: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#565656", 
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    gap: "24px",
  },
  name: {
    width: "426px",
    color:"#26241f"
    
  },
  w201: {
    width: "201px",
    color:"#26241f"
  },
  w2012:{
    width: "201px",
  },
  field: {
    width: "876px",
    marginBottom: "10px",
    marginTop: "14px",
  },
  combine: {
    width: "201px",
    flexWrap: "wrap",
    display: "flex",
    alignItems: "end",
  },
  select: {
    width: "100%",
    borderRadius: "8px",
    margin: "10px 0",
  },
  check: {
    display: "flex",
    gap: "24px",
    alignItems: "center",
  },
  subMedia: {
    fontSize: "16px",
    color: "#565656",
    marginBottom: "14px",
  },
  selectProfile: {
    display: "none",
  },
  uploadButton: {
    height: "32px",
    border: "1px solid #BE9E44",
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "#BE9E44",
    textTransform: "none",
  },
  uploadButton1: {
    color: "#111a5d",
},
  tagButton: {
    height: "32px",
    border: "1px solid #BE9E44",
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "#BE9E44",
  },
  submitButton: {
    height: "32px",
    backgroundColor: "#BE9E44",
    borderRadius: "8px",
    color: "#fff",
    textTransform: "none",
  },
  submit: {
    width: "876px",
    display: "flex",
    justifyContent: "end",
  },
  mainPhoto: {
    width: "123px",
    height: "65px",
    borderRadius: '8px',
    position: 'relative'
},
  editMedia: {
    width: "30px",
    height: "20px",
    borderTopRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    position: 'absolute',
    right: '-7px',
    background: 'lightgrey',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
},
editIcon: {
    width: '14px'
},
  photo: {
    height: "70px",
    width: "128px",
    border: "1px solid #BE9E44",
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  input: {
    marginTop: "80px",
    marginRight: "-57px",
    marginLeft: "-101px",
    width: "101px",
  },
};

const InputField: any = withStyles({
  root: {
    padding: "10px 0",
    borderRadius: "10px",
    fontFamily: "HelveticaNeue-medium",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontFamily: "HelveticaNeue",
      fontSize: "16px",
      "& fieldset": {
        fontSize: "16px",
        borderRadius: "10px",
        borderWidth: 1,
        fontWeight: 400,
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
    "& .Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D11024",
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#565656",
      fontFamily: "HelveticaNeue-Medium",
    },
  },
})(TextField);

const TextArea: any = withStyles({
  root: {
    width: "100%",
    paddingTop: "10px",
    borderRadius: "10px",
    fontFamily: "HelveticaNeue",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontFamily: "HelveticaNeue-Medium",
      fontSize: "16px",
      "& fieldset": {
        fontSize: "16px",
        borderRadius: "10px",
        borderWidth: 1,
        fontWeight: 400,
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#565656",
      fontFamily: "HelveticaNeue",
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
    "& .Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D11024",
      },
    },
  },
})(TextField);
