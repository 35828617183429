// Customizable Area Start
import React from 'react'
import { Pagination } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
interface Props {
    count: any;
    page: any;
    handleChange?: (e:any) => void

}
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPagination-root': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
        },
        "& .MuiPaginationItem-page.Mui-selected": {
            background: "#BE9E44",
            color: "#F9F9F9",
            border: 'none'
        },
        "& .MuiPaginationItem-outlined": {
            border: "none"
        },
        "& .MuiPagination-ul": {
            position: 'relative',
            width: '100%',
            justifyContent: 'center',
        },
        "& .MuiPagination-ul li:last-child": {
            background: "transparent",
            border: "none",
            position: 'absolute',
            right: 0
        },
        "& .MuiPagination-ul li:last-child button::before": {
            content: "'Next'",
            border: "none",
            posination: "absolute",
            right: "5px",
        },
        "& .MuiPagination-ul li:first-child": {
            background: "transparent",
            border: "none",
            position: 'absolute',
            left: 0

        },
        "& .MuiPagination-ul li:first-child button::after": {
            content: "'Previous'",
            posination: "absolute",
            left: "5px",
            border: "none"
        },
        "& .MuiPagination-ul li": {
            cursor:"pointer"
        },
        "& .MuiPaginationItem-icon":{
            margin:'0px'
        }
    }
}));
export function BottomPagination(props: Props) {
    const classes = useStyles();
    return (
        <Pagination
            style={{ width: "100%" }}
            count={100}
            className={classes.root}
            page={1}
            variant="outlined"
            onChange={props.handleChange}
            
        />
    )
}
// Customizable Area End