import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import { userProfile, edit, plus} from "./assets";


// Customizable Area End

import GuestProfileSkillAfterController, { Props } from "./GuestProfileSkillAfterController.web";
import CertificationModal from "../../../components/src/CertificationModal.web";
import AchievementModal from "../../../components/src/AchievementModal.web";
import AcademicSkillsModal from "../../../components/src/AcademicSkillsModal.web";

export default class GuestProfileSkillAfter extends GuestProfileSkillAfterController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCertificationDetailsMap =(certificationData:any)=>{
    return Array.isArray(this.props.certificationDetails) && certificationData.map((element: any) => {
      return (
        <Box>
          <Box style={webStyle.profiles}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box style={{ display: "flex" }}>
                <Box>
                  <img
                    src={userProfile}
                    style={webStyle.logo}
                    id="media"
                  />
                </Box>
                <Box
                  style={{
                    color: "#717171",
                    overflow: "auto",
                  }}
                >
                  <Typography style={webStyle.aboutHead}>
                    {element.attributes.title} ({" "}
                    {element.attributes.associated_with} )
                  </Typography>
                  <Typography>
                    {element.attributes.issuer}
                  </Typography>
                  <Typography>
                    {element.attributes.issue_month}{" "}
                    {element.attributes.issue_year}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <img
                  src={edit}
                  data-test-id="btnCertifications"
                  style={webStyle.addPlusIcon}
                  onClick={() => {
                    this.setState({
                      isModalOpenCertify: true,
                      isModalCertifiationId: element,
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Divider variant="middle" />
        </Box>
      );
    })
  }

  renderWorkExperienceDetailAcademic =(workExperienceData:any)=>{
return workExperienceData.length <= 0 ? (
                <Box style={webStyle.profileAbout}>
                  <Typography
                    style={webStyle.aboutHead}
                    data-test-id="Academic"
                  >
                    Academic skills
                  </Typography>
                  <img
                    src={plus}
                    data-test-id="btnAddAcademic"
                    style={webStyle.plus}
                    onClick={() => {
                      this.setState({
                        isModalOpenAcademic: true,
                      });
                    }}
                  />
                </Box>
              ) : (
                <>
                  <Box style={webStyle.profiles}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        style={webStyle.aboutHead}
                        data-test-id="Education1"
                      >
                        Academic skills
                      </Typography>
                      <Box>
                        <img
                          src={plus}
                          data-test-id="btnAddskills"
                          style={webStyle.plus}
                          onClick={() => {
                            this.setState({
                              isModalAcademicSkillsId: null,
                              isModalOpenAcademic: true,
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{filter:'blur(5px)'}}>
                  {this.props.workExperienceDetails &&
                    Array.isArray(this.props.workExperienceDetails) &&
                    this.props.workExperienceDetails.map((element: any) => {
                      return (
                        <Box>
                          <Box style={webStyle.profiles}>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {element.attributes.career_experience_system_experiences.map(
                                (skill: any, key: any) => {
                                  return (
                                    <>
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box>
                                          <img
                                            src={userProfile}
                                            style={webStyle.logo}
                                            id="media"
                                          />
                                        </Box>
                                        <Box
                                          style={{
                                            color: "#717171",
                                            overflow: "auto",
                                          }}
                                        >
                                          <Typography
                                            key={key}
                                            style={webStyle.aboutHead}
                                          >
                                            {skill.system_experience}
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <Box>
                                        <img
                                          src={edit}
                                          data-test-id="btnAddAcademic"
                                          style={webStyle.addPlusIcon}
                                          onClick={() => {
                                            this.setState({
                                              isModalOpenAcademic: true,
                                              isModalAcademicSkillsId:
                                                element,
                                            });
                                          }}
                                        />
                                      </Box>
                                    </>
                                  );
                                }
                              )}
                            </Box>
                          </Box>
                          <Divider variant="middle" />
                        </Box>
                      );
                    })}
                    </Box>
                </>
              )
  }

  renderWorkExperienceDetailMyAchievements =(workExperienceData:any)=>{
    return workExperienceData.length <= 0 ? (
                <Box style={webStyle.profileAbout}>
                  <Typography
                    style={webStyle.aboutHead}
                    data-test-id="achievements"
                  >
                    My achievements
                  </Typography>
                  <img
                    src={plus}
                    data-test-id="btnAddAchievements"
                    style={webStyle.plus}
                    onClick={() => {
                      this.setState({
                        isModalOpenAchieve: true,
                      });
                    }}
                  />
                </Box>
              ) : (
                <>
                  <Box style={webStyle.profiles}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        style={webStyle.aboutHead}
                        data-test-id="Education2"
                      >
                        My achievements
                      </Typography>
                      <Box>
                        <img
                          src={plus}
                          data-test-id="btnAddAchievementss"
                          style={webStyle.plus}
                          onClick={() => {
                            this.setState({
                              isModalAchievementId: null,
                              isModalOpenAchieve: true,
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{filter:'blur(5px)'}}>
                  {this.props.workExperienceDetails &&
                    Array.isArray(this.props.workExperienceDetails) &&
                    this.props.workExperienceDetails.map((element: any, key: any) => {
                      return (
                        <Box>
                          <Box style={webStyle.profiles}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {element.attributes.add_key_achievements.map(
                                (skill: any, key: any) => {
                                  return (
                                    <>
                                      <Typography
                                        key={key}
                                        style={webStyle.aboutHead}
                                      >
                                        {skill}
                                      </Typography>
                                      <Box>
                                        <img
                                          src={edit}
                                          data-test-id="btnAdd"
                                          style={webStyle.addPlusIcon}
                                          onClick={() => {
                                            this.setState({
                                              isModalOpenAchieve: true,
                                              isModalAchievementId: element,
                                            });
                                          }}
                                        />
                                      </Box>
                                    </>
                                  );
                                }
                              )}
                            </Box>
                          </Box>
                          <Divider variant="middle" />
                        </Box>
                      );
                    })}
                    </Box>
                </>
              )
  }
  
  renderCertificationAndDiploamas =(certificationDetailsData:any)=>{
    return certificationDetailsData.length <= 0 ? (
                <Box style={webStyle.profileAbout}>
                  <Typography
                    style={webStyle.aboutHead}
                    data-test-id="diploams"
                  >
                    Certifications / diplomas
                  </Typography>
                  <img
                    src={plus}
                    data-test-id="btnAddDiploams"
                    style={webStyle.plus}
                    onClick={() => {
                      this.setState({
                        isModalOpenCertify: true,
                      });
                    }}
                  />
                </Box>
              ) : (
                <>
                  <Box style={webStyle.profiles}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        style={webStyle.aboutHead}
                        data-test-id="Education3"
                      >
                        Certifications / diplomas
                      </Typography>
                      <Box>
                        <img
                          src={plus}
                          data-test-id="btnAddCertifications"
                          style={webStyle.plus}
                          onClick={() => {
                            this.setState({
                              isModalCertifiationId: null,
                              isModalOpenCertify: true,
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ filter:'blur(5px)' }} >
                    {this.renderCertificationDetailsMap(this.props.certificationDetails)}
               </Box>
                </>
              )
  }

  // Customizable Area End
  
  
  render() {
    // Customizable Area Start
    
    return (
      <>
      <CertificationModal
                open={this.state.isModalOpenCertify}
                handleClose={this.handleClose}
                postCeritificationDetails={this.postCeritificationDetails}
                certificationDetails={this.state.isModalCertifiationId}
              />
              <CustomPaper style={webStyle.userDetails}>
                {this.renderCertificationAndDiploamas(this.props.certificationDetails)}
              </CustomPaper>
              <AcademicSkillsModal
                open={this.state.isModalOpenAcademic}
                handleClose={this.handleClose}
                getWorkExperience={this.props.getWorkExperience}
                workExperienceDetails={this.state.isModalAcademicSkillsId}
              />
              <CustomPaper style={webStyle.userDetails}>
               {this.renderWorkExperienceDetailAcademic(this.props.workExperienceDetails)}
              </CustomPaper>
              <AchievementModal
                open={this.state.isModalOpenAchieve}
                handleClose={this.handleClose}
                getWorkExperience={this.props.getWorkExperience}
                workExperienceDetails={this.state.isModalAchievementId}
              />
              <CustomPaper style={webStyle.userDetails}>
                {this.renderWorkExperienceDetailMyAchievements(this.props.workExperienceDetails)}
              </CustomPaper>
           </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
  
  userDetails: {
    padding: 0,
  },
  logo: {
    width: "44px",
    height: "44px",
    paddingRight: "20px",
    paddingLeft: "3px",
    borderRadius: "50%",
  },
  profileAbout: {
    height: "72px",
    padding: "0px 24px 0px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  profiles: {
    height: "auto",
    padding: "24px",
  },

  aboutHead: {
    fontSize: "20px",
    color: "#1C1C1C",
  },
  plus: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },
  addPlusIcon: {
    height: "24px",
    width: "24px",
    paddingLeft: "10px",
    cursor: "pointer",
  },
};

const CustomPaper: any = withStyles({
  root: {
    boxShadow: "unset",
    margin: "15px 0",
    border: "1px solid #e2e2e2",
    padding: "15px",
    borderRadius: "10px",
  },
})(Paper);

// Customizable Area End
