import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Divider,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { userProfile, edit, medal, plus, camrow, book, dummy ,bags} from "./assets";
import AboutModal from "../../../components/src/AboutModel.web";
import AcademicModal from "../../../components/src/AcademicModal.web";
import NavBarWithSearch from '../../ElasticSearch/src/NavBarWithSearch.web';

const theme = createTheme({
  palette: {
    primary: {
      main: "#be9e44",
      contrastText: "#fff",
    },
  },
});
const Right = require("../assets/rightArrow.png");
import AddEducationModal from "../../../components/src/AddEducationModal.web";
import CreatePostModal from "../../../components/src/CreatePostModal.web";
// Customizable Area End

import StudentProfileController, { Props } from "./StudentProfileController";
import AppFooter from "../../../components/src/AppFooter.web";
import WorkModal from "../../../components/src/WorkModal.web";
import CertificationModal from "../../../components/src/CertificationModal.web";
import AchievementModal from "../../../components/src/AchievementModal.web";
import AddCoverImgModal from "../../../components/src/AddCoverImgModal.web";
import AddProfileImgModal from "../../../components/src/AddProfileImgModal.web";
import AcademicSkillsModal from "../../../components/src/AcademicSkillsModal.web";
import ShowMoreText from "react-show-more-text";
import ViewModal from "../../../components/src/ViewModel.web";
import ShareModel from "../../../components/src/ShareModel.web";
import AddEducationModelSelect from "../../../components/src/AddEducationModelSelect.web";

export default class StudentProfileWeb extends StudentProfileController {
  // Customizable Area Start

  rendereducationDetailsImage = (item: any) => {
    return userProfile;
  };

  rendereducationDetailsSchoolname = (item: any) => {
    return item?.attributes?.school_name;
  };

  rendereducationDetailsImageHobbies = (item: any) => {
    return item?.attributes?.images?.length > 0
      ? item?.attributes?.images[0]?.url
      : dummy;
  };

  renderworkExperienceDetailsProfileImage = (item: any) => {
    return userProfile
  };

  renderWorkExperienceDetailsMap = (
    workExperienceData: any,
    isOwner: boolean
  ) => {
    return (
      this.state.workExperienceDetails &&
      Array.isArray(this.state.workExperienceDetails) &&
      workExperienceData.map((element: any) => {
        return (
          <Box>
            <Box style={webStyle.profiles}>
              <Box style={{ display: "flex" }}>
                <Box>
                  <img
                    src={this.renderworkExperienceDetailsProfileImage(element)}
                    style={webStyle.logo}
                    id="media"
                  />
                </Box>
                <Box style={{ color: "#717171", overflow: "auto" }}>
                  <Typography style={webStyle.aboutHead}>
                    {element.attributes.job_title}
                  </Typography>
                  <Typography>
                    {element.attributes.company_name} -{" "}
                    {element.attributes.experience_type}
                  </Typography>
                  <Typography>
                    {element.attributes.location}{" "}
                    {element.attributes.location_type}
                  </Typography>
                  <Typography>
                    {element.attributes.start_month}{" "}
                    {element.attributes.start_year} -{" "}
                    {element.attributes.end_month} {element.attributes.end_year}
                  </Typography>
                  <Typography>
                    Activities and societies:{" "}Hobbies: {this.state.studentProfileDetails?.profile?.data?.attributes?.hobbies} Personality: {this.state.studentProfileDetails?.profile?.data?.attributes?.personality} Goal: {this.state.studentProfileDetails?.profile?.data?.attributes?.goal} 
                  </Typography>
                  {element.attributes.career_experience_system_experiences?.length > 1 && 
                  <Typography style={{display: 'flex'}}>
                    Skill:
                    {element.attributes.career_experience_system_experiences.slice(1).map(
                      (skill: any, key: any) => {
                        return <Typography key={key}>&nbsp;{skill?.system_experience}{key !== (element.attributes.career_experience_system_experiences?.length - 2) && `, `}</Typography>
                      }
                    )}
                  </Typography>}
                  <Box style={webStyle.row}>
                    <Box style={webStyle.photo}>
                      <img
                        src={this.rendereducationDetailsImageHobbies(element)}
                        style={webStyle.awardPhoto}
                        id="media"
                      />
                    </Box>
                    <Box style={webStyle.info}>
                      <Typography style={webStyle.medaihtxt}>
                        My skill reward
                      </Typography>
                      <Typography style={webStyle.medaitxt}>
                      Hobbies: {this.state.studentProfileDetails?.profile?.data?.attributes?.hobbies} Personality: {this.state.studentProfileDetails?.profile?.data?.attributes?.personality} Goal: {this.state.studentProfileDetails?.profile?.data?.attributes?.goal} 
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {isOwner && (
                    <img
                      src={edit}
                      data-test-id="btnEducation"
                      style={webStyle.addPlusIcon}
                      onClick={() => {
                        this.setState({
                          isModalOpenWork1: true,
                          isModelWork: element,
                          editWorkExpId: element.id
                        });
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Divider variant="middle" />
          </Box>
        );
      })
    );
  };

  renderCertificationDetailsMap = (
    certificationData: any,
    isOwner: boolean
  ) => {
    return (
      Array.isArray(this.state.certificationDetails) &&
      certificationData.map((element: any) => {
        return (
          <Box>
            <Box style={webStyle.profiles}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box style={{ display: "flex" }}>
                  <Box>
                    <img src={userProfile} style={webStyle.logo} id="media" />
                  </Box>
                  <Box
                    style={{
                      color: "#717171",
                      overflow: "auto",
                    }}
                  >
                    <Typography style={webStyle.aboutHead}>
                      {element.attributes.title} ({" "}
                      {element.attributes.associated_with} )
                    </Typography>
                    <Typography>{element.attributes.issuer}</Typography>
                    <Typography>
                      {element.attributes.issue_month}{" "}
                      {element.attributes.issue_year}
                    </Typography>
                  </Box>
                </Box>
                {isOwner && (
                  <Box>
                    <img
                      src={edit}
                      data-test-id="btnCertifications"
                      style={webStyle.addPlusIcon}
                      onClick={() => {
                        this.setState({
                          isModalOpenCertify: true,
                          isModalCertifiationId: element,
                        });
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Divider variant="middle" />
          </Box>
        );
      })
    );
  };

  renderWorkExperienceDetailAcademic = (
    workExperienceData: any,
    isOwner: boolean
  ) => {
    return workExperienceData.length <= 0 ? (
      <Box style={webStyle.profileAbout}>
        <Typography style={webStyle.aboutHead} data-test-id="Academic">
          Academic skills
        </Typography>
        {isOwner && (
          <img
            src={plus}
            data-test-id="btnAddAcademic"
            style={webStyle.plus}
            onClick={() => {
              this.setState({
                isModalOpenAcademic: true,
              });
            }}
          />
        )}
      </Box>
    ) : (
      <>
        <Box style={webStyle.profiles}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            <Typography style={webStyle.aboutHead} data-test-id="Education">
              Academic skills
            </Typography>
            {isOwner && (
              <Box>
                <img
                  src={plus}
                  data-test-id="btnAddskills"
                  style={webStyle.plus}
                  onClick={() => {
                    this.setState({
                      isModalAcademicSkillsId: null,
                      isModalOpenAcademic: true,
                    });
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {this.state.workExperienceDetails &&
          Array.isArray(this.state.workExperienceDetails) &&
          this.state.workExperienceDetails.map((element: any, key: any) => {
            return (
              <Box>
                <Box style={webStyle.profiles}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    
                    {element.attributes.career_experience_system_experiences.map(
                      (skill: any, key: any) => {
                        return (
                          <>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box>
                                <img
                                  src={userProfile}
                                  style={webStyle.logo}
                                  id="media"
                                />
                              </Box>
                              <Box
                                style={{
                                  color: "#717171",
                                  overflow: "auto",
                                }}
                              >
                                <Typography
                                  key={key}
                                  style={webStyle.aboutHead}
                                >
                                  {skill.system_experience}
                                </Typography>
                              </Box>
                            </Box>
                            {isOwner && (
                              <Box>
                                <img
                                  src={edit}
                                  data-test-id="btnAddAcademic"
                                  style={webStyle.addPlusIcon}
                                  onClick={() => {
                                    this.setState({
                                      isModalOpenAcademic: true,
                                      isModalAcademicSkillsId: element,
                                    });
                                  }}
                                />
                              </Box>
                            )}
                          </>
                        );
                      }
                    )}
                  </Box>
                </Box>
                <Divider variant="middle" />
              </Box>
            );
          })}
      </>
    );
  };

  renderWorkExperienceDetailMyAchievements = (
    workExperienceData: any,
    isOwner: boolean
  ) => {
    return workExperienceData.length <= 0 ? (
      <Box style={webStyle.profileAbout}>
        <Typography style={webStyle.aboutHead} data-test-id="achievements">
          My achievements
        </Typography>
        {isOwner && (
          <img
            src={plus}
            data-test-id="btnAddAchievements"
            style={webStyle.plus}
            onClick={() => {
              this.setState({
                isModalOpenAchieve: true,
              });
            }}
          />
        )}
      </Box>
    ) : (
      <>
        <Box style={webStyle.profiles}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            <Typography style={webStyle.aboutHead} data-test-id="Education">
              My achievements
            </Typography>
            {isOwner && (
              <Box>
                <img
                  src={plus}
                  data-test-id="btnAddAchievements"
                  style={webStyle.plus}
                  onClick={() => {
                    this.setState({
                      isModalAchievementId: null,
                      isModalOpenAchieve: true,
                    });
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {this.state.workExperienceDetails &&
          Array.isArray(this.state.workExperienceDetails) &&
          this.state.workExperienceDetails.map((element: any, key: any) => {
            return (
              <Box>
                <Box style={webStyle.profiles}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {element.attributes.add_key_achievements.map(
                      (skill: any, key: any) => {
                        return (
                          <>
                            <Typography key={key} style={webStyle.aboutHead}>
                              {skill}
                            </Typography>
                            {isOwner && (
                              <Box>
                                <img
                                  src={edit}
                                  data-test-id="btnAdd"
                                  style={webStyle.addPlusIcon}
                                  onClick={() => {
                                    this.setState({
                                      isModalOpenAchieve: true,
                                      isModalAchievementId: element,
                                    });
                                  }}
                                />
                              </Box>
                            )}
                          </>
                        );
                      }
                    )}
                  </Box>
                </Box>
                <Divider variant="middle" />
              </Box>
            );
          })}
      </>
    );
  };

  renderEducationDetailsData = (educationData: any, userTrue: boolean) => {
    return educationData.length <= 0 ? (
      <>
        <Box style={webStyle.profileAbout}>
          <Typography style={webStyle.aboutHead} data-test-id="Educationlable">
            Education details
          </Typography>

          {userTrue === true && (
            <img
              src={plus}
              data-test-id="Education"
              style={webStyle.plus}
              onClick={() => {
                this.setState({
                  isModalOpen: true
                });
              }}
            />
          )}
        </Box>
      </>
    ) : (
      <>
        <Box style={webStyle.profileAbout}>
          <Typography style={webStyle.aboutHead} data-test-id="EducationLable">
            Education details
          </Typography>
          {userTrue === true && (
            <Box>
              <img
                src={plus}
                data-test-id="btnAddEducation"
                style={webStyle.plus}
                onClick={() => {
                  this.setState({
                    isModalEditId: null,
                    isModalOpen: true,
                  });
                }}
              />
            </Box>
          )}
        </Box>
        {this.renderEducationDetailsMedia(
          this.state.educationDetails,
          userTrue
        )}
      </>
    );
  };

  renderCertificationAndDiploamas = (
    certificationDetailsData: any,
    isOwner: boolean
  ) => {
    return certificationDetailsData.length <= 0 ? (
      <Box style={webStyle.profileAbout}>
        <Typography style={webStyle.aboutHead} data-test-id="diploams">
          Certifications / diplomas
        </Typography>
        {isOwner && (
          <img
            src={plus}
            data-test-id="btnAddDiploams"
            style={webStyle.plus}
            onClick={() => {
              this.setState({
                isModalOpenCertify: true,
              });
            }}
          />
        )}
      </Box>
    ) : (
      <>
        <Box style={webStyle.profiles}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            <Typography style={webStyle.aboutHead} data-test-id="Education">
              Certifications / diplomas
            </Typography>
            {isOwner && (
              <Box>
                <img
                  src={plus}
                  data-test-id="btnAddCertifications"
                  style={webStyle.plus}
                  onClick={() => {
                    this.setState({
                      isModalCertifiationId: null,
                      isModalOpenCertify: true,
                    });
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {this.renderCertificationDetailsMap(
          this.state.certificationDetails,
          plus
        )}
      </>
    );
  };

  renderWorkExperienceDetailsWork = (
    workExperienceDetailsData: any,
    isOwner: boolean
  ) => {
    return workExperienceDetailsData.length <= 0 ? (
      <>
        <Box style={webStyle.profileAbout}>
          <Typography style={webStyle.aboutHead} data-test-id="Work">
            Work experience
          </Typography>
          {isOwner && (
            <img
              src={plus}
              data-test-id="btnAddWork"
              style={webStyle.plus}
              onClick={() => {
                this.setState({
                  isModalOpenWork: true,
                });
              }}
            />
          )}
        </Box>
      </>
    ) : (
      <>
        <Box style={webStyle.profiles}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            <Typography style={webStyle.aboutHead} data-test-id="Education">
              Work experience
            </Typography>
            {isOwner && (
              <Box>
                <img
                  src={plus}
                  data-test-id="btnAddWork"
                  style={webStyle.plus}
                  onClick={() => {
                    this.setState({
                      isModelWork: null,
                      isModalOpenWork: true,
                    });
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {this.renderWorkExperienceDetailsMap(
          this.state.workExperienceDetails,
          userProfile
        )}
      </>
    );
  };
  educationDetailsButton = (educationDetailsButton: any) => {
    return (
      educationDetailsButton.length > 0 && (
        <Button
          variant="outlined"
          color="primary"
          style={{
            height: "36px",
            fontSize: "16px",
            textTransform: "none",
            borderRadius: "6px",
            marginLeft: 10,
          }}
          data-test-id="share-image"
          onClick={() => {
            this.setState({ isModalOpenShare: true });
          }}
        >
          Share
        </Button>
      )
    );
  };

  renderEducationDetailsMedia = (
    educationDetailsdata: any,
    isOwner: boolean
  ) => {
    return (
      Array.isArray(educationDetailsdata) &&
      educationDetailsdata.map((element: any, key: any) => {
        return (
          <Box>
            <Box style={webStyle.profiles}>
              <Box style={{ display: "flex" }}>
                <Box>
                  <img
                    src={this.rendereducationDetailsImage(element)}
                    style={webStyle.logo}
                    id="media"
                  />
                </Box>
                <Box style={{ color: "#717171", overflow: "auto" }}>
                  <Typography style={webStyle.aboutHead}>
                    {this.rendereducationDetailsSchoolname(element)}
                  </Typography>
                  <Typography>
                    {
                      element.attributes.educational_qualification_field_studys
                        .field_of_study
                    }
                  </Typography>
                  <Typography>
                    {element.attributes.start_month}{" "}
                    {element.attributes.start_year} -{" "}
                    {element.attributes.end_month} {element.attributes.end_year}
                  </Typography>
                  <Typography>Grade:{element.attributes.grades}</Typography>
                  <Typography>
                    Activities and societies:{" "}Hobbies: {this.state.studentProfileDetails?.profile?.data?.attributes?.hobbies} Personality: {this.state.studentProfileDetails?.profile?.data?.attributes?.personality} Goal: {this.state.studentProfileDetails?.profile?.data?.attributes?.goal} 
                  </Typography>
                  <Typography style={{marginTop: '5px'}}>
                  Hobbies: {this.state.studentProfileDetails?.profile?.data?.attributes?.hobbies} Personality: {this.state.studentProfileDetails?.profile?.data?.attributes?.personality} Goal: {this.state.studentProfileDetails?.profile?.data?.attributes?.goal} 
                  </Typography>
                  <Box style={webStyle.row}>
                    <Box style={webStyle.photo}>
                      <img
                        src={this.rendereducationDetailsImageHobbies(element)}
                        style={webStyle.awardPhoto}
                        id="media"
                      />
                    </Box>
                    <Box style={webStyle.info}>
                      <Typography style={webStyle.medaihtxt}>
                        My award pic
                      </Typography>
                      <Typography style={webStyle.medaitxt}>
                      Hobbies: {this.state.studentProfileDetails?.profile?.data?.attributes?.hobbies} Personality: {this.state.studentProfileDetails?.profile?.data?.attributes?.personality} Goal: {this.state.studentProfileDetails?.profile?.data?.attributes?.goal} 
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {isOwner && (
                  <Box>
                    <img
                      src={edit}
                      data-test-id="btnEdit"
                      style={webStyle.addPlusIcon}
                      onClick={() => {
                        this.setState({
                          isModalOpen1: true,
                          isModalEditId: element.id,
                        });
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Divider variant="middle" />
          </Box>
        );
      })
    );
  };

  closeModal = () => {
    this.setState({ openCreatePost: false });
  }

  handlePostText = (value: any) => {
    this.setState({ description: value });
    let temp: any = []
    const words = value.split(/\s+/);
    words.map((word: any) => {
      if (word.startsWith("#")) {
        // this.searchHashTag(word)
        temp.push(word)
      }
    });
    this.setState({ body: temp.join(", ") })
  }

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    
    const photo =
      this.state.studentProfileDetails?.profile?.data?.attributes?.photo ??
      dummy;
    const profile_image =
      this.state.studentProfileDetails?.profile?.data?.attributes
        ?.profile_image ?? dummy;
    const first_name =
      this.state.studentProfileDetails?.account?.data?.attributes?.first_name;
    const last_name =
      this.state.studentProfileDetails?.account?.data?.attributes?.last_name;
    const city =
      this.state.studentProfileDetails?.profile?.data?.attributes?.city;
    const country =
      this.state.studentProfileDetails?.profile?.data?.attributes?.country;
    const { userTrue } = this.state;
    const hobbiess =
      this.state.studentProfileDetails?.profile?.data?.attributes?.hobbies;
    const personality =
      this.state.studentProfileDetails?.profile?.data?.attributes?.personality;
    const goal =
      this.state.studentProfileDetails?.profile?.data?.attributes?.goal;

    const findIdInEducationDetail = Array.isArray(this.state.educationDetails)
      ? this.state.educationDetails.find(
          (el: any) => el.id === this.state.isModalEditId
        ) ?? {}
      : {};

    return (
      <ThemeProvider theme={theme}>
         <NavBarWithSearch navigation={this.props.navigation} id={''}  />
        <Container style={webStyle.container}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <CustomPaper style={webStyle.userDetails}>
                <Box style={webStyle.coverImage}>
                  <img
                    src={photo}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: '10px'
                    }}
                    alt="cover image"
                  />
                  <img
                    // src={profileImage ? profileImage : dummy}
                    src={profile_image}
                    style={webStyle.profileImage}
                  />
                  <AddCoverImgModal
                    open={this.state.isModalOpenCover}
                    handleClose={this.handleClose}
                    handleSaveImgBackground={this.patchProfileBackGroundDetails}
                  />
                  {userTrue === true && (
                    <img
                      src={edit}
                      data-test-id="btnAddCover"
                      style={webStyle.coverImageEditIcon}
                      onClick={() => {
                        this.setState({
                          isModalOpenCover: true,
                        });
                      }}
                    />
                  )}
                </Box>
                <Box style={webStyle.userDetail}>
                  <Box>
                    <Box>
                      <Typography style={webStyle.stuName}>
                        {first_name} {last_name}
                      </Typography>
                      <Typography style={webStyle.stuAddress}>
                        {this.state.studentProfileDetails?.profile?.data?.attributes.name_of_organisation === null ? 'Student' : `Student at ${this.state.studentProfileDetails?.profile?.data?.attributes?.name_of_organisation}`}
                      </Typography>
                      <Typography style={webStyle.stuHours}>
                        {city}, {country}
                      </Typography>
                      <Typography style={webStyle.stuFollowers}>
                        0 Connections
                      </Typography>
                    </Box>
                    {userTrue === true ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          data-test-id="button-profile"
                          style={{
                            height: "36px",
                            fontSize: "16px",
                            textTransform: "none",
                            borderRadius: "6px",
                            marginRight: "10px",
                          }}
                        >
                          Add profile section
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          data-test-id="button-activity"
                          style={{
                            height: "36px",
                            fontSize: "16px",
                            textTransform: "none",
                            borderRadius: "6px",
                          }}
                          onClick={() => {this.props.navigation.navigate('AllActivity')}}
                        >
                          My activity
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          data-test-id="button-profile"
                          style={{
                            height: "36px",
                            fontSize: "16px",
                            textTransform: "none",
                            borderRadius: "6px",
                            marginRight: "10px",
                          }}
                          onClick={() => this.handleConnect()}
                        >
                          + Connect
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          data-test-id="button-activity"
                          style={{
                            height: "36px",
                            fontSize: "16px",
                            textTransform: "none",
                            borderRadius: "6px",
                          }}
                        >
                          Message
                        </Button>
                      </>
                    )}
                  </Box>
                  <Box>
                    <img src={medal} style={webStyle.stuImage} />
                  </Box>
                  <AddProfileImgModal
                    open={this.state.isModalOpenProfile}
                    handleClose={this.handleClose}
                    handleSaveImg={this.patchProfileDetails}
                  />
                  {userTrue === true ? (
                    <img
                      src={edit}
                      style={webStyle.userDetailsEditIcon}
                      data-test-id="edit-image"
                      onClick={() => {
                        this.setState({
                          isModalOpenProfile: true,
                        });
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </CustomPaper>
              <CustomPaper style={{ ...webStyle.userDetails, position: 'relative' }} className="profileComplete">
                {this.state.slideProfile === true && <Box style={{
                  position: "absolute",
                  top: "52%",
                  left: "-16px",
                  zIndex: 1,
                  padding: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#d3d3d3',
                  borderRadius: '50%'
                }}>
                  <img src={Right} style={{ transform: 'rotate(180deg)', cursor: 'pointer', width: '15px', height:'15px' }} onClick={() => {
                    this.setState({ slideProfile: false }, () => {
                      this.handleSlideRight()
                    })
                  }} />
                </Box>}
                <Box style={webStyle.profileComplete}>
                  <Typography
                    style={webStyle.completeHead}
                    data-test-id="Complete"
                  >
                    Complete your profile
                  </Typography>
                  <Box style={webStyle.cardrow} className={'complete_profile'} id="comp_profile">
                    <Box style={webStyle.card}>
                      <Box style={webStyle.cardHead}>
                        <img src={camrow} style={webStyle.cardImg} />
                        <Typography style={webStyle.aboutHeadtxt}>
                          Add a profile photo to help others recognize you
                        </Typography>
                      </Box>
                      <Box style={webStyle.cardButton}>
                        <AddProfileImgModal
                          open={this.state.isModalOpenCompleteProfile}
                          handleClose={this.handleClose}
                          handleSaveImg={this.patchProfileDetails}
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          data-test-id="profile-photo-button"
                          style={{
                            height: "36px",
                            fontSize: "16px",
                            textTransform: "none",
                            borderRadius: "6px",
                          }}
                          onClick={() => {
                            this.setState({
                              isModalOpenCompleteProfile: true,
                            });
                          }}
                        >
                          + Add profile photo
                        </Button>

                      </Box>
                    </Box>
                    <Box style={webStyle.card}>
                      <Box style={webStyle.cardHead}>
                        <img src={book} style={webStyle.cardImg} />
                        <Typography style={webStyle.aboutHeadtxt}>
                          Add a education details to your profile
                        </Typography>
                      </Box>
                      <Box style={webStyle.cardButton}>
                        <AddEducationModal
                          open={this.state.isModalOpen}
                          handleModalClose={() => {this.handleModalClose()}}
                          getEducationDetails={this.getEducationDetails}
                          isModalEditId={this.state.isModalEditId}
                          educationDetails={findIdInEducationDetail}
                          studentProfileDetails={this.state.studentProfileDetails}
                          isEdit={this.state.studentProfileDetails}
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          data-test-id="button-add-education"
                          style={{
                            height: "36px",
                            fontSize: "16px",
                            textTransform: "none",
                            borderRadius: "6px",
                          }}
                          onClick={() => {
                            this.setState({
                              isModalEditId: null,
                              isModalOpen: true,
                            });
                          }}
                        >
                          + Add education
                        </Button>
                      </Box>
                    </Box>
                    <Box style={webStyle.card}>
                      <Box style={webStyle.cardHead}>
                        <img src={bags} style={webStyle.cardImg} />
                        <Typography style={webStyle.aboutHeadtxt}>
                          Add a work exp. details to your profile
                        </Typography>
                      </Box>
                      <WorkModal
                        postWorkDetails={this.postWorkDetails}
                        postWorkDetailsEdit={this.postWorkDetailsEdit}
                        open={this.state.isModalOpenWork}
                        handleClose={this.handleClose}
                        workExperienceDetails={this.state.isModelWork}
                        studentProfileDetails={this.state.studentProfileDetails}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        data-test-id="profile-photo-button"
                        style={{
                          height: "36px",
                          fontSize: "16px",
                          textTransform: "none",
                          borderRadius: "6px",
                        }}
                        onClick={() => {
                          this.setState({
                            isModelWork: null,
                            isModalOpenWork: true,
                          });
                        }}
                      >
                        + Add Work details
                      </Button>
                    </Box>
                  </Box>
                </Box>
                {this.state.slideProfile === false && <Box style={{
                  position: "absolute",
                  top: "52%",
                  right: "-16px",
                  zIndex: 1,
                  padding: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#d3d3d3',
                  borderRadius: '50%'
                }}>
                  <img src={Right} style={{ cursor: 'pointer', width: '15px', height:'15px' }} onClick={() => {
                    this.setState({ slideProfile: true }, () => {
                      this.handleSlideRight()
                    })
                  }} />
                </Box>}
              </CustomPaper>

              <CustomPaper style={webStyle.userDetails}>
                <Box style={webStyle.about}>
                  <AboutModal
                    open={this.state.isAboutModelOpen}
                    handleClose={this.handleClose}
                    studentProfileDetails={this.state.studentProfileDetails}
                    handlePatchProfileDetails={(values:any) => {this.handlePatchProfileDetails(values)}}
                  />
                  <Box style={{width:"100%"}}>
                    <Box style={{ display: "flex" ,width:"100%",justifyContent:"space-between"}}>
                      <Typography
                        style={webStyle.aboutHead}
                        data-test-id="About"
                      >
                        About
                      </Typography>
                      <Box>
                        {this.state.studentProfileDetails?.profile?.data
                          ?.attributes?.hobbies?.length > 0 ? (
                          <img
                            src={plus}
                            style={webStyle.plus}
                            onClick={() => {
                              this.setState({ isAboutModelOpen: true });
                            }}
                          />
                        ) : (
                          <img
                            src={edit}
                            style={webStyle.plus}
                            onClick={() => {
                              this.setState({ isAboutModelOpen: true });
                            }}
                          />
                        )}
                      </Box>
                    </Box>

                    <Box>
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#252525",
                          paddingTop: "5px",
                        }}
                      >
                        Hobbies: {hobbiess}
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", color: "#252525" }}
                      >
                        Personality: {personality}
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", color: "#252525" }}
                      >
                        Goal: {goal}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CustomPaper>
              <CustomPaper style={webStyle.userDetails}>
                <Box style={webStyle.profileConnect}>
                  <Box>
                    <Typography
                      style={webStyle.connectHead}
                      data-test-id="connections"
                    >
                      My Ed connections
                    </Typography>
                    <Typography style={webStyle.connectSub}>
                      See and manage your Ed connections and following.
                    </Typography>
                  </Box>
                </Box>
              </CustomPaper>
              <CustomPaper style={webStyle.userDetails}>
                <Box style={webStyle.profileActivity}>
                  <Box>
                    <Typography
                      style={webStyle.activityHead}
                      data-test-id="activity"
                    >
                      My activity
                    </Typography>
                    <Typography style={webStyle.activityCount}>
                      0 Connections
                    </Typography>
                    <Typography style={webStyle.activityDes}>
                      You haven't posted anything yet.
                    </Typography>
                    <Typography style={webStyle.activitySubDes}>
                      Recent posts you like, share or comment on will be
                      displayed here
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    data-test-id="button-start-post"
                    style={{
                      height: "36px",
                      fontSize: "16px",
                      textTransform: "none",
                      borderRadius: "6px",
                    }}
                    onClick={()=>{this.setState({openCreatePost: true})}}
                  >
                    Start a post
                  </Button>
                  <CreatePostModal
                    openCreatePost={this.state.openCreatePost}
                    closeModal={this.closeModal}
                    handlePostText={this.handlePostText}
                    uploadedImages={this.state.uploadedImages}
                    handleRemoveImage={this.handleRemoveImage.bind(this)}
                    hashTags={this.state.hashTags}
                    handleImage={this.handleImage.bind(this)}
                    AddPostCreation={this.AddPostCreation.bind(this)}
                    imgRef={this.imgRef}
                    userAccountObj={this.state?.userAccountObj}
                    loading={this.state.isLoading}
                />
                </Box>
                <hr style={{ margin: "0", borderTop: "1px #E2E2E2" }} />
                <Box style={webStyle.activitySeeAll}>
                  <Typography style={webStyle.activitySee} onClick={() => {this.props.navigation.navigate('AllActivity')}}>
                    See all activity
                  </Typography>
                </Box>
              </CustomPaper>
              <Box style={webStyle.portfolio}>
                <Typography
                  style={webStyle.portfolioHead}
                  data-test-id="portfolio"
                >
                  Ed portfolio
                </Typography>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    data-test-id="button-view"
                    style={{
                      height: "36px",
                      width: "64px",
                      fontSize: "16px",
                      textTransform: "none",
                      borderRadius: "6px",
                    }}
                    onClick={() => {
                      this.setState({ isModalOpenView: true });
                    }}
                  >
                    View
                  </Button>
                  <ViewModal
                    open={this.state.isModalOpenView}
                    handleClose={this.handleClose}
                    educationDetails={this.state.educationDetails}
                    certificationDetails={this.state.certificationDetails}
                    workExperienceDetails={this.state.workExperienceDetails}
                    studentProfileDetails={this.state.studentProfileDetails}
                  />
                  {this.educationDetailsButton(this.state.educationDetails)}
                  <ShareModel
                    copyText={this.state.copyText}
                    open={this.state.isModalOpenShare}
                    handleClose={this.handleClose}
                  />
                </Box>
              </Box>
              <AddEducationModal
                open={this.state.isModalOpen1}
                handleModalClose={() => {this.handleModalClose()}}
                getEducationDetails={this.getEducationDetails}
                isModalEditId={this.state.isModalEditId}
                educationDetails={findIdInEducationDetail}
                studentProfileDetails={this.state.studentProfileDetails}
                isEdit={this.state.studentProfileDetails}
              />
              <CustomPaper style={webStyle.userDetails}>
                {this.renderEducationDetailsData(
                  this.state.educationDetails,
                  userTrue
                )}
              </CustomPaper>
              <WorkModal
                postWorkDetails={this.postWorkDetails}
                postWorkDetailsEdit={this.postWorkDetailsEdit}
                open={this.state.isModalOpenWork1}
                handleClose={this.handleClose}
                workExperienceDetails={this.state.isModelWork}
                studentProfileDetails={this.state.studentProfileDetails}
              />
              <CustomPaper style={webStyle.userDetails}>
                {this.renderWorkExperienceDetailsWork(
                  this.state.workExperienceDetails,
                  userTrue
                )}
              </CustomPaper>
              <CertificationModal
                open={this.state.isModalOpenCertify}
                handleClose={this.handleClose}
                postCeritificationDetails={this.postCeritificationDetails}
                postCeritificationDetailsEdit={this.postCeritificationDetailsEdit}
                certificationDetails={this.state.isModalCertifiationId}
              />
              <CustomPaper style={webStyle.userDetails}>
                {this.renderCertificationAndDiploamas(
                  this.state.certificationDetails,
                  userTrue
                )}
              </CustomPaper>
              <AcademicModal
                open={this.state.isModalOpenAcademic}
                handleClose={this.handleClose}
                getWorkExperience={this.getWorkExperience}
                workExperienceDetails={this.state.isModalAcademicSkillsId}
              />
              <CustomPaper style={webStyle.userDetails}>
                {this.renderWorkExperienceDetailAcademic(
                  this.state.workExperienceDetails,
                  userTrue
                )}
              </CustomPaper>
              <AchievementModal
                open={this.state.isModalOpenAchieve}
                handleClose={this.handleClose}
                getWorkExperience={this.getWorkExperience}
                workExperienceDetails={this.state.isModalAchievementId}
              />
              <CustomPaper style={webStyle.userDetails}>
                {this.renderWorkExperienceDetailMyAchievements(
                  this.state.workExperienceDetails,
                  userTrue
                )}
              </CustomPaper>
            </Grid>
            <Grid item xs={4}>
              <Box style={webStyle.footerMenus}>
                <Typography style={webStyle.footerMenu}>
                  <NavLink
                    to={{ pathname: "/About" }}
                    style={{
                      textDecoration: "none",
                      fontSize: "10px",
                      color: "#7c7c7c",
                    }}
                  >
                    About us
                  </NavLink>
                </Typography>
                <Typography style={webStyle.footerMenu}>Contact us</Typography>
                <Typography style={webStyle.footerMenu}>
                  Privacy Policy
                </Typography>
                <Typography style={webStyle.footerMenu}>
                  Terms & Conditions
                </Typography>
              </Box>
              <Box>
                <Typography style={webStyle.footerContent}>
                  Copyright &#169;{new Date().getFullYear()} Edconnect. All
                  right reserved.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <AppFooter />
      </ThemeProvider>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start

const webStyle: any = {
  container: {
    minHeight: "calc(100vh - 270px)",
  },
  userDetails: {
    padding: 0,
  },
  coverImage: {
    height: "195px",
    position: "relative",
    background: "lightblue",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  profileImage: {
    width: "154px",
    height: "154px",
    position: "absolute",
    bottom: "-34px",
    left: "23px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  coverImageEditIcon: {
    width: "32px",
    height: "32px",
    position: "absolute",
    right: "23px",
    top: "12px",
    cursor: "pointer",
  },
  userDetail: {
    display: "flex",
    justifyContent: "space-between",
    padding: "55px 80px 26px 24px",
    position: "relative",
  },
  userDetailsEditIcon: {
    width: "32px",
    height: "32px",
    position: "absolute",
    right: "23px",
    top: "12px",
    cursor: "pointer",
  },
  stuImage: {
    width: "40px",
    height: "40px",
  },
  stuName: {
    fontSize: "24px",
    color: "#1C1C1C",
  },
  stuAddress: {
    fontSize: "17px",
    color: "#252525",
  },
  stuHours: {
    fontSize: "17px",
    color: "#7C7C7C",
  },
  stuFollowers: {
    fontSize: "17px",
    color: "#ab9045",
  },
  footerMenus: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: "15px",
  },
  footerMenu: {
    fontSize: "10px",
    color: "#7c7c7c",
    margin: "5px 35px",
    cursor: "pointer",
  },
  row: {
    display: "flex",
    gap: "24px",
    paddingTop: "10px",
  },
  photo: {
    width: "123px",
    height: "65px",
  },
  awardPhoto: {
    width: "123px",
    height: "60px",
    borderRadius: "5px",
  },
  logo: {
    width: "44px",
    height: "44px",
    marginRight: "20px",
    marginLeft: "3px",
    borderRadius: "50%",
  },
  medaihtxt: {
    fontSize: "14px",
    color: "#454545",
  },
  info: {
    width: "737px",
    height: "65px",
    color: '#717171'
  },
  medaitxt: {
    fontSize: "14px",
    color: "#1C1C1C",
  },
  footerContent: {
    fontSize: "10px",
    color: "#7c7c7c",
    marginTop: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  profileComplete: {
    height: "236px",
    padding: "24px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    overflow: "hidden",
    transition: '0.5s'
  },
  completeHead: {
    width: "-moz-available",
    fontSize: "20px",
    color: "#1C1C1C",
  },
  profileAbout: {
    height: "72px",
    padding: "0px 24px 0px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  about: {
    height: "160px",
    padding: "0px 24px 0px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  profiles: {
    height: "auto",
    padding: "24px",
  },

  aboutHead: {
    fontSize: "20px",
    color: "#1C1C1C",
  },
  plus: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },
  addPlusIcon: {
    height: "24px",
    width: "24px",
    paddingLeft: "10px",
    cursor: "pointer",
  },
  profileConnect: {
    height: "102px",
    padding: "0px 24px 0px 24px",
    display: "flex",
    alignItems: "center",
  },
  connectHead: {
    fontSize: "20px",
    color: "#1C1C1C",
  },
  connectSub: {
    fontSize: "14px",
    color: "#717171",
  },
  profileActivity: {
    height: "167px",
    padding: "24px 24px 0px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  activityHead: {
    fontSize: "20px",
    color: "#1C1C1C",
  },
  activityCount: {
    fontSize: "14px",
    color: "#BE9E44",
    marginBottom: "4px",
  },
  activityDes: {
    fontSize: "16px",
    color: "#252525",
  },
  activitySubDes: {
    fontSize: "14px",
    color: "#717171",
  },
  activitySeeAll: {
    minWidth: "65px",
    margin: "15px",
    display: "flex",
    justifyContent: "center",
  },
  activitySee: {
    fontSize: "16px",
    color: "#BE9E44",
    cursor: 'pointer'
  },
  portfolio: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "40px 0px 30px 0px",
  },
  portfolioHead: {
    fontSize: "24px",
    color: "#1C1C1C",
  },
  cardrow: {
    display: "flex",
    gap: "16px",
    transition: '0.5s'
  },
  card: {
    width: "320px",
    border: "1px solid #E4E4E4",
    borderRadius: "10px",
    padding: "24px 24px 24px 24px",
    display: "flex",
    flexWrap: "wrap",
  },
  cardHead: {
    display: "flex",
    marginBottom: "17px",
  },
  cardImg: {
    height: "41px",
    width: "41px",
    marginRight: "16px",
  },
  aboutHeadtxt: {
    fontSize: "14px",
    color: "#252525",
  },
};

const CustomPaper: any = withStyles({
  root: {
    boxShadow: "unset",
    margin: "15px 0",
    border: "1px solid #e2e2e2",
    padding: "15px",
    borderRadius: "10px",
  },
})(Paper);

// Customizable Area End
