import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall, getHeaders } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  userDetails?: any;
  isBack?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  name: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  initialRegistration: any;
  selectedTab: any;
  initialStudentDetails: any;
  initialPasswordDetails: any;
  initialOrganisationDetails: any;
  mainState: any;
  imageUploaded: any;
  selectedFile: any;
  selectedImage: any;
  isDetails: boolean;
  isLoading: boolean;
  accountError: any;
  userDetails: any;
  openTermsConditionPopup: any;
  openPrivacyPolicyPopup: any;
  termsPolicyData: any;
  organisationList: any;
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  emailVerificationApiCallId: string = "";
  userRegistrationApiCallId: string = "";
  userVerificationApiCallId: string = "";
  getTermsPolicyCallId: string = "";
  getOrganisationListApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  labelName: string;
  labelEmailAddress: string;
  txtCheckEmail: string;
  txtSentEmail: string;
  btnTxtRegister: string;
  btnTxtClose: string;
  btnTxtContinue: string;
  labelConfirmPassword: string;
  labelMiddleName: string;
  labelDob: string;
  labelLocation: string;
  labelCountry: string;
  labelOrgName: string;
  labelJobTitle: string;
  labelHeadline: string;
  labelProfilePhoto: string;
  lableAdminDetails: string;
  labelPosition: string;
  txtOrgDetails: string;
  labelNameofOrg: string;
  labelTypeofOrg: string;
  txtAddressOrg: string;
  labelStreetName: string;
  labelPincode: string;
  labelCity: string;
  labelOpeningHours: string;
  labelClosingHours: string;
  labelAbout: string;
  errorEmailRequired: string;
  errorNameRequired: string;
  errorPasswordRequired: string;
  errorPasswordLength: string;
  errorPassNumber: string;
  errorPassLower: string;
  errorPassUpper: string;
  errorPassSymbol: string;
  errorConfirmPassRequired: string;
  errorPasswordMatch: string;
  errorFirstnameRequired: string;
  errorDobRequired: string;
  errorLastnameRequired: string;
  errorCityRequired: string;
  errorCountryRequired: string;
  errorHeadlineRequired: string;
  errorAcceptTermsCondition: string;
  errorOrgNameRequired: string;
  errorOrgTypeRequired: string;
  errorStreetnameRequired: string;
  errorPincodeRequired: string;
  errorPincodeNumber: string;
  errorOpeningHoursRequired: string;
  errorClosingHoursRequired: string;

  currentCountryCode: any;
  authToken: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);


    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      name: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      selectedTab: 1,
      initialRegistration: {
        first_name: "",
        email: ""
      },
      initialStudentDetails: {
        first_name: "",
        middle_name: "",
        date_of_birth: "",
        last_name: "",
        email: "",
        city: "",
        country: "",
        name_of_organisation: "",
        job_title: "",
        headline: "",
        profilePic: "",
        studentTermsAgree: false,
      },
      initialPasswordDetails: {
        password: "",
        confirm_password: "",
      },
      initialOrganisationDetails: {
        first_name: "",
        last_name: "",
        email: "",
        position: "",
        name_of_organisation: "",
        type_of_organisation: "",
        street_name: "",
        postal_code: "",
        city: "",
        country: "",
        opening_hour: "",
        closing_hour: "",
        headline: "",
        orgProfilePic: "",
        orgTermsAgree: false,
      },
      mainState: "initial",
      imageUploaded: 0,
      selectedFile: null,
      isDetails: true,
      isLoading: false,
      accountError: "",
      selectedImage: require("../assets/user_icon.png"),
      userDetails: {},
      openTermsConditionPopup: false,
      openPrivacyPolicyPopup: false,
      termsPolicyData: {},
      organisationList: []
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.labelEmailAddress= configJSON.labelEmailAddress;
    this.labelName= configJSON.labelName;
    this.btnTxtRegister= configJSON.btnTxtRegister;
    this.txtCheckEmail= configJSON.txtCheckEmail;
    this.txtSentEmail= configJSON.txtSentEmail;
    this.btnTxtClose= configJSON.btnTxtClose;
    this.btnTxtContinue= configJSON.btnTxtContinue;
    this.labelConfirmPassword= configJSON.labelConfirmPassword;
    this.labelMiddleName= configJSON.labelMiddleName;
    this.labelDob= configJSON.labelDob;
    this.labelLocation= configJSON.labelLocation;
    this.labelCountry= configJSON.labelCountry;
    this.labelCity= configJSON.labelCity;
    this.labelOrgName= configJSON.labelOrgName;
    this.labelJobTitle= configJSON.labelJobTitle;
    this.labelHeadline= configJSON.labelHeadline;
    this.labelProfilePhoto= configJSON.labelProfilePhoto;
    this.lableAdminDetails= configJSON.lableAdminDetails;
    this.labelPosition= configJSON.labelPosition;
    this.txtOrgDetails= configJSON.txtOrgDetails;
    this.labelNameofOrg= configJSON.labelNameofOrg;
    this.labelTypeofOrg= configJSON.labelTypeofOrg;
    this.txtAddressOrg= configJSON.txtAddressOrg;
    this.labelStreetName= configJSON.labelStreetName;
    this.labelPincode= configJSON.labelPincode;
    this.labelOpeningHours= configJSON.labelOpeningHours;
    this.labelClosingHours= configJSON.labelClosingHours;
    this.labelAbout= configJSON.labelAbout;
    this.errorEmailRequired= configJSON.errorEmailRequired;
    this.errorNameRequired= configJSON.errorNameRequired;
    this.errorPasswordRequired= configJSON.errorPasswordRequired;
    this.errorPasswordLength= configJSON.errorPasswordLength;
    this.errorPassNumber= configJSON.errorPassNumber;
    this.errorPassLower= configJSON.errorPassLower;
    this.errorPassUpper= configJSON.errorPassUpper;
    this.errorPassSymbol= configJSON.errorPassSymbol;
    this.errorConfirmPassRequired= configJSON.errorConfirmPassRequired;
    this.errorPasswordMatch= configJSON.errorPasswordMatch;
    this.errorFirstnameRequired= configJSON.errorFirstnameRequired;
    this.errorDobRequired= configJSON.errorDobRequired;
    this.errorLastnameRequired= configJSON.errorLastnameRequired;
    this.errorCityRequired= configJSON.errorCityRequired;
    this.errorCountryRequired= configJSON.errorCountryRequired;
    this.errorHeadlineRequired= configJSON.errorHeadlineRequired;
    this.errorAcceptTermsCondition= configJSON.errorAcceptTermsCondition;
    this.errorOrgNameRequired= configJSON.errorOrgNameRequired;
    this.errorOrgTypeRequired= configJSON.errorOrgTypeRequired;
    this.errorStreetnameRequired= configJSON.errorStreetnameRequired;
    this.errorPincodeRequired= configJSON.errorPincodeRequired;
    this.errorPincodeNumber= configJSON.errorPincodeNumber;
    this.errorOpeningHoursRequired= configJSON.errorOpeningHoursRequired;
    this.errorClosingHoursRequired= configJSON.errorClosingHoursRequired;
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;
          this.checkArrayHolder();
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          this.checkResponseError(responseJson);

          this.parseApiCatchErrorResponse(errorReponse);
        } else if(apiRequestCallId === this.emailVerificationApiCallId){
          this.checkEmailVerificationResponse(responseJson);

          this.parseApiCatchErrorResponse(errorReponse);
        } else if(apiRequestCallId === this.userVerificationApiCallId){
          this.checkUserVerificationResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if(apiRequestCallId === this.userRegistrationApiCallId){
          this.checkUserRegistrationCallId(responseJson)
        } else if(apiRequestCallId === this.getTermsPolicyCallId){
          this.checkUserRegistrationCallId3(responseJson)
        } else if(apiRequestCallId === this.getOrganisationListApiCallId){
          this.checkUserRegistrationCallId2(responseJson)
        }  
      }
    }
    this.checkNavigationPayload(message);

    this.checkCountryCode(message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const authToken = localStorage.getItem("authToken");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.getTermsPolicy();
    this.getTermsPolicy();
    let getUserType:any = localStorage.getItem('userType');
    if(getUserType !== undefined && getUserType?.length > 0 ){
      if(getUserType === 'student'){
        this.setState({selectedTab: 0})
      }else{
        this.setState({selectedTab: 1})
      }
    }
  }

  checkEmailValidation(regexData: any) {
    if (regexData.email_validation_regexp) {
      this.emailReg = new RegExp(regexData.email_validation_regexp);
    }
  }

  checkPasswordRules(regexData:any) {
    if (regexData.password_validation_rules) {
      this.setState({
        passwordHelperText: regexData.password_validation_rules
      });
    }
  }

  checkPasswordRegex(regexData:any) {
    if (regexData.password_validation_regexp) {
      this.passwordReg = new RegExp(
        regexData.password_validation_regexp
      );
    }
  }

  checkArrayHolder() {
    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      this.checkPasswordRegex(regexData);

      this.checkPasswordRules(regexData)

      this.checkEmailValidation(regexData)
    }
  }

  checkResponseError(responseJson:any) {
    if (!responseJson.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      this.send(msg);
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  checkEmailVerificationResponse(responseJson:any) {
    this.setState({isLoading: false})
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      // this.setState({isLoading: false})
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.props.navigation.navigate("RegisterEmailSent");
    } else {
      //Check Error Response
      this.setState({isLoading: false, accountError: responseJson.errors})
    }
  }

  checkRegAccount(responseJson:any) {
    if(responseJson.type === "StudentAccount"){
      this.props.navigation.navigate("RegisterStudentDetails");
      this.setState({isLoading: false})
    }
    else if(responseJson.type === "OrganisationAccount"){
      this.props.navigation.navigate("EmailAccountOrganisationDetails");
      this.setState({isLoading: false})
    }
    else {
      this.props.navigation.navigate("Register");
      this.setState({isLoading: false})
    }
  }

  checkUserVerificationResponse(responseJson:any) {
    if (responseJson) {
            
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.checkRegAccount(responseJson);
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
      // this.sendLoginFailMessage();
    }
  }

  checkUserRegistrationCallId(responseJson:any) {
    if (responseJson && !responseJson.errors) {
      this.setState({isLoading: false})
      this.props.navigation.navigate("Login");
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      //Check Error Response
      this.checkAgeError(responseJson);
    }
  }

  checkUserRegistrationCallId2(responseJson:any) {
    if (responseJson && !responseJson.errors) {
      this.setState({isLoading: false, organisationList: responseJson})
    } else {
      //Check Error Response
      this.setState({isLoading: false})
    }
  }

  checkUserRegistrationCallId3(responseJson:any) {
    if (responseJson && !responseJson.errors) {
      this.setState({termsPolicyData: responseJson})
    }
  }



  checkAgeError(responseJson:any) {
    if(responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0].token){
      this.setState({isLoading: false, accountError: responseJson.errors[0].token})
    }
    else{
      this.setState({isLoading: false, accountError: responseJson.errors})
    }
  }

  checkSelectedCode(selectedCode:any) {
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
  }

  checkCountryCode(message:any) {
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      this.checkSelectedCode(selectedCode);
    }
  }

  checkOtpToken(otpAuthTkn:any, message:any) {
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  }

  checkNavigationPayload(message:any){
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      this.checkOtpToken(otpAuthTkn, message);
    }
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  emailVerification(values:any) {
    this.setState({isLoading: true})
    const studentData = {
      type: "student_account",
      attributes: {...values},
    };

    const orgData = {
      type: "organisation_account",
      attributes: {...values},
    };

    const header = getHeaders({token: this.authToken})
    const httpBody = {
      data: this.state.selectedTab === 0 ? studentData : orgData,
    };
    let url = configJSON.emailSentApiEndpoint;
    const getAccount = apiCall({
      httpBody: httpBody,
      header: header,
      url: url,
      httpMethod: configJSON.emailSentApiMethod,
    });

    this.emailVerificationApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleChangeTab = (event: any, newValue: any) => {
    let bool;
    if (newValue === 1) {
      bool = true;
    } else {
      bool = false
    }
    this.setState({ selectedTab: newValue });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setName = (text: string) => {
    this.setState({
      name: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableReTypePasswordField: !this.state.enableReTypePasswordField,
    });
  };

  handleUploadClick = (event:any) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function() {
      //@ts-ignore
      this.setState({
        selectedImage: [reader.result]
      });
    }.bind(this);
    console.log(url); // Would see a path?

    this.setState({
      mainState: "uploaded",
      selectedFile: event.target.files[0],
      selectedImage: event.target.files[0],
      imageUploaded: 1,
    });
  };

  continueToSetPassword = (values:any) => {
    this.setState({userDetails: values, isDetails: false})
  }

  userVerification() {
    this.setState({isLoading: true})
    let urlToken: any = this.props.navigation.getParam("token");
    let urlEmail: any = this.props.navigation.getParam("email");
    let urlName: any = this.props.navigation.getParam("name");
    localStorage.setItem("authToken", urlToken);
    localStorage.setItem("userEmail", urlEmail);
    localStorage.setItem("userFirstName", urlName);
    const httpBody = { token: urlToken };
    const header = {
      "Content-type": configJSON.contentTypeApiAddDetail
    }
    let url = configJSON.verifyUserApiEndpoint;
    const getAccount = apiCall({
      httpBody: httpBody,
      header: header,
      url: url,
      httpMethod: configJSON.verifyUserApiMethod,
    });

    this.userVerificationApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleBack(){
    this.setState({isDetails: true})
  }


  handleTermsConditionPopup = () => {
    this.setState({ openTermsConditionPopup: !this.state.openTermsConditionPopup })
  };

  handlePrivacyPolicyPopup = () => {
    this.setState({ openPrivacyPolicyPopup: !this.state.openPrivacyPolicyPopup })
  };

  getTermsPolicy = () => {
    const header = {
      "Content-type": configJSON.getEducationContentType
    }
    let url = configJSON.getTermsPolicyApiEndPoint;
    const getAccount = apiCall({
      header: header,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getTermsPolicyMethod,
    });

    this.getTermsPolicyCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  autoPopulateEmail(){
    let getEmail:any = localStorage.getItem("userEmail");
    let getFirstName:any = localStorage.getItem("userFirstName");
    if(getEmail !== null){
      this.setState(() => ({
          initialStudentDetails: {
            ...this.state.initialStudentDetails,
            email: getEmail,
            first_name: getFirstName
        }
      }),() => {
        this.setState({isLoading: false})
      })
    }
    else{
      this.setState({isLoading: false})
      this.props.navigation.navigate("Register")
    }
  }

  autoPopulateOrgEmail(){
    let getEmail:any = localStorage.getItem("userEmail");
    let getFirstName:any = localStorage.getItem("userFirstName");
    if(getEmail !== null){
      this.setState(() => ({
          initialOrganisationDetails: {
            ...this.state.initialOrganisationDetails,
            email: getEmail,
            first_name: getFirstName
        }
      }),() => {
        this.setState({isLoading: false})
      })
    }
    else{
      this.setState({isLoading: false})
      this.props.navigation.navigate("Register")
    }
  }

  getOrganisationList() {
    this.setState({isLoading: true})
    const headers = getHeaders({token: this.authToken})
    let url = configJSON.getOrganisationListApiEndpoint;
    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: url,
      httpMethod: configJSON.getOrganisationListApiMethod,
    });

    this.getOrganisationListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
  // Customizable Area End
}
