Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "HashtagGenerator";
exports.labelBodyText = "HashtagGenerator Body";

exports.btnExampleTitle = "CLICK ME";
exports.getPostsForSelectedHashtag = "GET";
exports.getFollowedHashTagListsUrl = "bx_block_posts/posts/hashtag_list";
exports.followedHashTagMethodType = "GET";
exports.followHashtagUrl = "bx_block_posts/posts/follow";
exports.unfollowHashtagUrl = "bx_block_posts/posts/unfollow"
exports.followHashtagMethodType = "POST";
exports.unfollowHashtagMethodType = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.getProfileEndPoint="bx_block_profile/profiles/get_profile";
exports.getProfileMethod="GET"
exports.followingCountMethod = "POST"
exports.followingCountUrl = "/bx_block_followers/follows/following_list"
exports.connectionCountMethod = "GET"
exports.connectionCountUrl = "/bx_block_friendlist/connects/connection_list"
// Customizable Area End